import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { ReactVideoPlayer } from "src/components";
import ReactVideoPlayerForMobile from "src/components/ReactPlayers/ReactVideoPlayerForMobile";

export default function MobilePlayerEmbedCode() {
  const params = useParams();
  const location = useLocation();
  let url =
    '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://vimeo.com/event/3401003/embed" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>';
  let timeToSeek = location?.pathname.split("=")[1];
  let fString = decodeURIComponent(timeToSeek);
  console.log(decodeURIComponent(timeToSeek), "locationlocationlocation");

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: fString,
        }}
      ></div>
    </>
  );
}
