import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { CircularProgress } from "@mui/material";
import { ReactVideoPlayer } from "src/components";
import { s3baseUrl } from "src/config/config";
import {
  filteredRecordingList,
  consultantRecordingList,
} from "src/DAL/Programmes/Programmes";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { htmlDecode } from "src/utils/convertHtml";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function ConsultantRecordings(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false);
  const [recordingsList, setRecordingsList] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterdata] = useState({});
  const { contentSettingData } = useContentSetting();
  const [pageNumber, setPageNumber] = useState(0);
  const [totlePages, setTotlePages] = useState(0);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [loadMore, setLoadMore] = useState(
    "api/program_recording/get_consultant_filtered_recording_list?page=0&limit=10"
  );

  const formik = useFormik({
    initialValues: {
      gender: "",
      category: "",
      colors: "",
      priceRange: "",
      rating: "",
    },
    onSubmit: () => {
      setOpenFilter(false);
    },
  });

  const getFilteredRecordingList = async () => {
    const result = await filteredRecordingList(
      loadMore,
      JSON.stringify(filterData)
    );
    if (result.code === 200) {
      var newArray = recordingsList.concat(result.recording);
      setRecordingsList(newArray);
      setIsLoading(false);
      setPageNumber(pageNumber + 1);
      setTotlePages(result.total_pages + 1);
      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const getConsultantRecordingList = async () => {
    setIsLoading(true);
    const result = await consultantRecordingList();
    if (result.code === 200) {
      setRecordingsList(result.recording);
      setIsLoading(false);
      setPageNumber(pageNumber + 1);
      setTotlePages(result.total_pages + 1);
      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    setLoadMore(loadMorePath);
  };

  useEffect(() => {
    getConsultantRecordingList();
  }, []);

  useEffect(() => {
    getFilteredRecordingList();
  }, [loadMore]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mb-5">
        <div className="col-12">
          {contentSettingData && contentSettingData.associate_description ? (
            <div
              className="dashboard_description"
              dangerouslySetInnerHTML={{
                __html: contentSettingData.associate_description,
              }}
            ></div>
          ) : (
            <h2>Consultant Recordings</h2>
          )}
        </div>
      </div>
      {recordingsList.length > 0 ? (
        recordingsList.map((value, index) => {
          return (
            <div className="card recordings-card h-100 secondary-background">
              <div className="row section-space">
                <div className="col-10">
                  <h3 className="programmes-heading">
                    {htmlDecode(value.title)}
                  </h3>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 text-end">
                  <p className="vault-date text-secondary">
                    {value.recording_date}
                  </p>
                </div>
              </div>
              <div className="row media-margin">
                <div className="col-12">
                  <ReactVideoPlayer url={value.video_url} />
                </div>

                {value.audio_recording && (
                  <div className="col-12 mt-3 text-center">
                    <div>
                      <audio
                        controlsList="nodownload"
                        className="w-100"
                        src={s3baseUrl + value.audio_recording}
                        controls
                      />
                    </div>
                  </div>
                )}
                <div className="col-12 section-space">
                  <p>{htmlDecode(value.short_description)}</p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <RecordNotFound title="Recordings Not Found" />
      )}
      {totlePages > pageNumber ? (
        <div className="col-12 text-center">
          <button
            className="small-contained-button mt-3"
            onClick={loadMoreData}
            disabled={isLoadingMore}
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ConsultantRecordings;
