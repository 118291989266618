import React from "react";
import { Card, FormControlLabel, Radio, Slider } from "@mui/material";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopover from "../GeneralComponents/CustomPopover";
import { useNavigate } from "react-router-dom";

function HabitsDayWiseCard({ data, handleClick }) {
  const navigate = useNavigate();
  function valuetext(value) {
    return `${value}°C`;
  }

  const handleEdit = () => { }
  const handleAgreeDelete = () => { }

  const hanleChangePage = () => {
    // navigate(`/habits-tracker/day-wise-habits`);
  };

  const MENU_OPTIONS = [
    {
      label: "Mark Complete",
      icon: "gg:details-more",
      handleClick: hanleChangePage,
    },

  ];

  return (
    <div className="row">
      {data.map((item, index) => {
        return (
          <div className={`col-12 mt-3 habits-box ${item.card_classes}`} key={index}>
            <Card
              className="side-image-mui-card"

            // onClick={
            //   handleClick
            //     ? () => {
            //       handleClick(item);
            //     }
            //     : undefined
            // }
            >
              {!item.completed && <CustomPopover menu={MENU_OPTIONS} data={item} />}
              <div className="row p-3">
                <div className="col-lg-2 col-md-3 col-sm-12">
                  <img
                    className="side-image-mui-card-image"
                    src={item.image}
                    alt={item.title}
                  />
                </div>
                <div className="col-lg-10 col-md-9 col-sm-12">
                  <h4 className="side-image-mui-card-heading">
                    {htmlDecode(item.title)}
                  </h4>
                  <p className="habits-gray-description mb-2">
                    <FormControlLabel value="male" control={<Radio checked={item.completed} />} label={`${item.completed ? "Completed" : "Pending"}`} />
                  </p>
                </div>
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
}

export default HabitsDayWiseCard;
