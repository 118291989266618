import React from "react";
import { last_section_img } from "src/assets";
import CurrentPods from "./CurrentPods";
import EventVideos from "./EventVideos";
import TwitterFeeds from "./TwitterFeeds";
import AboutSection from "./AboutSection";
import AnalyticsChart from "../MissionLevels/AnalyticsChart";
import AnalyticsChartHealth from "../MissionLevels/AnalyticsChartHealth";
import AnalyticsChartrecreational from "../MissionLevels/AnalyticsChartrecreational";

function LeftPart({
  showInfoBox,
  setShowInfoBox,
  upcomingEvents,
  currenEvents,
  userInfo,
  maximumLikeCount,
  bonusCount,
  descriptions,
  feedSetting,
  feeds_type,
  event_category,
  event_id,
  startingSoonPods,
  livePods,
  event_slug,
}) {
  return (
    <div className="community-left-sidebar">
      {feeds_type === "general" ? (
        <>
          <AboutSection
            setShowInfoBox={setShowInfoBox}
            showInfoBox={showInfoBox}
            userInfo={userInfo}
            maximumLikeCount={maximumLikeCount}
            bonusCount={bonusCount}
            descriptions={descriptions}
            feedSetting={feedSetting}
          />
          <CurrentPods
            feeds_type={feeds_type}
            livePods={livePods}
            startingSoonPods={startingSoonPods}
          />
          <TwitterFeeds
            upcomingEvents={upcomingEvents}
            currenEvents={currenEvents}
            userInfo={userInfo}
            feeds_type={feeds_type}
          />
          <img className="mt-3" src={last_section_img}></img>
        </>
      ) : feeds_type === "mission" ? (
        <>
          <div className="">
            <div className="mission-control-card  p-3">
              <div className="d-flex justify-content-between members-leader-board">
                <h4 style={{ textAlign: "left" }} className="mb-3">
                  Continued Mission
                </h4>
              </div>

              <div className="challenges-card">
                <div className="challenge-item">
                  <div className="challenge-title">Drip Sequence</div>
                  <div className="coins-slider mt-1">
                    <div
                      className="coins-slider-outer"
                      style={{
                        height: "5px",
                      }}
                    >
                      <div
                        className="coins-slider-inner"
                        style={{
                          width: "43%",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="challenge-item mt-3">
                  <div className="challenge-title">The Lost Kingdom</div>
                  <div className="coins-slider mt-1">
                    <div
                      className="coins-slider-outer"
                      style={{
                        height: "5px",
                      }}
                    >
                      <div
                        className="coins-slider-inner"
                        style={{
                          width: "75%",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="challenge-item mt-3">
                  <div className="challenge-title">New Dynamite Level</div>
                  <div className="coins-slider mt-1">
                    <div
                      className="coins-slider-outer"
                      style={{
                        height: "5px",
                      }}
                    >
                      <div
                        className="coins-slider-inner"
                        style={{
                          width: "25%",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="challenge-item mt-3">
                  <div className="challenge-title">
                    The Source Starting Point
                  </div>
                  <div className="coins-slider mt-1">
                    <div
                      className="coins-slider-outer"
                      style={{
                        height: "5px",
                      }}
                    >
                      <div
                        className="coins-slider-inner"
                        style={{
                          width: "75%",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <EventVideos
          feeds_type={feeds_type}
          event_category={event_category}
          event_id={event_id}
          event_slug={event_slug}
        />
      )}
    </div>
  );
}

export default LeftPart;
