import { Link as RouterLink, Outlet, Navigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
// components
import Logo from "../components/Logo";
import { get_app_logo } from "src/config/config";
import { Box } from "@mui/system";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "relative",
  padding: theme.spacing(1, 1, 0),
  textAlign: "end",
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(2, 2, 0),
    position: "absolute",
  },
}));

// ----------------------------------------------------------------------

export default function MinimalLayout() {
  const logo = get_app_logo();
  return (
    <>
      <HeaderStyle>
        <RouterLink to="/">
          <Box
            component="img"
            className="live-stream-logo"
            src={logo}
            sx={{ width: 200, margin: "auto" }}
          />
        </RouterLink>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
