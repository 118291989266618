import React, { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import { useNavigate } from "react-router-dom";
import EarningList from "../../components/message/EarningList";

import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import AddNewEarning from "../90DayPlan/AddNewEarning";
import { Card, Divider } from "@mui/material";

import {
  AddNineteenDay,
  EarningListApi,
  NinteenDayQuestionList,
} from "src/DAL/90Day/NinetyDay";
import { get_root_value } from "src/utils/domUtils";
import moment from "moment";
import { _get_user_from_localStorage } from "src/DAL/localStorage/localStorage";
import NumberFormat from "react-number-format";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { convertCurrencyToSign } from "src/utils/constants";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MomentUtils from "@date-io/moment";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import { useSnackbar } from "notistack";
import { CircularProgress, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import ChallengeList from "./ChallengeList";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import LessonsCardTab from "src/components/programmes/LessonsCardTab";
import MUICustomChallengeTabs from "src/components/GeneralComponents/MUICustomChallengeTabs";
import ChallengeCardTab from "./ChallengeCardTab";
import AddOrUpdateChallenge from "./AddOrUpdateChallenge";
import {
  challengeListApi,
  deleteChallengeApi,
} from "src/DAL/ChallengeApi/challengeListApi";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  // valueLabel: {
  //   "&>*": {
  //     backgroundColor: get_root_value(""),
  //   },
  // },
}));

const marks = [
  {
    value: 1,
    label: "Day 1",
  },

  {
    value: 90,
    label: "Day 90",
  },
];

function valuetext(value) {
  return `${value}°C`;
}
const theme = createTheme({});

const ChallengeListAdd = () => {
  const classes = useStyles();
  const user_profile = _get_user_from_localStorage();
  const { enqueueSnackbar } = useSnackbar();
  const [drawerState, setDrawerState] = useState(false);
  const [AddDrawerState, setAddDrawerState] = useState(false);
  const [member, setMember] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [formType, setFormType] = useState("Add");
  const [TargetAmount, setTargetAmount] = useState([
    // {
    //   value: 0,
    //   label: `${convertCurrencyToSign(
    //     user_profile.nineteen_day_plan_currency
    //   )}0`,
    // },
  ]);
  const [totalAmount, setTotalAmount] = useState("");
  const [earningList, setEarningList] = useState([]);
  const [MemberearningList, setMemberearningList] = useState([]);
  const [lastEarningDate, setLastEarningdate] = useState("");
  const [lastEarningamount, setLastEarningAmount] = useState("");
  const [TargetToAchieve, setTargetToAchieve] = useState("");
  const [days, setDays] = useState([]);
  const [daysList, setDaysList] = useState([]);
  const [sliderDay, setSliderDay] = useState(1);
  const [chartDay, setChartDay] = useState([]);
  const [chartDynamicData, setChartDynamicData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState(new Date());
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reverseEarningList, setReverseEarningList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSliderDay, setShowSliderDay] = useState("");
  const [dayCalculate, setDayCalculate] = useState("");
  const [questionMessages, setQuestionMessages] = useState([]);
  const [ChallengeDate, setChallengeDate] = useState();
  const [ChallengesList, setChallengeList] = useState([]);
  const [ChallengeData, setChallengeData] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState();

  const getChallengeList = async () => {
    setIsLoading(true);
    let todayDate = moment(new Date()).format("YYYY-MM-DD");
    const result = await challengeListApi(
      ChallengeDate ? ChallengeDate : todayDate
    );

    if (result.code == 200) {
      setChallengeList(result.challenges);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSubmitTarget = async (e) => {
    e.preventDefault();
    let postData = {
      nineteen_day_vision: {
        aim: questionMessages[9].answer,
        achive_goal: questionMessages[8].answer,
        list_achive_goal: questionMessages[7].answer,
        list_habit_or_belife: questionMessages[6].answer,
        bullet_proof_attitide: questionMessages[5].answer,
        self_image_attainment: questionMessages[4].answer,
        analyse_your_activities: questionMessages[3].answer,
        take_action: questionMessages[2].answer,
        create_winning_formula: questionMessages[1].answer,
        rinse_and_repeat: questionMessages[0].answer,
        target_amount: amount,
        start_date: date,
      },
    };
    setLoading(true);
    const result = await AddNineteenDay(postData);

    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  // const demoDay = dateDifference("11/10/2022", "12/11/22"); //difference
  const handleAgreeDelete = (challenge) => {
    console.log(challenge, "delete clicked");
    setDeleteData(challenge);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    let formData = new FormData();
    formData.append("challenge", deleteData?._id);
    formData.append("challenge_info_id", deleteData?.user_challenge_info?._id);
    console.log(...formData, "formData");
    const result = await deleteChallengeApi(formData);
    if (result.code === 200) {
      setIsLoading(false);
      getChallengeList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleEdit = (challenge) => {
    if (Object.keys(challenge?.user_challenge_info).length === 0) {
      setFormType("Add");
      setChallengeData(challenge);
      setDrawerState(true);
    } else {
      setFormType("Edit");
      setChallengeData(challenge);
      setDrawerState(true);
    }
  };
  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const handleDateChange = (value) => {
    console.log(value, "handleDateChange clicked");
    setChallengeDate(value.challengeDate);
  };

  const handleChangeInEarnings = () => {
    setReload(!reload);
  };

  useEffect(() => {
    getChallengeList();
  }, []);
  useEffect(() => {
    getChallengeList();
  }, [ChallengeDate]);

  // if (isLoading) {
  //   return <CircularProgress className={classes.loading} color="primary" />;
  // }
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const DataForCard = [
    {
      title: "Challenge 1",
      card_image:
        "https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg",
      challengeDescription: "lorem ipsum",
      status: true,
    },
    {
      title: "Challenge 2",
      card_image:
        "https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg",
      challengeDescription: "lorem ipsum",
      status: false,
    },
  ];
  const DataForCard2 = [
    {
      title: "Challenge 3",
      card_image:
        "https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg",
      challengeDescription: "lorem ipsum",
      status: true,
    },
    {
      title: "Challenge 4",
      card_image:
        "https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg",
      challengeDescription: "lorem ipsum",
      status: false,
    },
    {
      title: "Challenge 5",
      card_image:
        "https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg",
      challengeDescription: "lorem ipsum",
      status: true,
    },
  ];
  var day1 = moment(new Date()).format("Do ddd");
  var day2 = moment().add(1, "days").format("Do ddd");
  var day3 = moment().add(2, "days").format("Do ddd");
  var day4 = moment().add(3, "days").format("Do ddd");
  var day5 = moment().add(4, "days").format("Do ddd");
  var day6 = moment().add(5, "days").format("Do ddd");
  var day7 = moment().add(6, "days").format("Do ddd");
  const TABS_OPTIONS = [
    {
      title: moment(new Date()).format("Do ddd"),
      challengeDate: moment().format("YYYY-MM-DD"),
      onClick: handleDateChange,
      component: (
        <ChallengeCardTab
          challenge={ChallengesList}
          programLockStatus=""
          programSlug=""
          isLoading={isLoading}
          MENU_OPTIONS={MENU_OPTIONS}
          handleEdit={handleEdit}
        />
      ),
    },
    {
      title: day2,
      challengeDate: moment().add(1, "days").format("YYYY-MM-DD"),
      onClick: handleDateChange,
      component: (
        <ChallengeCardTab
          challenge={ChallengesList}
          programLockStatus=""
          programSlug=""
          MENU_OPTIONS={MENU_OPTIONS}
          isLoading={isLoading}
          handleEdit={handleEdit}
        />
      ),
    },
    {
      title: day3,
      challengeDate: moment().add(2, "days").format("YYYY-MM-DD"),
      onClick: handleDateChange,
      component: (
        <ChallengeCardTab
          challenge={ChallengesList}
          isLoading={isLoading}
          programLockStatus=""
          programSlug=""
          MENU_OPTIONS={MENU_OPTIONS}
          handleEdit={handleEdit}
        />
      ),
    },
    {
      title: day4,
      challengeDate: moment().add(3, "days").format("YYYY-MM-DD"),
      onClick: handleDateChange,
      component: (
        <ChallengeCardTab
          challenge={ChallengesList}
          programLockStatus=""
          programSlug=""
          isLoading={isLoading}
          MENU_OPTIONS={MENU_OPTIONS}
          handleEdit={handleEdit}
        />
      ),
    },
    {
      title: day5,
      challengeDate: moment().add(4, "days").format("YYYY-MM-DD"),
      onClick: handleDateChange,
      component: (
        <ChallengeCardTab
          challenge={ChallengesList}
          programLockStatus=""
          programSlug=""
          isLoading={isLoading}
          MENU_OPTIONS={MENU_OPTIONS}
          handleEdit={handleEdit}
        />
      ),
    },
    {
      title: day6,
      challengeDate: moment().add(5, "days").format("YYYY-MM-DD"),
      onClick: handleDateChange,
      component: (
        <ChallengeCardTab
          challenge={ChallengesList}
          programLockStatus=""
          programSlug=""
          isLoading={isLoading}
          MENU_OPTIONS={MENU_OPTIONS}
          handleEdit={handleEdit}
        />
      ),
    },
    {
      title: day7,
      challengeDate: moment().add(6, "days").format("YYYY-MM-DD"),
      onClick: handleDateChange,
      component: (
        <ChallengeCardTab
          challenge={ChallengesList}
          programLockStatus=""
          programSlug=""
          isLoading={isLoading}
          MENU_OPTIONS={MENU_OPTIONS}
          handleEdit={handleEdit}
        />
      ),
    },
  ];

  return (
    <>
      <div className="container">
        <PageDescription
          parameter="challenge_page_top_description"
          else_title="Challenges"
        />
        <div className="row mt-5">
          <div className="col-12 my-4">
            <Divider />
          </div>
          {/* <div className="col-12 text-end mb-4">
            <button
              className="small-contained-button"
              onClick={handleOpenDrawer}
            >
              Add New Challenge
            </button>
          </div> */}
          <div className="col-12 section-space">
            <MUICustomChallengeTabs
              data={TABS_OPTIONS}
              value={tabValue}
              handleChange={handleChange}
            />
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType} Challenge`}
        componentToPassDown={
          <AddOrUpdateChallenge
            reloadListing={getChallengeList}
            editData={ChallengeData}
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Challenge?"}
        handleAgree={handleDelete}
      />
    </>
  );
};

export default ChallengeListAdd;
