import { CircularProgress, IconButton } from "@mui/material";
import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import { makeStyles } from "@mui/styles";
import { update_QR_code_api } from "src/DAL/QR-Code";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useParams } from "react-router-dom";
import { scan_billing_ticket_api } from "src/DAL/WebPages/WebPages";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const CheckinUser = () => {
  const { token } = useParams();
  const classes = useStyles();
  const settings = useContentSetting();
  const { socket } = settings;
  const [isLoading, setIsLoading] = useState(false);
  const [pageState, setPageState] = useState(0);
  const [message, setMessage] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const handleScan = async (data) => {
    if (data) {
      setIsLoading(true);
      let postData = {
        ...JSON.parse(data),
        checin_token: token,
      };
      const result = await scan_billing_ticket_api(postData);
      if (result.code === 200) {
        let successData = JSON.parse(data);
        let message = successData.message
          ? successData.message
          : result.message;
        setMessage(message);
        setPageState(1);
        socket.emit("qrcode_scan_event", result);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
      setIsLoading(false);
    }
  };

  const handleError = (error) => {
    // console.error(error, "errorerrorerrorerror");
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div>
      {/* <h1>QR Scanner</h1> */}
      {pageState == 0 ? (
        <QrReader
          onResult={(result, error) => {
            if (!!result) {
              handleScan(result?.text);
            }
            if (!!error) {
              handleError(error);
            }
          }}
          constraints={{ facingMode: "environment" }}
          style={{ width: "100%" }}
        />
      ) : (
        <div className="container">
          <IconButton
            className="back-screen-button"
            onClick={() => setPageState(0)}
          >
            <ArrowBackIcon />
          </IconButton>
          <div
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default CheckinUser;
