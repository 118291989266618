import React, { useEffect, useState } from "react";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import {
  bookings_list_api,
  cancel_bookings_api,
} from "src/DAL/Booking/booking";
import {
  TIME_ZONE_CONVERSION,
  current_time_with_timezone,
} from "src/utils/constants";
import CustomMUITable from "src/components/CustomMUITable/CustomMUITable";
import UpdateBooking from "src/components/Booking/UpdateBooking";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function BookingList() {
  const { enqueueSnackbar } = useSnackbar();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [bookingsData, setBookingsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState({});
  const { userInfo } = useContentSetting();

  const get_booking_date = (booking) => {
    let booking_date_time = TIME_ZONE_CONVERSION(
      booking.start_date_time,
      "YYYY-MM-DD hh:mm A",
      booking.time_zone,
      userInfo.time_zone
    );

    const now = current_time_with_timezone(
      userInfo.time_zone,
      "YYYY-MM-DD hh:mm A"
    );
    const now_time = moment(now, "YYYY-MM-DD hh:mm A");
    const start_time = moment(booking_date_time, "YYYY-MM-DD hh:mm A");
    const diffInHours = start_time.diff(now_time, "hours");

    let booking_date = `${moment(booking_date_time).format("DD-MM-YYYY")}`;
    let booking_start_time = `${moment(booking_date_time).format("hh:mm A")}`;
    const dateTime = moment().format("YYYY-MM-DD") + " " + booking.time;
    let end_time = `${moment(dateTime, "DD-MM-YYYY hh:mm A")
      .add(booking.slot_duration, "minutes")
      .format("hh:mm A")}`;

    let booking_end_time = `${moment(booking_date_time, "DD-MM-YYYY hh:mm A")
      .add(booking.slot_duration, "minutes")
      .format("hh:mm A")}`;
    let booking_time = `${booking_start_time + " - " + booking_end_time}`;

    return {
      booking_date: booking_date,
      remaining_hours: diffInHours,
      booking_time: booking_time,
      booking_end_time: booking_end_time,
      end_time: end_time,
    };
  };

  const TABLE_HEAD = [
    {
      id: "number",
      label: "#",
      type: "number",
    },
    {
      id: "page_title",
      label: "Booking Page",
    },
    { id: "booking_date", label: "Date" },
    { id: "booking_time", label: "Time" },

    {
      id: "status",
      label: "Booking Status",
      renderData: (row) => {
        let find_status = row.booking_status_info;
        if (find_status) {
          return (
            <Tooltip title={find_status?.title}>
              <Chip
                label={find_status?.title}
                variant="contained"
                className="booking-status-chip"
                style={{
                  backgroundColor: find_status.background_color,
                  color: find_status.text_color,
                  height: "22px",
                }}
                size="small"
              />
            </Tooltip>
          );
        }
      },
    },
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
  ];

  const handleOpenEditDrawer = (value) => {
    setSelectedBooking(value);
    setEditDrawerState(true);
  };

  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const handleAgreeDelete = (value) => {
    setSelectedBooking(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await cancel_bookings_api(selectedBooking._id);
    if (result.code === 200) {
      getBookingListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const getBookingListing = async () => {
    setIsLoading(true);
    const result = await bookings_list_api(page, rowsPerPage);
    if (result.code == 200) {
      const bookings = result.bookings.map((booking) => {
        let page_title = booking?.page?.sale_page_title;
        let remaining_hours = get_booking_date(booking).remaining_hours;

        let selected_slot = {
          start_time: booking.time,
          end_time: get_booking_date(booking).end_time,
          slot_id: booking.slot_id,
          slot_duration: booking.slot_duration,
          is_booked: true,
        };

        return {
          ...booking,
          selected_slot: selected_slot,
          booking_date: get_booking_date(booking).booking_date,
          booking_time: get_booking_date(booking).booking_time,
          page_title: page_title ? page_title : "N/A",
          MENU_OPTIONS: getMenuList(
            { ...booking, remaining_hours },
            result.hours_for_pass_booking
          ),
        };
      });

      setBookingsData(bookings);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const getMenuList = (row, hours) => {
    const MENU_OPTIONS = [];
    if (
      row.remaining_hours > hours - 1 &&
      row.status !== "cancelled" &&
      row.status !== "complete"
    ) {
      MENU_OPTIONS.push(
        {
          label: "Edit Booking",
          icon: "akar-icons:edit",
          handleClick: handleOpenEditDrawer,
        },
        {
          label: "Cancel Booking",
          icon: "ant-design:delete-twotone",
          handleClick: handleAgreeDelete,
        }
      );
    }
    return MENU_OPTIONS;
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getBookingListing();
  };

  useEffect(() => {
    getBookingListing();
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row section-space">
          <div className="col-sm-12 col-md-6">
            <h2>Bookings</h2>
          </div>
          <div className="col-12 mt-3">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={bookingsData}
              className="card-with-background mt-1"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              pagePagination={true}
              text={"Total Bookings"}
            />
          </div>
        </div>

        <CustomConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to cancel this booking?"}
          handleAgree={handleDelete}
        />

        <CustomDrawer
          isOpenDrawer={editDrawerState}
          onOpenDrawer={handleOpenEditDrawer}
          onCloseDrawer={handleCloseEditDrawer}
          pageTitle="Edit Booking"
          componentToPassDown={
            <UpdateBooking
              selectedBooking={selectedBooking}
              dataList={searchFunction}
              onCloseDrawer={handleCloseEditDrawer}
            />
          }
        />
      </div>
    </>
  );
}
