import React from "react";
import { htmlDecode } from "src/utils/convertHtml";

export default function CongratulationsPopup(props) {
  const { handleOpenCreateModal, rewardData } = props;

  return (
    <>
      <div className="congratulations-popup">
        <div
          dangerouslySetInnerHTML={{
            __html: rewardData.congratulations_message,
          }}
        ></div>
        {/* <div className="text-center">
          <img src={congratulations_image} alt="" />
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo nam
          corrupti ratione reprehenderit culpa accusantium odit ipsa explicabo
          ea eum eveniet officiis officia nemo expedita odio mollitia recusandae
          possimus, aperiam laudantium quidem at, quod totam maxime nostrum!
          Mollitia sapiente cumque nobis temporibus, fugit necessitatibus
          accusamus architecto qui? Atque voluptatem in illo dignissimos! Nisi,
          officia quidem est, veritatis ducimus natus molestias eos ratione
          laudantium dolore praesentium numquam ad autem et similique incidunt
          quam perferendis tempore eligendi iusto harum ab sequi. Accusamus
          quibusdam sit vitae laboriosam voluptates ab facilis mollitia porro,
          omnis nobis deleniti eaque? Expedita sit harum laudantium natus ipsum
          ea.
        </p> */}
        {rewardData.share_thought_button_text && (
          <div class="col-12 mt-3">
            <button
              class="small-contained-button w-100"
              onClick={handleOpenCreateModal}
            >
              {htmlDecode(rewardData.share_thought_button_text)}
            </button>
          </div>
        )}
      </div>
    </>
  );
}
