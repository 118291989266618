import * as React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Modal from "@mui/material/Modal";
import { Box, Checkbox, IconButton, Radio } from "@mui/material";
import { book_image, pen_image } from "src/assets";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { htmlDecode } from "src/utils/convertHtml";

export default function ShoppingCart() {
  const navigate = useNavigate();
  const { handleUpdateCard, cartElement, setCardElement } = useContentSetting();
  console.log(cartElement, "cartElement");
  const handleCheckOut = () => {
    // navigate(`/shop/checkouts/1`);
    // handleClose();
  };

  return (
    <div className="container">
      <div className="col-12">
        <IconButton className="back-screen-button" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <div className="row">
        <div className="col-12 col-md-8">
          <div className="modal-theme p-4">
            <div className="shop-modal-box shop-modal-box-cart">
              <div className="item-title">
                <h2>Shopping Cart</h2>
                {/* <span onClick={handleClose}>x</span> */}
              </div>
              {cartElement && cartElement.length > 0
                ? cartElement.map((item, index) => {
                    return (
                      <>
                        <hr></hr>
                        <div className="row">
                          <div className="col-2 item-image">
                            <img src={pen_image} alt="" className="mx-auto" />
                          </div>
                          <div className="col-9">
                            <h4>{item.name}</h4>
                            <p className="mt-0">
                              {htmlDecode(item.detailed_description)}
                            </p>
                            <div className="d-flex">
                              <div className="me-4 mt-2">
                                <h4>$100.00 USD</h4>
                              </div>
                              <div className="item-quantity">
                                <span className="me-1">+</span>
                                <input type="text" name="" id="" value="2 x" />
                                <span className="ms-1">-</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-1 mt-2 text-end single-cross-button">
                            <span>x</span>
                          </div>
                        </div>
                        <hr></hr>
                      </>
                    );
                  })
                : ""}

              {/* <div className="">
              <div className="col-12">
                
              </div>
            </div> */}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 p-4 modal-theme">
          <h2>Summary</h2>
          <div className="d-flex justify-content-between mt-3">
            <p>Subtotal</p>
            <h4>$352.00 USD</h4>
          </div>
          <div className="d-flex justify-content-between">
            <p>Shipping</p>
            <h4>$32.00 USD</h4>
          </div>
          <hr></hr>
          <div className="d-flex justify-content-between mt-3">
            <p>Total</p>
            <h4>$382.00 USD</h4>
          </div>
          <div className="text-end subtotal">
            <button
              className="small-contained-button add-to-cart-button mt-3"
              onClick={handleCheckOut}
            >
              <>
                <ShoppingCartIcon />
                CHECKOUT
              </>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
