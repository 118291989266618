import React from "react";
import { useNavigate } from "react-router-dom";
import SideImageCard from "src/components/GeneralComponents/SideImageCard";

export default function StudySessionCardTabs({ studySession, imageLinks }) {
  const navigate = useNavigate();
  const handleClick = (study_session) => {
    navigate(
      `/study-session/${study_session.study_session_slug}/study-session-recordings`
    );
  };
  return <SideImageCard data={studySession} handleClick={handleClick} />;
}
