import { TextField } from "@mui/material";
import React, { useMemo } from "react";
import PhoneInput from "react-phone-input-2";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { htmlDecode } from "src/utils/convertHtml";
import parsePhoneNumber from "libphonenumber-js";
import GPlacesAutocomplete from "src/components/GeneralComponents/GPlacesAutocomplete";
import countryList from "react-select-country-list";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { useParams } from "react-router-dom";

const handle_format_address = (place, options) => {
  let city = "";
  let country = null;
  let state = "";
  let street2 = "";
  let zip_code = "";

  if (place.city) {
    city = place.city;
  }
  if (place.country) {
    country = options.find(
      (c) => c.value == place.country || c.label == place.country
    );
  }
  if (place.state) {
    state = place.state;
  }
  if (place.street2) {
    street2 = place.street2;
  }
  if (place.zip_code) {
    zip_code = place.zip_code;
  }

  return {
    city: city,
    country: country,
    state: state,
    street2: street2,
    zip_code: zip_code,
    street: place.formated_address,
  };
};

export default function UserInformation(props) {
  const {
    inputs,
    setInputs,
    isOrderPlaced,
    phoneData,
    billingObject,
    setBillingObject,
  } = props;

  const options = useMemo(() => countryList().getData(), []);
  const { order_id } = useParams();
  const { userInfo, shopContentSettings } = useContentSetting();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handlePlaceSelect = (place) => {
    const address = handle_format_address(place, options);
    setInputs({ ...inputs, ...address });
  };

  const handleShipping = (place) => {
    const address = handle_format_address(place, options);
    setBillingObject({ ...billingObject, ...address });
  };

  const handleChangeShipping = (name, value) => {
    setBillingObject({ ...billingObject, [name]: value });
  };

  const handleChangeBilling = (e) => {
    const { name, value } = e.target;
    setBillingObject({ ...billingObject, [name]: value });
  };

  const parsedPhoneNumber = parsePhoneNumber(`+${userInfo.contact_number}`);
  const isValidPhone = parsedPhoneNumber ? parsedPhoneNumber.isValid() : false;

  return (
    <div className="col-lg-5 col-md-12 ">
      <div className="card p-3 pb-4">
        <h4 className="mb-2 address-heading">
          {htmlDecode(shopContentSettings.billing_details_heading)}
        </h4>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              size="small"
              fullWidth
              required
              name="first_name"
              value={inputs.first_name}
              onChange={handleChange}
              disabled={isOrderPlaced || Boolean(userInfo.first_name)}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Last Name"
              variant="outlined"
              fullWidth
              required
              size="small"
              name="last_name"
              value={inputs.last_name}
              onChange={handleChange}
              disabled={isOrderPlaced || Boolean(userInfo.last_name)}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              size="small"
              type="email"
              fullWidth
              required
              name="email"
              value={inputs.email}
              onChange={handleChange}
              disabled={isOrderPlaced || Boolean(userInfo.email)}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <PhoneInput
              country={"gb"}
              inputClass="react-phone-input"
              dropdownClass="flag-input"
              inputProps={{
                name: "contact_number",
                autoComplete: "off",
              }}
              disabled={isOrderPlaced || isValidPhone}
              value={phoneData}
              enableSearch
              disableSearchIcon
              countryCodeEditable={false}
              buttonStyle={{
                border: "none",
                backgroundColor: "transparent",
              }}
              onChange={(value) => {
                handleChangeOthers("contact_number", value);
              }}
            />
            <div className="row">
              <div className="col-lg-12 col-md-6 col-sm-12 mt-4">
                <GPlacesAutocomplete
                  onSelect={handlePlaceSelect}
                  street={inputs.street}
                  disabled={isOrderPlaced}
                  handleChaAddress={(value) => {
                    handleChangeOthers("street", value);
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="City"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  name="city"
                  value={inputs?.city}
                  onChange={handleChange}
                  disabled={isOrderPlaced}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Zip Code"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  name="zip_code"
                  value={inputs?.zip_code}
                  onChange={handleChange}
                  disabled={isOrderPlaced}
                />
              </div>
              <div className="col-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="State"
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  name="state"
                  value={inputs?.state}
                  onChange={handleChange}
                  disabled={isOrderPlaced}
                />
              </div>
              <div className="col-12 mt-4 ">
                <MUIAutocomplete
                  inputLabel="Country"
                  selectedOption={inputs.country}
                  setSelectedOption={(e) => handleChangeOthers("country", e)}
                  optionsList={options}
                  autoComplete="new-password"
                  name="label"
                  size="small"
                  required={true}
                  disabled={isOrderPlaced}
                />
              </div>
            </div>
            {shopContentSettings.save_in_profile_text && !order_id && (
              <div
                className="d-flex cursor-pointer mt-3"
                onClick={() =>
                  handleChangeOthers("save_in_profile", !inputs.save_in_profile)
                }
              >
                <div
                  className={`custome-checkbox-box me-2 ${
                    inputs.save_in_profile ? "selected" : ""
                  }`}
                >
                  {inputs.save_in_profile && <i class="fa-solid fa-check"></i>}
                </div>
                <div className={`custome-radio-label`}>
                  {htmlDecode(shopContentSettings.save_in_profile_text)}
                </div>
              </div>
            )}
            <h4 className="mt-3 address-heading">
              {htmlDecode(shopContentSettings.shopping_details_heading)}
            </h4>
            <div className="col-12 mt-3">
              <div className="d-flex flex-column flex-md-row">
                <div
                  className="d-flex me-3 cursor-pointer"
                  onClick={() => {
                    if (!isOrderPlaced) {
                      handleChangeOthers("is_shipping_same_as_billing", true);
                    }
                  }}
                >
                  <div className="custome-radio-box me-2">
                    {inputs.is_shipping_same_as_billing && (
                      <div className="selected"></div>
                    )}
                  </div>
                  <div
                    className={`custome-radio-label ${
                      isOrderPlaced ? "disabled" : ""
                    }`}
                  >
                    {htmlDecode(
                      shopContentSettings.same_as_billing_detail_text
                    )}
                  </div>
                </div>
                <div
                  className="d-flex cursor-pointer mt-2 mt-md-0"
                  onClick={() => {
                    if (!isOrderPlaced) {
                      handleChangeOthers("is_shipping_same_as_billing", false);
                    }
                  }}
                >
                  <div className="custome-radio-box me-2">
                    {!inputs.is_shipping_same_as_billing && (
                      <div className="selected"></div>
                    )}
                  </div>
                  <div
                    className={`custome-radio-label ${
                      isOrderPlaced ? "disabled" : ""
                    }`}
                  >
                    {htmlDecode(shopContentSettings.send_to_friend_text)}
                  </div>
                </div>
              </div>
            </div>
            {!inputs.is_shipping_same_as_billing && (
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
                  <GPlacesAutocomplete
                    onSelect={handleShipping}
                    street={billingObject.street}
                    disabled={isOrderPlaced}
                    handleChaAddress={(e) => handleChangeShipping("street", e)}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    name="city"
                    value={billingObject?.city}
                    onChange={handleChangeBilling}
                    disabled={isOrderPlaced}
                  />
                </div>{" "}
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Zip Code"
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    name="zip_code"
                    value={billingObject?.zip_code}
                    onChange={handleChangeBilling}
                    disabled={isOrderPlaced}
                  />
                </div>
                <div className="col-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="State"
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    name="state"
                    value={billingObject?.state}
                    onChange={handleChangeBilling}
                    disabled={isOrderPlaced}
                  />
                </div>
                <div className="col-12 mt-4 ">
                  <MUIAutocomplete
                    inputLabel="Country"
                    selectedOption={billingObject.country}
                    setSelectedOption={(e) =>
                      handleChangeShipping("country", e)
                    }
                    optionsList={options}
                    autoComplete="new-password"
                    name="label"
                    size="small"
                    required={true}
                    disabled={isOrderPlaced}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
