import moment from "moment";
import { useEffect, useState } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { get_kmb_number } from "../../../utils/formatNumber";
import DashboardProgress from "./DashboardProgress";

export default function AppBugReports({ data }) {
  const { userInfo, adminTimeZone } = useContentSetting();
  const [hours, setHours] = useState(0);
  const [remainTime, setRemainTime] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const get_update_time_with_time_zone = (date) => {
    let new_date = get_date_with_user_time_zone(
      date,
      "YYYY-MM-DD HH:mm:ss",
      userInfo.time_zone,
      adminTimeZone
    );
    return Date.parse(new_date);
  };

  const deadline = `${moment(new Date()).format("YYYY-MM-DD")} ${
    data.remaining_time
  }`;
  console.log(deadline, "deadline");
  const getTime = (deadline) => {
    const time = deadline
      ? Date.parse(deadline) - get_update_time_with_time_zone(Date.now())
      : "";
    setRemainTime(time);
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="dashboard-card w-100 h-100"
      style={{ paddingBottom: `${data.type == "growth_tools" ? 0 : "24px"}` }}
    >
      <p className="title">{data.name}</p>
      <div className="d-flex justify-content-between main-box">
        <div className="text-section">
          <h3>{get_kmb_number(data.coins_count)}</h3>
          <p className="date-color">{data.coins_title}</p>
        </div>
        <DashboardProgress value={data.days_count} />
      </div>
      {data.type == "growth_tools" &&
        remainTime > 0 &&
        !data.is_completed &&
        hours <= data.hours_reminder && (
          <div className="timer-box d-flex justify-content-between">
            <p>Remaining Time</p>
            <p>{`${hours < 10 ? "0" + hours : hours}:${
              minutes < 10 ? "0" + minutes : minutes
            }:${seconds < 10 ? "0" + seconds : seconds}`}</p>
          </div>
        )}
    </div>
  );
}
