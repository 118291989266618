import React from "react";
import { useNavigate } from "react-router-dom";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import SideImageCard from "src/components/GeneralComponents/SideImageCard";

export default function ModuleCardTab({
  lessons,
  programLockStatus,
  programmesDetail,
}) {
  const navigate = useNavigate();
  const handleClick = (moduleData) => {
    if (programLockStatus) {
    } else {
      navigate(`/programmes/modules/${moduleData.program_module_slug}`);
    }
  };

  return (
    <>
      {lessons.length > 0 ? (
        <SideImageCard
          programLockStatus={programLockStatus}
          data={lessons}
          handleClick={handleClick}
          programmesDetail={programmesDetail}
        />
      ) : (
        <RecordNotFound title="Modules Not Found" />
      )}
    </>
  );
}
