import React, { useState, useEffect } from "react";
import PinterestLayout from "src/components/GeneralComponents/Pinterest";
import { gratitudesListing } from "../../DAL/Gratitude/Gratitude";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DeleteGratitudeApi } from "../../DAL/Gratitude/Gratitude";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import AddOrUpdateGratitudes from "../../components/Gratitude/AddOrUpdateGratitudes";
import PageDescription from "src/components/GeneralComponents/PageDescription";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function Gratitude() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [gratitudesData, setGratitudesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [drawerState, setDrawerState] = useState(false);
  const [gratitudeId, setGratitudeId] = useState(0);
  const [formType, setFormType] = useState("ADD");
  const [openDelete, setOpenDelete] = useState(false);
  const [editValues, setEditValues] = useState({});
  const { contentSettingData } = useContentSetting();

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
    setFormType("ADD");
  };

  const handleDetailPage = () => {};

  const getGratitudesListing = async () => {
    const result = await gratitudesListing();
    if (result.code === 200) {
      let gratitudeArray = [];
      let gratitudeObject = {};
      result.gratitude.map((gratitude, index) => {
        gratitudeObject = {
          pinteres_id: gratitude._id,
          pinterestImage: gratitude.gratitude_gallery.thumbnail_1,
          pinterestDescription: gratitude.gratitude_text,
          pinterestDate: gratitude.gratitude_date,
        };
        gratitudeArray.push(gratitudeObject);
      });
      setGratitudesData(gratitudeArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  //Getting Note In textarea
  const handleEdit = (value) => {
    setEditValues(value);
    setDrawerState(true);
    setFormType("EDIT");
  };
  const handleAgreeDelete = (value) => {
    setGratitudeId(value.pinteres_id);
    setOpenDelete(true);
  };

  //Deleting Note
  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await DeleteGratitudeApi(gratitudeId);
    if (result.code === 200) {
      getGratitudesListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGratitudesListing();
  }, []);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <PageDescription
          parameter="gratitude_description"
          else_title="Gratitude"
        />
        <div className="row">
          <div className="col-12">
            <button
              className="small-contained-button float-end mt-1 mb-4"
              onClick={handleOpenDrawer}
            >
              Add Gratitude
            </button>
          </div>
        </div>
        <div className="row">
          <PinterestLayout
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are you sure you want to delete this Gratitude?"}
            handleAgree={handleDelete}
            menuOptions={MENU_OPTIONS}
            data={gratitudesData}
            notFoundtitle="Gratitude Not Found"
            handleDetailPage={handleDetailPage}
          />
        </div>

        <CustomDrawer
          isOpenDrawer={drawerState}
          onOpenDrawer={handleOpenDrawer}
          onCloseDrawer={handleCloseDrawer}
          pageTitle={`${formType === "ADD" ? "Add" : "Edit"} Gratitude`}
          componentToPassDown={
            <AddOrUpdateGratitudes
              reloadListing={getGratitudesListing}
              editData={editValues}
              formType={formType}
              onCloseDrawer={handleCloseDrawer}
            />
          }
        />
      </div>
    </>
  );
}

export default Gratitude;
