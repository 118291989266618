import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { PodsListing } from "src/DAL/Pods/Pods";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import { s3baseUrl } from "src/config/config";
import { get_date_with_user_time_zone } from "src/utils/constants";
import moment from "moment";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function Pods({ list_type, query, setQuery }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [podsData, setPodsData] = useState([]);
  const { userInfo, adminTimeZone } = useContentSetting();
  const getFilteredData = (query) => {
    let dataToFilter = [...podsData];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };
  const getPodsListing = async () => {
    const result = await PodsListing(list_type);
    if (result.code === 200) {
      setQuery("");
      let CARD_OPTIONS = [];
      result.room.map((pod) => {
        CARD_OPTIONS.push({
          ...pod,
          card_title: pod.title,
          card_short_description:
            pod.start_date &&
            `Start Time :
          ${get_date_with_user_time_zone(
            moment(pod.start_date).format("YYYY-MM-DD") + " " + pod.start_time,
            "DD-MM-YYYY [at] hh:mm A",
            userInfo.time_zone,
            adminTimeZone
          )}`,
          card_short_description_2: pod.short_description,
          card_gray_description: `Active Members ${pod.room_user.length}`,
          card_image: s3baseUrl + pod.room_image.thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
        });
      });
      setPodsData(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = (object) => {
    navigate(`/pods/pods-detail/${object.room_slug}`);
  };

  useEffect(() => {
    getPodsListing();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      {podsData.length > 0 ? (
        <CustomMUICard
          data={getFilteredData(query)}
          handleClick={handleNavigate}
        />
      ) : (
        <RecordNotFound title="Pods Not Found" />
      )}
    </div>
  );
}

export default Pods;
