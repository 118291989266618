import { seedValue } from "faker";
import React from "react";
import { useNavigate } from "react-router";
import RequestCard from "./RequestCard";
const RequestsList = ({ data, signButton }) => {
  const navigate = useNavigate();
  const handlePage = (request_type, request_slug, payment_mode) => {
    console.log(payment_mode, "payment_modepayment_mode");
    if (request_type === "pending") {
      navigate(`/pending-request/${request_slug}`, {
        state: { payment_mode: payment_mode },
      });
    } else {
      navigate(`/active-request/${request_slug}`);
    }
  };

  return (
    <div className="col-lg-12">
      {data.map((bill, index) => {
        return (
          <RequestCard
            bill={bill}
            handlePage={handlePage}
            btn_title="View detail"
            signButton={signButton}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default RequestsList;
