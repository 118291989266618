import { invokeApi } from "../../bl_libs/invokeApi";

export const upload_document_api = async (data) => {
  const requestObj = {
    path: `app/upload_csv_file_on_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _change_currency = async (data) => {
  const requestObj = {
    path: `app/convert_currency/portal`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
