import React from "react";
import { communityMoneyImage } from "src/assets";
import { htmlDecode } from "src/utils/convertHtml";
import { fShortenNumber } from "src/utils/formatNumber";
import { Icon } from "@iconify/react";

export default function StrakPerformanceMobile(props) {
  const {
    icon,
    title,
    coins,
    day_streak,
    longest_streak,
    completed_streak,
    consistency,
    reminder,
  } = props;
  return (
    <div className="justify-align-content-xl-end px-3 streak-performance-card">
      <div className="row">
        <div className="col-7 col-lg-4">
          <img src={icon} alt="" />
          <span className="">{title}</span>
        </div>
        <div className="col-5 col-lg-4">
          <div className="streak_text mt-1">
            <img src={communityMoneyImage} alt="" />
            <span className="coins-counts">{fShortenNumber(coins)}</span>
          </div>
        </div>
        <div className="col-7 col-lg-4">
          <div className="streak_text d-flex align-items-baseline mt-3 mt-lg-0">
            <span className="me-2">{`${consistency} %`}</span>
            <p>Consistency</p>
          </div>
        </div>
        <div className="col-5 col-lg-4">
          <div className="streak_text d-flex align-items-center me-2 mt-3">
            <span className="me-2">{day_streak}</span>
            <p>
              Day <br /> Streak
            </p>
          </div>
        </div>
        <div className="col-7 col-lg-4">
          <div className="streak_text d-flex align-items-center mt-3">
            <span className="me-2">{longest_streak}</span>
            <p>
              Longest <br /> Streak
            </p>
          </div>
        </div>
        <div className="col-5 col-lg-4">
          <div className="streak_text d-flex align-items-center me-2 mt-3">
            <span className="me-2">{completed_streak}</span>
            <p>
              Days <br /> Completed
            </p>
          </div>
        </div>
        <div className="col-12">
          <div className="reminder-text">
            <i class="fa-regular fa-bell"></i>&nbsp;
            {reminder}&nbsp;
            <span className="highlight-text">{title}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
