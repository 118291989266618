import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { _list_of_member_for_chat_against_event } from "src/DAL/LiveEvents/LiveEvents";
import { s3baseUrl } from "src/config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { _get_mentorship_category_list } from "src/DAL/BonusContent/BonusContent";
import PageDescription from "src/components/GeneralComponents/PageDescription";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MentorShip() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [eventsList, setEventsList] = useState({});

  const handleClick = (object) => {
    delete object.card_button;
    navigate(`/mentorship/${object.mentorship_category_slug}`);
  };

  const getLiveEventsList = async () => {
    const result = await _get_mentorship_category_list();
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.meditation_category.map((category) => {
        CARD_OPTIONS.push({
          ...category,
          card_title: category.title,
          card_short_description: category.short_description,
          card_image: s3baseUrl + category.image.thumbnail_1,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
        });
      });
      setEventsList(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getLiveEventsList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <PageDescription
          parameter="bonus_content_description"
          else_title="Categories"
        />
        {eventsList.length > 0 ? (
          <CustomMUICard data={eventsList} handleClick={handleClick} />
        ) : (
          <RecordNotFound title="Categories Not Found" />
        )}
      </div>
    </>
  );
}
