import React from "react";
import { communityLine1 } from "src/assets";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";

export default function RewardsList({ clientDashboardSetting, reward, index }) {
  const get_title = (title, length, i) => {
    const center = parseInt(length / 2);
    const title_with_class =
      length % 2 === 0 ? (
        <div className="hz-scroll-title-even pt-1">{htmlDecode(title)}</div>
      ) : (
        <div className="hz-scroll-title-odd pt-1">{htmlDecode(title)}</div>
      );

    if (i === center) {
      return title_with_class;
    }
  };
  return (
    <>
      {reward.reward_list.map((reward_object, i) => {
        return (
          <div className="hz-scroll-div" key={i}>
            {get_title(
              clientDashboardSetting[`${reward.level_name}_text`],
              reward.reward_list.length,
              i
            )}

            <div className="img-back-border">
              <img
                src={
                  reward_object.is_user_reward === true
                    ? s3baseUrl + reward_object.icon_with_border.thumbnail_1
                    : s3baseUrl + reward_object.icon_without_border.thumbnail_1
                }
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="mt-3">
              <button>{htmlDecode(reward_object.title)}</button>
              {index !== 0 && i === 0 && (
                <div className="img-border">
                  <img src={communityLine1} alt="" />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}
