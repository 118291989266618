import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { warning_icon } from "src/assets";
import { get_date_with_user_time_zone } from "src/utils/constants";

export default function EventConfigCard({ data }) {
  const { userInfo, adminTimeZone } = useContentSetting();
  const [hours, setHours] = useState(0);
  const [remainTime, setRemainTime] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  let datetime = data?.event_date
    ? moment(data?.event_date).format("YYYY-MM-DD") + " " + data?.event_time
    : "";

  const handleNavigate = () => {
    window.open(data?.button_link);
  };
  const get_update_time_with_time_zone = (date) => {
    let new_date = get_date_with_user_time_zone(
      date,
      "YYYY-MM-DD HH:mm:ss",
      userInfo.time_zone,
      adminTimeZone
    );
    return Date.parse(new_date);
  };
  const deadline = `${moment(data?.event_date).format("YYYY-MM-DD")} ${
    data?.event_time
  }`;
  const getTime = (deadline) => {
    const time = deadline
      ? Date.parse(deadline) - get_update_time_with_time_zone(Date.now())
      : "";
    setRemainTime(time);
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };
  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="dashboard-notification-card">
      {/* <div className="d-flex">
        <img src={warning_icon} alt="" className="warning-icon" />
        {remainTime > 0 ? (
          <span className="ps-2 event-config-time">
            {`${hours < 10 ? "0" + hours : hours}:${
              minutes < 10 ? "0" + minutes : minutes
            }:${seconds < 10 ? "0" + seconds : seconds}`}
          </span>
        ) : (
          <span className="ps-2 event-config-time">00:00:00</span>
        )}
      </div> */}
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: data.title,
          }}
          className="ps-2 w-100 mb-2"
        ></div>
      </div>
      <div className="col-12 text-end">
        {data.button_text && (
          <button
            className="small-contained-button text-end"
            onClick={handleNavigate}
          >
            {data.button_text ? data.button_text : "Complete Now"}
          </button>
        )}
      </div>
    </div>
  );
}
