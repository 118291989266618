import React, { useEffect, useState } from "react";
import {
  GoogleOAuthProvider,
  hasGrantedAnyScopeGoogle,
} from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import LoginByGoogle from "./LoginByGoogle";
import { hasGrantedAllScopesGoogle } from "@react-oauth/google";
import { connect_with_google } from "src/DAL/Calender/Calender";
import { useSnackbar } from "notistack";
import { get_site_setting } from "src/DAL/Payment/Payment";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  member_init_with_token_api,
  remove_google_access_api,
} from "src/DAL/ContentSetting/ContentSetting";
import GoogleIcon from "@mui/icons-material/Google";
import { Avatar, Tooltip } from "@mui/material";
import CustomConfirmation from "./GeneralComponents/CustomConfirmation";
import CalendarIcon from "react-calendar-icon";
import { google_calendar_icon } from "src/assets";

const dateOptions = {
  header: { weekday: "short", outerWidth: "20px" },
  footer: { month: "short" },
  value: { day: "2-digit" },

  locale: "nl",
};

const GoogleAuth = () => {
  const { googleClientId } = useContentSetting();
  const responseGoogle = (response) => {
    console.log(response, "resssssssssssss"); // Handle the response from Google
    if (response.error === "popup_closed_by_user") {
      // Handle user closing the login popup
      return;
    }

    if (response.accessToken) {
      // Send the access token to your server for further processing
      // and requesting calendar permissions
      const accessToken = response.credential;
      requestCalendarPermissions(accessToken);
    }
  };
  console.log(googleClientId, "googleClientIdgoogleClientId");
  const requestCalendarPermissions = (accessToken) => {
    const options = {
      scope: "https://www.googleapis.com/auth/calendar",
      access_type: "offline",
      include_granted_scopes: true,
    };
  };
  // zohaib bhai key   --- 48261507008-nhiup1us5qbdq528d5j0gpe0ac87b27t.apps.googleusercontent.com
  // sir mohsin key   --- 6648837183-lnc45jan2c0g7rd07tbrrlu4s9d5d459.apps.googleusercontent.com

  return (
    <div>
      <GoogleOAuthProvider clientId={googleClientId}>
        {/* <GoogleLogin onSuccess={responseGoogle} onFailure={responseGoogle} /> */}
        <Hook />
        {/* <LoginByGoogle /> */}
      </GoogleOAuthProvider>
    </div>
  );
};

export default GoogleAuth;

function Hook() {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useContentSetting();
  const { handleUserInfo, handleConsultantInfo, googleClientId } =
    useContentSetting();
  const [openDelete, setOpenDelete] = useState(false);

  const getContentSetting = async () => {
    const result = await member_init_with_token_api();
    console.log(result, "member_init_with_token_api");
    if (result.code === 200) {
      if (result.content_setting === null) {
        handleUserInfo({});
        handleConsultantInfo({});
        return;
      }
      localStorage.setItem(`user_data`, JSON.stringify(result.member));
      localStorage.setItem(
        `consultant_data`,
        JSON.stringify(result.member_consultant)
      );
      handleUserInfo(result.member);
      handleConsultantInfo(result.member_consultant);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const connectGoogle = async (token) => {
    let postData = {
      code: token,
    };
    const result = await connect_with_google(postData);
    if (result.code == 200) {
      console.log(result, "from api ");
      enqueueSnackbar("Connected With Google.", { variant: "success" });
      getContentSetting();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => connectGoogle(codeResponse.code),
    flow: "auth-code",
    scope:
      "openid email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
  });

  const handleRemove = async () => {
    const result = await remove_google_access_api();
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setOpenDelete(false);
      getContentSetting();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDelete(false);
    }
  };
  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
  };
  // useGoogleLogin({
  //   onSuccess: (codeResponse) => console.log(codeResponse),
  //   scope:
  //     "openid email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
  // });
  // useEffect(() => {}, []);

  return (
    <div>
      {userInfo.is_google_signin ? (
        <div className="d-flex me-2 position-relative">
          <Tooltip title="Change Access">
            <Avatar
              className="pointer"
              alt={userInfo?.google_account_info?.given_name}
              src={userInfo?.google_account_info?.picture}
              onClick={login}
            />
          </Tooltip>

          <Tooltip title="Remove Access">
            <span className="remove-google-account" onClick={handleAgreeDelete}>
              x
            </span>
          </Tooltip>
          {
            <div>
              <p className="mb-0 ms-2 me-2">
                {userInfo?.google_account_info?.given_name +
                  " " +
                  userInfo?.google_account_info?.family_name}
              </p>

              <p className="ms-2">{userInfo?.google_account_info?.email}</p>
            </div>
          }
          {/* <button
            className="small-contained-button me-2 remove-access-google"
            onClick={handleRemove}
          >
            {
              <>
                <Tooltip title="Remove Access">
                  <GoogleIcon />
                </Tooltip>
              </>
            }
          </button> */}
        </div>
      ) : (
        <button
          className="small-contained-button google-button d-flex"
          onClick={login}
        >
          <img
            src={google_calendar_icon}
            height="25px"
            width="25px"
            className="me-2"
          />
          Sync With Google Calendar
        </button>
      )}
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove this account"}
        handleAgree={handleRemove}
      />
    </div>
  );
}
