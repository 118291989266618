import { api_key } from "src/config/config";
import { invokeApi } from "../../bl_libs/invokeApi";
export const websitePagesContent = async (data) => {
  const requestObj = {
    path: `app/get_website_page`,
    method: "POST",
    headers: {
      "x-auth-api-key": api_key,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const scan_billing_ticket_api = async (data) => {
  const requestObj = {
    path: `app/scan_billing/ticket`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};
