import { Avatar } from "@mui/material";
import React from "react";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { get_date_with_user_time_zone } from "src/utils/constants";

export default function ParticipantInfoCard({
  profile_image,
  user_name,
  date,
}) {
  const { userInfo, adminTimeZone } = useContentSetting();

  return (
    <div className="post-creator user-info-card ps-2 mt-2">
      <div className="like-profile-image">
        <Avatar src={s3baseUrl + profile_image} alt="photoURL">
          {user_name[0]}
        </Avatar>
      </div>
      <div className="creator-name ps-2 pt-1 text-start">
        <span className="d-flex feed-level">
          <h3 className="mb-0">{user_name}</h3>
        </span>
        <span className="date-color">
          Joined at{" "}
          {get_date_with_user_time_zone(
            date,
            "hh:mm A",
            userInfo.time_zone,
            adminTimeZone
          )}
        </span>
      </div>
    </div>
  );
}
