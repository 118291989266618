import React, { useEffect, useState } from "react";
import { tex_rate_list_api } from "src/DAL/ShopApi/ApiShop";

export default function TaxRate(props) {
  const {
    selectedTax,
    totalPrice,
    inputs,
    setSelectedTax,
    setTaxCharges,
    taxCharges,
  } = props;

  const [ratesList, setRatesList] = useState([]);

  const tex_rate_list = async () => {
    const result = await tex_rate_list_api();
    if (result.code === 200) {
      setRatesList(result.data);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    tex_rate_list();
  }, []);

  useEffect(() => {
    if (totalPrice > 0 && ratesList.length > 0) {
      let find_rate = ratesList.find(
        (rate) => rate.country == inputs.country?.label
      );
      if (find_rate) {
        setSelectedTax(find_rate);
        let percentage = find_rate.percentage;
        if (percentage) {
          setTaxCharges(totalPrice * (percentage / 100));
        } else {
          setTaxCharges(0);
        }
      } else {
        setSelectedTax({});
        setTaxCharges(0);
      }
    } else {
      setTaxCharges(0);
    }
  }, [totalPrice, ratesList, inputs.country]);

  if (!selectedTax?.tax_type_title) return null;

  return (
    <>
      <tr>
        <td colSpan="3" className="text-right">
          {selectedTax.tax_type_title + " (" + selectedTax.percentage + "%)"}
        </td>
        <td style={{ color: "#f6bd4b" }}>
          <>£{taxCharges?.toFixed(2)}</>
        </td>
      </tr>
    </>
  );
}
