import React, { useState } from "react";

export default function CustomChart({ data }) {
  const [showIndex, setShowIndex] = useState(null);

  let HEIGHT = 35;
  let WIDTH = 35;

  const get_background = (index) => {
    let length = data.length;
    let colors = data.map((q) => q.scaling_color);
    let angle_step = 360 / length;
    let gradient_stops = "";

    for (let i = 0; i < length; i++) {
      let start_angle = i * angle_step;
      let end_angle = (i + 1) * angle_step;
      if (data[i].answer && data[i].answer >= index) {
        gradient_stops += `${colors[i]} ${start_angle}deg ${end_angle}deg, `;
      } else {
        gradient_stops += `#000 ${start_angle}deg ${end_angle}deg, `;
      }
    }
    gradient_stops = gradient_stops.slice(0, -2); // Remove the trailing comma and space
    return `conic-gradient(from 0deg, ${gradient_stops})`;
  };

  function calculateDistances(elem, mouseX, mouseY) {
    var rect = elem.getBoundingClientRect();
    let scroll = window.scrollY;
    var elemCenterX = rect.left + rect.width / 2;
    var elemCenterY = scroll + rect.top + rect.height / 2;
    var offsetX = mouseX - elemCenterX;
    var offsetY = elemCenterY - mouseY;
    return {
      distanceX: offsetX,
      distanceY: offsetY,
    };
  }

  function calculateAngle(x, y, depth) {
    let radians = Math.atan2(x, y);
    if (radians < 0) {
      radians += 2 * Math.PI;
    }
    let degrees = radians * (180 / Math.PI);
    let quadrant = parseInt(degrees / 40);
    let quadrant_data = data[quadrant];
    if (quadrant_data?.answer >= depth) {
      setShowIndex(data[quadrant]);
      return;
    }
    setShowIndex(null);
  }

  const get_selected_angle = (event, depth) => {
    let element = document.getElementById("most-inner-div");
    let mX = event.pageX;
    let mY = event.pageY;
    let distant = calculateDistances(element, mX, mY);
    calculateAngle(distant.distanceX, distant.distanceY, depth);
  };

  const generateNestedDivs = (depth) => {
    let is_even = depth % 2;
    if (depth === 0) {
      return (
        <div
          className={`outer-main mx-auto outer-${depth + 1}`}
          style={{
            height: `${HEIGHT * depth}px`,
            width: `${WIDTH * depth}px`,
            border: is_even ? "1px solid gray" : "",
            background: get_background(depth + 1),
            transition: "background-color 5s ease-in-out",
          }}
          onMouseMove={(e) => {
            e.stopPropagation();
            get_selected_angle(e, depth + 1);
          }}
          onMouseLeave={() => {
            setShowIndex(null);
          }}
        >
          <span>
            {is_even ? depth + 1 : ""}
            <span id="most-inner-div" className="mt-3 text-black">
              .
            </span>
          </span>
        </div>
      );
    }
    return (
      <div
        className={`outer-main mx-auto outer-${depth + 1}`}
        style={{
          height: `${HEIGHT * depth}px`,
          width: `${WIDTH * depth}px`,
          border: is_even ? "1px solid gray" : "",
          background: get_background(depth + 1),
          transition: "background-color 5s ease-in-out",
        }}
        onMouseMove={(e) => {
          e.stopPropagation();
          get_selected_angle(e, depth + 1);
        }}
        onMouseLeave={() => {
          setShowIndex(null);
        }}
      >
        <span>{is_even ? depth + 1 : ""}</span>
        {generateNestedDivs(depth - 1)}
      </div>
    );
  };

  return (
    <div>
      {showIndex && (
        <div className="show-answers-detail-box text-center">
          <div className="answer-box">
            <div
              className="color-box"
              style={{ backgroundColor: showIndex.scaling_color }}
            ></div>
            <div className="me-2">{showIndex.scaling_main_heading}</div>
            <div className="">:&nbsp;&nbsp;{showIndex.answer}</div>
          </div>
        </div>
      )}
      {generateNestedDivs(9)}
    </div>
  );
}
