import React, { useState, useEffect } from "react";
import { IconButton, CircularProgress, Chip, Tooltip, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LockIcon from "@mui/icons-material/Lock";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { PodsDetailApi } from "src/DAL/Pods/Pods";
import { s3baseUrl } from "src/config/config";
import ReactTooltip from "react-tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { htmlDecode } from "src/utils/convertHtml";
import countryList from "react-select-country-list";

import moment from "moment";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import ShopDialog from "src/components/Shop/ShopDialog";
import { get_root_value } from "src/utils/domUtils";
import { useMemo } from "react";
import { book_image, pen_image } from "src/assets";
import CardInformation from "src/components/PaymentRequests/CardInformation";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const ShopItemCheckout = () => {
  const { userInfo, adminTimeZone } = useContentSetting();
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const [itemData, setItemData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [copied, setCopied] = useState(false);
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const options = useMemo(() => countryList().getData(), []);

  // const getMemoryDetail = async () => {
  //   const result = await PodsDetailApi(params.pod_slug);
  //   if (result.code === 200) {
  //     setitemData(result.room);
  //     setIsLoading(false);
  //   } else {
  //     enqueueSnackbar(result.message, { variant: "error" });
  //     navigate(`/pods`);
  //   }
  // };

  const handleCardAction = () => {

  }

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container shop-item-checkout">
      <div className="row mobile-margin display-flex">
        <div className="col-12 col-md-6 ">
          <div className="row">
            <div className="col-12">
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />

              </IconButton>
            </div>
            <div className="row">
              <h2>Shipping Details</h2>
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="mt-4 select-box">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Country
                        </InputLabel>
                        <Select
                          className="svg-color"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value='IE'
                          name="country"
                          label="Country"
                          MenuProps={{
                            classes: {
                              paper: classes.paper,
                            },
                          }}
                          // onChange={handleChange}
                          size="small"
                          sx={{
                            color: get_root_value("--input-text-color"),
                          }}
                        >
                          {options.map((option_value, index) => (
                            <MenuItem
                              value={option_value.value}
                              eventKey={index}
                              key={index}
                            >
                              {option_value.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="mt-4">
                      <TextField
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="first_name"
                        value='Hafiz'
                        // onChange={(e) => handleChange(e)}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="mt-4">
                      <TextField
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value='Aslam'
                        name="last_name"
                        // onChange={(e) => handleChange(e)}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mt-4">
                      <TextField
                        id="outlined-basic"
                        label="Address"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value='Sahiwal'
                        name="street"
                      // onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="mt-4">
                      <TextField
                        id="outlined-basic"
                        label="Town or City"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value='Sahiwal'
                        name="city"
                      // onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="mt-4">
                      <TextField
                        id="outlined-basic"
                        label="Area Code"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value='57000'
                        name="zip_code"
                      // onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  {/* <div className="col-12 text-end mt-4">
                    <button
                      className="small-contained-button add-to-cart-button"
                    // onClick={handleCheckOut}
                    >
                      <>
                        <ShoppingCartIcon />
                        Continue to shipping
                      </>
                    </button>
                  </div> */}
                </div>
              </div>
              <div className="col-12">
                <CardInformation
                  isLoading={isLoadingCard}
                  setIsLoading={setIsLoadingCard}
                  handleCardAction={handleCardAction}
                  headingTitle='Card Details'
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6  ms-auto shop-modal-box shop-item-checkout-bill">
          <div className="row">
            <div className="col-2 item-image">
              <img src={pen_image} alt="" className="mx-auto" />
              <span className="items-quantity">3</span>
            </div>
            <div className="col-7 mt-2">Dynamite Life Style Pen 1</div>
            <div className="col-3 mt-2 text-end">$100.00</div>
          </div>
          <div className="row mt-3">
            <div className="col-2 item-image">
              <img src={book_image} alt="" className="mx-auto" />
              <span className="items-quantity">2</span>
            </div>
            <div className="col-7 mt-2">Dynamite Life Style Book 1</div>
            <div className="col-3 mt-2 text-end">$48.00</div>
          </div>
          <hr></hr>
          <div className="d-flex justify-content-between">
            <p className="mb-0">Subtotal</p>
            <h4 className="mb-0">$352.00</h4>
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-0 mt-3">Shipping</p>
            <h4 className="mb-0 mt-3">$32.00</h4>
          </div>
          <hr></hr>
          <div className="d-flex justify-content-between total-price">
            <p>Total</p>
            <h4>$382.00</h4>
          </div>
        </div>
      </div>

    </div>
  );
};

export default ShopItemCheckout;
