import React, { useState, useEffect, useRef } from "react";
import PinterestLayout from "src/components/GeneralComponents/Pinterest";
import { quotesListing } from "../../DAL/Quotes/Quotes";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import SlickCarousel from "src/components/GeneralComponents/SlickCarousel";
import {
  slider_image_1,
  slider_image_2,
  slider_image_3,
  slider_image_Vector,
} from "src/assets";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function Members() {
  const classes = useStyles();
  const [quotesData, setQuotesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { userInfo } = useContentSetting();
  const navigate = useNavigate();

  const carouselRef = useRef();

  const goNext = () => {
    carouselRef.current.slickNext();
  };
  const goPrev = () => {
    carouselRef.current.slickPrev();
  };

  const handleDetailPage = () => {};
  //   useEffect(() => {
  //     getQuotesListing();
  //   }, []);
  // if (isLoading) {
  //   return <CircularProgress className={classes.loading} color="primary" />;
  // }
  useEffect(() => {
    if (userInfo.dashboard == true) {
      navigate("/mission-control");
    }
  }, []);

  return (
    <>
      <section className="portal-banner">
        <div className="container">
          <div className="text-start">
            <h2>Your Result</h2>
          </div>
          <div className="row mt-4">
            <div className="col-lg-8 p-0">
              <div className="video-portal-border">
                <iframe
                  width="650"
                  height="450"
                  src="https://player.vimeo.com/video/746594408?h=457d80b5d9&badge=0&autopause=0&player_id=0&app_id=58479"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen=""
                ></iframe>
              </div>
            </div>
            <div className="col-lg-4 p-0">
              <div className="portal-card-linear">
                <h6>Description</h6>
                <p>
                  Gratitude is a positive emotion that involves being thankful
                  and appreciative and is associated with several mental and
                  physical health benefits. When you experience Gratitude,
                  Gratitude is a positive emotion.
                </p>
                <p>
                  That involves being thankful and appreciative and is
                  associated with several mental and physical health benefits.
                  When you experience Gratitude,{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="portal-wrapper">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6">
              <h2>Upgrade for Paid Portal</h2>
            </div>
            <div className="col-md-6 d-flex justify-content-lg-end justify-content-md-end justify-content-center">
              <a href="#" className="btn-btn-portal">
                Continue with 7 day free trial{" "}
              </a>
            </div>
          </div>
          <div className="row mt-4 justify-content-center">
            <div className="col-lg-6">
              <div className="portal-card h-100 position-relative">
                <div className="row align-items-center">
                  <div className="col-md-5">
                    <div className="bg-card-black">
                      <h6>Basic</h6>
                      <h5>
                        £47 <span>/ month</span>{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <ul className="list-unstyled faq-portal-list">
                      <li>
                        <i className="fa-solid fa-check"></i>
                        <span>Get Daily Updates</span>
                      </li>
                      <li>
                        <i className="fa-solid fa-check"></i>
                        <span>30 Videos weekly</span>
                      </li>
                      <li>
                        <i className="fa-solid fa-xmark"></i>
                        <span>24/7 hours support we will asisst you</span>
                      </li>
                      <li>
                        <i className="fa-solid fa-xmark"></i>
                        <span>Weekly Newsletter</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="btn-portal">
                  <a href="#">Upgrade Now</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <div className="portal-card h-100 position-relative">
                <div className="row align-items-center">
                  <div className="col-md-5">
                    <div className="bg-card-yellow">
                      <h6>Premium</h6>
                      <h5>
                        £29 <span>/ yearly</span>{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <ul className="list-unstyled faq-portal-list">
                      <li>
                        <i className="fa-solid fa-check"></i>
                        <span>
                          One year of unlimited access to courses and live
                          coaching events
                        </span>
                      </li>
                      <li>
                        <i className="fa-solid fa-check"></i>
                        <span>Live coaching events every month</span>
                      </li>
                      <li>
                        <i className="fa-solid fa-check"></i>
                        <span>Includes ongoing access to future courses</span>
                      </li>
                      <li>
                        <i className="fa-solid fa-check"></i>
                        <span>Cancel anytime</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="btn-portal">
                  <a href="#">Upgrade Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="portal-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center portal-member">
              <h2>
                Behind Every Single Action Is a Science. Once You Understand
                This, The World Of No Limits Undfolds
              </h2>
            </div>
            <div className="text-center mt-5">
              <a href="#" className="btn-btn-portal">
                ENTER THE SOURCE HERE
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="services-testimonial-wrapper">
        <div className="container p-lg-0 overflow-hidden">
          <div className="row justify-content-start">
            <div className="col-lg-10">
              <h2>Testimonial</h2>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Excepturi, rerum tenetur? Vero sapiente iste<br></br> minus
                architecto, voluptatibus quaerat eius voluptates.
              </p>
            </div>
          </div>
          <div className="carousel-parent mt-5">
            <div className="owl-nav">
              <button onClick={goPrev} className="arrowStyle">
                <i
                  className="fa-solid fa-chevron-left style"
                  aria-hidden="true"
                ></i>
              </button>
              <button onClick={goNext} className="arrowStyle">
                <i
                  className="fa-solid fa-chevron-right style"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
            <SlickCarousel
              carouselRef={carouselRef}
              // responsive={responsive_css}
            >
              <div className="item set-margen">
                <div className="outer-home-card h-100 position-relative">
                  <div className="inner-div-home">
                    <img
                      src={slider_image_Vector}
                      className="slider-home-img"
                      width={50}
                      alt=""
                    />
                    <div className="home-slider-block">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Excepturi, rerum tenetur? Vero sapiente iste minus
                        architecto, voluptatibus quaerat eius voluptates.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <img
                      src={slider_image_1}
                      className="card-img-home"
                      alt=""
                    />
                    <div className="d-block card-img-para ps-3">
                      <h6> Arlene McCoy</h6>
                      <p> Vero sapiente iste</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item set-margen">
                <div className="outer-home-card h-100 position-relative">
                  <div className="inner-div-home">
                    <img
                      src={slider_image_Vector}
                      className="slider-home-img"
                      width={50}
                      alt=""
                    />
                    <div className="home-slider-block">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Excepturi, rerum tenetur? Vero sapiente iste minus
                        architecto, voluptatibus quaerat eius voluptates.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <img
                      src={slider_image_2}
                      className="card-img-home"
                      alt=""
                    />
                    <div className="d-block card-img-para ps-3">
                      <h6> Arlene McCoy</h6>
                      <p> Vero sapiente iste</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item set-margen">
                <div className="outer-home-card h-100 position-relative">
                  <div className="inner-div-home">
                    <img
                      src={slider_image_Vector}
                      className="slider-home-img"
                      width={50}
                      alt=""
                    />
                    <div className="home-slider-block">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Excepturi, rerum tenetur? Vero sapiente iste minus
                        architecto, voluptatibus quaerat eius voluptates.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <img
                      src={slider_image_3}
                      className="card-img-home"
                      alt=""
                    />
                    <div className="d-block card-img-para ps-3">
                      <h6> Arlene McCoy</h6>
                      <p> Vero sapiente iste</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item set-margen">
                <div className="outer-home-card h-100 position-relative">
                  <div className="inner-div-home">
                    <img
                      src={slider_image_Vector}
                      className="slider-home-img"
                      width={50}
                      alt=""
                    />
                    <div className="home-slider-block">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Excepturi, rerum tenetur? Vero sapiente iste minus
                        architecto, voluptatibus quaerat eius voluptates.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <img
                      src={slider_image_1}
                      className="card-img-home"
                      alt=""
                    />
                    <div className="d-block card-img-para ps-3">
                      <h6> Arlene McCoy</h6>
                      <p> Vero sapiente iste</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item set-margen">
                <div className="outer-home-card h-100 position-relative">
                  <div className="inner-div-home">
                    <img
                      src={slider_image_Vector}
                      className="slider-home-img"
                      width={50}
                      alt=""
                    />
                    <div className="home-slider-block">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Excepturi, rerum tenetur? Vero sapiente iste minus
                        architecto, voluptatibus quaerat eius voluptates.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <img
                      src={slider_image_2}
                      className="card-img-home"
                      alt=""
                    />
                    <div className="d-block card-img-para ps-3">
                      <h6> Arlene McCoy</h6>
                      <p> Vero sapiente iste</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item set-margen">
                <div className="outer-home-card h-100 position-relative">
                  <div className="inner-div-home">
                    <img
                      src={slider_image_Vector}
                      className="slider-home-img"
                      width={50}
                      alt=""
                    />
                    <div className="home-slider-block">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Excepturi, rerum tenetur? Vero sapiente iste minus
                        architecto, voluptatibus quaerat eius voluptates.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <img
                      src={slider_image_3}
                      className="card-img-home"
                      alt=""
                    />
                    <div className="d-block card-img-para ps-3">
                      <h6> Arlene McCoy</h6>
                      <p> Vero sapiente iste</p>
                    </div>
                  </div>
                </div>
              </div>
            </SlickCarousel>
          </div>
        </div>
      </section>
    </>
  );
}

export default Members;
