import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import { Divider } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import EditEarning from "src/pages/90DayPlan/EditEarning";
import { useSnackbar } from "notistack";
import { convertCurrencyToSign } from "src/utils/constants";
import { _get_user_from_localStorage } from "src/DAL/localStorage/localStorage";
import { htmlDecode } from "src/utils/convertHtml";
import { EarningListApi } from "src/DAL/90Day/NinetyDay";

const useStyles = makeStyles((theme) => ({
  dense: {
    marginTop: theme.spacing(0),
  },
}));

// dummy list data
const dummyData = [
  {
    date: "27 July 2022",
    description: "I have to earn 3k Dollars a week",
    earning: 5000,
    isExpanded: true,
    isShowDots: true,
    member_id: "62d6945471e3b4afba337883",
    _id: "62d7d696f5ab78c4747e1105",
  },
  {
    date: "27 July 2022",
    description: "I have to earn 3k Dollars a week",
    earning: 5000,
    isExpanded: false,
    isShowDots: true,
    member_id: "62d6945471e3b4afba337883",
    _id: "62d7d696f5ab78c4747e1105",
  },
  {
    date: "27 July 2022",
    description: "I have to earn 3k Dollars a week",
    earning: 5000,
    isExpanded: false,
    isShowDots: true,
    member_id: "62d6945471e3b4afba337883",
    _id: "62d7d696f5ab78c4747e1105",
  },
  {
    date: "27 July 2022",
    description: "I have to earn 3k Dollars a week",
    earning: 5000,
    isExpanded: false,
    isShowDots: true,
    member_id: "62d6945471e3b4afba337883",
    _id: "62d7d696f5ab78c4747e1105",
  },
];
const ChallengeList = ({
  // setEarningList,
  memberEarningList,
  reload,
  setReload,
  setMemberearningList,
  dataList,
  handleChangeInEarnings,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [toggle, setToggle] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const [editEarning, seteditEarning] = useState();
  const [earningList, setEarningList] = useState([]);
  const [deleteEarningId, setDeleteEarningId] = useState("");
  const [startDate, setStartDate] = useState("");

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const user_profile = _get_user_from_localStorage();

  const toggleInput = () => {
    setToggle(!toggle);
  };

  // get earning list
  const getEarningList = async () => {
    dataList();
    const result = await EarningListApi();

    if (result.code == 200) {
      let questionsArray = [];
      result.memberEarning.map((value, index) => {
        if (index === 0) {
          questionsArray.push({
            ...value,
            isExpanded: true,
            isShowDots: true,
          });
        } else {
          questionsArray.push({
            ...value,
            isExpanded: false,
            isShowDots: false,
          });
        }
      });
      setEarningList(questionsArray);
      setStartDate(result.ninteen_day_vision_start_date);
      setMemberearningList(result.memberEarning);
      // setEarningList(result.memberEarning);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  //Deleting earning
  const handleDelete = async (e) => {
    const result = await DeleteEarning(deleteEarningId);
    if (result.code == 200) {
      setOpenDelete(false);
      dataList();
      console.log("on reload ");
      enqueueSnackbar(result.message, { variant: "success" });
      getEarningList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const expandArea = (index) => {
    let temp_state = [...earningList];
    let temp_element = { ...temp_state[index] };
    if (temp_element.isExpanded === false) {
      temp_element.isShowDots = true;
      temp_element.isExpanded = true;
    } else {
      temp_element.isShowDots = false;
      temp_element.isExpanded = false;
    }
    temp_state[index] = temp_element;
    setEarningList(temp_state);
  };

  const handleEdit = (value) => {
    seteditEarning(value);

    setDrawerState(true);
  };
  const handleAgreeDelete = (value) => {
    setDeleteEarningId(value._id);
    setOpenDelete(true);
  };
  const getReverseList = (earningListing) => {
    let reversed = earningListing;
    console.log(reversed, "reversed");
    return reversed;
    // return earningListing.reverse();
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    getEarningList();
  }, [reload]);

  return (
    <>
      <div className="row goal-statement-messages">
        <div className="col-12">
          {getReverseList(dummyData).length > 0
            ? getReverseList(dummyData).map((message, index) => {
                return (
                  <div className="card mb-2" key={message.id}>
                    <form>
                      <Accordion expanded={message.isExpanded}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          onClick={() => expandArea(index)}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="svg-color"
                        >
                          <Typography>
                            {`Earning Date: ${message.date} : `}
                          </Typography>
                          <Typography>
                            &nbsp;
                            {`${convertCurrencyToSign(
                              user_profile.nineteen_day_plan_currency
                            )}${message.earning.toLocaleString()}`}
                          </Typography>
                        </AccordionSummary>
                        <div className="Ninty-Day-trackerList-dots">
                          {message.isShowDots && (
                            <CustomPopover menu={MENU_OPTIONS} data={message} />
                          )}
                        </div>
                        <div className="pe-5">
                          <p className="ms-3">
                            {htmlDecode(message.description)}
                          </p>
                        </div>
                        <AccordionDetails>
                          <div className="d-flex justify-content-between mt-3"></div>
                        </AccordionDetails>
                      </Accordion>
                    </form>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
      <div className="row">
        <div className="d-flex justify-content-between mt-3">
          {/* <button
            className="small-contained-button ms-auto"
            disabled={isLoadingAll}
            onClick={handleSubmitAll}
          >
            {isLoadingAll ? "Saving..." : "Save All"}
          </button> */}
        </div>
      </div>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Edit Earning"
        componentToPassDown={
          <EditEarning
            dataList={getEarningList}
            onCloseDrawer={handleCloseDrawer}
            editObject={editEarning}
            targetDate={startDate}
          />
        }
      />
      {/* <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Add Earning"
        componentToPassDown={
          <AddNewEarning
            // setMember={setMember}
            dataList={getEarningList}
            // earningList={earningList}
            // setEarningList={setEarningList}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      /> */}
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete?"}
        handleAgree={handleDelete}
      />
    </>
  );
};

export default ChallengeList;
