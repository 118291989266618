import { AppBugReports } from "src/components/_dashboard/app";
import AboutSection from "../Community/AboutSection";
import SalePages from "src/components/_dashboard/app/SalePages";
import GraphicsLinks from "src/components/_dashboard/app/GraphicsLinks";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { useState } from "react";
import { get_stats_data } from "src/DAL/Dashboard/Dashboard";
import { useSnackbar } from "notistack";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const SourceLibrary = () => {
  const [salePages, setSalePages] = useState([]);
  const [graphicsLinks, setGraphicsLinks] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const getDashboardSetting = async () => {
    const result = await get_stats_data("source_links");
    if (result.code === 200) {
      setSalePages(result.sale_pages);
      setGraphicsLinks(result.graphics);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    getDashboardSetting();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-3">
            <h2 style={{ textAlign: "" }}>
              <strong>Source Library</strong>
            </h2>
          </div>
          <div className="col-lg-8 mb-4">
            <SalePages
              title="Affiliate Links"
              hide_title={true}
              sourceSessions={salePages}
            />
          </div>
          <div className=" col-lg-4 mb-4 ps-0">
            <div className="profile-cards" style={{ height: "100%" }}>
              <GraphicsLinks graphicsLinks={graphicsLinks} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SourceLibrary;
