import React, { useRef } from "react";
import ReactPlayer from "react-player";

const MissionVideoPlayer = (props) => {
  const playerRef = useRef(null);
  const { isDisabled } = props;

  const stopVideo = () => {
    if (isDisabled) {
      playerRef.current.seekTo(0);
    }
  };

  return (
    <div className="wrapper">
      <ReactPlayer
        ref={playerRef}
        controls={!isDisabled}
        onContextMenu={(e) => e.preventDefault()}
        className="player"
        url={props.url}
        width="100%"
        height="100%"
        stopOnUnmount
        pip
        playsinline
        onPlay={stopVideo}
        onStart={stopVideo}
      />
    </div>
  );
};

export default MissionVideoPlayer;
