import React, { useState, useEffect } from "react";
import {
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  AddAffirmationsApi,
  EditAffirmationsApi,
} from "src/DAL/AffirmationCategories/Affirmations/Affirmations";
import { categoriesListing } from "../../DAL/AffirmationCategories/Categories/Categories";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import { get_root_value } from "src/utils/domUtils";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));

export default function AddOrUpdateAffirmations({
  editData,
  onCloseDrawer,
  reloadListing,
  category_slug,
  formType,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [image, setImage] = React.useState("");
  const [previews, setPreviews] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    affirmation_text: "",
    affirmation_id: "",
    category_slug: "",
    categoriesList: [],
  });
  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (image === "") {
      enqueueSnackbar("Image is required", { variant: "error" });
      setIsLoading(false);
    } else if (state.category === "") {
      enqueueSnackbar("Please Choose category", { variant: "error" });
      setIsLoading(false);
    } else {
      const formData = new FormData();
      formData.append("affirmation_text", state.affirmation_text);
      formData.append("category_slug", state.category_slug);
      formData.append("image", image);
      const result = await AddAffirmationsApi(formData);
      if (result.code === 200) {
        onCloseDrawer();
        reloadListing();
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  //Adding Category
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (image === "" && previews === "") {
      enqueueSnackbar("Image is required", { variant: "error" });
      setIsLoading(false);
    } else if (state.category_slug === "") {
      enqueueSnackbar("Please Choose category", { variant: "error" });
      setIsLoading(false);
    } else {
      const formData = new FormData();
      formData.append("affirmation_text", state.affirmation_text);
      formData.append("category_slug", state.category_slug);
      if (image) {
        formData.append("image", image);
      }
      const result = await EditAffirmationsApi(state.affirmation_id, formData);
      if (result.code === 200) {
        reloadListing();
        onCloseDrawer();
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const getCategoriesListing = async () => {
    const result = await categoriesListing();
    if (result.code === 200) {
      setState((prevState) => {
        return {
          ...prevState,
          ["categoriesList"]: result.category,
        };
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategoriesListing();
  }, []);

  useEffect(() => {
    if (formType == "EDIT") {
      setState((prevState) => ({
        ...prevState,
        ["affirmation_text"]: editData.pinterestDescription,
        ["affirmation_id"]: editData.pinteres_id,
        ["category_slug"]: category_slug,
      }));
      setPreviews(s3baseUrl + editData.pinterestImage);
    }
  }, [formType]);

  return (
    <div className="container new-memories">
      <form onSubmit={formType === "ADD" ? handleSubmit : handleUpdate}>
        <FormControl variant="outlined" className="mt-4">
          <InputLabel id="demo-simple-select-outlined-label">
            Category
          </InputLabel>
          <Select
            sx={{
              color: get_root_value("--input-text-color"),
            }}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={state.category_slug}
            onChange={(e) => handleChange(e)}
            label="Category"
            name="category_slug"
            className="svg-color"
            MenuProps={{
              classes: {
                paper: classes.paper,
              },
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {state.categoriesList.map((categories, index) => (
              <MenuItem value={categories.category_slug} eventKey={index}>
                {categories.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          id="outlined-multiline-static"
          label="Affirmation Description"
          multiline
          rows={5}
          name="affirmation_text"
          value={state.affirmation_text}
          onChange={(e) => handleChange(e)}
          variant="outlined"
          style={{ width: "100%" }}
          className="mt-3"
          required={true}
        />
        <p className="mt-2 mb-0">Recommended Size (1000x670) *</p>
        <div className="col-md-12 mt-2 d-flex">
          {previews && (
            <span className="preview mt-2">
              <span onClick={handleRemove}>x</span>
              <img src={previews} />
            </span>
          )}
          {previews == "" && (
            <span className="upload-button mt-2">
              <input
                color="primary"
                accept="image/*"
                type="file"
                id="icon-button-file"
                style={{ display: "none" }}
                name="affirmationImage"
                onChange={handleUpload}
              />
              <label htmlFor="icon-button-file">
                <CloudUploadIcon />
              </label>
            </span>
          )}
        </div>

        <div className="mt-3">
          <span className="float-end">
            <button className="submit-button" disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </span>
        </div>
      </form>
    </div>
  );
}
