import {
  Checkbox,
  IconButton,
  Slider,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { cointlogo } from "src/assets";
import Upload from "./UploadImage";
import { useState, useEffect } from "react";
import "react-range-slider-input/dist/style.css";
import { get_root_value } from "src/utils/domUtils";
import { ReactVideoPlayer } from "src/components";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import {
  get_challange_history_by_member_api,
  update_lifeScript_challange_history_by_member_api,
} from "src/DAL/ChallengeApi/Visioneering";
import { uploadImageOns3 } from "src/DAL/Login/Login";
import { useSnackbar } from "notistack";
import MUIStaticCalendar from "src/components/GeneralComponents/MUIStaticCalendar";
import CongratulationAnimation from "src/components/challenges/CongratulationAnimation";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import CongratulationsPopup from "src/components/challenges/CongratulationsPopup";
import CreatePost from "src/components/challenges/CreatePost";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { check } from "prettier";

function LifeScript() {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [challengeDate, setChallengeDate] = useState(new Date());
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [challengeData, setChallengeData] = useState({});
  const [files, setFiles] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);
  const [imagePreview, setImagePreview] = useState();
  const [totalCoins, setTotalCoins] = useState(0);
  const [oldImagesArray, setOldImagesArray] = useState([]);
  const [dateChangeLocally, setDateChangeLocally] = useState(false);
  const [rewardData, setRewardData] = useState({});
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [openCongratulation, setOpenCongratulation] = useState(false);
  const [isShowINtention, setShowINtention] = useState(false);
  const [isShowINtentionHeading, setIntentionHeading] = useState("");
  const [addArray, setaddArray] = useState([
    { option: "", complete: false },
    { option: "", complete: false },
    { option: "", complete: false },
  ]);
  const handleAdd = () => {
    const addedElement = [...addArray, { option: "", complete: false }];
    setaddArray(addedElement);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };

  const handleChangeCheckBox = async (data, index) => {
    // const { name, checked } = e.target;

    if (imagePreview || files.length > 0) {
      const list = [...addArray];
      list[index]["complete"] = data?.complete ? !data.complete : true;
      setaddArray(list);
      if (deadline) {
        handleUpdate("checked");
      } else {
        handleSubmit("checked");
      }
    } else {
      enqueueSnackbar("Please Upload Image", { variant: "error" });
    }
  };
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index][name] = value;
    setaddArray(list);
  };
  const handleOpenCreateModal = () => {
    setOpenCongratulation(false);
    setOpenCreateModal(true);
  };

  const deadline = moment(
    challengeData?.challange_info?.challange_end_date
  ).add(1, "day");

  const getChallengeDetail = async (isCompleting) => {
    const formData = {
      challange: params.id,
      challange_date: moment(challengeDate).format("YYYY-MM-DD"),
    };
    const result = await get_challange_history_by_member_api(formData);
    if (result.code === 200) {
      console.log(result, "resultresultresultresultresult");
      setChallengeData(result.challenge);
      setShowINtention(result?.challenge?.is_show_intentions);
      setIntentionHeading(result?.challenge?.intentions_heading);
      setTotalCoins(result.challange_coins_count_attracted);
      let old_array = [];
      if (result.challenge?.challange_history?._id) {
        let images = result.challenge?.challange_history?.money_script_images;
        if (images?.length > 0) {
          old_array = images.map((images) => {
            return {
              path: images,
              type: "image",
            };
          });
        }
      }
      if (
        result.challenge?.challange_history &&
        result.challenge?.challange_history.intention &&
        result.challenge?.challange_history.intention.length > 0
      ) {
        setaddArray(result.challenge?.challange_history.intention);
      } else {
        setaddArray([
          { option: "", complete: false, is_moved_to_tomorrow: false },
          { option: "", complete: false, is_moved_to_tomorrow: false },
          { option: "", complete: false, is_moved_to_tomorrow: false },
        ]);
      }
      setOtherFiles(result.challenge?.previous_challange_history);
      setFiles(old_array);
      setOldImagesArray(old_array);
      setTimeout(() => {
        if (isCompleting) {
          setIsCompleted(false);
          setOpenCongratulation(true);
        }
      }, 3000);
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChangeDate = (value) => {
    setDateChangeLocally(false);
    setChallengeDate(value.$d);
  };

  let challange_start_date = moment(
    challengeData?.challange_info?.challange_start_date,
    "YYYY-MM-DD"
  );
  let complete_date = moment();
  if (challengeData.challange_info?.is_complete) {
    complete_date = moment(
      challengeData.challange_info?.challange_completed_date,
      "YYYY-MM-DD"
    );
  }
  const no_of_days = complete_date.diff(challange_start_date, "days");
  const UploadImages = async (formData) => {
    const result = await uploadImageOns3(formData);
    return result.image_path;
  };
  const handleSubmit = async (e) => {
    if (e == "checked") {
    } else {
      e.preventDefault();
      setIsLoadingForm(true);
    }

    const results = files.map((image, index) => {
      const formData = new FormData();
      formData.append("image", image.path);
      formData.append("width", 500);
      const result = UploadImages(formData);
      return result;
    });

    Promise.all(results).then(async (img_results) => {
      var setImageArray = [];
      img_results.map((image_path, index) => {
        setImageArray.push(image_path);
      });
      let complete_intentions = addArray.filter(
        (value) => value.complete == true
      );
      let incomplete_intentions = addArray.filter(
        (value) => value.complete == false
      );
      const formDataObject = {
        challange: params.id,
        challange_date: moment(challengeDate).format("YYYY-MM-DD"),
        intention: addArray,
        completed_intention: complete_intentions?.length,
        incomplete_intention: incomplete_intentions?.length,
      };
      if (files.length > 0) {
        formDataObject.images = setImageArray;
      }
      const result = await update_lifeScript_challange_history_by_member_api(
        formDataObject
      );
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        let is_completed = false;
        if (
          result.data.is_reward_assigned &&
          result.challenge_reward_content.enable_reward_setting
        ) {
          setRewardData({ ...result.data, ...result.challenge_reward_content });
          setIsCompleted(true);
          is_completed = true;
        }
        getChallengeDetail(is_completed);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingForm(false);
      }
    });
  };
  const handleUpdate = async (e) => {
    if (e == "checked") {
    } else {
      e.preventDefault();
      setIsLoadingForm(true);
    }
    const deleted_images_array = [];
    oldImagesArray.map((old_image, index) => {
      const filtered_array = files.filter(
        (image, index) => image.path == old_image.path
      );
      delete old_image.type;
      if (filtered_array.length == 0) {
        deleted_images_array.push(old_image);
      }
    });

    const results = files.map((image, index) => {
      if (image.type === "file") {
        const formData = new FormData();
        formData.append("image", image.path);
        formData.append("width", 300);
        const result = UploadImages(formData);
        return result;
      } else {
        return image.path;
      }
    });

    Promise.all(results).then(async (img_results) => {
      var setImageArray = [];
      img_results.map((image_path, index) => {
        setImageArray.push(image_path);
      });
      let complete_intentions = addArray.filter(
        (value) => value.complete == true
      );
      let incomplete_intentions = addArray.filter(
        (value) => value.complete == false
      );
      const formDataObject = {
        challange: params.id,
        challange_date: moment(challengeDate).format("YYYY-MM-DD"),
        intention: addArray,
        completed_intention: complete_intentions?.length,
        incomplete_intention: incomplete_intentions?.length,
      };
      if (files.length > 0) {
        formDataObject.images = setImageArray;
      }
      const result = await update_lifeScript_challange_history_by_member_api(
        formDataObject
      );
      if (result.code === 200) {
        setIsLoadingForm(false);
        let is_completed = false;
        if (
          result.data.is_reward_assigned &&
          result.challenge_reward_content.enable_reward_setting
        ) {
          setRewardData({ ...result.data, ...result.challenge_reward_content });
          setIsCompleted(true);
          is_completed = true;
        }
        getChallengeDetail(is_completed);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingForm(false);
      }
    });
  };

  const handleRemove = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
  };
  const handleChangeImage = (data) => {
    setDateChangeLocally(true);
    setChallengeDate(data.challange_date);
    let old_array = [];
    if (data.money_script_images?.length > 0) {
      old_array = data?.money_script_images?.map((images) => {
        return {
          path: images,
          type: "image",
        };
      });
    }
    setFiles(old_array);
    setOldImagesArray(old_array);
  };

  const handleUpload = (event) => {
    setImagePreview(URL.createObjectURL(event.target.files[0]));
    enqueueSnackbar("Image is Selected", { variant: "success" });
    let setImageObject = {};
    const fileList = event.target.files;
    const arr = [];

    for (let i = 0; i < fileList.length; i++) {
      setImageObject = {
        path: fileList[i],
        type: "file",
      };

      arr.push(setImageObject);
    }
    setFiles((prevFiles) => [setImageObject]);
  };

  const getTime = () => {
    const time = deadline ? Date.parse(deadline) - Date.now() : "";
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);
    return () => clearInterval(interval);
  }, [deadline]);

  const getOneDigit = (digi) => {
    let reminder = digi % 10;
    let deviser = digi / 10;
    let decimalPart = Math.floor(deviser);
    return { reminder, decimalPart };
  };

  function valuetext(value) {
    return `${value}°C`;
  }
  const Days = [
    {
      value: 0,
      label: "Day 1",
    },

    {
      value: 100,
      label: `${challengeData.challange_info?.no_of_days} Days`,
    },
  ];

  useEffect(() => {
    if (dateChangeLocally == false) {
      getChallengeDetail();
    }
  }, [params.visioneering_id, challengeDate]);

  return (
    <>
      <div className="assessment-wrapper">
        <div className="container">
          <div className="row pt-4">
            <div className="col-12 d-flex">
              <span>
                <IconButton
                  className="back-screen-button"
                  onClick={() => navigate("/money-script")}
                >
                  <ArrowBackIcon />
                </IconButton>
              </span>
              <span className="mt-2 ms-2">
                <PageDescription
                  parameter="lifescript_description"
                  else_title="Living Your Best Money Script"
                />
              </span>
            </div>
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
              <div className="d-flex text-align vision-div align-items-center">
                <img className="coint-img img-fluid" src={cointlogo}></img>
                <h4>Total Coins Attracted: {totalCoins}</h4>
              </div>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-lg-7 col-md-6">
              <ReactVideoPlayer url={challengeData?.challange_video_url} />
            </div>
            <div className="col-lg-5 col-md-6 mt-md-0 mt-4">
              <div className="d-flex calender w-100 full-open-calender">
                <MUIStaticCalendar
                  selectedDate={challengeDate}
                  handleChangeDate={handleChangeDate}
                  minDate={
                    challengeData.challange_info?.challange_start_date
                      ? challengeData.challange_info?.challange_start_date
                      : new Date()
                  }
                  maxDate={new Date()}
                />
              </div>
            </div>
            {isShowINtention && (
              <div className="col-lg-12">
                <div className="text-start label-text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: challengeData?.challange_intro_video_description,
                    }}
                    className="diary-para mt-1"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="container mt-4">
          <div className="row mt-3">
            <div className="col-lg-7 pe-lg-2 col-md-6">
              {!challengeData.challange_info?.is_complete && isShowINtention ? (
                <>
                  {isShowINtentionHeading && (
                    <h3 style={{ fontSize: "20px" }}>
                      {isShowINtentionHeading}
                    </h3>
                  )}
                  {addArray?.map((data, index) => {
                    return (
                      <div className="d-flex position-relative mt-1">
                        <div className="mt-2 field-container">
                          <TextareaAutosize
                            aria-label="empty textarea"
                            placeholder={`${index + 1}.`}
                            className="text-color textarea-autosize"
                            required
                            value={data.option}
                            minRows={1}
                            name="option"
                            onChange={(e) => handleChange(e, index)}
                            style={{
                              width: "100%",
                              borderRadius: "11px",
                              background: "#141717",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              paddingLeft: "15px",
                              color: "white",
                              fontSize: "15px",
                              border: "2px solid rgba(71, 66, 34, 0.3)",
                            }}
                          />
                          {data.option && (
                            <div className="text-end intentionCheckox d-flex justify-content-between on-mobile">
                              <div></div>
                              <div>
                                <Checkbox
                                  checked={data?.complete}
                                  onClick={(e) =>
                                    handleChangeCheckBox(data, index)
                                  }
                                  // onChange={(e) => handleChange(e, index)}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />

                                {data.complete == true ? (
                                  <span className="pt-2">
                                    Intention Completed
                                  </span>
                                ) : (
                                  <span className="pt-2">
                                    Mark Intention Complete
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        {/* <div className="icon-container"> */}
                        <span className="cross-icon cross-daily">
                          {addArray.length > 1 ? (
                            <Tooltip title="Remove">
                              <RemoveCircleOutlineIcon
                                onClick={() => handleDelete(index)}
                                className="diary-icon-remove"
                              />
                            </Tooltip>
                          ) : (
                            ""
                          )}
                          <Tooltip title="Add">
                            <AddCircleOutlineIcon
                              onClick={() => handleAdd()}
                              className="diary-icon-add"
                            />
                          </Tooltip>
                        </span>
                        {/* </div> */}
                      </div>
                    );
                  })}
                </>
              ) : (
                ""
              )}
            </div>
            {!isShowINtention && (
              <div className="col-lg-7 pe-lg-5 col-md-6">
                <div className="text-start label-text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: challengeData?.challange_intro_video_description,
                    }}
                    className="diary-para mt-1"
                  ></div>
                </div>
              </div>
            )}
            {!challengeData.challange_info?.is_complete && (
              <>
                <div className="col-lg-5 ps-lg-5 col-md-6 mt-md-0 mt-4">
                  <div className="text-start label-text text-white-label">
                    <h4>Uploading Your Daily Script Challenge*</h4>
                  </div>
                  <Upload
                    handleRemove={handleRemove}
                    files={files}
                    otherFiles={otherFiles}
                    imagePreview={imagePreview}
                    setFiles={setFiles}
                    handleUpload={handleUpload}
                    handleChangeImage={handleChangeImage}
                  />
                </div>
              </>
            )}
          </div>

          {challengeData?.challange_info?.challange_end_date && (
            <>
              {new Date(
                challengeData?.challange_info?.challange_end_date
              ).getTime() >= new Date().getTime() && (
                <div className="text-start label-text pt-5 countdown-timer">
                  <h4>Countdown Timer</h4>
                </div>
              )}
              <div className="row justify-content-between">
                {new Date(
                  challengeData?.challange_info?.challange_end_date
                ).getTime() >= new Date().getTime() && (
                  <div className="col-md-7 pt-3">
                    <div className="timer-count d-flex justify-content-between">
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(days).decimalPart}
                        </div>
                      </div>
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(days).reminder}
                        </div>
                      </div>
                      <div className="colon">:</div>
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(hours).decimalPart}
                        </div>
                      </div>
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(hours).reminder}
                        </div>
                      </div>
                      <div className="colon">:</div>
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(minutes).decimalPart}
                        </div>
                      </div>
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(minutes).reminder}
                        </div>
                      </div>
                      <div className="colon">:</div>
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(seconds).decimalPart}
                        </div>
                      </div>
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(seconds).reminder}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between time-container-site">
                      <div className="time-detail">Days</div>
                      <div className="time-detail">Hours</div>
                      <div className="time-detail">Minutes</div>
                      <div className="time-detail">Seconds</div>
                    </div>
                  </div>
                )}

                {challengeData?.challange_info?.challange_completed_days && (
                  <div className="col-12 ps-lg-2 ps-md-1 mt-4">
                    <div className="d-flex align-items-end">
                      <div className="w-100 ms-2">
                        <div className="text-end days-in-total-text">
                          <p>
                            <span>Days in Total : </span>
                            {no_of_days +
                              1 +
                              "/" +
                              challengeData?.challange_info?.no_of_days}
                          </p>
                        </div>
                        <div class="habits-slider d-flex justify-content-between">
                          <Slider
                            style={{
                              color: get_root_value("--portal-theme-primary"),
                            }}
                            aria-label="Custom marks"
                            valueLabelFormat={`${
                              challengeData.challange_info
                                .challange_completed_days > 1
                                ? "Days"
                                : "Day"
                            } ${
                              challengeData.challange_info
                                .challange_completed_days
                            }`}
                            defaultValue={
                              (challengeData.challange_info
                                .challange_completed_days /
                                challengeData?.challange_info?.no_of_days) *
                              100
                            }
                            marks={Days}
                            valueLabelDisplay="on"
                            getAriaValueText={valuetext}
                            step={10}
                            className="mui-slider mt-4"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <div className="row">
            <div className="col-md-12 mt-4">
              {!challengeData.challange_info?.is_complete ? (
                <div className="text-end visioneer-btn mark-complete-check-box-lable">
                  <button onClick={deadline ? handleUpdate : handleSubmit}>
                    {isLoadingForm ? (
                      "Saving ..."
                    ) : (
                      <>
                        Mark Complete For Today
                        <input type="checkbox" id="MarkComplete" checked />
                        <label htmlFor="MarkComplete"></label>
                      </>
                    )}
                  </button>
                </div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: challengeData.challenge_completion_message,
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isCompleted && <CongratulationAnimation rewardData={rewardData} />}
      <CreatePost
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        rewardData={rewardData}
      />

      <GeneralModelBox
        open={openCongratulation}
        setOpen={setOpenCongratulation}
        componentToPassDown={
          <CongratulationsPopup
            handleOpenCreateModal={handleOpenCreateModal}
            rewardData={rewardData}
          />
        }
      />
    </>
  );
}

export default LifeScript;
