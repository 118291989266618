import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { htmlDecode } from "src/utils/convertHtml";
import MissionLevelsOverviewTab from "../../components/MissionLevels/MissionLevelsOverviewTab";
import MissionLevelsScheduleTab from "../../components/MissionLevels/MissionLevelsScheduleTab";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import UnlockVideo from "src/components/MissionLevels/UnlockVideo";
import {
  mission_level_detail_api,
  start_mission_api,
  unlock_mission_schedule_api,
} from "src/DAL/MissionLevels/MissionLevels";
import { ReactVideoPlayer } from "src/components";
import { s3baseUrl } from "src/config/config";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { dd_date_format, number_with_comma } from "src/utils/constants";
import StartMission from "src/components/MissionLevels/StartMission";
import moment from "moment";
import ApexChart from "./ApexChart";
import OverView from "./OverView";
import MainDashBoard from "./MainDashBoard";
import MissionFeedDetailNew from "./MissionFeedDetailNew";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MissionDetailNew() {
  const { mission__id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openMissionConfirm, setOpenMissionConfirm] = useState(false);
  const [openUnlock, setOpenUnlock] = useState(null);
  const [missionDetail, setMissionDetail] = useState({});
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [unLockCoin, setUnLockCoin] = useState(0);
  const [userCoin, setUserCoin] = useState(0);

  const [tabValue, setTabValue] = useState(0);
  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClick = (schedule) => {
    const today = moment();
    const startedAt = moment(schedule.started_at);
    setSelectedSchedule(schedule);
    if (
      missionDetail.mission_status === "not_started" &&
      schedule.schedule_status === "locked"
    ) {
      return;
    }

    if (schedule.schedule_status === "locked") {
      if (startedAt.isAfter(today, "day")) {
        let error = `You can't unlock it before ${dd_date_format(
          schedule.started_at
        )}`;
        enqueueSnackbar(error, { variant: "error" });
        return;
      }
      setOpenUnlock(1);
      return;
    }
    navigate(`/mission-levels/schedule-details/${mission__id}`, {
      state: schedule,
    });
  };

  const handleClickStart = () => {
    if (missionDetail.mission_locked) {
      enqueueSnackbar(
        "You can't start this mission because your level does not match with the level of this mission",
        {
          variant: "error",
        }
      );
      return;
    }
    setOpenUnlock(2);
  };

  const handleCrossUnlock = () => {
    setSelectedSchedule({});
    setOpenUnlock(null);
  };

  const get_mission_detail = async () => {
    const result = await mission_level_detail_api(mission__id);
    if (result.code == 200) {
      setMissionDetail({ ...result.mission, user_data: result.user_data });
      setSchedules(result.mission_schedules);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleStartMission = async () => {
    const result = await start_mission_api(mission__id);
    if (result.code == 200) {
      let mission_status = "in_progress";
      let user_data = result.user_data;
      setMissionDetail((old) => ({ ...old, mission_status, user_data }));
      setSchedules(result.userMission.mission_schedules);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      setOpenMissionConfirm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenMissionConfirm(false);
      setIsLoading(false);
    }
  };

  const handleUnlock = (unlock_coin, user_coin) => {
    if (user_coin < unlock_coin) {
      enqueueSnackbar("You do not have enough coins to start Schedule", {
        variant: "error",
      });
      return;
    }
    setOpenConfirm(true);
    setUnLockCoin(unlock_coin);
    setUserCoin(user_coin);
    setTimeout(() => {
      setOpenUnlock(null);
    }, 500);
  };

  const handleMissionStart = (unlock_coin, user_coin) => {
    if (user_coin < unlock_coin) {
      enqueueSnackbar("You do not have enough coins to start this Mission", {
        variant: "error",
      });
      return;
    }
    setUnLockCoin(unlock_coin);
    setUserCoin(user_coin);
    setOpenMissionConfirm(true);
    setTimeout(() => {
      setOpenUnlock(null);
    }, 100);
  };

  const handleConfirmSubmit = async () => {
    setIsSubmitting(true);
    const result = await unlock_mission_schedule_api(selectedSchedule._id);
    if (result.code == 200) {
      setSchedules((old) =>
        old.map((item) => {
          if (item._id === selectedSchedule._id) {
            item.schedule_status = "in_progress";
          }
          return item;
        })
      );
      setOpenConfirm(false);
      setIsSubmitting(false);
      setOpenUnlock(null);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenConfirm(false);
      setIsSubmitting(false);
    }
  };

  // const TABS_DATA = {
  //   handleClick,
  //   schedules,
  //   missionDetail,
  //   handleClickStart,
  // };

  const TABS_OPTIONS = [
    {
      title: "Dashboard",
      component: <MainDashBoard />,
    },
    {
      title: "Overview",
      component: <OverView />,
    },
    {
      title: "Community",
      component: <MissionFeedDetailNew />,
    },
  ];

  // useEffect(() => {
  //   get_mission_detail();
  // }, []);

  // if (isLoading) {
  //   return <CircularProgress className={classes.loading} color="primary" />;
  // }

  return (
    <>
      <div className="container">
        <div className="row mobile-margin">
          <div className="col-12">
            <div className="d-flex challenge-video-detail-title">
              {/* <span>
                <IconButton
                  className="back-screen-button"
                  onClick={() => navigate("/mission-levels")}
                >
                  <ArrowBackIcon />
                </IconButton>
              </span> */}
              <h2 className="ms-2">
                All Starts Here - Lets Begin Your Journey
              </h2>
            </div>
          </div>

          <div className="col-12 mt-3 d-block  level-icons position-relative">
            <div className="d-flex align-items-center level-icons mb-3 missionIcons">
              <img
                src="https://dd-feature-app-bucket.s3.amazonaws.com/badge/8ee1d5bf-6108-4d13-bcca-310a97d480d2.webp"
                alt=""
              />
              <img
                src="https://dd-feature-app-bucket.s3.amazonaws.com/badge/ae7a5ba1-f7b0-42b2-b27a-4d4c0ebdc6ed.webp"
                alt=""
              />
              <img
                src="https://dd-feature-app-bucket.s3.amazonaws.com/badge/4cf07bf7-d76f-466d-a4f0-22d7bd200c89.jpg"
                alt=""
              />
            </div>

            <MUICustomTabs
              data={TABS_OPTIONS}
              value={tabValue}
              handleChange={handleChange}
              // className="challenge-video-detail-tabs"
            />
          </div>
        </div>
      </div>
    </>
  );
}
