import React from "react";

export default function DashboardNotificationCard({ data }) {
  return (
    <div className="dashboard-notification-card">
      <div className="d-flex">
        <img src={data.icon} alt="" className="warning-icon" />
        <div
          dangerouslySetInnerHTML={{
            __html: data.description,
          }}
          className="ps-2 w-100"
        ></div>
      </div>
    </div>
  );
}
