import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexChartDonat = () => {
  const series = [44, 55];
  const options = {
    chart: {
      type: "donut",
      height: 100, // Set the desired chart height
      width: 100, // Set the desired chart width
    },
    labels: ["Completed", "Uncompleted"],
    colors: ["#f6bd4b", "#f6bd4b50"],
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
          labels: {
            show: true,
            total: {
              show: false, // Hide the total label
            },
          },
        },
      },
    },
    fill: {
      colors: ["#f6bd4b", "#f6bd4b50"],
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "10px", // Adjust the font size here
        colors: ["#ffffff"], // Set the font color to white
      },
    },
    legend: {
      position: "bottom",
      labels: {
        colors: "#ffffff", // Set indicators (labels) color to white
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
            height: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div style={{ width: "75%", margin: "auto" }}>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="donut" />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChartDonat;
