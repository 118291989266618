import { Icon } from "@iconify/react";
import { Avatar, Tooltip } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { communityRectangleImage } from "src/assets";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import { s3baseUrl } from "src/config/config";
import {
  get_date_with_user_time_zone,
  get_short_string,
  is_small_screen,
  LONG_TEXT_LIMIT,
  string_avatar,
} from "src/utils/constants";
import { htmlDecode, urlify } from "src/utils/convertHtml";
import SingleChildInception from "./SingleChildInception";
import { communityHandScreenshot, communityHeart } from "src/assets";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

export default function SingleCommentInception(props) {
  const {
    comment,
    handleCommentEdit,
    handleCommentDelete,
    handleCommentPin,
    handleOpenReplyBox,
    handleLikeComment,
    eventDetail,
    handleOpenLikes,
    is_pinned,
  } = props;

  const settings = useContentSetting();
  const [isShowCommentMore, setIsShowCommentMore] = useState(true);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleCommentEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleCommentDelete,
    },
  ];

  const get_short_date = () => {
    let from_now = moment(
      get_date_with_user_time_zone(
        comment?.createdAt,
        "DD MMM YYYY hh:mm A",
        settings.userInfo.time_zone,
        settings.adminTimeZone
      )
    );

    // from_now = from_now.replace("a few seconds ago", "just now");
    // from_now = from_now.replace("seconds", "s");
    // from_now = from_now.replace("minutes", "m");
    // from_now = from_now.replace("hours", "h");
    // from_now = from_now.replace("day", "d");
    // console.log(from_now._i, "from_nowfrom_nowfrom_nowfrom_now");
    return from_now?._i;
  };

  return (
    <div>
      <div className={"profile-comments inception p-2 mb-1 pb-1"}>
        <div className="d-flex position-relative">
          <div className="poster-name-image d-flex w-100">
            <Avatar
              src={s3baseUrl + comment?.member?.profile_image}
              alt="photoURL"
              style={{ width: 30, height: 30 }}
            >
              {string_avatar(comment?.member?.first_name)}
            </Avatar>
            <div className="w-100">
              <div className="text-start set-title-width poster-name ps-1 w-100">
                <span className="card-title mb-0 fs-12 fw-500">
                  {htmlDecode(
                    comment?.member?.first_name +
                      " " +
                      comment?.member?.last_name
                  )}
                </span>

                {comment.is_featured && (
                  <Icon
                    icon="bi:pin-angle-fill"
                    className="pinned-icon ms-2"
                  ></Icon>
                )}
                {!is_small_screen() && (
                  <span className="date-color float-end pt-0 fw-400 fs-8">
                    {get_short_date()}
                  </span>
                )}
              </div>
              {is_small_screen() && (
                <span className="date-color ps-1 pt-0">
                  {get_date_with_user_time_zone(
                    comment?.createdAt,
                    "DD MMM YYYY [at] hh:mm A",
                    settings.userInfo.time_zone,
                    settings.adminTimeZone
                  )}
                </span>
              )}
              <div className="ps-1 pt-0 fw-400 live-event-comment-message">
                {comment?.message?.length > 200 ? (
                  isShowCommentMore ? (
                    <>
                      <span className="text-start mb-0 pt-0 pb-1">
                        {get_short_string(
                          `${htmlDecode(comment?.message)}`,
                          LONG_TEXT_LIMIT
                        )}
                      </span>
                      <span
                        className="ms-2 see-more"
                        onClick={() => {
                          setIsShowCommentMore(false);
                        }}
                      >
                        See More
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: urlify(htmlDecode(comment?.message)),
                        }}
                        className="text-start mb-0"
                      ></span>
                      <span
                        className="ms-2 see-more"
                        onClick={() => {
                          setIsShowCommentMore(true);
                        }}
                      >
                        See Less
                      </span>
                    </>
                  )
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: urlify(htmlDecode(comment?.message)),
                    }}
                    className="text-start mb-0 pt-0"
                  ></span>
                )}
                {comment.like_count > 0 && (
                  <Tooltip title={`${comment.like_count} Likes`}>
                    <div
                      className="d-flex comment-like-heart live-event-comment-message chat-history-comment"
                      onClick={() => {
                        handleOpenLikes(comment);
                      }}
                    >
                      <div className="comment-like-heart-image">
                        <img src={communityHeart} alt="" />
                      </div>
                      {comment.like_count > 0 && (
                        <div className="comment-like-heart-count">
                          {comment.like_count}
                        </div>
                      )}
                    </div>
                  </Tooltip>
                )}
              </div>
              <div className="col-12">
                {comment.file_url && (
                  <a
                    href={s3baseUrl + comment.file_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="comment-view-attachment"
                  >
                    <span className="chat-image-preview mt-2">
                      <img src={s3baseUrl + comment.file_url} />
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>
          {/* {comment?.comment_is_self === true && (
            <div className="affirmation-dots">
              <CustomPopover menu={MENU_OPTIONS} data={comment} />
            </div>
          )} */}
        </div>

        {/* <div className="like-reply-inception-box pt-2 py-1 px-2">
          {!is_pinned && (
            <div
              className="comment-reply action-box"
              onClick={() => {
                handleOpenReplyBox(comment);
              }}
            >
              <ChatBubbleOutlineIcon /> Reply
            </div>
          )}
          <div
            className="comment-like action-box"
            onClick={() => {
              handleLikeComment(comment);
            }}
          >
            {comment.is_liked ? (
              <>
                <img src={communityHeart} alt="" /> Liked
              </>
            ) : (
              <>
                <FavoriteBorderIcon /> Like
              </>
            )}
          </div>
        </div> */}
      </div>

      {comment?.replies?.length > 0 &&
        comment?.replies.map((reply, index) => {
          return (
            <div
              className="ps-3"
              key={index}
              style={{
                backgroundColor: "#000",
              }}
            >
              <SingleChildInception
                reply={reply}
                handleCommentEdit={handleCommentEdit}
                handleCommentDelete={handleCommentDelete}
                handleLikeComment={handleLikeComment}
                comment={comment}
                handleOpenLikes={handleOpenLikes}
              />
            </div>
          );
        })}
    </div>
  );
}
