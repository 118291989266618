import { CircularProgress, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { requestDetailApi } from "src/DAL/Payment/Payment";
import TransactionList from "../Billing/TransactionList";
import RequestCard from "./RequestCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ActiveRequests = () => {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const [requestData, setRequestData] = useState({});
  const [transactions, setTransactions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const is_transaction = window.location.pathname.includes("transaction");
  const { enqueueSnackbar } = useSnackbar();

  const getRequestDetail = async () => {
    const result = await requestDetailApi(params.request_slug);
    if (result.code === 200) {
      setRequestData(result.payment_request_info);
      setTransactions(result.payment_request_transaction);
      setIsLoading(false);
    } else {
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/payment-request`);
    }
  };

  const handleNavigate = () => {
    if (is_transaction) {
      navigate(`/transaction`);
    } else {
      navigate(`/payment-request/accepted`);
    }
  };

  useEffect(() => {
    getRequestDetail();
  }, [params.request_slug]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <IconButton
        className="back-screen-button"
        onClick={() => handleNavigate()}
      >
        <ArrowBackIcon />
      </IconButton>
      <h2 className="mb-3">Payment Request Information</h2>
      <RequestCard bill={requestData} signButton={true} />
      <div className="col-12">
        <TransactionList transactions={transactions} />
      </div>
    </div>
  );
};

export default ActiveRequests;
