import { Avatar, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { s3baseUrl } from "src/config/config";
import {
  get_date_with_user_time_zone,
  get_date_with_user_time_zone_only,
  string_avatar,
} from "src/utils/constants";
import { _add_chat, _get_chat_message, _send_message } from "src/DAL/Chat/Chat";
import { useEffect } from "react";
import { useChat } from "src/Hooks/ChatContext/ChatContext";

import { Icon } from "@iconify/react";
import { update_image_on_s3_for_chat } from "src/DAL/LiveEvents/LiveEvents";
import ChatMessages from "src/pages/Chat/components/ChatMessages/ChatMessages";
function ChatBox(props) {
  const { selectedChat, selectedUser, handleCloseChat } = props;

  const [isExpandedChat, setIsExpandedChat] = useState(false);
  console.log(window.screen.width, "window.width");
  const bottom_in_chat = {
    hidden: {
      y: "100px",
      x: "0px",
      height: "0",
      opacity: 1,
      scale: 1,
    },
    visible: {
      opacity: 1,
      scale: 1,
      height: "60vh",
      // height: window.screen.width < 540 ? "45vh" : "60vh",
      y: "0px",
      x: "0px",
      transition: { delay: 0, duration: 0.5 },
    },
  };

  const profile = selectedUser ? selectedUser : selectedChat.receiver_profile;

  useEffect(() => {
    // for pop-up the chat
    setIsExpandedChat(true);
  }, [selectedUser?._id]);
  return (
    <>
      {/* Chat box */}

      <div
        className="user-chat-box-header d-flex align-items-center justify-content-between"
        onClick={() => setIsExpandedChat(!isExpandedChat)}
      >
        <div className="d-flex align-items-center">
          <div className="user-profile">
            <Avatar
              src={s3baseUrl + profile.profile_image}
              style={{ height: 30, width: 30 }}
            >
              {string_avatar(profile.first_name[0])}
            </Avatar>
            <div className={profile.is_online ? "online" : "offline"}></div>
          </div>
          <div className="user-box-heading ms-2">
            {profile.first_name + " " + profile.last_name}
          </div>
        </div>
        <div className="d-flex align-items-center">
          {/* {my_profile.unread_message_count > 0 && (
            <div className="chat-unread-count-style m-2">
              {my_profile.unread_message_count}
            </div>
          )} */}
          {isExpandedChat ? (
            <Icon icon="akar-icons:chevron-down" />
          ) : (
            <Icon icon="akar-icons:chevron-up" />
          )}
          <Icon
            onClick={handleCloseChat}
            className="ms-2 chat-box-cross-icon"
            icon="charm:cross"
          />
        </div>
      </div>

      <motion.div
        variants={bottom_in_chat}
        initial="hidden"
        animate={isExpandedChat ? "visible" : "hidden"}
        exit="hidden"
        className={""}
      >
        <ChatMessages is_popup={true} isExpandedChat={isExpandedChat} />
      </motion.div>
    </>
  );
}

export default ChatBox;
