import React, { useEffect, useState } from "react";
import { CircularProgress, Divider } from "@mui/material";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import GeneralQuestionsList from "../GeneralQuestions/GeneralQuestionsList";
import StartDateCard from "src/components/NintyDay/StartDateCard";
import { questions_list_for_ninety_day_api } from "src/DAL/90Day/NinetyDay";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const NintyDayPlan = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [startDateUpdated, setStartDate] = useState(null);
  const [targetAmount, setTargetAmount] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [resultData, setResultData] = useState({});
  const [contentSettings, setContentSetting] = useState({});

  const get_ninety_day_plan_info = async () => {
    const result = await questions_list_for_ninety_day_api();
    if (result.code == 200) {
      setResultData(result);
      setContentSetting(result.ninety_day_content_settings);
      let { start_date, target_amount } = result.user_ninety_day_plan;
      if (target_amount) {
        setTargetAmount(target_amount);
      }
      if (start_date && start_date !== "Invalid date") {
        setStartDate(start_date);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/ninety-day-plan/previous-histories`);
  };

  const handleClosePlan = () => {
    setTargetAmount("");
    setStartDate(null);
    setResultData((old) => ({
      ...old,
      questionnaire_replies: [],
      is_history_available: true,
    }));
  };

  useEffect(() => {
    get_ninety_day_plan_info();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <PageDescription
          parameter="ninteen_day_plan_description"
          else_title="90 Day Plan"
        />
        <div className="row mt-3">
          <div className="col-12 mt-4">
            <Divider />
          </div>
          {startDateUpdated && (
            <div className="col-12 mt-5">
              <StartDateCard
                startDateUpdated={startDateUpdated}
                targetAmount={targetAmount}
                setStartDate={setStartDate}
                setTargetAmount={setTargetAmount}
                isPlan={true}
                contentSettings={contentSettings}
                onClosePlan={handleClosePlan}
              />
            </div>
          )}
        </div>
      </div>
      {contentSettings.formula_history_button_text &&
        resultData.is_history_available && (
          <div className="text-end mb-3 mt-4">
            <button className="small-contained-button" onClick={handleNavigate}>
              {contentSettings.formula_history_button_text}
            </button>
          </div>
        )}
      <GeneralQuestionsList
        heading="90 Day Questions"
        question_answers={resultData.questionnaire_replies}
        questions_list={resultData.questionnaire}
        isDisabled={!Boolean(startDateUpdated)}
        remove_back_button={true}
        created_for="90-day-questions"
        disableTooltip={contentSettings.tooltip_text_for_unset_date}
      />
    </>
  );
};

export default NintyDayPlan;
