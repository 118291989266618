import * as React from "react";
import { s3baseUrl } from "../../config/config";
import CustomPopover from "./CustomPopover";
import CustomConfirmation from "./CustomConfirmation";
import { htmlDecode } from "../../utils/convertHtml";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import RecordNotFound from "./RecordNotFound";
function PinterestLayout({
  open,
  setOpen,
  menuOptions,
  data,
  title,
  handleAgree,
  handleDetailPage,
  notFoundtitle,
  curserPointer,
}) {
  const { userInfo, adminTimeZone } = useContentSetting();
  return (
    <>
      {data.length > 0 ? (
        <div id="columns" className={`${curserPointer ? "show-pointer" : ""}`}>
          {data.map((value, index) => (
            <div
              key={value.pinteres_id}
              className="set-display-inline pinterest-card"
            >
              <figure>
                <img
                  className="grid-item"
                  src={s3baseUrl + value.pinterestImage}
                  alt=""
                  onClick={() => handleDetailPage(value.pinterestSlug)}
                />
                {value.pinterestDate && (
                  <span>
                    {get_date_with_user_time_zone(
                      value.pinterestDate,
                      "DD MMM YYYY",
                      adminTimeZone,
                      userInfo.time_zone
                    )}
                  </span>
                )}
                {menuOptions && (
                  <CustomPopover menu={menuOptions} data={value} />
                )}
                <p
                  dangerouslySetInnerHTML={{
                    __html: htmlDecode(value.pinterestDescription),
                  }}
                  className="programme-card-desc"
                  onClick={() => handleDetailPage(value.pinterestSlug)}
                ></p>
              </figure>
            </div>
          ))}
          <CustomConfirmation
            open={open}
            setOpen={setOpen}
            title={title}
            handleAgree={handleAgree}
          />
        </div>
      ) : (
        <RecordNotFound title={notFoundtitle} />
      )}
    </>
  );
}

export default PinterestLayout;
