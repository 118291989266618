import { CircularProgress, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  requestDetailApi,
  request_verification_Api,
} from "src/DAL/Payment/Payment";
import TransactionList from "../Billing/TransactionList";
import RequestCard from "./RequestCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const RequestVerification = (props) => {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  //Getting lesson detail

  const getRequestDetail = async () => {
    setIsLoading(true);
    const result = await request_verification_Api(params.token);
    if (result.code === 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result.user_data));
      localStorage.setItem("token", result.token);
      window.open(result?.request_url, "_self");
    } else {
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate("/");
    }
  };

  useEffect(() => {
    localStorage.clear();
    getRequestDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return <div className="container"></div>;
};

export default RequestVerification;
