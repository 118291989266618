import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CircularProgress, Pagination, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { UserListHead, UserListToolbar } from "src/components/_dashboard/user";
import SearchNotFound from "src/components/SearchNotFound";
import moment from "moment";
import { filter } from "lodash";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  DeleteSupportTicket,
  get_list_support_ticket_with_pagination,
  markResolved,
} from "src/DAL/SupportTicket/SupportTicket";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import AddOrUpdateTickets from "src/components/SupportTickets/AddOrUpdateTickets";
import CustomPopovers from "src/components/GeneralComponents/CustomPopovers";
import Label from "src/components/Label";
import {
  cancelSubscriptionsListApi,
  getSubscriptionsListApi,
  getSubscriptionsListApiUpdated,
} from "src/DAL/Profile/Profile";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function CancelSubscription({
  tabValue,
  valueData,
  setAnswered,
  setSolved,
  setOpen,
  CloseTab,
  waitingTab,
}) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [drawerState, setDrawerState] = useState(false);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState();
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [order, setOrder] = useState("asc");
  const [totalPages, setTotalPages] = useState(0);
  const [orderBy, setOrderBy] = useState("number");
  const [openDelete, setOpenDelete] = useState(false);
  const [userList, setUserList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [editData, setEditData] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [ticketId, setTicketId] = useState("");
  const [openResolved, setOpenResolved] = useState(false);
  const [memberCounts, setmemberCounts] = useState("");
  const [memberId, setMemberId] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const is_trash_path = window.location.pathname.includes(
    "trash-support-ticket"
  );
  const path = window.location.pathname;
  const { userInfo, adminTimeZone } = useContentSetting();
  const [filterNameStatus, setFilterNameStatus] = useState(false);
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const getTickestsListing = async () => {
    setIsLoading(true);
    try {
      const result = await getSubscriptionsListApiUpdated();
      if (result.code === 200) {
        const data = result.subscription.map((ticket, index) => {
          let sale_page_title = "N/A";

          let Product = "N/A";
          let plan_title = "N/A";
          if (ticket?.plan) {
            plan_title = ticket?.plan?.plan_title;
          }
          if (ticket.sale_page) {
            sale_page_title = `Sale Page (${ticket?.sale_page?.sale_page_title} | ${plan_title})`;
          }
          let payment_request = "N/A";
          if (ticket?.payment_request_info) {
            payment_request = `Payment Request (${ticket?.payment_request_info.request_title} | ${ticket?.payment_request_info.request_type})`;
          }
          if (ticket?.subscription_created_by == "client") {
            Product = payment_request;
          } else {
            Product = sale_page_title;
          }

          return {
            id: ticket?._id,
            name: ticket?.sale_page?.sale_page_title,
            planName: ticket?.plan?.plan_title,
            Product,
            status: ticket?.subscription_status,
            cancelStatus: ticket?.cancelation_requested,
            stripe_mode: ticket?.stripe_mode,
            subscription_date: ticket?.subscription_date,
            next_invoice_date: ticket?.next_invoice_date,
            subscription_cancelled_date: ticket?.subscription_cancelled_date,
            object: ticket,
            count: index + 1 + rowsPerPage * page,
          };
        });

        setUserList(data);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false },
    { id: "subject", label: "Product", alignRight: false },

    // { id: "stripe_mode", label: "Stripe Mode", alignRight: false },
    { id: "created", label: "Subscription Date", alignRight: false },
    // { id: "created", label: "Next Invoice Date", alignRight: false },
    // { id: "created", label: "Expire On", alignRight: false },
    { id: "created", label: "Status", alignRight: false },
    { id: "action", label: "Action", alignCenter: true },
  ];
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };
  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    console.log(array, "arrayarrayarray");
    if (query) {
      return filter(
        array,
        (_user) =>
          _user?.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOpenDrawer = (value) => {
    setDrawerState(true);
    setTicketId(value.id);
    setReminderMessage(value);
  };
  const isUserNotFound = filteredUsers.length === 0;
  const hanleChangePage = (value) => {
    navigate(`/support-ticket/${value.id}`, {
      state: { value, tabValue },
    });
  };
  const handleNavigate = () => {
    setMemberId("");
    navigate("/support-ticket", { state: tabValue });
  };
  const handleAgreeDelete = (value) => {
    setTicketId(value.id);
    setOpenDelete(true);
  };
  const hanleChangeSubscription = (value) => {
    setTicketId(value.id);
    setOpenDelete(true);
  };
  const handleEdit = (value) => {
    navigate(`/manage-subscription/${value.id}`);
    // setEditData(value);
    // setEditDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };
  const handleMarkResolvedPopup = (value) => {
    setTicketId(value.id);
    setOpenResolved(true);
  };
  const MENU_OPTIONS1 = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: handleEdit,
    },
  ];
  const MENU_OPTIONS = [
    {
      label: "Detail",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Cancel Subscription",
      icon: "akar-icons:edit",
      handleClick: hanleChangeSubscription,
    },
  ];

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  console.log(filterName, "filterNamefilterNamefilterNamefilterNamefilterName");
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await cancelSubscriptionsListApi(ticketId);
    if (result.code === 200) {
      getTickestsListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleMarkResolved = async () => {
    setOpenResolved(false);
    setIsLoading(true);
    const result = await markResolved(ticketId);
    if (result.code === 200) {
      getTickestsListing("all");
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/support-ticket`);
    }
  };

  const resolve_date = {
    id: "created",
    label: "Resolved Date",
    alignRight: false,
  };
  if (CloseTab) {
    TABLE_HEAD.splice(3, 1, resolve_date);
  }
  console.log(CloseTab, "CloseTabCloseTab");
  useEffect(() => {
    getTickestsListing(is_trash_path ? "trash" : "");
  }, [is_trash_path, rowsPerPage, page, path]);
  useEffect(() => {
    if (!filterNameStatus) {
      return;
    }
    getMemberListing();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  moment.tz.setDefault(userInfo.time_zone);

  return (
    <div className="container">
      <h2 className="mb-3">Subscriptions</h2>
      <div className="row mobile-margin display-flex">
        <Card className="card" style={{ overflowX: "auto" }}>
          <div className="col-12">
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          </div>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const {
                      id,
                      name,
                      planName,
                      Product,
                      count,
                      subject,
                      status,
                      cancelStatus,
                      stripe_mode,
                      subscription_date,
                      next_invoice_date,
                      subscription_cancelled_date,
                    } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="left">{count}</TableCell>
                        <TableCell
                          className="cursor-pointer"
                          onClick={() => handleEdit(row)}
                        >
                          <Tooltip title={stripe_mode}>
                            <p>{Product}</p>
                          </Tooltip>
                        </TableCell>

                        {/* <TableCell
                          component="th"
                          scope="row"
                          className="cursor-pointer"
                          // onClick={() => hanleChangePage(row)}
                        >
                          {stripe_mode}
                        </TableCell> */}
                        <TableCell
                          component="th"
                          scope="row"
                          className="cursor-pointer"
                          onClick={() => handleEdit(row)}
                        >
                          {moment(subscription_date).format("DD-MM-YYYY")}
                        </TableCell>
                        {/* <TableCell
                          component="th"
                          scope="row"
                          className="cursor-pointer"
                          // onClick={() => hanleChangePage(row)}
                        >
                          {moment(next_invoice_date).format("DD-MM-YYYY")}
                        </TableCell> */}
                        {/* <TableCell
                          component="th"
                          scope="row"
                          className="cursor-pointer"
                          // onClick={() => hanleChangePage(row)}
                        >
                          {cancelStatus
                            ? moment(subscription_cancelled_date).format(
                                "DD-MM-YYYY"
                              )
                            : "N/A"}
                        </TableCell> */}
                        {
                          <TableCell
                            align="left"
                            component="th"
                            scope="row"
                            className="cursor-pointer"
                            style={{ width: "250px" }}
                            // onClick={() => hanleChangePage(row)}
                          >
                            {cancelStatus && status && (
                              <Label
                                variant="ghost"
                                className={`${
                                  status == true ? "success" : "error"
                                }
                                 `}
                              >
                                {status ? "Active" : "Expire"}
                              </Label>
                            )}
                            <Label
                              variant="ghost"
                              className={`ms-1 ${
                                cancelStatus == false && status == true
                                  ? "success"
                                  : cancelStatus == true && status == true
                                  ? "cancel"
                                  : "error"
                              }
                                 `}
                            >
                              {!cancelStatus && status
                                ? "Active"
                                : cancelStatus && !status
                                ? "Cancelled"
                                : cancelStatus && status
                                ? "Cancels " +
                                  moment(subscription_cancelled_date).format(
                                    "DD MMM"
                                  )
                                : "Expire"}
                            </Label>
                          </TableCell>
                        }
                        {/* {CloseTab && (
                          <TableCell
                            component="th"
                            scope="row"
                            className="cursor-pointer"
                            onClick={() => hanleChangePage(row)}
                          >
                            {resolve_date !== "empty"
                              ? get_date_with_user_time_zone(
                                  resolve_date,
                                  "YYYY-MM-DD hh:mm A",
                                  userInfo.time_zone,
                                  adminTimeZone
                                )
                              : "N/A"}
                          </TableCell>
                        )} */}
                        <TableCell style={{ textAlign: "left" }}>
                          {!cancelStatus && status ? (
                            <CustomPopovers menu={MENU_OPTIONS} data={row} />
                          ) : (
                            <CustomPopovers menu={MENU_OPTIONS1} data={row} />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[2, 5, 10, 20, 50]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* <Pagination
            count={totalPages}
            page={pageCount}
            defaultPage={0}
            onChange={handleChangePages}
            className="pagination-style"
          /> */}
        </Card>
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to Cancel this Subscription?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openResolved}
        setOpen={setOpenResolved}
        title={"Are you sure you want to mark this ticket as resolved?"}
        handleAgree={handleMarkResolved}
      />
      <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Edit Support Ticket"
        componentToPassDown={
          <AddOrUpdateTickets
            editData={editData}
            formType="EDIT"
            dataList={getTickestsListing}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      />
    </div>
  );
}
export default CancelSubscription;
