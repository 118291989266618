import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "../../config/config";
import { ReactVideoPlayer } from "src/components";
import { useSnackbar } from "notistack";
import {
  ProgramRecordingDetail,
  LessonRecordingDetail,
} from "../../DAL/Programmes/Programmes";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import ReactVideoDurationPlayer from "src/components/ReactPlayers/ReactVideoDurationPlayer";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function ProgrammRecordingDetail(props) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [videoProgressData, setVideoProgressData] = useState([]);
  const [recordingData, seRecordingData] = React.useState({});
  const handleNavigate = () => {
    navigate(-1);
  };

  const getRecordingDetail = async () => {
    setIsLoading(true);
    let result = {};
    if (location.pathname.includes("lessons-recordings") == true) {
      result = await LessonRecordingDetail(params.recording_slug);
    } else {
      result = await ProgramRecordingDetail(params.recording_slug);
    }

    if (result.code === 200) {
      let data = [
        {
          recording_id: result.recording?._id,
          video_duration: result.recording?.video_duration,
          total_video_duration: result.recording?.total_video_duration,
          is_complete: result.recording?.is_complete,
        },
      ];
      setVideoProgressData(data);
      localStorage.setItem("video_list", JSON.stringify(data));
      seRecordingData(result.recording);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  useEffect(() => {
    getRecordingDetail();
  }, [params.recording_slug]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button"
              onClick={() => handleNavigate()}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h1 className="programmes-heading ms-1">
            {htmlDecode(recordingData.title)}
          </h1>
        </div>
      </div>

      <div className="row media-margin">
        <div className="col-12">
          <ReactVideoDurationPlayer
            url={recordingData.video_url}
            type={
              location.pathname.includes("lessons-recordings") == true
                ? "lesson_recording"
                : "program_recording"
            }
            SaveInfo={
              location.pathname.includes("lessons-recordings") == true
                ? "lesson_recording"
                : "program_recording"
            }
            value={recordingData}
            setVideoProgressData={setVideoProgressData}
            videoProgressData={videoProgressData}
          />
        </div>

        {recordingData.audio_recording && (
          <div className="col-12 mt-3 text-center">
            <div>
              <audio
                controlsList="nodownload"
                className="w-100"
                src={s3baseUrl + recordingData.audio_recording}
                controls
              />
            </div>
          </div>
        )}
        <div className="col-12 section-space">
          <p>{htmlDecode(recordingData.short_description)}</p>
        </div>
      </div>
    </div>
  );
}

export default ProgrammRecordingDetail;
