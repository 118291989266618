import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "../../config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { source_session_list_api } from "src/DAL/SourceSession/SourceSession";
import SearchIcon from "@mui/icons-material/Search";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function SourceSessionsListing() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [sourceSessionList, setSourceSessionList] = useState({});
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
    localStorage.setItem("sourceSessionList", e.target.value);
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...sourceSessionList];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };

  const handleClick = (object) => {
    navigate(`/source-session/${object._id}/source-session-recordings`);
  };

  const getSourceSessionDetail = async () => {
    const result = await source_session_list_api();
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.source_session.map((source_session_category) => {
        CARD_OPTIONS.push({
          ...source_session_category,
          card_title: source_session_category.title,
          card_short_description: source_session_category.short_description,
          card_image: s3baseUrl + source_session_category.image.thumbnail_1,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
        });
      });
      setSourceSessionList(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  useEffect(() => {
    let sourceSessionList = localStorage.getItem("sourceSessionList");
    if (sourceSessionList) {
      setQuery(sourceSessionList);
    }
    getSourceSessionDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-8">
            <h2>Source Sessions</h2>
          </div>
          <div className="col-4">
            <div>
              <FormControl variant="outlined" style={{ marginTop: "2px" }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  variant="small"
                  value={query}
                  onChange={handleChangeSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </div>
          </div>
        </div>
        {sourceSessionList.length > 0 ? (
          <CustomMUICard
            data={getFilteredData(query)}
            handleClick={handleClick}
          />
        ) : (
          <RecordNotFound title="Source Sessions Not Found" />
        )}
      </div>
    </>
  );
}
