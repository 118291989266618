import React from "react";
import no_access from "src/assets/images/no_access.png";
export default function NoAccess(props) {
  return (
    <div className="no-access-string">
      <img className="mx-auto" src={no_access} />
      <p className="mt-3">{`${
        props.title ? props.title : "You have no access to see this data"
      }`}</p>
    </div>
  );
}
