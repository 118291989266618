import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PaymentRequestsList } from "src/DAL/Payment/Payment";
import MUICustomTabs from "../GeneralComponents/MUICustomTabs";
import RequestsCardComponent from "./RequestsCardComponent";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function PaymentRequests() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [pendingRequest, setPendingRequest] = useState([]);
  const [activeRequest, setActiveRequest] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const accepted = window.location.pathname.includes(
    "/payment-request/accepted"
  );
  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleTabClick = (e, row) => {
    if (row.tab_slug == "accepted") {
      navigate(`/payment-request/accepted`);
    } else {
      navigate(`/payment-request/pending`);
    }
  };
  //Getting lesson detail
  const PaymentRequestsListing = async () => {
    const result = await PaymentRequestsList();
    if (result.code === 200) {
      let all_requests = [];
      result.pending_payment_request.map((request, index) => {
        all_requests.push({
          ...request,
          payment_request_type: "pending",
        });
      });
      setPendingRequest(all_requests);
      setActiveRequest(result.active_payment_request);
      setIsLoading(false);
    } else {
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/mission-control`);
    }
  };

  const TABS_OPTIONS = [
    {
      title: "Pending Requests",
      tab_slug: "pending",
      onClick: handleTabClick,
      component: (
        <RequestsCardComponent
          data={pendingRequest}
          notFoundTitle="Pending Requests Not Found"
        />
      ),
    },
    {
      title: "Accepted Requests",
      tab_slug: "accepted",
      onClick: handleTabClick,
      component: (
        <RequestsCardComponent
          data={activeRequest}
          signButton={true}
          notFoundTitle="Accepted Requests Not Found"
        />
      ),
    },
  ];

  useEffect(() => {
    PaymentRequestsListing();
  }, []);
  useEffect(() => {
    if (accepted) setTabValue(1);
  }, [TABS_OPTIONS]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <h2>Payment Requests</h2>
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12 section-space">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentRequests;
