import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Icon } from "@iconify/react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { challenge_member_list_api } from "src/DAL/ChallengeApi/meditationApi";
import { useState } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { Avatar, CircularProgress, Divider, IconButton } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import closeFill from "@iconify/icons-eva/close-fill";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "45%",
    marginTop: "20%",
  },
}));
export default function CustomListPopup({
  open,
  setOpen,
  scroll,
  setScroll,
  handleClickOpen,
  memberArray,
  totalPages,
  setPageNumber,
  pageNumber,
  isLoadingMore,
  setIsLoadingMore,
  getChallengeMember,
  loadMorePath,
  setLoadMorePath,
  isLoading,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const observer = useRef();
  const classes = useStyles();
  //   const [isLoadingMore, setIsLoadingMore] = useState(false);
  //   const [totalPages, setTotalPages] = useState(0);
  //   const [pageNumber, setPageNumber] = useState(0);
  //   const [loadMorePath, setLoadMorePath] = useState("");
  //   const [memberArray, setMemberArray] = useState([]);

  const loadMoreData = () => {
    setIsLoadingMore(true);
    getChallengeMember(true);
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, loadMorePath, observer]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  //   useEffect(() => {
  //     if (challengeId !== "" && open == true) {
  //       getChallengeMember();
  //     }
  //   }, [challengeId, open]);

  return (
    <div>
      {/* <Button onClick={handleClickOpen("paper")}>scroll=paper</Button> */}

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        // className="box-user"
        PaperProps={{ className: "box-user" }}
      >
        <div className="text-end cursor">
          <IconButton onClick={handleClose}>
            <Icon
              icon={closeFill}
              width={15}
              height={15}
              onClick={handleClose}
            />
          </IconButton>
        </div>
        <DialogTitle id="scroll-dialog-title">
          Currently Doing Challenge
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {isLoading ? (
              <CircularProgress className={classes.loading} color="primary" />
            ) : (
              memberArray.map((val) => {
                return (
                  <>
                    <div className="col-12 d-flex mb-2 member-box">
                      <Avatar
                        sx={{ width: 30, height: 30 }}
                        alt={val.first_name}
                        src={s3baseUrl + val.profile_image}
                      />
                      <p className="ms-3 text-white">
                        {val.first_name + " " + val.last_name}
                      </p>
                    </div>
                    <Divider className="mb-2" />
                  </>
                );
              })
            )}
            {totalPages > pageNumber ? (
              <div className="col-12 text-center p-2">
                <span
                  ref={lastBookElementRef}
                  className=" mt-3"
                  onClick={loadMoreData}
                  id="load-more-feed"
                >
                  {isLoadingMore ? "Loading..." : "Load More"}
                </span>
              </div>
            ) : (
              ""
            )}
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
