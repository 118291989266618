import React from "react";
import { Card, Slider } from "@mui/material";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopover from "../GeneralComponents/CustomPopover";
import { useNavigate } from "react-router-dom";

function HabitsMainCard({ data, handleClick }) {
  const navigate = useNavigate();
  function valuetext(value) {
    return `${value}°C`;
  }

  const handleEdit = () => { }
  const handleAgreeDelete = () => { }

  const hanleChangePage = () => {
    navigate(`/habits-tracker/day-wise-habits`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Day Wise Habits",
      icon: "gg:details-more",
      handleClick: hanleChangePage,
    },
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: handleClick,
    },

  ];

  return (
    <div className="row">
      {data.map((item, index) => {
        return (
          <div className={`col-12 mb-3 habits-box ${item.card_classes}`} key={index}>
            <Card
              className="side-image-mui-card"

            // onClick={
            //   handleClick
            //     ? () => {
            //       handleClick(item);
            //     }
            //     : undefined
            // }
            >
              <CustomPopover menu={MENU_OPTIONS} data={item} />
              <div className="row p-3">
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <img
                    className="side-image-mui-card-image"
                    src={item.image}
                    alt={item.title}
                  />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-12">
                  <h4 className="side-image-mui-card-heading">
                    {htmlDecode(item.title)}
                  </h4>
                  <p className="habits-gray-description mb-2">
                    Target Date : {htmlDecode(item.target_date)}
                  </p>
                  <p className="habits-gray-description mb-">
                    Reminder at {htmlDecode(item.reminder)}
                  </p>
                  <div className="habit-days mt-2">
                    <ul>
                      <li>M</li>
                      <li>T</li>
                      <li>W</li>
                      <li>T</li>
                      <li>F</li>
                      <li>S</li>
                      <li>S</li>
                    </ul>
                  </div>
                  <div className="habits-slider d-flex">
                    <div className="silder-value habits-gray-description">
                      <span>{`${item.progress}%`}</span>
                    </div>
                    <Slider
                      aria-label="Custom marks"
                      defaultValue={item.progress}
                      getAriaValueText={valuetext}
                      step={10}
                      valueLabelDisplay="auto"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
}

export default HabitsMainCard;
