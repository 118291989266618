import React, { useEffect, useState } from "react";
import LeaderBoard from "./components/LeaderBoard";
import { CircularProgress, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import { useSnackbar } from "notistack";
import { leader_board_data_api } from "src/DAL/Community/Community";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AllLeaderBoards() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [leaderBoards, setLeaderBoards] = useState({});
  const [feedSetting, setFeedSetting] = useState({});

  const handleChangePage = () => {
    navigate("/thesource");
  };

  const get_leader_boards_data = async () => {
    const result = await leader_board_data_api();
    if (result.code === 200) {
      setLeaderBoards(result);
      setFeedSetting(result.feed_setting);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_leader_boards_data();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-12 d-flex">
          <IconButton className="back-screen-button" onClick={handleChangePage}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <PageDescription
          parameter="leader_boards_description"
          else_title="Leader Boards"
        />
        <div className="col-12 col-md-6 col-lg-3 mb-3">
          <LeaderBoard
            title={feedSetting.weekly_leaderboard_heading}
            data={leaderBoards.weekly_leader_board}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-3 mb-3">
          <LeaderBoard
            title={feedSetting.monthly_leaderboard_heading}
            data={leaderBoards.monthly_leader_board}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-3 mb-3">
          <LeaderBoard
            title={feedSetting.yearly_leaderboard_heading}
            data={leaderBoards.yearly_leader_board}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-3 mb-3">
          <LeaderBoard
            title={feedSetting.life_time_leaderboard_heading}
            data={leaderBoards.life_time_leader_board}
          />
        </div>
      </div>
    </div>
  );
}
