import React, { useEffect, useRef } from "react";
import Slider from "react-slick";

const SlickCarousel = ({ carouselRef, children, ...rest }) => {
  /* <<--------------------------------------------------------->> */

  /* <<--------------------------------------------------------->> */
  return (
    <Slider
      dots={false}
      ref={carouselRef}
      speed={500}
      // centerPadding="5px"
      slidesToShow={3}
      slidesToScroll={1}
      autoplay
      {...rest}
    >
      {children}
    </Slider>
  );
};

export default SlickCarousel;
