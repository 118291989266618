import * as React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import moment from "moment";

export default function MUIStaticCalendar({
  selectedDate,
  handleChangeDate,
  minDate,
  maxDate
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        className="static-date-picker"
        value={selectedDate}
        onChange={(newValue) => handleChangeDate(newValue)}
        renderInput={(params) => <TextField {...params} />}
        minDate={minDate}
        maxDate={maxDate}
        componentsProps={{
          actionBar: {
            actions: ["today"],
          },
        }}
      />
    </LocalizationProvider>
  );
}
