import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  programmDetail,
  programmModuleDetail,
  programmeViewCountIncrease,
} from "../../DAL/Programmes/Programmes";
import { htmlDecode } from "../../utils/convertHtml";
import { s3baseUrl } from "../../config/config";
import { styled } from "@mui/material/styles";
import { ReactVideoPlayer } from "src/components";
import LessonsCardTab from "src/components/programmes/LessonsCardTab";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import ProgrammeRecordingTab from "src/components/programmes/ProgrammeRecordingTab";
import { NoAccess } from "src/components/programmes";
import ResourcesCardTab from "src/components/programmes/ResourcesCardTab";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import StudySessionCardTabs from "src/components/programmes/StudySessionCardTabs";
import RatingCardTab from "src/components/programmes/RatingCardTab";
import ProgramSectionTabs from "src/components/programmes/ProgramSectionTabs";
import ModuleCardTab from "src/components/programmes/ModuleCardTab";
import ReactVideoDurationPlayer from "src/components/ReactPlayers/ReactVideoDurationPlayer";
import ReactVideoPlayerNew from "src/components/ReactPlayers/ReactVideoPlayerNew";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function ProgrammesModuleDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [programmesDetail, setProgrammesDetail] = useState({});
  const [lessonsList, setLessonsList] = useState([]);
  const [modulesInfo, setModuleInfo] = useState();
  const [resourcesList, setResourcesList] = useState([]);
  const [reviewsList, setReviewsList] = useState([]);
  const [recordingList, setRecordingList] = useState([]);
  const [programSection, setProgramSection] = useState([]);
  const [studySession, setStudySession] = useState([]);
  const [videoProgressData, setVideoProgressData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [watched, setWatched] = useState(false);
  const [programModuleAccess, setProgramModuleAccess] = useState();

  const ProgrammesImgStyle = styled("img")({
    width: "100%",
    height: "100%",
    objectFit: "cover",
  });

  const imageLinks = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const increaseWatchCount = async (type) => {
    const result = await programmeViewCountIncrease(type, params.slug);
    if (result.code === 200) {
      console.log("watched increased");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getProgrammesDetail = async () => {
    const result = await programmModuleDetail(params.slug);
    increaseWatchCount("view");
    if (result.code === 200) {
      setProgrammesDetail(result.program);
      console.log(result, "result for the program module");
      setProgramModuleAccess(result.is_program_module);
      let lessons_array = [];
      result.lesson.map((lesson) => {
        lessons_array.push({
          ...lesson,
          card_title: lesson.title,
          card_image: s3baseUrl + lesson.lesson_images?.thumbnail_3,
          card_short_description: lesson.short_description,
          card_classes: "col-12 mt-3",
          start_time: "",
        });
      });
      console.log(
        result.program_module,
        "result.program_moduleresult.program_module"
      );
      setModuleInfo(result.program_module);
      let data = {
        _id: result.program_module?._id,
        video_duration: result.program_module?.video_duration,
        total_video_duration: result.program_module?.total_video_duration,
        is_complete: result.program_module?.is_complete,
      };
      setVideoProgressData(data);
      setLessonsList(lessons_array);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_programme_content = () => {
    if (programmesDetail?.program_lock_status === true) {
      return {
        video_url: programmesDetail?.locked_program_info.video_url,
        detailed_description:
          programmesDetail?.locked_program_info?.detailed_description,
        image: programmesDetail?.locked_program_info?.image,
      };
    } else {
      return {
        video_url: modulesInfo?.video_url,
        audio_file: modulesInfo?.audio_file
          ? s3baseUrl + modulesInfo?.audio_file
          : "",
        detailed_description: modulesInfo?.detailed_description,
        image: modulesInfo?.program_images?.thumbnail_1,
      };
    }
  };

  const handleTabClick = (e, row) => {
    navigate(`/programmes/${params.slug}/${row.tab_slug}`);
  };
  console.log(programmDetail, "params.slug");
  const TABS_OPTIONS = [
    // {
    //   title:
    //     programModuleAccess && programmesDetail?.module_tab_name
    //       ? programmesDetail?.module_tab_name
    //       : "Lessons",
    //   tab_slug: "lessons-tab",
    //   component: programModuleAccess ? (
    //     <ModuleCardTab
    //       lessons={modulesInfo}
    //       programLockStatus={programmesDetail.program_lock_status}
    //       programSlug={params.slug}
    //     />
    //   ) : (
    //     <LessonsCardTab
    //       lessons={lessonsList}
    //       programLockStatus={programmesDetail?.program_lock_status}
    //       programSlug={params.slug}
    //     />
    //   ),
    //   onClick: handleTabClick,
    // },
    // {
    //   title: "Recordings",
    //   tab_slug: "recordings-tab",
    //   component:
    //     programmesDetail?.program_lock_status === false ? (
    //       <ProgrammeRecordingTab data={recordingList} />
    //     ) : (
    //       <NoAccess title="You have no access to see the Recordings" />
    //     ),
    //   onClick: handleTabClick,
    // },
    // {
    //   title: "Resources",
    //   tab_slug: "resources-tab",
    //   component:
    //     programmesDetail?.program_lock_status === false ? (
    //       <ResourcesCardTab data={resourcesList} imageLink={imageLinks} />
    //     ) : (
    //       <NoAccess title="You have no access to see the Resource" />
    //     ),
    //   onClick: handleTabClick,
    // },
    // {
    //   title: "Reviews",
    //   tab_slug: "reviews-tab",
    //   component: <RatingCardTab reviews={reviewsList} />,
    //   onClick: handleTabClick,
    // },
  ];

  // if (studySession.length > 0) {
  //   TABS_OPTIONS.push({
  //     title: "Study Session",
  //     tab_slug: "study-session-tab",
  //     onClick: handleTabClick,
  //     component:
  //       programmesDetail.program_lock_status === false ? (
  //         <StudySessionCardTabs
  //           studySession={studySession}
  //           imageLink={imageLinks}
  //         />
  //       ) : (
  //         <NoAccess title="You have no access to see the Study Sessions" />
  //       ),
  //   });
  // }
  // programSection.length > 0 &&
  //   programSection.map((Section) => {
  //     TABS_OPTIONS.push({
  //       title: Section.title,
  //       tab_slug: Section.section_slug,
  //       onClick: handleTabClick,
  //       component:
  //         programmesDetail.program_lock_status === false ? (
  //           <ProgramSectionTabs programSection={Section} />
  //         ) : (
  //           <NoAccess
  //             title={`You have no access to see the ${Section.title}`}
  //           />
  //         ),
  //     });
  //   });

  useEffect(() => {
    getProgrammesDetail();
  }, []);
  useEffect(() => {
    if (watched == false) {
    } else {
      increaseWatchCount("watch");
    }
  }, [watched]);

  useEffect(() => {
    setTimeout(() => {
      let indexOfTab = 0;
      if (params.tab_slug) {
        indexOfTab = TABS_OPTIONS?.findIndex(
          (x) => x.tab_slug === params.tab_slug
        );
      }
      setTabValue(indexOfTab);
    }, 1000);
  }, [TABS_OPTIONS]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(videoProgressData, "videoProgressDatavideoProgressData");
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() =>
              navigate(
                `/programmes/${programmesDetail?.program_slug}/module-tab`
              )
            }
          >
            <ArrowBackIcon />
          </IconButton>

          {programmesDetail?.program_lock_status === false ? (
            <button
              className="small-contained-button float-end mt-1"
              onClick={() =>
                navigate(`/programmes/modules/${params.slug}/lessons`)
              }
            >
              View Lessons
            </button>
          ) : (
            <button
              className="small-contained-button float-end mt-1 disabled"
              disabled="disabled"
            >
              View Lessons
            </button>
          )}
        </div>
      </div>
      <div className="row section-space">
        <div className="col-12">
          <h1>{htmlDecode(modulesInfo?.title)}</h1>
        </div>
      </div>
      <div className="row media-margin">
        <div className="col-12">
          {get_programme_content().video_url ? (
            <ReactVideoPlayerNew
              url={get_programme_content().video_url}
              setWatched={setWatched}
              type={"program_pillars"}
              value={videoProgressData}
              setVideoProgressData={setVideoProgressData}
              videoProgressData={videoProgressData}
            />
          ) : get_programme_content().image ? (
            <ProgrammesImgStyle
              src={s3baseUrl + get_programme_content().image}
            />
          ) : (
            ""
          )}
        </div>
        {get_programme_content().audio_file && (
          <div className="col-12 section-space">
            <audio
              controlsList="nodownload"
              className="w-100"
              src={get_programme_content().audio_file}
              controls
            />
          </div>
        )}

        <div className="col-12 section-space set-image-center">
          <div
            dangerouslySetInnerHTML={{
              __html: get_programme_content().detailed_description,
            }}
          ></div>
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12">
          {/* <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          /> */}
          <LessonsCardTab
            lessons={lessonsList}
            programLockStatus={programmesDetail?.program_lock_status}
            programSlug={params.slug}
            programmesDetail={programmesDetail}
          />
        </div>
      </div>
    </div>
  );
}

export default ProgrammesModuleDetail;
