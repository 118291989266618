import { Tooltip } from "@mui/material";
import React from "react";
import { convertCurrencyToSign } from "src/utils/constants";

export default function ProductsList(props) {
  const { selectedProducts } = props;

  return (
    <tbody>
      {selectedProducts?.length > 0 ? (
        selectedProducts.map((item) => {
          return (
            <>
              <tr>
                <td>{item.name}</td>
                <td>
                  {convertCurrencyToSign(item.currency)}
                  {item.price?.toFixed(2)}
                  {item.old_price && item.old_price !== item.price && (
                    <Tooltip title="Old price">
                      <span className="product-old-price">
                        {convertCurrencyToSign(item.currency)}
                        {item.old_price.toFixed(2)}
                      </span>
                    </Tooltip>
                  )}
                </td>
                <td>{item.selected_quantity}</td>
                <td>
                  {convertCurrencyToSign(item.currency)}
                  {(item.price * item.selected_quantity).toFixed(2)}
                </td>
              </tr>
            </>
          );
        })
      ) : (
        <tbody>
          <tr>
            <td colSpan="5" className="text-center mb-4">
              <h4>Item Is Not Available</h4>
            </td>
          </tr>
        </tbody>
      )}
    </tbody>
  );
}
