import { useState } from "react";
import { kimSig } from "src/assets";
import SignatureCanvas from "react-signature-canvas";
import { Checkbox, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { requestDetailApi, sign_agreement_api } from "src/DAL/Payment/Payment";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const AgreementPage = () => {
  const [signature, setSignature] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [configurationData, setconfigurationData] = useState();
  const handleClearSign = () => {
    signature.clear();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (signature.isEmpty()) {
      enqueueSnackbar("Please sign the document", {
        variant: "error",
      });
      return;
    }
    setIsLoadingCard(true);
    const dataUrl = signature.toDataURL("image/png");
    const formData = new FormData();
    formData.append("image", dataUrl);
    formData.append("payment_request_slug", params.id);

    const result = await sign_agreement_api(formData);
    if (result.code === 200) {
      setIsLoading(true);

      navigate(`/active-request/${params.id}`);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingCard(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingCard(false);
    }
  };
  const getRequestDetail = async () => {
    const result = await requestDetailApi(params.id);
    if (result.code === 200) {
      setIsLoading(false);
      handleFormat(result.payment_request);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleFormat = (data) => {
    setconfigurationData(data.agreement_config);
    setIsLoading(false);
  };
  useEffect(() => {
    if (state) {
      handleFormat(state);
    } else {
      getRequestDetail();
    }
  }, []);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="mt-4">
        <div
          dangerouslySetInnerHTML={{
            __html: configurationData?.agreement_description,
          }}
        ></div>
      </div>
      <div className="conatiner">
        <div className="row mt-5">
          <div className="col-12 col-md-6 mt-4 image-bottom-border">
            <img
              // src={s3baseUrl + agreementData.agreement_kim_sign}
              src={kimSig}
              alt=""
              className="mb-3 img-fluid"
            />
          </div>
          <div className="col-12 col-md-6">
            <div className="signature-style">
              <SignatureCanvas
                ref={(data) => setSignature(data)}
                canvasProps={{
                  height: 150,
                  className: "sigCanvas",
                }}
              />
              <div className="signature-label d-flex justify-content-between">
                <label for="Signature"></label>
                <button class="clearButton" onClick={handleClearSign}>
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-4">
          <div className="checkbox">
            <Checkbox {...label} defaultChecked />
            <label htmlFor="checkbox" className="ms-2">
              I agree to the terms and conditions above and give my consent
            </label>
          </div>
        </div>
        <div className="text-center mt-5">
          <button
            className="small-contained-button"
            style={{ paddingLeft: "50px", paddingRight: "50px" }}
            onClick={handleSubmit}
          >
            {isLoadingCard ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
};

export default AgreementPage;
