import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import {
  IconButton,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "src/config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import { meditations_list } from "src/DAL/Meditations/Meditations";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import SearchIcon from "@mui/icons-material/Search";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function MeditationsList() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [meditationList, setMeditationList] = useState([]);
  const [categoryDetail, setCategoryDetail] = useState({});
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
    localStorage.setItem("maditationss", e.target.value);
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...meditationList];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };
  const handleClick = (object) => {
    navigate(
      `/meditation-categories/${params.category_slug}/meditations/${object.meditation_slug}`
    );
  };

  const getMeditationsList = async () => {
    const result = await meditations_list(params.category_slug);
    if (result.code === 200) {
      setCategoryDetail(result.meditation_category);
      let CARD_OPTIONS = [];
      result.meditation.map((meditation) => {
        CARD_OPTIONS.push({
          ...meditation,
          card_title: meditation.title,
          card_short_description: meditation.short_description,
          card_image: s3baseUrl + meditation.image.thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
        });
      });
      setMeditationList(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/meditation-categories`);
    }
  };

  useEffect(() => {
    let maditationss = localStorage.getItem("maditationss");
    if (maditationss) {
      setQuery(maditationss);
    }
    getMeditationsList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mobile-margin ">
          <div className="col-8 d-flex">
            <span>
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(`/meditation-categories`)}
              >
                <ArrowBackIcon />
              </IconButton>
            </span>

            <h2 className="ms-2">{categoryDetail.title}</h2>
          </div>
          <div className="col-4">
            <div>
              <FormControl variant="outlined" style={{ marginTop: "2px" }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  variant="small"
                  value={query}
                  onChange={handleChangeSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="row section-space">
          <div className="col-12"></div>
        </div>
        {getFilteredData(query).length > 0 ? (
          <CustomMUICard
            data={getFilteredData(query)}
            handleClick={handleClick}
          />
        ) : (
          <RecordNotFound title="Meditations Not Found" />
        )}
      </div>
    </>
  );
}
