import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { cointlogo } from "src/assets";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import ActionBarComponentProps from "./calender";
// import TimePicker from "react-time-picker";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import moment from "moment";
import {
  addDynamiteDiaryApi,
  deleteDiaryApi,
  diaryListWithDate,
  move_to_tomorrow,
  updateDynamiteDiaryApi,
  update_reminder_time_api,
} from "src/DAL/dynamiteDiary/dynamiteDiary";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import { ReactVideoPlayer } from "src/components";
import WaveformAudioPlayer from "src/components/WaveformAudioPlayer";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { get_date_with_user_time_zone } from "src/utils/constants";
import NewWaveAudioPlayer from "src/components/NewWaveAudioPlayer";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const DynamiteDiary = () => {
  const { userInfo, adminTimeZone } = useContentSetting();
  const get_update_time_with_time_zone = (date) => {
    let new_date = get_date_with_user_time_zone(
      date,
      "YYYY-MM-DD",
      userInfo.time_zone,
      adminTimeZone
    );
    return new_date;
  };
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const [addArray, setaddArray] = useState([
    { option: "", complete: false, is_moved_to_tomorrow: false },
    { option: "", complete: false, is_moved_to_tomorrow: false },
    { option: "", complete: false, is_moved_to_tomorrow: false },
  ]);
  const [diaryDate, setDiaryDate] = useState(
    get_update_time_with_time_zone(new Date())
  );
  const [firstDiayDate, setFirstDiayDate] = useState(
    get_update_time_with_time_zone(new Date())
  );
  const [diaryDateSelection, setDiaryDateSelection] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [objectDataAvailable, setObjectDataAvailable] = useState(false);
  const [objectData, setObjectData] = useState(false);
  const [isDateUpdate, setIsDateUpdate] = useState(false);
  const [prevDiaries, setPrevDiaries] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [isSubmitReminder, setIsSubmitReminder] = useState(false);
  const [isSubmitIntentionReminder, setIsSubmitIntentionReminder] =
    useState(false);
  const [reminderTime, setReminderTime] = useState({
    daily_dynamite_reminder_time: "2018-01-01 00:00 am",
    daily_dynamite_intention_reminder_time: "2018-01-01 00:00 am",
  });

  const [openHide, setOpenHide] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [editValue, setEditValue] = useState("");
  const [editDiaryId, setEditDiaryId] = useState("");
  const [diarySetting, setDiarySetting] = useState();
  const [totalCoins, setTotalCoins] = useState(0);
  const [dailyMessage, setDailyMessage] = useState({});
  const [userCreateAt, setUserCreateAt] = useState("");
  const [hideVideo, setHideVideo] = useState(
    localStorage.getItem("hide_dynamite_diary_video")
  );
  const [checked, setChecked] = useState(true);

  const getDiaryAccordingDate = async (value) => {
    if (value == "load") {
    } else {
      setIsLoading(true);
    }

    let todayDate = moment(diaryDate, "YYYY-MM-DD").format("DD/MM/YYYY");
    let postData = {};
    if (isDateUpdate) {
      postData = { type: "date", date: todayDate };
    } else if (params.id) {
      postData = { type: "_id", _id: params.id };
    } else {
      postData = { type: "date", date: todayDate };
    }

    const result = await diaryListWithDate(postData);
    if (result.code == 200) {
      setDiarySetting(result.dynamite_diary_settings);
      setFirstDiayDate(result.first_dynamite_diary_date);
      if (result.daily_dynamite_reminder_time) {
        //2018-01-01 does nothing but timepicker supports to this format you can add event any date
        setReminderTime((prev) => {
          return {
            ...prev,
            daily_dynamite_reminder_time: `2018-01-01 ${result.daily_dynamite_reminder_time}`,
          };
        });
      }
      if (result.daily_dynamite_intention_reminder_time) {
        //2018-01-01 does nothing but timepicker supports to this format you can add event any date
        setReminderTime((prev) => {
          return {
            ...prev,
            daily_dynamite_intention_reminder_time: `2018-01-01 ${result.daily_dynamite_intention_reminder_time}`,
          };
        });
      }
      setDailyMessage(result.daily_message);
      setTotalCoins(result.total_coins_count_attracted);
      if (Object.keys(result.dynamite_diary).length > 0) {
        setObjectDataAvailable(true);
        setEditDiaryId(result.dynamite_diary._id);
        setObjectData(result.dynamite_diary);
        let diaryObject = result.dynamite_diary;
        let formatedDate = result.dynamite_diary.date
          ? moment(result.dynamite_diary.date, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            )
          : moment(todayDate, "DD-MM-YYYY").format("YYYY-MM-DD");
        setTitle(diaryObject?.title);
        if (diaryObject?.statement_array) {
          let intensionArray = diaryObject?.statement_array.map((item) => ({
            ...item,
            complete: item.complete ? item.complete : false,
          }));
          setaddArray(intensionArray);
        }
        setDiaryDate(formatedDate);
        setNote(diaryObject?.note);
      } else {
        let dateee = moment(todayDate, "DD/MM/YYYY").format("YYYY-MM-DD");
        setDiaryDate(dateee);
        setTitle("");
        setNote("");
        setaddArray([
          { option: "", complete: false, is_moved_to_tomorrow: false },
          { option: "", complete: false, is_moved_to_tomorrow: false },
          { option: "", complete: false, is_moved_to_tomorrow: false },
        ]);
        setObjectDataAvailable(false);
      }

      setPrevDiaries(result.prevoius_dynamite_diaries);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAdd = () => {
    const addedElement = [
      ...addArray,
      { option: "", complete: false, is_moved_to_tomorrow: false },
    ];
    setaddArray(addedElement);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };
  const handleChangeDate = (value) => {
    setIsDateUpdate(true);
    setDiaryDateSelection(value);
    setDiaryDate(value.$d);
  };

  const handleClickTomorrow = async (value, index) => {
    if (!value.is_moved_to_tomorrow) {
      const existingDate = diaryDate;
      const dateObject = moment(existingDate, "YYYY-MM-DD");
      const futureDate = dateObject.add(1, "day").format("YYYY-MM-DD");
      delete value.is_moved_to_tomorrow;
      let data = {
        date: futureDate,
        intention_object: value,
      };
      let add_array = [...addArray];
      let selected_obj = add_array[index];
      selected_obj.is_moved_to_tomorrow = true;
      add_array[index] = selected_obj;
      setaddArray(add_array);

      const result = await move_to_tomorrow(data);
      if (result.code == 200) {
        handleUpdateMove(add_array, index);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };
  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteValue(value);
  };
  const handleDeleteDiary = async (value) => {
    const result = await deleteDiaryApi(deleteValue._id);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getDiaryAccordingDate();
      setOpenDelete(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDelete(false);
    }
  };
  const handleEdit = (value) => {
    setEditValue(value);
    setIsDateUpdate(false);
    navigate(`/dynamite-diary/edit-dynamite-diary/${value._id}`, {
      state: value,
    });
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index][name] = value;
    setaddArray(list);
  };
  const handleChangeCheckBox = async (data, index) => {
    // const { name, checked } = e.target;
    const list = [...addArray];
    list[index]["complete"] = data?.complete ? !data.complete : true;
    setaddArray(list);
    objectDataAvailable == true
      ? handleUpdate("checked")
      : handleSubmit("checked");
  };
  const handleChangeReminderTime = (newValue, name) => {
    setReminderTime((prevState) => {
      return {
        ...prevState,
        [name]: newValue?.$d,
      };
    });
  };

  const handleNavigate = (e, index) => {
    navigate(`/dynamite-diary/past-activities`);
  };

  const handleAgreeHide = (value) => {
    setOpenHide(true);
  };

  const handleSubmit = async (e) => {
    if (e == "checked") {
    } else {
      e.preventDefault();
      setIsSubmit(true);
    }
    let postData = {
      title: title ? title : "",
      date: diaryDate
        ? moment(diaryDate).format("DD-MM-YYYY")
        : moment(new Date()).format("DD-MM-YYYY"),
      note: note,
      statement_array: addArray,
    };
    const result = await addDynamiteDiaryApi(postData);
    if (result.code == 200) {
      enqueueSnackbar(
        e == "checked" ? "Intension Status Updated" : result.message,
        { variant: "success" }
      );
      // navigate(`/dynamite-diary`);
      setIsSubmit(false);
      getDiaryAccordingDate("load");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmit(false);
    }
  };

  const handleUpdate = async (e) => {
    if (e == "checked") {
    } else {
      e.preventDefault();
      setIsSubmit(true);
    }
    let complete_intentions = addArray.filter(
      (value) => value.complete == true
    );
    let incomplete_intentions = addArray.filter(
      (value) => value.complete == false
    );
    let postData = {
      title: title ? title : "",
      date: diaryDate?.$d
        ? moment(diaryDate?.$d).format("DD-MM-YYYY")
        : objectDataAvailable == true
        ? moment(diaryDate).format("DD-MM-YYYY")
        : moment(new Date()).format("DD-MM-YYYY"),
      note: note,
      statement_array: addArray,
      completed_intention: complete_intentions?.length,
      incomplete_intention: incomplete_intentions?.length,
    };
    const result = await updateDynamiteDiaryApi(
      editDiaryId ? editDiaryId : objectData._id,
      postData
    );

    if (result.code == 200) {
      enqueueSnackbar(
        e == "checked" ? "Intension Status Updated" : result.message,
        {
          variant: "success",
        }
      );
      // getDiaryAccordingDate();
      // navigate(`/dynamite-diary`);
      setIsSubmit(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmit(false);
    }
  };
  const handleUpdateMove = async (add_array, index) => {
    let complete_intentions = add_array.filter(
      (value) => value.complete == true
    );
    let incomplete_intentions = add_array.filter(
      (value) => value.complete == false
    );
    let postData = {
      title: title ? title : "",
      date: diaryDate?.$d
        ? moment(diaryDate?.$d).format("DD-MM-YYYY")
        : objectDataAvailable == true
        ? moment(diaryDate).format("DD-MM-YYYY")
        : moment(new Date()).format("DD-MM-YYYY"),
      note: note,
      statement_array: add_array,
      completed_intention: complete_intentions?.length,
      incomplete_intention: incomplete_intentions?.length,
    };
    const result =
      editDiaryId && objectData?._id
        ? await updateDynamiteDiaryApi(objectData._id, postData)
        : await addDynamiteDiaryApi(postData);

    if (result.code == 200) {
      setIsSubmit(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmit(false);
    }
  };

  const handleUpdateReminderTime = async (e, name) => {
    e.preventDefault();
    let postData = {};
    if (name == "daily_dynamite_reminder_time") {
      postData.daily_dynamite_reminder_time = moment(
        reminderTime.daily_dynamite_reminder_time
      ).format("HH:mm");
      setIsSubmitReminder(true);
    } else {
      postData.daily_dynamite_intention_reminder_time = moment(
        reminderTime.daily_dynamite_intention_reminder_time
      ).format("HH:mm");
      setIsSubmitIntentionReminder(true);
    }

    const result = await update_reminder_time_api(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsSubmitReminder(false);
      setIsSubmitIntentionReminder(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmitReminder(false);
      setIsSubmitIntentionReminder(false);
    }
  };

  const handleHideVideo = () => {
    localStorage.setItem("hide_dynamite_diary_video", true);
    setHideVideo(true);
    setOpenHide(false);
  };
  const handleShowVideo = () => {
    localStorage.setItem("hide_dynamite_diary_video", false);
    setHideVideo(false);
  };

  useEffect(() => {
    getDiaryAccordingDate();
    let userDate = userInfo
      ? moment(userInfo.createdAt).format("YYYY-MM-DD")
      : "";
    setUserCreateAt(userDate);
  }, [diaryDateSelection, params]);

  useEffect(() => {
    if (Object.keys(objectData).length === 0) {
      setObjectDataAvailable(false);
    }
  }, [objectData]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete diary ?"}
        handleAgree={handleDeleteDiary}
      />
      <CustomConfirmation
        open={openHide}
        setOpen={setOpenHide}
        title={"Are you sure you want to hide video ?"}
        handleAgree={handleHideVideo}
      />

      <section className="container dynamite-diary">
        <div className="row mb-4">
          <div className={`col-lg-4`}>
            <PageDescription
              parameter="dynamite_diary_description"
              else_title="Dynamite Diary"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-3 mt-lg-2">
            <div className={`d-flex coint-image text-start`}>
              <img className="img-fluid" src={cointlogo}></img>
              <h4 className="coint-detail">
                Total Coins Attracted: {totalCoins}
              </h4>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-4 mt-md-3 mt-lg-2">
            <div className="row">
              <div className="col-12">
                <form
                  className="d-flex text-start coint-image reminder-time-form"
                  onSubmit={(e) => {
                    handleUpdateReminderTime(e, "daily_dynamite_reminder_time");
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Reminder Time"
                      value={reminderTime.daily_dynamite_reminder_time}
                      onChange={(e) => {
                        handleChangeReminderTime(
                          e,
                          "daily_dynamite_reminder_time"
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" required={true} />
                      )}
                    />
                  </LocalizationProvider>
                  <button
                    className="submit-button mb-0 ms-2"
                    disabled={isSubmitReminder}
                  >
                    {isSubmitReminder ? "Saving..." : "Save"}
                  </button>
                </form>
              </div>
              <div className="col-12 mt-3">
                <form
                  className="d-flex text-start coint-image reminder-time-form"
                  onSubmit={(e) => {
                    handleUpdateReminderTime(
                      e,
                      "daily_dynamite_intention_reminder_time"
                    );
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Intention Reminder Time"
                      value={
                        reminderTime.daily_dynamite_intention_reminder_time
                      }
                      onChange={(e) => {
                        handleChangeReminderTime(
                          e,
                          "daily_dynamite_intention_reminder_time"
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" required={true} />
                      )}
                    />
                  </LocalizationProvider>
                  <button
                    className="submit-button mb-0 ms-2"
                    disabled={isSubmitIntentionReminder}
                  >
                    {isSubmitIntentionReminder ? "Saving..." : "Save"}
                  </button>
                </form>
              </div>
            </div>
          </div>
          {(hideVideo == true || hideVideo == "true") && (
            <div className="col-lg-12 text-end mt-3">
              <button
                className="small-contained-button float-end mt-1"
                onClick={handleShowVideo}
              >
                Show Video
              </button>
            </div>
          )}
        </div>
        {JSON.stringify(diarySetting) == "{}" ? (
          ""
        ) : (
          <section className="container top-section-video mt-3 mb-3">
            <div className="row pt-70" id="assessment-container">
              {(hideVideo == false ||
                hideVideo == "false" ||
                hideVideo == null) && (
                <div className="col-lg-7 col-md-6">
                  <ReactVideoPlayer url={diarySetting?.diary_video_url} />
                  <FormControlLabel
                    control={
                      <Checkbox onClick={handleAgreeHide} checked={false} />
                    }
                    label={
                      diarySetting?.diary_dont_show_text
                        ? diarySetting?.diary_dont_show_text
                        : "Don't Show me this video again"
                    }
                  />
                </div>
              )}
              <div
                className={` ${
                  hideVideo == false ||
                  hideVideo == "false" ||
                  hideVideo == null
                    ? "col-lg-5 col-md-6"
                    : "col-12"
                } text-md-start text-center mt-md-0 mt-4`}
              >
                {dailyMessage && dailyMessage?.type == "audio" ? (
                  <div className="mb-3">
                    <h4>{dailyMessage.title}</h4>

                    <NewWaveAudioPlayer
                      url={s3baseUrl + dailyMessage.media_url}
                    />
                  </div>
                ) : dailyMessage && dailyMessage?.type == "url" ? (
                  <>
                    <h4>{dailyMessage.title}</h4>
                    <ReactVideoPlayer
                      url={dailyMessage.media_url}
                      title={dailyMessage.title}
                    />
                  </>
                ) : (
                  ""
                )}
                <div
                  dangerouslySetInnerHTML={{
                    __html: diarySetting?.diary_video_description,
                  }}
                  className="diary-para"
                ></div>
              </div>
            </div>
          </section>
        )}
        <form
          className="container"
          onSubmit={objectDataAvailable == true ? handleUpdate : handleSubmit}
        >
          <div className="row">
            <div className="col-lg-7 ps-0">
              <div className="d-flex">
                <div className="mt-2 ms-2 field-container diray-title">
                  <h4>{diarySetting?.diary_title}</h4>
                </div>
              </div>

              {addArray?.map((data, index) => {
                return (
                  <div className="d-flex position-relative mt-1">
                    <div className="mt-2 field-container">
                      <TextareaAutosize
                        aria-label="empty textarea"
                        placeholder={`${index + 1}.`}
                        className="text-color textarea-autosize"
                        required
                        value={data.option}
                        minRows={1}
                        name="option"
                        onChange={(e) => handleChange(e, index)}
                        style={{
                          width: "100%",
                          borderRadius: "11px",
                          background: "#141717",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          paddingLeft: "15px",
                          color: "white",
                          fontSize: "15px",
                          border: "2px solid rgba(71, 66, 34, 0.3)",
                        }}
                      />
                      {data.option && (
                        <div className="text-end intentionCheckox d-flex justify-content-between on-mobile">
                          <div>
                            <Checkbox
                              checked={Boolean(data.is_moved_to_tomorrow)}
                              onClick={(e) => handleClickTomorrow(data, index)}
                              // onChange={(e) => handleUpdateMove(e, index)}
                              inputProps={{ "aria-label": "controlled" }}
                            />

                            {data.is_moved_to_tomorrow == true ? (
                              <span className="pt-2 cursor mobile-screen-text">
                                Moved to Tomorrow
                              </span>
                            ) : (
                              <span className="pt-2">Move to Tomorrow</span>
                            )}
                          </div>
                          <div>
                            <Checkbox
                              checked={data?.complete}
                              onClick={(e) => handleChangeCheckBox(data, index)}
                              // onChange={(e) => handleChange(e, index)}
                              inputProps={{ "aria-label": "controlled" }}
                            />

                            {data.complete == true ? (
                              <span className="pt-2">Intention Completed</span>
                            ) : (
                              <span className="pt-2">
                                Mark Intention Complete
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className="icon-container"> */}
                    <span className="cross-icon cross-daily">
                      {addArray.length > 1 ? (
                        <Tooltip title="Remove">
                          <RemoveCircleOutlineIcon
                            onClick={() => handleDelete(index)}
                            className="diary-icon-remove"
                          />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      <Tooltip title="Add">
                        <AddCircleOutlineIcon
                          onClick={() => handleAdd()}
                          className="diary-icon-add"
                        />
                      </Tooltip>
                    </span>
                    {/* </div> */}
                  </div>
                );
              })}
            </div>
            <div className="col-lg-5 ps-4">
              <div className="d-flex calender w-100 full-open-calender">
                <ActionBarComponentProps
                  diaryDate={diaryDate}
                  setDiaryDate={setDiaryDate}
                  handleChangeDate={handleChangeDate}
                  userCreateAt={firstDiayDate}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row">
              <div
                dangerouslySetInnerHTML={{
                  __html: diarySetting?.diary_journal_text,
                }}
                className="diary-para"
              ></div>
            </div>
            <div className="col-12">
              <div className="mt-2">
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder={
                    diarySetting?.notes_placeholder
                      ? diarySetting?.notes_placeholder
                      : ""
                  }
                  className="text-color textarea-autosize"
                  value={note}
                  sx={{ color: "white" }}
                  minRows={7}
                  onChange={(e) => setNote(e.target.value)}
                  style={{
                    width: "100%",
                    borderRadius: "11px",
                    background: "#141717",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "15px",
                    color: "white",
                    fontSize: "15px",
                    border: "2px solid rgba(71, 66, 34, 0.3)",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              {objectDataAvailable ? (
                <button
                  className="small-contained-button float-end mt-1 mb-4"
                  // onClick={() => handleUpdate()}
                >
                  {isSubmit ? "Updating.." : "Update"}
                </button>
              ) : (
                <button
                  className="small-contained-button float-end mt-1 mb-4"
                  // onClick={() => handleSubmit()}
                >
                  {isSubmit ? "Saving.." : "Save"}
                </button>
              )}
            </div>
          </div>
        </form>
        {prevDiaries.length > 0 ? (
          <div className="container mt-5">
            <div className="row">
              <div className="recent col-lg-12 d-flex justify-content-end me-2 pe-3">
                <p>Most Recent</p>
              </div>
              {prevDiaries?.map((value) => {
                return (
                  <div className="col-lg-6 mb-3" style={{ cursor: "pointer" }}>
                    <div className="mini-cards d-flex">
                      <div className="w-100 ms-2 me-2">
                        <div className="diary-font-weight d-flex">
                          <h4 onClick={() => handleEdit(value)}>
                            {value?.date ? value?.date : ""}
                          </h4>
                          <div className="d-flex">
                            <span
                              style={{ paddingTop: "2px" }}
                              onClick={() => handleEdit(value)}
                              className="diary-first-heading pe-2"
                            >
                              {value?.date_time
                                ? moment(value?.date_time).format("hh:mm A")
                                : ""}
                            </span>
                            <CustomPopover menu={MENU_OPTIONS} data={value} />
                          </div>
                        </div>

                        <div className="d-flex justify-content-between ml-3 mt-2 diary-first-heading">
                          <div onClick={() => handleEdit(value)}>
                            <h4>{value.statement_array[0]?.option}</h4>
                          </div>
                          {/* <div className="me-4">
                            <h4 className="date-heading">
                              {value?.date_time
                                ? moment(value?.date_time).format("hh:mm A")
                                : ""}
                            </h4>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <button
                  className="small-contained-button float-end mt-1 mb-4"
                  onClick={handleNavigate}
                >
                  Past Activities
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    </>
  );
};

export default DynamiteDiary;
