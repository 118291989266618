import React from "react";
import { useRef } from "react";
import { useCallback } from "react";
import ReactPlayer from "react-player";

const ReactVideoPlayerForMobile = (props) => {
  const playerRef = useRef();
  const setVideoPlayed = () => {
    if (window.ReactNativeWebView) {
      // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "onPlay", ...progress })
      );
    }
  };
  const onStart = () => {
    if (window.ReactNativeWebView) {
      // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "onStart", ...progress })
      );
    }
  };
  const handlePause = (progress) => {
    if (window.ReactNativeWebView) {
      // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "onPause", ...progress })
      );
    }
  };
  const handleDuration = (progress) => {
    if (window.ReactNativeWebView) {
      // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "onDuration", ...progress })
      );
    }
  };

  const handleEnded = (progress) => {
    if (window.ReactNativeWebView) {
      // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "onEnd", ...progress })
      );
    }
  };

  const handleProgess = (progress) => {
    console.log(progress, "progess.playedSeconds");
    if (window.ReactNativeWebView) {
      // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "onProgress", ...progress })
      );
    }
    // if (isStarted) {
    //   setVideoPlayed(progess.playedSeconds);
    //   if (videosList) {
    //     let find_video = videosList.find(
    //       (video) => video.lesson_slug == lesson_slug
    //     );
    //     if (!find_video) {
    //       setVideosList((videosList) => [
    //         ...videosList,
    //         {
    //           lesson_slug: lesson_slug,
    //           // date: moment(new Date()).format("YYYY-MM-DD"),
    //           url: url,
    //           start_time: progess.playedSeconds,
    //         },
    //       ]);
    //     } else {
    //       const newState = videosList.map((obj) => {
    //         if (obj.lesson_slug === lesson_slug) {
    //           return {
    //             lesson_slug: lesson_slug,
    //             // date: moment(new Date()).format("YYYY-MM-DD"),
    //             url: url,
    //             start_time: progess.playedSeconds,
    //           };
    //         }
    //         return obj;
    //       });
    //       setVideosList(newState);
    //     }
    //   } else {
    //     setVideosList([
    //       {
    //         lesson_slug: lesson_slug,
    //         // date: moment(new Date()).format("YYYY-MM-DD"),
    //         url: url,
    //         start_time: progess.playedSeconds,
    //       },
    //     ]);
    //   }
    // }
  };
  const onReady = useCallback(() => {
    if (!!props.seekTo) {
      let timeToStart = 0;
      playerRef.current.seekTo(props.seekTo, "seconds");
      playerRef.current.state.showPreview = true;
      console.log(playerRef.current.state.showPreview, "playerRef.current");
    }
  }, [props.url]);
  return (
    <div className="wrapper">
      <ReactPlayer
        ref={playerRef}
        controls
        className="player"
        url={props.url}
        width="100vw"
        height="100vh"
        stopOnUnmount
        pip
        playsinline
        onReady={onReady}
        // onStart={handleSetDate}
        onProgress={handleProgess}
        onEnded={handleEnded}
        onPause={handlePause}
        onDuration={handleDuration}
        onPlay={setVideoPlayed}
        onStart={onStart}
        // onDuration={handleDuration}
      />
    </div>
  );
};

export default ReactVideoPlayerForMobile;
