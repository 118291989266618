import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactVideoPlayer } from "src/components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { get_series_video_detail } from "src/DAL/Community/Community";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function VideoDetails(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [featureVideo, setFeatureVideo] = useState({});

  const getVideoContent = async () => {
    const result = await get_series_video_detail(params.video_id);
    if (result.code === 200) {
      setFeatureVideo(result.seriesvideos);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      navigate(`/thesource`);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getVideoContent();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container new-community-page">
      <section className="success-wrapper pt-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className="col-lg-12">
              <h1>{htmlDecode(featureVideo.title)}</h1>
              <h2>
                {featureVideo.series_category &&
                  featureVideo.series_category.title && (
                    <>
                      <span>{featureVideo.series_category.title}</span> &nbsp;
                    </>
                  )}
                {moment(featureVideo.date).format("YYYY")} &nbsp;
                {htmlDecode(featureVideo.season_statement)}
              </h2>
              <p>{htmlDecode(featureVideo.short_description)}</p>
            </div>
            <div className="col-lg-12 mt-2">
              <ReactVideoPlayer url={featureVideo.video_url} />
            </div>
            <div className="col-lg-12 mt-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: featureVideo.detail_description,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default VideoDetails;
