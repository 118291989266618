import React from 'react'
import CustomPopover from '../GeneralComponents/CustomPopover';

export default function NoteCard() {
    const handleEdit = () => { }
    const handleAgreeDelete = () => { }
    const MENU_OPTIONS = [
        {
            label: "Edit",
            icon: "akar-icons:edit",
            handleClick: handleEdit,
        },

        {
            label: "Delete",
            icon: "ant-design:delete-twotone",
            handleClick: handleAgreeDelete,
        },
    ];
    return (
        <div className="col-12 section-space">
            <div className="card">
                <div className="card-body notes-input set-display-inline">
                    <CustomPopover menu={MENU_OPTIONS} data={{}} />
                    <p className="mb-0 normal-text">
                        Completed
                    </p>
                    <p className="lesson-notes-title mt-3 mb-1 date-color">
                        Monday, 26 Dec 2022
                    </p>
                </div>
            </div>
        </div>
    )
}
