import React from "react";
import { s3baseUrl } from "src/config/config";
import MissionVideoPlayer from "../ReactPlayers/MissionVideoPlayer";
import { dd_date_format } from "src/utils/constants";

export default function DayVideoTab({
  setOpenModal,
  schedule,
  handleChangeAction,
  isSubmitting,
  handleSubmit,
}) {
  const replacedString = (string) => {
    if (!string) return;
    return string.replace(
      /{completed_date}/g,
      dd_date_format(schedule.completed_at)
    );
  };
  console.log(schedule, "schedulescheduleschedule");
  return (
    <div className="row mt-3">
      <div
        className={`col-12 ${schedule.detailed_description ? "col-lg-8" : ""} `}
      >
        {schedule.video_url ? (
          <MissionVideoPlayer
            url={schedule.video_url}
            isDisabled={Boolean(schedule.schedule_status === "locked")}
          />
        ) : (
          <img src={s3baseUrl + schedule.image.thumbnail_1} className="w-100" />
        )}
      </div>
      {schedule.detailed_description && (
        <div
          dangerouslySetInnerHTML={{
            __html: schedule.detailed_description,
          }}
          className={`col-lg-4 mission-levels-overview-tab py-2 ${
            schedule.schedule_status === "locked" ? "not-started" : ""
          }`}
        ></div>
      )}

      <div className="d-flex justify-content-between mt-3">
        <div className="w-100">
          {schedule?.audio_url && (
            <audio
              src={s3baseUrl + schedule?.audio_url}
              controls
              className="w-100"
            />
          )}
        </div>
        {schedule.question_configration?.show_question &&
          schedule.question_configration?.button_text && (
            <button
              className="mc-button-contained ms-4"
              disabled={Boolean(schedule.schedule_status === "locked")}
              onClick={() => setOpenModal(1)}
            >
              {schedule.question_configration?.button_text}
            </button>
          )}
      </div>
      {schedule.allowed_actions?.length > 0 && (
        <div className="col-12">
          <form className="allowed-actions-box">
            <h2
              className={
                schedule.schedule_status === "locked" ? "heading-disabled" : ""
              }
            >
              {schedule.content_settings?.action_statement_heading}
            </h2>
            {schedule.allowed_actions.map((item, index) => {
              let { _id, action_statement, is_required, is_checked } = item;

              let find_answer = null;
              if (schedule.completed_allowed_actions?.length > 0) {
                find_answer = schedule.completed_allowed_actions.find(
                  (i) => i._id == _id
                );
              }

              let isDisabled = Boolean(
                schedule.schedule_status === "locked" ||
                  (find_answer && schedule.schedule_status === "completed")
              );

              return (
                <div
                  className={`mt-3 action-checkbox-box ${
                    isDisabled ? "disabled" : ""
                  }`}
                  onClick={() => handleChangeAction(_id, isDisabled, schedule)}
                >
                  <div
                    className={`action-checkbox ${is_checked ? "checked" : ""}`}
                  >
                    {is_checked && <i class="fa-solid fa-check"></i>}
                  </div>
                  <div className="checkbox-label">{action_statement}</div>
                  <span className="required-asterisk ms-1">
                    {is_required && "*"}
                  </span>
                </div>
              );
            })}
          </form>
        </div>
      )}
      {schedule.schedule_status !== "completed" && (
        <div className="text-end">
          <button
            className="mc-button-contained mb-3 mt-4"
            disabled={
              Boolean(schedule.schedule_status === "locked") || isSubmitting
            }
            onClick={(e) => handleSubmit(e, schedule)}
          >
            {isSubmitting
              ? "Submitting..."
              : schedule.content_settings?.complete_schedule_button_text ||
                "Complete Schedule"}
          </button>
        </div>
      )}
      <div className="col-12">
        {schedule.content_settings?.schedule_completed_description &&
          schedule.schedule_status == "completed" && (
            <div
              dangerouslySetInnerHTML={{
                __html: replacedString(
                  schedule.content_settings?.schedule_completed_description
                ),
              }}
              className="mt-4 schedule_completed_description"
            ></div>
          )}
      </div>
    </div>
  );
}
