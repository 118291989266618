import { Avatar } from "@mui/material";
import React from "react";
import { s3baseUrl } from "src/config/config";
import { get_kmb_number } from "src/utils/formatNumber";

export default function LeaderBoard({ data, title, handleClickAll, type }) {
  return (
    <div className="card p-2 pt-3 members-leader-board">
      <h4 className="mb-2">{title}</h4>
      {data.map((item, index) => {
        return (
          <div
            className={`d-flex justify-content-between align-items-center mt-2 ${
              index === 0 ? "colored-user border-changing-text" : ""
            }`}
            key={item._id}
          >
            <div className="d-flex align-items-center">
              <Avatar
                sx={{ width: 30, height: 30 }}
                alt={item.user_info?.name}
                src={s3baseUrl + item.user_info?.profile_image}
              />
              <div className="user-name ms-2">{item.user_info?.name}</div>
            </div>
            {type == "mission" ? (
              <div className="user-coins">{item.coins}</div>
            ) : (
              <div className="user-coins">{get_kmb_number(item.coins)}</div>
            )}
          </div>
        );
      })}
      {handleClickAll && (
        <div className="text-end mt-2">
          <span className="view-all-boards" onClick={handleClickAll}>
            View all
          </span>
        </div>
      )}
    </div>
  );
}
