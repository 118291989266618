import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomPopover from "../GeneralComponents/CustomPopover";
import { convertCurrencyToSign, dd_date_format } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const EarningList = ({ memberEarning, expandArea, MENU_OPTIONS }) => {
  const { userInfo } = useContentSetting();

  return (
    <div className="row goal-statement-messages">
      <div className="col-12">
        {memberEarning.length > 0 &&
          memberEarning.map((message, index) => {
            return (
              <div className="card mb-2" key={index}>
                <form>
                  <Accordion expanded={message.isExpanded}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => expandArea(index)}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="svg-color"
                    >
                      <Typography>
                        {`Earning Date: ${dd_date_format(message.date)} : `}
                      </Typography>
                      <Typography>
                        &nbsp;
                        {`${convertCurrencyToSign(
                          userInfo.nineteen_day_plan_currency
                        )}${message.earning.toLocaleString()}`}
                      </Typography>
                    </AccordionSummary>
                    <div className="Ninty-Day-trackerList-dots">
                      {message.isExpanded && (
                        <CustomPopover menu={MENU_OPTIONS} data={message} />
                      )}
                    </div>
                    <div className="pe-5 mb-4">
                      <p className="ms-3">{htmlDecode(message.description)}</p>
                    </div>
                  </Accordion>
                </form>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default EarningList;
