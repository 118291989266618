import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PaymentRequestsList } from "src/DAL/Payment/Payment";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import RequestsCardComponent from "src/components/PaymentRequests/RequestsCardComponent";
import Pods from "./Pods";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function MainPods() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [pendingRequest, setPendingRequest] = useState([]);
  const [activeRequest, setActiveRequest] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const expire = window.location.pathname.includes("/pods/expire");
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
  };

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setQuery("");
  };
  const handleTabClick = (e, row) => {
    if (row.tab_slug == "expire") {
      navigate(`/pods/expire`);
      setQuery("");
    } else {
      navigate(`/pods`);
      setQuery("");
    }
  };
  //Getting lesson detail
  const PaymentRequestsListing = async () => {
    const result = await PaymentRequestsList();
    if (result.code === 200) {
      let all_requests = [];
      result.pending_payment_request.map((request, index) => {
        all_requests.push({
          ...request,
          payment_request_type: "pending",
        });
      });
      setPendingRequest(all_requests);
      setActiveRequest(result.active_payment_request);
      setIsLoading(false);
    } else {
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/thesource`);
    }
  };

  const TABS_OPTIONS = [
    {
      title: "Active Pods",
      tab_slug: "pending",
      onClick: handleTabClick,
      component: (
        <Pods list_type={"active"} query={query} setQuery={setQuery} />
      ),
    },
    {
      title: "EXPIRED PODS",
      tab_slug: "expire",
      onClick: handleTabClick,
      component: (
        <Pods list_type={"expired"} query={query} setQuery={setQuery} />
      ),
    },
  ];

  useEffect(() => {
    PaymentRequestsListing();
  }, []);
  useEffect(() => {
    if (expire) setTabValue(1);
  }, [TABS_OPTIONS]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-lg-8 col-md-8 col-sm-12">
          <PageDescription parameter="rooms_description" else_title="Pods" />
        </div>
        <div className="col-lg-4 col-md- col-sm-12">
          <div>
            <FormControl variant="outlined" style={{ marginTop: "2px" }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                variant="small"
                value={query}
                onChange={handleChangeSearch}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search"
              />
            </FormControl>
          </div>
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12 section-space">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default MainPods;
