import { Avatar, Tooltip } from "@mui/material";
import React from "react";
import { s3baseUrl } from "src/config/config";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import { string_avatar } from "src/utils/constants";
import {
  _delete_message,
  _get_chat_message,
  _send_message,
  _update_message,
} from "src/DAL/Chat/Chat";
import { Icon } from "@iconify/react";
import ChatMessages from "../ChatMessages/ChatMessages";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

function ChatRight() {
  const { feedSetting } = useContentSetting();
  const { selectedChat, handleCloseChat, selectedChatUser } = useChat();
  const receiver_profile = selectedChatUser;

  return (
    <div className="chat-right-wrapper">
      <div className="chat-right-head p-1 d-flex justify-content-between">
        <div className="d-flex">
          {selectedChat && (
            <>
              <div className="user-profile">
                {/* showing user level dynamically*/}
                <div className="user-level">
                  <Tooltip
                    title={
                      feedSetting[receiver_profile?.community_level + "_title"]
                        ? feedSetting[
                            receiver_profile?.community_level + "_title"
                          ]
                        : "Elite"
                    }
                  >
                    <img
                      src={
                        feedSetting[
                          receiver_profile?.community_level + "_badge"
                        ]
                          ? s3baseUrl +
                            feedSetting[
                              receiver_profile?.community_level + "_badge"
                            ]
                          : ""
                      }
                    />
                  </Tooltip>
                </div>
                <Avatar
                  src={s3baseUrl + receiver_profile?.profile_image}
                  style={{ height: 45, width: 45 }}
                >
                  {string_avatar(receiver_profile?.first_name[0])}
                </Avatar>
                <div
                  className={receiver_profile?.is_online ? "online" : "offline"}
                ></div>
              </div>
              <div className="chat-profile-data">
                <div className="chat-profile-name ps-2 text-capitalize">
                  {receiver_profile?.first_name +
                    " " +
                    receiver_profile?.last_name}
                </div>
              </div>
            </>
          )}
        </div>

        {receiver_profile && (
          <div className="me-2" onClick={handleCloseChat}>
            <Icon
              fontSize={18}
              className="chat-box-cross-icon"
              icon="material-symbols:close"
            />
          </div>
        )}
      </div>
      <ChatMessages is_popup={false} />
    </div>
  );
}

export default ChatRight;
