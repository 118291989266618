import { Avatar } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  communityDownload12,
  gallery1,
  gallery2,
  gallery3,
  profileImage,
} from "src/assets";
import { ReactVideoPlayer } from "src/components";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  clickAbleUrl,
  get_date_with_user_time_zone,
  get_short_string,
  LONG_TEXT_LIMIT,
  string_avatar,
} from "src/utils/constants";
import { htmlDecode, urlify } from "src/utils/convertHtml";
import LeaderBoardPopup from "./components/LeaderBoardPopup";
function Suggestions({ winFeedsList }) {
  const [isShowMore, setIsShowMore] = useState(true);
  const settings = useContentSetting();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handlePostDetail = (post_id) => {
    navigate(`/feed-detail/${post_id}`);
  };

  return (
    <>
      <div className="profile-cards p-3 community-card-title leader-board-component mt-3">
        {open && (
          <div className="info-popup-box leader-board-popup">
            <LeaderBoardPopup
              description={
                settings?.dashboardSettingData?.win_leader_board_description
              }
              setShowInfoBox={setOpen}
              title={"WIN. WIN. WIN"}
            />
          </div>
        )}
        <h4>WIN. WIN. WIN</h4>
        <img
          src={communityDownload12}
          alt=""
          className="leader-board-info-icon"
          onClick={() => {
            setOpen(true);
          }}
        />

        {winFeedsList &&
          winFeedsList.map((feeds_list, index) => {
            return (
              <div key={index}>
                <div className="d-flex mt-3">
                  <Avatar
                    src={s3baseUrl + feeds_list.action_info?.profile_image}
                    alt="photoURL"
                  >
                    {string_avatar(feeds_list.action_info?.name)}
                  </Avatar>
                  <div className="text-start comment-icon w-100 ms-2">
                    <div className="d-flex justify-content-between w-100">
                      <p className="card-title mb-0">
                        {feeds_list.action_info?.name}
                      </p>
                    </div>
                    <p className="card-title date-time mb-0 mt-1">
                      {get_date_with_user_time_zone(
                        feeds_list?.createdAt,
                        "DD MMM YYYY [at] hh:mm A",
                        settings.userInfo.time_zone,
                        settings.adminTimeZone
                      )}
                    </p>
                    {/* <p className="text-start reduce-margin-from-para mb-0">
                      <span className="date-color">12 Dec 2018 at 1:16 AM</span>
                    </p> */}
                  </div>
                </div>
                <div className="mt-2 post-description">
                  {/* <p>{htmlDecode(feeds_list.description)}</p> */}
                  {feeds_list.description.length > LONG_TEXT_LIMIT ? (
                    isShowMore ? (
                      <>
                        <p
                          onClick={() => handlePostDetail(feeds_list._id)}
                          dangerouslySetInnerHTML={{
                            __html: urlify(
                              get_short_string(
                                feeds_list.description,
                                LONG_TEXT_LIMIT
                              )
                            ),
                          }}
                        ></p>
                        <span
                          className="ms-2"
                          onClick={() => {
                            setIsShowMore(false);
                          }}
                        >
                          See More
                        </span>
                      </>
                    ) : (
                      <>
                        <p
                          onClick={() => handlePostDetail(feeds_list._id)}
                          dangerouslySetInnerHTML={{
                            __html: urlify(feeds_list.description),
                          }}
                        ></p>
                        <span
                          className="ms-2"
                          onClick={() => {
                            setIsShowMore(true);
                          }}
                        >
                          See Less
                        </span>
                      </>
                    )
                  ) : (
                    <p
                      onClick={() => handlePostDetail(feeds_list._id)}
                      dangerouslySetInnerHTML={{
                        __html: urlify(feeds_list.description),
                      }}
                    ></p>
                  )}
                </div>
                {feeds_list.feed_type === "image" &&
                  feeds_list.image &&
                  feeds_list.image.thumbnail_1 && (
                    <div className="feed-image">
                      <img
                        src={s3baseUrl + feeds_list.image.thumbnail_1}
                        onClick={() => handlePostDetail(feeds_list._id)}
                      />
                    </div>
                  )}
                {feeds_list.feed_type === "video" && feeds_list.video_url && (
                  <div className="feed-image">
                    <ReactVideoPlayer url={feeds_list.video_url} />
                  </div>
                )}
                <hr />
              </div>
            );
          })}
      </div>
    </>
  );
}

export default Suggestions;
