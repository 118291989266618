import { CircularProgress, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  confirm_one_time_payment,
  confirm_subscription_payment,
  get_bank_request_url_api,
  get_intent_client_secret_for_one_time,
  pay_now_for_subscription,
  requestDetailApi,
} from "src/DAL/Payment/Payment";
import CardInformation from "./CardInformation";
import RequestCard from "./RequestCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useStripe } from "@stripe/react-stripe-js";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { handle_open_browser } from "src/utils/constants";
import { _change_currency } from "src/DAL/GeneralAPIs/GeneralAPIs";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const PendingRequests = (props) => {
  const classes = useStyles();
  const params = useParams();
  const { userInfo } = useContentSetting();
  const location = useLocation();
  const navigate = useNavigate();
  const stripe = useStripe();
  const [requestData, setRequestData] = useState({});
  const [countriesList, setCountriesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [isBankPayment, setIsBankPayment] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [resPostData, setResPostData] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  function test(words) {
    var n = words.split("=");
    return n[n.length - 1];
  }
  let mobileView = test(location.search);

  const handleCurrency = async (from, country, total_price) => {
    if (from.toLowerCase() !== country.which_currency_using.toLowerCase()) {
      let postData = {
        from: from.toLowerCase(),
        to: country.which_currency_using.toLowerCase(),
        amount: total_price,
      };
      const result = await _change_currency(postData);
      if (result.code === 200) {
        setRequestData((old) => ({
          ...old,
          converted_amount: result.data,
          cost_in_one_pound: country.cost_in_one_pound,
          converted_currency: country.which_currency_using.toLowerCase(),
        }));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      setRequestData((old) => ({
        ...old,
        converted_amount: 0,
        converted_currency: "gbp",
      }));
      setIsLoading(false);
    }
  };

  //Getting lesson detail
  const getRequestDetail = async () => {
    const result = await requestDetailApi(params.request_slug);
    if (result.code === 200) {
      if (result.payment_request.is_first_paid === true) {
        navigate(`/active-request/${params.request_slug}`);
      }
      setRequestData(result.payment_request_info);
      setCountriesList(result.countries);

      let find = result.countries.find(
        (country) =>
          country.value == userInfo.country &&
          country.fire_payment_enabled == true
      );
      let { currency, total_amount } = result.payment_request_info;
      if (
        find &&
        find.which_currency_using.toLowerCase() !== currency &&
        result.payment_request_info.request_type == "onetime"
      ) {
        handleCurrency(currency, find, total_amount);
      } else {
        setIsLoading(false);
      }
    } else {
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/payment-request`);
    }
  };
  const handleBackMobile = (data) => {
    if (window.ReactNativeWebView) {
      // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }
  };
  const handleNavigateAgreement = (result) => {
    if (result.payment_request.agreement_config) {
      if (result.payment_request.agreement_config.is_sign_agreement === true) {
        navigate(`/agreement/${result.payment_request.payment_request_slug}`, {
          state: result.payment_request,
        });
      } else {
        navigate(`/payment-request`);
      }
    } else {
      navigate(`/payment-request`);
    }
  };
  const payNowForSubscription = async (token, cardElement) => {
    const postData = {
      payment_request_slug: requestData.payment_request_slug,
      source_token: token,
    };

    // if client secret is already generated
    if (clientSecret && resPostData) {
      handleSecureCard(clientSecret, cardElement, resPostData);
      return;
    }

    const result = await pay_now_for_subscription(postData);

    if (result.code === 200) {
      setIsLoadingCard(false);
      enqueueSnackbar("Payment succeeded successfully.", {
        variant: "success",
      });
      handleBackMobile(result);
      handleNavigateAgreement(result);
    } else if (result.code === 210) {
      const postData = {
        payment_request_slug: requestData.payment_request_slug,
        price_id: result.stripe_initial_price_id,
        recurring_price_id: result.strip_recurring_price_id,
        subscription_id: result.strip_subscription_id,
      };
      // save data for failure use
      setClientSecret(result.client_secret);
      setResPostData(postData);
      // handle secure card action
      handleSecureCard(result.client_secret, cardElement, postData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingCard(false);
    }
  };

  const getIntentClientSecretForOneTime = async (token, cardElement) => {
    const postData = {
      payment_request_slug: requestData.payment_request_slug,
    };

    // if client secret is already generated
    if (clientSecret && resPostData) {
      handleSecureCard(clientSecret, cardElement, resPostData);
      return;
    }

    const result = await get_intent_client_secret_for_one_time(postData);

    if (result.code === 200) {
      // confirm payment data
      const postData = {
        payment_request_slug: requestData.payment_request_slug,
        payment_intent: result.payment_intent,
      };

      // save data for failure use
      setClientSecret(result.client_secret);
      setResPostData(postData);

      // handle secure card action
      handleSecureCard(result.client_secret, cardElement, postData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingCard(false);
    }
  };

  const confirmCardPayment = async (postData) => {
    if (requestData.request_type === "recurring") {
      delete postData.payment_intent;
      const result = await confirm_subscription_payment(postData);
      if (result.code === 200) {
        setIsLoadingCard(false);
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
        handleBackMobile(result);
        handleNavigateAgreement(result);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingCard(false);
      }
    } else {
      const result = await confirm_one_time_payment(postData);
      if (result.code === 200) {
        setIsLoadingCard(false);
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
        handleBackMobile(result);
        handleNavigateAgreement(result);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingCard(false);
      }
    }
  };

  const handleSecureCard = (client_secret, cardElement, postData) => {
    enqueueSnackbar("Processing card...", {
      variant: "info",
    });

    stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(function (result) {
        // Handle result.error or result.paymentIntent
        setIsLoadingCard(false);
        if (result.error) {
          setIsLoadingCard(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        }

        confirmCardPayment(postData);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const handleCardAction = (card_token, cardElement) => {
    if (requestData.request_type === "recurring") {
      payNowForSubscription(card_token, cardElement);
    } else {
      getIntentClientSecretForOneTime(card_token, cardElement);
    }
  };

  const handlePayBank = async () => {
    setIsLoading(true);
    const result = await get_bank_request_url_api(params.request_slug);
    if (result.code === 200) {
      handle_open_browser(result.redirect_url);
      navigate(`/payment-request`);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getRequestDetail();
  }, [params.request_slug]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let find_country = countriesList.find(
    (country) =>
      country.value == userInfo.country && country.fire_payment_enabled == true
  );

  return (
    <div className="container mt-2">
      <div className="d-flex">
        {mobileView == "mobile" ? (
          ""
        ) : (
          <span>
            <IconButton
              className="back-screen-button"
              onClick={() => navigate("/payment-request")}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
        )}
        <h2 className="mb-3 ms-2">Payment Request Information</h2>
      </div>

      <RequestCard
        bill={requestData}
        btn_title="View detail"
        isBankPayment={isBankPayment}
      />
      <div className="col-12"></div>
      <div className="row">
        {find_country && requestData.request_type === "onetime" && (
          <div className="col-12 col-md-5 mt-5">
            <h2>Payment Method</h2>
            <div className="row mt-4 payment-method">
              <div className="col-5">
                <div
                  className="d-flex cursor-pointer"
                  onClick={() => {
                    setIsBankPayment(true);
                  }}
                >
                  <div className="method-box me-2">
                    {isBankPayment && <div className="selected-method"></div>}
                  </div>
                  <div>Bank</div>
                </div>
              </div>
              <div className="col-7">
                <div
                  className="d-flex cursor-pointer"
                  onClick={() => {
                    setIsBankPayment(false);
                  }}
                >
                  <div className="method-box me-2">
                    {!isBankPayment && <div className="selected-method"></div>}
                  </div>
                  <div>Credit Card</div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={`col-12 ${
            find_country && requestData.request_type === "onetime"
              ? "col-md-7"
              : ""
          }`}
        >
          {isBankPayment &&
          find_country &&
          requestData.request_type === "onetime" ? (
            <div className="text-end mt-5">
              <button
                className="small-contained-button mt-5 mb-4"
                type="submit"
                disabled={isLoadingCard}
                onClick={handlePayBank}
              >
                {isLoadingCard ? "Processing..." : "Pay Now"}
              </button>
            </div>
          ) : (
            <CardInformation
              isLoading={isLoadingCard}
              setIsLoading={setIsLoadingCard}
              handleCardAction={handleCardAction}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PendingRequests;
