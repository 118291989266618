import React, { useState, useEffect, useRef } from "react";
import PinterestLayout from "src/components/GeneralComponents/Pinterest";
import { quotesListing } from "../../DAL/Quotes/Quotes";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import SlickCarousel from "src/components/GeneralComponents/SlickCarousel";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function Members2() {
  const classes = useStyles();
  const [quotesData, setQuotesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleDetailPage = () => {};
  //   useEffect(() => {
  //     getQuotesListing();
  //   }, []);
  // if (isLoading) {
  //   return <CircularProgress className={classes.loading} color="primary" />;
  // }
  return (
    <>
      <section className="portal-banner">
        <div className="container">
          <div className="text-start">
            <h2>Your Result</h2>
          </div>
          <div className="row mt-4">
            <div className="col-lg-8 p-0">
              <div className="video-portal-border">
                <iframe
                  width="650"
                  height="450"
                  src="https://player.vimeo.com/video/746594408?h=457d80b5d9&badge=0&autopause=0&player_id=0&app_id=58479"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen=""
                ></iframe>
              </div>
            </div>
            <div className="col-lg-4 p-0">
              <div className="portal-card-linear">
                <h6>Description</h6>
                <p>
                  Gratitude is a positive emotion that involves being thankful
                  and appreciative and is associated with several mental and
                  physical health benefits. When you experience Gratitude,
                  Gratitude is a positive emotion.
                </p>
                <p>
                  That involves being thankful and appreciative and is
                  associated with several mental and physical health benefits.
                  When you experience Gratitude,{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="portal-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center portal-member">
              <h2>
                Behind Every Single Action Is a Science. Once You Understand
                This, The World Of No Limits Undfolds
              </h2>
            </div>
            <div className="text-center mt-5">
              <a href="#" className="btn-btn-portal">
                ENTER THE SOURCE HERE
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Members2;
