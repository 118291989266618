import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import ShopCheckOut from "./ShopCheckOut";

export default function CheckOutFile() {
  const { stripeKeyForShop } = useContentSetting();

  return (
    <Elements stripe={loadStripe(stripeKeyForShop)}>
      <ShopCheckOut />
    </Elements>
  );
}
