import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactVideoPlayer } from "src/components";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

function Welcome() {
  const navigate = useNavigate();
  const { contentSettingData, dashboardSettingData, navbarList } =
    useContentSetting();

  useEffect(() => {
    let find_mc = navbarList.find(
      (item) => item.value === "mission_control" && item.is_access !== false
    );
    if (find_mc) {
      navigate("/mission-control", { replace: true });
    }
  }, [navbarList]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 dashboard-welcome-description">
            {contentSettingData && contentSettingData.dashboard_description ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: contentSettingData.dashboard_description,
                }}
              ></div>
            ) : (
              <h2 className="dashboard-heading">Hi, Welcome back</h2>
            )}
          </div>
          <div className="col-12 mt-3">
            {dashboardSettingData?.welcome_video_url && (
              <ReactVideoPlayer url={dashboardSettingData?.welcome_video_url} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Welcome;
