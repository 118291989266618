import { invokeApi } from "../../bl_libs/invokeApi";

export const live_stream_by_module_id_api = async (module_id) => {
  const requestObj = {
    path: `api/live_stream/get/${module_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const record_media_stream_api = async (data) => {
  const requestObj = {
    path: `api/live_stream/upload_live_stream_video`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
