import React from "react";
import { ReactVideoPlayer } from "src/components";
import { htmlDecode } from "src/utils/convertHtml";

export default function QuestionTopSection({ data, userInfo, pageState }) {
  const replacedString = (string) => {
    if (string) {
      return string.replace(/{Name}/g, userInfo.first_name);
    } else {
      return "";
    }
  };

  let video_url = data.assessment_video_url;
  let description = data.assessment_description;

  if (pageState == 2) {
    video_url = data.wheel_of_life_video_url;
    description = data.wheel_of_life_video_description;
  }

  return (
    <div className="container">
      <div className="row top-vedio-container">
        {video_url && (
          <div className=" col-lg-8 attach-with-iframe">
            <ReactVideoPlayer url={video_url} />
          </div>
        )}
        <div className={` ${video_url ? "col-lg-4" : "col-lg-12"} p-0`}>
          <div className="portal-card-linear question-description-top-box">
            <div
              dangerouslySetInnerHTML={{
                __html: replacedString(description),
              }}
            ></div>
            {pageState == 1 && data.assessment_button_text && (
              <a href={data.assessment_button_link}>
                <button className="btn-btn-portal">
                  {htmlDecode(data.assessment_button_text)}
                </button>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
