import React, { useState } from "react";
import { dlx_logo } from "src/assets";
import { websiteBaseUrl } from "src/config/config";
import { token_generate_for_upgrade_portal_api } from "src/DAL/WheelOfLife/WheelOfLifeApi";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

export default function AssessmentResultBox({
  resultsData,
  get_scores,
  total_scores,
  percentage_color,
  get_coins,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { affiliateUrlName } = useContentSetting();
  const replacedResultString = (string) => {
    if (string) {
      return string.replace(/{Result}/g, `${get_scores} / ${total_scores}`);
    } else {
      return "";
    }
  };

  const handle_change_page = async (page_link) => {
    setIsLoading(true);
    let page_url = {
      page_url: page_link,
    };
    const result = await token_generate_for_upgrade_portal_api(page_url);
    if (result.code === 200) {
      window.open(
        `${websiteBaseUrl}auth-redirect-controller/${result.token}`,
        "_self"
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="col-lg-8 ps-lg-5 col-md-7 mt-4 mt-md-0">
        <div
          dangerouslySetInnerHTML={{
            __html: resultsData.result_box_1,
          }}
          className="assetssment-tab-para text-md-start text-center"
        ></div>
        <div className="mt-4 d-flex h-auto position-relative">
          <div className="result-div">
            {get_coins > 0 ? (
              <div className="coins-div text-center">
                <img src={dlx_logo} alt="" />
                <h3>{get_coins}</h3>
                <p>COINS ACHIEVED</p>
              </div>
            ) : (
              <div className="bg-color-div">
                <i className="fa-solid fa-check"></i>
              </div>
            )}
          </div>
          <div
            className="assessment-result-box w-100"
            style={{ backgroundColor: percentage_color }}
          >
            {/* <h4>{`${get_scores} / ${total_scores}`}</h4> */}
            <div
              dangerouslySetInnerHTML={{
                __html: replacedResultString(resultsData.result_box_2),
              }}
            ></div>
          </div>
        </div>
        {(resultsData.result_box_5 || resultsData.result_box_3) && (
          <div className="border-div-assessment assetssment-para text-center mt-4">
            <div
              dangerouslySetInnerHTML={{
                __html: resultsData.result_box_3,
              }}
            ></div>
            <div
              className={`${resultsData.result_box_3 ? "borderbg-top" : ""}`}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: resultsData.result_box_5,
                }}
              ></div>
              {resultsData.button_title && (
                <div className="assessment-btn-tab mt-4">
                  <button
                    onClick={() => {
                      handle_change_page(
                        `${resultsData.button_link}/${
                          affiliateUrlName ? affiliateUrlName : ""
                        }`
                      );
                    }}
                    disabled={isLoading}
                  >
                    {resultsData.button_title}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {resultsData.result_box_4 && (
        <div className="assetssment-tab-para pt-5 ps-lg-5 text-md-start text-center">
          <div
            dangerouslySetInnerHTML={{
              __html: resultsData.result_box_4,
            }}
          ></div>
        </div>
      )}
    </>
  );
}
