import React from "react";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import RatingCard from "./RatingCard";

export default function RatingCardTab({ reviews }) {
  return (
    <div className="row mt-3">
      <div className="col-12">
        <h2>WHAT OUR CLIENTS SAY</h2>
        <p className="normal-font">
          It is for anyone who wants calmness and serenity in their life, and
          it’s perfect for people that crave clarity and direction and are ready
          to uplevel their thinking and results.
        </p>
      </div>
      {reviews.length > 0 ? (
        reviews.map((review, i) => (
          <div
            className="col-lg-4 col-md-6 col-sm-12 mt-4 d-flex"
            key={review._id}
          >
            <RatingCard review={review} reviews={reviews} />
          </div>
        ))
      ) : (
        <RecordNotFound title="Reviews Not Found" />
      )}
    </div>
  );
}
