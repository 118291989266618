import React, { useState } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { htmlDecode } from "src/utils/convertHtml";
import { ReactVideoPlayer } from "src/components";
import { useNavigate } from "react-router-dom";
import { handle_change_page } from "src/utils/constants";

function ThankYou() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { wheelOfLifeSetting, userInfo } = useContentSetting();

  const replacedString = (string) => {
    if (string) {
      return string.replace(/{Name}/g, userInfo.first_name);
    } else {
      return "";
    }
  };

  const handleNavigate = () => {
    navigate("/mission-control");
  };

  return (
    <>
      <section className="portal-banner">
        <div className="container">
          <div
            dangerouslySetInnerHTML={{
              __html: replacedString(wheelOfLifeSetting.thank_you_heading),
            }}
            className="text-start"
          ></div>
          <div className="row mt-4">
            <div className="col-lg-8 p-0">
              <div className="video-portal-border">
                <ReactVideoPlayer
                  url={wheelOfLifeSetting.thank_you_video_url}
                />
              </div>
            </div>
            <div className="col-lg-4 p-0">
              <div className="portal-card-linear">
                <div
                  dangerouslySetInnerHTML={{
                    __html: replacedString(
                      wheelOfLifeSetting.thank_you_description
                    ),
                  }}
                ></div>
                {wheelOfLifeSetting.thank_you_button_text && (
                  <a href={wheelOfLifeSetting.thank_you_button_link}>
                    <button className="btn-btn-portal">
                      {htmlDecode(wheelOfLifeSetting.thank_you_button_text)}
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="portal-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                dangerouslySetInnerHTML={{
                  __html: wheelOfLifeSetting.plan_heading_description,
                }}
                className="home-page-plan"
              ></div>
            </div>
          </div>
          <div className="row justify-content-center">
            {(wheelOfLifeSetting.plan_1_title_and_amount ||
              wheelOfLifeSetting.plan_1_description ||
              wheelOfLifeSetting.plan_1_button_text) && (
              <div className="col-lg-6 mt-4">
                <div className="portal-card h-100 position-relative">
                  <div className="row mb-3">
                    <div className="col-md-5">
                      {wheelOfLifeSetting.plan_1_title_and_amount && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: wheelOfLifeSetting.plan_1_title_and_amount,
                          }}
                          className="bg-card-black"
                        ></div>
                      )}
                    </div>
                    <div className="col-md-7">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: wheelOfLifeSetting.plan_1_description,
                        }}
                        className="home-page-plan"
                      ></div>
                    </div>
                  </div>
                  <div className="btn-portal  d-flex justify-content-center">
                    {wheelOfLifeSetting.plan_1_button_text && (
                      <button
                        onClick={() => {
                          handle_change_page(
                            wheelOfLifeSetting.plan_1_button_link,
                            setIsLoading
                          );
                        }}
                        disabled={isLoading}
                      >
                        {htmlDecode(wheelOfLifeSetting.plan_1_button_text)}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
            {(wheelOfLifeSetting.plan_2_title_and_amount ||
              wheelOfLifeSetting.plan_2_description ||
              wheelOfLifeSetting.plan_2_button_text) && (
              <div className="col-lg-6 mt-4">
                <div className="portal-card h-100 position-relative">
                  <div className="row mb-3">
                    <div className="col-md-5">
                      {wheelOfLifeSetting.plan_2_title_and_amount && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: wheelOfLifeSetting.plan_2_title_and_amount,
                          }}
                          // className="bg-card-yellow"
                          className="bg-card-black"
                        ></div>
                      )}
                    </div>
                    <div className="col-md-7">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: wheelOfLifeSetting.plan_2_description,
                        }}
                        className="home-page-plan"
                      ></div>
                    </div>
                  </div>
                  <div className="btn-portal d-flex justify-content-center">
                    {wheelOfLifeSetting.plan_2_button_text && (
                      <button
                        onClick={() => {
                          handle_change_page(
                            wheelOfLifeSetting.plan_2_button_link,
                            setIsLoading
                          );
                        }}
                        disabled={isLoading}
                      >
                        {htmlDecode(wheelOfLifeSetting.plan_2_button_text)}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="portal-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            {wheelOfLifeSetting.understand_science_description && (
              <div className="col-lg-8 text-center portal-member">
                <div
                  dangerouslySetInnerHTML={{
                    __html: wheelOfLifeSetting.understand_science_description,
                  }}
                  className="mb-5"
                ></div>
              </div>
            )}
            <div className="text-center">
              {wheelOfLifeSetting.understand_science_button_text && (
                <a
                  className="btn-btn-portal btn-btn-portal-navigate"
                  onClick={handleNavigate}
                >
                  {htmlDecode(
                    wheelOfLifeSetting.understand_science_button_text
                  )}
                </a>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <section className="services-testimonial-wrapper">
        <div className="container p-lg-0 overflow-hidden">
          <div className="row justify-content-start">
            <div className="col-lg-10">
              <h2>Testimonial</h2>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Excepturi, rerum tenetur? Vero sapiente iste<br></br> minus
                architecto, voluptatibus quaerat eius voluptates.
              </p>
            </div>
          </div>
          <div className="carousel-parent mt-5">
            <div className="owl-nav">
              <button onClick={goPrev} className="arrowStyle">
                <i
                  className="fa-solid fa-chevron-left style"
                  aria-hidden="true"
                ></i>
              </button>
              <button onClick={goNext} className="arrowStyle">
                <i
                  className="fa-solid fa-chevron-right style"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
            <SlickCarousel
              carouselRef={carouselRef}
            // responsive={responsive_css}
            >
              <div className="item set-margen">
                <div className="outer-home-card h-100 position-relative">
                  <div className="inner-div-home">
                    <img
                      src={slider_image_Vector}
                      className="slider-home-img"
                      width={50}
                      alt=""
                    />
                    <div className="home-slider-block">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Excepturi, rerum tenetur? Vero sapiente iste minus
                        architecto, voluptatibus quaerat eius voluptates.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <img
                      src={slider_image_1}
                      className="card-img-home"
                      alt=""
                    />
                    <div className="d-block card-img-para ps-3">
                      <h6> Arlene McCoy</h6>
                      <p> Vero sapiente iste</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item set-margen">
                <div className="outer-home-card h-100 position-relative">
                  <div className="inner-div-home">
                    <img
                      src={slider_image_Vector}
                      className="slider-home-img"
                      width={50}
                      alt=""
                    />
                    <div className="home-slider-block">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Excepturi, rerum tenetur? Vero sapiente iste minus
                        architecto, voluptatibus quaerat eius voluptates.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <img
                      src={slider_image_2}
                      className="card-img-home"
                      alt=""
                    />
                    <div className="d-block card-img-para ps-3">
                      <h6> Arlene McCoy</h6>
                      <p> Vero sapiente iste</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item set-margen">
                <div className="outer-home-card h-100 position-relative">
                  <div className="inner-div-home">
                    <img
                      src={slider_image_Vector}
                      className="slider-home-img"
                      width={50}
                      alt=""
                    />
                    <div className="home-slider-block">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Excepturi, rerum tenetur? Vero sapiente iste minus
                        architecto, voluptatibus quaerat eius voluptates.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <img
                      src={slider_image_3}
                      className="card-img-home"
                      alt=""
                    />
                    <div className="d-block card-img-para ps-3">
                      <h6> Arlene McCoy</h6>
                      <p> Vero sapiente iste</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item set-margen">
                <div className="outer-home-card h-100 position-relative">
                  <div className="inner-div-home">
                    <img
                      src={slider_image_Vector}
                      className="slider-home-img"
                      width={50}
                      alt=""
                    />
                    <div className="home-slider-block">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Excepturi, rerum tenetur? Vero sapiente iste minus
                        architecto, voluptatibus quaerat eius voluptates.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <img
                      src={slider_image_1}
                      className="card-img-home"
                      alt=""
                    />
                    <div className="d-block card-img-para ps-3">
                      <h6> Arlene McCoy</h6>
                      <p> Vero sapiente iste</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item set-margen">
                <div className="outer-home-card h-100 position-relative">
                  <div className="inner-div-home">
                    <img
                      src={slider_image_Vector}
                      className="slider-home-img"
                      width={50}
                      alt=""
                    />
                    <div className="home-slider-block">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Excepturi, rerum tenetur? Vero sapiente iste minus
                        architecto, voluptatibus quaerat eius voluptates.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <img
                      src={slider_image_2}
                      className="card-img-home"
                      alt=""
                    />
                    <div className="d-block card-img-para ps-3">
                      <h6> Arlene McCoy</h6>
                      <p> Vero sapiente iste</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item set-margen">
                <div className="outer-home-card h-100 position-relative">
                  <div className="inner-div-home">
                    <img
                      src={slider_image_Vector}
                      className="slider-home-img"
                      width={50}
                      alt=""
                    />
                    <div className="home-slider-block">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Excepturi, rerum tenetur? Vero sapiente iste minus
                        architecto, voluptatibus quaerat eius voluptates.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <img
                      src={slider_image_3}
                      className="card-img-home"
                      alt=""
                    />
                    <div className="d-block card-img-para ps-3">
                      <h6> Arlene McCoy</h6>
                      <p> Vero sapiente iste</p>
                    </div>
                  </div>
                </div>
              </div>
            </SlickCarousel>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default ThankYou;
