import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import ReactPlayer from "react-player";

const SeekVideoPlayer = ({
  url,
  handleDuration,
  setVideoPlayed,
  visioneering_id,
}) => {
  const playerRef = useRef();
  const [isReady, setIsReady] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [videosList, setVideosList] = useState([]);

  const onReady = useCallback(() => {
    if (!isReady) {
      let timeToStart = 0;
      const video_list = JSON.parse(localStorage.getItem("video_info"));
      setVideosList(video_list);
      let video_info = video_list.find(
        (video) => video.visioneering_id == visioneering_id
      );
      if (
        video_info &&
        video_info.date == moment(new Date()).format("YYYY-MM-DD") &&
        video_info.url == url &&
        video_info.visioneering_id == visioneering_id
      ) {
        setVideoPlayed(video_info.start_time);
        timeToStart = video_info.start_time;
      }
      playerRef.current.seekTo(timeToStart, "seconds");
      setIsReady(true);
    }
  }, [isReady]);

  const handleSetDate = () => {
    setIsStarted(true);
  };

  const handleProgess = (progess) => {
    if (isStarted) {
      setVideoPlayed(progess.playedSeconds);
      if (videosList) {
        let find_video = videosList.find(
          (video) => video.visioneering_id == visioneering_id
        );
        if (!find_video) {
          setVideosList((videosList) => [
            ...videosList,
            {
              visioneering_id: visioneering_id,
              date: moment(new Date()).format("YYYY-MM-DD"),
              url: url,
              start_time: progess.playedSeconds,
            },
          ]);
        } else {
          const newState = videosList.map((obj) => {
            if (obj.visioneering_id === visioneering_id) {
              return {
                visioneering_id: visioneering_id,
                date: moment(new Date()).format("YYYY-MM-DD"),
                url: url,
                start_time: progess.playedSeconds,
              };
            }
            return obj;
          });
          setVideosList(newState);
        }
      } else {
        setVideosList([
          {
            visioneering_id: visioneering_id,
            date: moment(new Date()).format("YYYY-MM-DD"),
            url: url,
            start_time: progess.playedSeconds,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    if (isStarted) {
      localStorage.setItem("video_info", JSON.stringify(videosList));
    }
  }, [videosList]);

  return (
    <div className="wrapper">
      <ReactPlayer
        ref={playerRef}
        controls
        onContextMenu={(e) => e.preventDefault()}
        className="player"
        url={url}
        width="100%"
        height="100%"
        stopOnUnmount
        onReady={onReady}
        onStart={handleSetDate}
        onProgress={handleProgess}
        onDuration={handleDuration}
      />
    </div>
  );
};

export default SeekVideoPlayer;
