import React from "react";
import { Box, Card, Link, Typography, Stack, Button } from "@mui/material";
import bugFilled from "@iconify/icons-ant-design/bug-filled";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import moment from "moment";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import { convertCurrencyToSign } from "src/utils/constants";
import outlineDateRange from "@iconify/icons-ic/outline-date-range";
import calendarOutline from "@iconify/icons-eva/calendar-outline";
import { htmlDecode } from "src/utils/convertHtml";

function RequestCard() {
  return (
    <Card className="payment-card mb-3">
      <div className="row p-3">
        <div className="col-12">
          <div className="mb-3">
            <span>
              Thinking into Results & Ignite - 12 Month Mentorship - Pay in Full
            </span>
          </div>
          <div className="mb-3 d-flex align-items-center svg-color date-color">
            <Icon icon="uiw:date" />
            <span className="ms-2">Mar 20 2023</span>
          </div>
          <div className="row">
            <div className="col-8 col-md-6 col-lg-3 request-type">
              Request Type:
            </div>
            <div className="col-4 col-md-6 col-lg-3 ">OneTime</div>
            <div className="col-8 col-md-6 col-lg-3 mt-3 mt-lg-0 request-type">
              Total Programme Amount:
            </div>
            <div className="col-4 col-md-6 col-lg-3  mt-3 mt-lg-0 ">
              £11,997
            </div>

            {/* {bill.request_type === "recurring" && (
              <>
                <div className="col-8 col-md-6 col-lg-3  mt-3 request-type">
                  Initial Deposit Amount:
                </div>
                <div className="col-4 col-md-6 col-lg-3  mt-3 ">
                  {convertCurrencyToSign(bill.currency)}
                  {bill.initial_amount.toLocaleString()}
                </div>
                <div className="col-8 col-md-6 col-lg-3  mt-3 request-type">
                  Monthly Installments:
                </div>
                <div className="col-4 col-md-6 col-lg-3  mt-3 ">
                  {convertCurrencyToSign(bill.currency)}
                  {bill.installment_amount.toLocaleString()}
                </div>
                <div className="col-8 col-md-6 col-lg-3  mt-3 request-type">
                  Installments Plan:
                </div>
                <div className="col-4 col-md-6 col-lg-3 mt-3 ">
                  {bill.month + " Months"}
                </div>
              </>
            )} */}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default RequestCard;
