import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "../../config/config";
import { favourite_videos_list } from "src/DAL/Community/Community";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function FavouriteVideos(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [favouriteVideosList, setFavouriteVideosList] = useState({});

  const getFavouriteVideos = async () => {
    const result = await favourite_videos_list();
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.favorite_video.map((video) => {
        CARD_OPTIONS.push({
          ...video,
          card_title: video.series_video.title,
          card_short_description: video.series_video.short_description,
          card_image: s3baseUrl + video.series_video.thumbnail.thumbnail_1,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
        });
      });
      setFavouriteVideosList(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFavouriteVideos();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const handleChangePage = (object) => {
    navigate(`/series-video/${object.series_video._id}`);
  };

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate("/thesource")}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-12">
          <h1>Favourite Videos</h1>
        </div>
      </div>
      {favouriteVideosList.length > 0 ? (
        <CustomMUICard
          data={favouriteVideosList}
          handleClick={handleChangePage}
        />
      ) : (
        <RecordNotFound title="Videos Not Found" />
      )}
    </div>
  );
}

export default FavouriteVideos;
