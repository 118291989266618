import React from "react";

export default function PlanModalContent(props) {
  const {
    handleClosePlan,
    openPlanPopup,
    contentSettings,
    isSubmitting,
    setOpenPlanPopup,
  } = props;

  return (
    <div className="position-relative">
      <div className="close-popup" onClick={() => setOpenPlanPopup("")}>
        x
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html:
            openPlanPopup === "close_plan"
              ? contentSettings?.close_plan_popup_description
              : contentSettings?.start_plan_popup_description,
        }}
        className="ps-2 w-100 close_plan_popup_description"
      ></div>
      {openPlanPopup === "close_plan" &&
        contentSettings.close_plan_button_text && (
          <div className="text-center">
            <button
              className="small-contained-button mt-4"
              onClick={handleClosePlan}
            >
              {isSubmitting
                ? "Submitting..."
                : contentSettings.close_plan_button_text}
            </button>
          </div>
        )}
    </div>
  );
}
