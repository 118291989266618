import React from "react";
import { useEffect } from "react";
import StarRating from "src/pages/AttitudeAssessment/StarRating";
import { htmlDecode } from "src/utils/convertHtml";

export default function AttitudeAssessmentQuestions({
  data,
  activeSection,
  assessmentSetting,
  handleAnswer,
  buttonHandler,
  gainedMarks,
  setGainedMarks,
  totalMarks,
}) {
  useEffect(() => {
    if (data.length > 0) {
      let scores = 0;
      data.map((question) => {
        const new_rating = question.answer ? question.answer : 0;
        scores = scores + new_rating;
      });
      setGainedMarks(scores);
    }
  }, [data]);

  return (
    <div className="col-lg-8 ps-lg-5 col-md-8 mt-md-0 mt-5">
      <div
        dangerouslySetInnerHTML={{
          __html: assessmentSetting.statement,
        }}
        className="assetssment-tab-para text-md-start text-center"
      ></div>
      <ul className="list-unstyled pt-1 assesment-list">
        {data.length > 0 &&
          data.map((question, index) => {
            return (
              <li key={index} className="row">
                <div className="col-12 col-md-7 p-2 pt-3">
                  <span>{htmlDecode(question.question_statement)}</span>
                </div>
                <div className="col-12 col-md-5">
                  <StarRating
                    question={question}
                    handleAnswer={handleAnswer}
                    question_index={index}
                  />
                </div>
              </li>
            );
          })}
      </ul>
      <div className="d-flex justify-content-between pt-5">
        <div className="assetssment-tab-para">
          {gainedMarks > 0 && (
            <h4>Total Score: {gainedMarks + "/" + totalMarks}</h4>
          )}
        </div>
        <div className="assessment-btn">
          <button onClick={() => buttonHandler()}>
            {activeSection == 3 ? "Submit" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
}
