import React from "react";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import GraphicsLinksCard from "./GraphicsLinksCard";
import { projectMode } from "src/config/config";

function GraphicsLinks({ graphicsLinks }) {
  return (
    <div className="mb-3 graphics-links">
      <h4 className="p-3 pb-0">Graphic Links</h4>
      <hr />
      <div
        className={`p-3 pt-0 ${
          projectMode == "DEV"
            ? "graphics-links-height-dev"
            : "graphics-links-height"
        }`}
      >
        {graphicsLinks?.length < 1 ? (
          <RecordNotFound title="Graphic Links not found" />
        ) : (
          graphicsLinks?.map((value) => {
            return <GraphicsLinksCard value={value} />;
          })
        )}
      </div>
    </div>
  );
}

export default GraphicsLinks;
