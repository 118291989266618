import React from "react";

export default function WebPageSetting({ settingData }) {
  return (
    <div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-12 ms-banner-description">
            <div
              dangerouslySetInnerHTML={{
                __html: settingData.banner_description,
              }}
            ></div>
          </div>
          {settingData.video_description && (
            <div
              className={`col-12 ${
                settingData.event_schedule_description ? "col-md-8" : ""
              } mt-5`}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: settingData.video_description,
                }}
              ></div>
            </div>
          )}
          {settingData.event_schedule_description && (
            <div
              className={`col-12 mt-5 event-schedule-description ${
                settingData.video_description ? "col-md-4" : ""
              }`}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: settingData.event_schedule_description,
                }}
                className="p-3"
              ></div>
            </div>
          )}
          {settingData.event_portal_text && (
            <div
              className={`col-12 mt-5 text-center ${
                settingData.download_app_text ? "col-md-6" : ""
              }`}
            >
              <a href={settingData.event_portal_link} target="_blank">
                <button
                  className="mc-page-setting-button"
                  style={{
                    color: settingData.button_text_color,
                    backgroundColor: settingData.button_bg_color,
                  }}
                >
                  {settingData.event_portal_text}
                </button>
              </a>
            </div>
          )}
          {settingData.download_app_text && (
            <div
              className={`col-12 mt-5 text-center ${
                settingData.event_portal_text ? "col-md-6" : ""
              }`}
            >
              <a href={settingData.download_app_link} target="_blank">
                <button
                  className="mc-page-setting-button"
                  style={{
                    color: settingData.button_text_color,
                    backgroundColor: settingData.button_bg_color,
                  }}
                >
                  {settingData.download_app_text}
                </button>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
