import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
// import CustomPopover from "src/components/GeneralComponents/CustomPopover";
// import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { DeleteEvent } from "src/DAL/Calender/Calender";
// import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { htmlDecode } from "src/utils/convertHtml";
export default function DiaryDetail({ data }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [eventSlug, setEventSlug] = useState("");
  const [editValues, setEditValues] = useState({});
  const [showEditComponent, setShowEditComponent] = useState(false);

  const handleEdit = (value) => {
    setShowEditComponent(true);
  };
  const handleAgreeDelete = (value) => {
    setEventSlug(value);
    setOpenDelete(true);
  };

  //Deleting event
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteEvent(eventSlug);
    if (result.code === 200) {
      setIsLoading(false);
      dataList();
      enqueueSnackbar(result.message, { variant: "success" });
      navigate("/calender");
      onCloseDrawer();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    // setEditValues(eventDetailData);
  }, []);

  return (
    <>
      <div className="container event-details">
        <h1>{htmlDecode(data.title)}</h1>
        <div className="row media-margin">
          <div className="col-12 section-space">
            <div className="row">
              <div className="col-4">
                <b>Date :</b>&nbsp;
              </div>
              <div className="col-8">
                <p>{moment(data?.date).format("DD-MM-YYYY")}</p>
              </div>

              <div className="col-4">
                <b>Note :</b>&nbsp;
              </div>
              <div className="col-8">
                <p>{data.note}</p>
              </div>
              <div className="col-4">
                <b>Statement Options :</b>&nbsp;
              </div>
              <div className="col-8">
                <p>
                  {data?.statement_array?.map((value) => {
                    return value?.option + ", ";
                  })}
                </p>
              </div>
            </div>
            <div
            //   dangerouslySetInnerHTML={{
            //     __html: editValues.description,
            //   }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}
