import React from "react";
import { useState } from "react";
import {
  pen2_image,
  pen3_image,
  pen4_image,
  pen5_image,
  pen_image,
} from "src/assets";
import ReactVideoPlayer from "../ReactPlayers/ReactVideoPlayer";
import ClassNameGenerator from "@mui/utils/ClassNameGenerator/ClassNameGenerator";
import { s3baseUrl } from "src/config/config";

export default function ImageSlider({ images }) {
  const [showFile, setShowFile] = useState({
    type: "image",
    path: s3baseUrl + images[0]?.image,
    is_selected: 0,
  });
  console.log(images, "imageimageimage");
  const handleChangeFile = (type, path, i) => {
    setShowFile({
      type: type,
      path: path,
      is_selected: i,
    });
  };
  return (
    <div className="image-slider">
      <div className="main-image">
        {showFile.type === "video" ? (
          <ReactVideoPlayer url={showFile.path} />
        ) : (
          <img width="100%" src={showFile.path} />
        )}
      </div>
      <div className="image-thumbnails mt-3 text-center">
        {images?.map((item, i) => {
          return (
            <img
              width="100%"
              style={{ marginRight: "10px" }}
              className={showFile.is_selected == i ? "is_selected" : ""}
              src={s3baseUrl + item.image}
              onClick={() => {
                handleChangeFile("image", s3baseUrl + item.image, i);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
