import React, { useState, useEffect } from "react";
import {
  IconButton,
  CircularProgress,
  Chip,
  Tooltip,
  Rating,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LockIcon from "@mui/icons-material/Lock";
import { convertCurrencyToSign } from "src/utils/constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { PodsDetailApi } from "src/DAL/Pods/Pods";
import { s3baseUrl } from "src/config/config";
import ReactTooltip from "react-tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import ShopDialog from "src/components/Shop/ShopDialog";
import ImageSlider from "src/components/Shop/ImageSlider";
import { Shop_list_detail } from "src/DAL/Shop/ShopApi";
import { AddtoCArt } from "src/DAL/ShopApi/ApiShop";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const ShopItemDetail = () => {
  const { userInfo, adminTimeZone } = useContentSetting();
  const { handleUpdateCard, cartElement, setCardElement } = useContentSetting();
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const [itemData, setItemData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const handleShoppingCart = () => {
    navigate(`/shop/shopping-checkout`);
  };

  const getShopDetail = async () => {
    const result = await Shop_list_detail(params.item_slug);
    if (result.code === 200) {
      console.log(result);
      setItemData({
        ...state.object,
        selected_quantity: 1,
        product_id: state.object._id,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleIncrease = () => {
    setItemData((item) => {
      return {
        ...item,
        selected_quantity: Number(item.selected_quantity) + 1,
      };
    });
  };

  const handledecrease = () => {
    if (itemData.selected_quantity > 1) {
      setItemData((item) => {
        return {
          ...item,
          selected_quantity: Number(item.selected_quantity) - 1,
        };
      });
    }
  };
  const handleChangeQuantity = (event, item) => {
    let value = event.target.value;
    if (value >= 0 && value <= item.remaining_quantity) {
      setItemData((item) => {
        return {
          ...item,
          selected_quantity: value ? value : "",
        };
      });
    }
  };
  const addToCart = (itemData) => {
    console.log(itemData, "itemDataitemData");
    const existingItem = cartElement.find(
      (item) => item.product_id === itemData.product_id
    );
    console.log(existingItem, "existingItemexistingItem");
    if (existingItem) {
      setCardElement((old) => {
        return old.map((old_item) => {
          if (old_item.product_id == itemData.product_id) {
            return {
              ...itemData,
              selected_quantity: itemData.selected_quantity,
            };
          } else {
            return old_item;
          }
        });
      });
      // setCardElement(updatedCart);
    } else {
      setCardElement([...cartElement, itemData]);
    }
  };
  const handleSubmit = async (item) => {
    console.log(item, "itemitemitem");
    let postData = {
      product_id: item.product_id,
      quantity: itemData.selected_quantity,
      price: item.price,
    };
    const result = await AddtoCArt(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      addToCart(item);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    const existingItem = cartElement.find(
      (item) => item.product_id === params.item_slug
    );
    if (existingItem) {
      setItemData({
        ...existingItem,
      });

      setIsLoading(false);
    } else {
      getShopDetail();
    }
  }, []);
  let product_catagory_title = localStorage.getItem("product_catagory_title");
  let breadCrumbMenu = [
    {
      title: "Shop",
      navigation: `/shop`,
      active: false,
    },
    {
      title: product_catagory_title,
      navigation: -1,
      active: false,
    },
    {
      title: itemData.name,
      navigation: ``,
      active: true,
    },
  ];
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex mb-4">
        <div className="col-12">
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          {itemData?.image.length > 0 ? (
            <ImageSlider images={itemData?.image} />
          ) : (
            ""
          )}
        </div>
        <div className="col-12 col-md-6 pt-3">
          <h2 className="d-inline">{htmlDecode(itemData.name)}</h2>

          <p className="shop-detail-price mt-2 mb-2">
            {htmlDecode(
              convertCurrencyToSign(itemData.currency) +
                itemData.price.toFixed(2)
            )}
          </p>

          <p className="mt-0">{htmlDecode(itemData.detailed_description)}</p>

          <div className="col-12 container-out-stock  d-flex justify-content-between mt-2 mb-3 text-center detail-item-quantity">
            <Tooltip
              title={`Quantity should be less than or equal to ${itemData.remaining_quantity}`}
            >
              <div className="item-quantity">
                <span
                  onClick={handledecrease}
                  className={`cursor-pointer px-2 ${
                    itemData.selected_quantity <= 1
                      ? "item-quantity-diabled"
                      : ""
                  }`}
                >
                  -
                </span>
                <input
                  type="text"
                  name=""
                  id=""
                  value={itemData.selected_quantity}
                  onChange={(e) => {
                    handleChangeQuantity(e, itemData);
                  }}
                />
                <span
                  className={`cursor-pointer px-2 ${
                    itemData.selected_quantity >= itemData.remaining_quantity
                      ? "item-quantity-diabled"
                      : ""
                  }`}
                  onClick={
                    itemData.selected_quantity >= itemData.remaining_quantity
                      ? ""
                      : () => handleIncrease(itemData)
                  }
                >
                  +
                </span>
              </div>
            </Tooltip>
            <div className="mt-2 mb-1">
              <span className="">Available Quantity :</span>
              <span className="ms-2 quantity">
                {itemData.remaining_quantity > 0
                  ? itemData.remaining_quantity + " " + "Items"
                  : "Out Of Stock"}
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-1">
              <button
                className="small-contained-button add-to-cart-button"
                onClick={
                  itemData.selected_quantity > itemData.remaining_quantity
                    ? ""
                    : () => handleSubmit(itemData)
                }
              >
                {itemData.selected_quantity > itemData.remaining_quantity ? (
                  "Out Of Stock"
                ) : (
                  <>
                    <ShoppingCartIcon />
                    Add To Cart
                  </>
                )}
              </button>
              {cartElement.length > 0 ? (
                <button
                  className="small-contained-button add-to-cart-button mt-1 ms-2"
                  onClick={handleShoppingCart}
                >
                  <>
                    <ShoppingCartIcon />
                    CHECKOUT
                  </>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopItemDetail;
