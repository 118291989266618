import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  CircularProgress,
  Divider,
  FormControlLabel,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { useEffect } from "react";
import {
  get_notification_setting,
  notification_setting,
} from "src/DAL/Profile/Profile";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function NotificationsSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [notificationArray, setNotificationArray] = useState([]);
  const { notificationSettings, handleNotifications } = useContentSetting();

  const [inputs, setInputs] = useState({
    feed_notifications: true,
    support_notifications: false,
    chat_notifications: true,
    daily_dynamite_notification: false,
    meditation_notification: true,
    gratitude_notification: true,
    calendar_notification: true,
  });

  const getNotificationSetting = async () => {
    setIsLoading(true);
    const result = await get_notification_setting();
    if (result.code == 200) {
      if (notificationSettings) {
        setInputs(notificationSettings);
      }
      setNotificationArray(result.notification_setting);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    let postData = {
      notification_settings: inputs,
    };

    const result = await notification_setting(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
      handleNotifications(inputs);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    getNotificationSetting();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex ">
        <div className="col-6">
          <h2>Notification Settings</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row track notifications-setting">
          {notificationArray.map((notification) => {
            return (
              <>
                <div className="col-10 mt-4 d-flex">
                  <Avatar alt="DD" src={s3baseUrl + notification.icon} />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: notification.description,
                    }}
                    className="ms-2 notification-description"
                  ></div>
                </div>
                <div className="col-2 mt-4 text-end">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={inputs[notification.notification_for]}
                        name={notification.notification_for}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                </div>
                <Divider />
              </>
            );
          })}

          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
