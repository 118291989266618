import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Slider,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@mui/material";
import { cointlogo } from "src/assets";
import { useState, useEffect } from "react";
import "react-range-slider-input/dist/style.css";
import { get_root_value } from "src/utils/domUtils";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ReactVideoPlayer } from "src/components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import {
  get_challange_history_by_member_api,
  update_challange_history_by_member_meditation_api,
} from "src/DAL/ChallengeApi/meditationApi";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useSnackbar } from "notistack";
import SeekVideoPlayer from "src/components/ReactPlayers/SeekVideoPlayer";
import { s3baseUrl } from "src/config/config";
import MUIStaticCalendar from "src/components/GeneralComponents/MUIStaticCalendar";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import CongratulationAnimation from "src/components/challenges/CongratulationAnimation";
import CreatePost from "src/components/challenges/CreatePost";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import CongratulationsPopup from "src/components/challenges/CongratulationsPopup";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function MediationChallenge() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [totalCoins, setTotalCoins] = useState(0);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [challengeData, setChallengeData] = useState({});
  const [meditationSetting, setMeditationSetting] = useState({});
  const [challengeDate, setChallengeDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [videoDuration, setVideoDuration] = useState(0);
  const [openHide, setOpenHide] = useState(false);
  const [videoPlayed, setVideoPlayed] = useState(0);
  const [audioDuration, setAudioDuration] = useState(0);
  const [reaminingTime, setReaminingTime] = useState(0);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [playType, setPlayType] = useState("pause");
  const [hideVideo, setHideVideo] = useState(
    localStorage.getItem("hide_challenge_meditation_video")
  );

  const [addArray, setaddArray] = useState([
    { option: "" },
    { option: "" },
    { option: "" },
  ]);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [inputs, setInputs] = useState({
    note_1: "",
    note_2: "",
  });
  const [rewardData, setRewardData] = useState({});
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [openCongratulation, setOpenCongratulation] = useState(false);

  const handleOpenCreateModal = () => {
    setOpenCongratulation(false);
    setOpenCreateModal(true);
  };

  const handleLoadMetadata = (meta) => {
    const { duration } = meta.target;
    setAudioDuration(duration);
  };
  const handlePause = (meta) => {
    setPlayType("pause");
  };
  const handleSeeked = (meta) => {
    console.log(meta, "seeked");
  };
  const handleListen = (meta) => {
    if (playType == "play") {
      setReaminingTime(meta);
    }
  };
  const handlePlay = (meta) => {
    const { duration } = meta.target;
    setAudioDuration(duration);
    setPlayType("play");
    setAudioPlaying(true);
  };

  const handleDuration = (duration) => {
    setVideoDuration(duration);
  };
  const getChallengeDetail = async (isCompleting) => {
    const formData = {
      challange: params.meditation_id,
      challange_date: moment(challengeDate).format("YYYY-MM-DD"),
    };
    const result = await get_challange_history_by_member_api(formData);
    if (result.code === 200) {
      setChallengeData(result.challenge);
      setMeditationSetting(result.meditation_challenge_setting);
      setTotalCoins(result.challange_coins_count_attracted);
      if (result?.challenge?.challange_history?.intention?.length > 0) {
        setaddArray(result?.challenge?.challange_history?.intention);
      } else {
        setaddArray([{ option: "" }, { option: "" }, { option: "" }]);
      }
      if (result.challenge.challange_history?.note_1) {
        setInputs(result.challenge.challange_history);
      } else {
        setInputs({
          note_1: "",
          note_2: "",
        });
      }
      setTimeout(() => {
        if (isCompleting) {
          setIsCompleted(false);
          setOpenCongratulation(true);
        }
      }, 3000);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const deadline = moment(
    challengeData?.challange_info?.challange_end_date
  ).add(1, "day");
  const getTime = (deadline) => {
    const time = deadline ? Date.parse(deadline) - Date.now() : "";
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  // const getTime = () => {
  //   const time = moment(new Date());
  //   setHours(Math.floor(time / (60 * 60)));
  //   setMinutes(Math.floor(time / 60));
  //   setSeconds(Math.round(time % 60));
  // };
  const getAudioTime = () => {
    const time =
      playType == "play" ? audioDuration - reaminingTime : audioDuration;
    setHours(Math.floor(time / (60 * 60)));
    setMinutes(Math.floor(time / 60));
    setSeconds(Math.round(time % 60));
  };

  // useEffect(() => {
  //   getTime();
  // }, [videoPlayed, videoDuration]);
  // useEffect(() => {
  //   getAudioTime();
  // }, [audioDuration, reaminingTime]);
  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 100);
    return () => clearInterval(interval);
  }, [challengeData]);

  const getOneDigit = (digi) => {
    let reminder = digi % 10;
    let deviser = digi / 10;
    let decimalPart = Math.floor(deviser);
    return { reminder, decimalPart };
  };

  const handleChangeNote = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index][name] = value;
    setaddArray(list);
  };

  const handleAdd = () => {
    const addedElement = [...addArray, {}];
    setaddArray(addedElement);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = {
      challange: params.meditation_id,
      challange_date: moment(challengeDate).format("YYYY-MM-DD"),
      note_1: inputs.note_1,
      note_2: inputs.note_2,
      intention: addArray,
    };
    const result = await update_challange_history_by_member_meditation_api(
      formData
    );
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      let is_completed = false;
      if (
        result.data.is_reward_assigned &&
        result.challenge_reward_content.enable_reward_setting
      ) {
        setRewardData({ ...result.data, ...result.challenge_reward_content });
        setIsCompleted(true);
        setIsLoading(false);
        is_completed = true;
      }
      getChallengeDetail(is_completed);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  let challange_start_date = moment(
    challengeData?.challange_info?.challange_start_date,
    "YYYY-MM-DD"
  );
  let complete_date = moment();
  if (challengeData.challange_info?.is_complete) {
    complete_date = moment(
      challengeData.challange_info?.challange_completed_date,
      "YYYY-MM-DD"
    );
  }
  const no_of_days = complete_date.diff(challange_start_date, "days");

  const handleChangeDate = (value) => {
    setChallengeDate(value.$d);
  };

  useEffect(() => {
    getChallengeDetail();
  }, [params.meditation_id, challengeDate]);

  useEffect(() => {
    getTime();
  }, [videoPlayed, videoDuration]);

  const handleAgreeHide = (value) => {
    setOpenHide(true);
  };

  const Days = [
    {
      value: 0,
      label: "Day 1",
    },

    {
      value: 100,
      label: `Day ${challengeData?.number_of_days}`,
    },
  ];

  const handleHideVideo = () => {
    localStorage.setItem("hide_challenge_meditation_video", true);
    setHideVideo(true);
    setOpenHide(false);
  };
  const handleShowVideo = () => {
    localStorage.setItem("hide_challenge_meditation_video", false);
    setHideVideo(false);
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="assessment-wrapper">
        <div className="container">
          <div className="row pt-4">
            <div className="col-lg-6">
              <IconButton
                className="back-screen-button"
                onClick={() => navigate("/meditations")}
              >
                <ArrowBackIcon />
              </IconButton>
              <span className="title-with-back-button">
                {challengeData?.title}
              </span>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-lg-6">
              <PageDescription
                parameter="mediation_description"
                else_title="Mediation"
              />
            </div>
            <div className="col-lg-6">
              <div className="d-flex text-align vision-div align-items-center">
                <img className="coint-img img-fluid" src={cointlogo}></img>
                <h4>Total Coins Attracted: {totalCoins}</h4>
              </div>
            </div>
            <div className="col-lg-12 text-end">
              {(hideVideo == true || hideVideo == "true") && (
                <>
                  <button
                    className="small-contained-button float-end mt-3"
                    onClick={handleShowVideo}
                  >
                    Show Video
                  </button>
                </>
              )}
            </div>
          </div>
          {(hideVideo == false ||
            hideVideo == "false" ||
            hideVideo == null) && (
            <div className="row pt-4">
              {challengeData.challange_intro_video_url && (
                <div className="col-lg-7 col-md-6">
                  <ReactVideoPlayer
                    url={challengeData.challange_intro_video_url}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox onClick={handleAgreeHide} checked={false} />
                    }
                    label="Don't Show me this video again"
                  />
                </div>
              )}
              <div
                className={`${
                  challengeData.challange_intro_video_url == ""
                    ? "col-12"
                    : "col-lg-5"
                } mt-lg-0 mt-4`}
              >
                <div className="text-start label-text">
                  <div
                    className="programme-card-desc"
                    dangerouslySetInnerHTML={{
                      __html: challengeData.challange_intro_video_description,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="container mt-4">
          <div className="mediation-text">
            <div className="mt-2 field-container diray-title">
              <h4 className="mb-0">My top 3 intentions today are</h4>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-7">
                {addArray?.map((data, index) => {
                  return (
                    <div className="d-flex position-relative mt-3">
                      <div className="mt-2 field-container">
                        <TextField
                          required
                          id="outlined-basic"
                          label={`${index + 1}.`}
                          variant="outlined"
                          name="option"
                          value={data.option}
                          onChange={(e) => handleChange(e, index)}
                          sx={{
                            background: "#141717",
                            borderRadius: "5px",
                            input: {
                              "&::placeholder": {
                                opacity: 1,
                              },
                            },
                            label: { color: "#637381" },
                          }}
                        />
                      </div>
                      {/* <div className="icon-container"> */}
                      <span className="cross-icon">
                        {/* <Icon
                        icon="akar-icons:cross"
                        onClick={() => handleDelete(index)}
                      /> */}
                        {addArray.length > 1 ? (
                          <Tooltip title="Remove">
                            <RemoveCircleOutlineIcon
                              onClick={() => handleDelete(index)}
                              className="diary-icon-remove"
                            />
                          </Tooltip>
                        ) : (
                          ""
                        )}
                        <Tooltip title="Add">
                          <AddCircleOutlineIcon
                            onClick={() => handleAdd()}
                            className="diary-icon-add"
                          />
                        </Tooltip>
                      </span>
                      {/* </div> */}
                    </div>
                  );
                })}
              </div>

              <div className="col-lg-5 col-md-6 mt-md-0 mt-4">
                <div className="d-flex calender wi-100 full-open-calender">
                  <MUIStaticCalendar
                    selectedDate={challengeDate}
                    handleChangeDate={handleChangeDate}
                    minDate={
                      challengeData.challange_info?.challange_start_date
                        ? challengeData.challange_info?.challange_start_date
                        : new Date()
                    }
                    maxDate={new Date()}
                  />
                </div>
              </div>
            </div>
            {challengeData.challange_file_type == "video" ? (
              <div className="text-center mt-5">
                <SeekVideoPlayer
                  url={challengeData.challange_video_url}
                  handleDuration={handleDuration}
                  setVideoPlayed={setVideoPlayed}
                  visioneering_id={params.meditation_id}
                />
              </div>
            ) : (
              challengeData.challange_audio_url && (
                <div className="mt-3">
                  <audio
                    controlsList="nodownload"
                    className="w-100"
                    src={s3baseUrl + challengeData.challange_audio_url}
                    controls
                  />
                  {/* <ReactAudioPlayer
                  src={s3baseUrl + challengeData.challange_audio_url}
                  controls
                  onLoadedMetadata={handleLoadMetadata}
                  onPlay={handlePlay}
                  onPause={handlePause}
                  onListen={handleListen}
                  listenInterval={1000}
                  onSeeked={handleSeeked}
                /> */}
                </div>
              )
            )}
            {challengeData?.challange_info?.challange_end_date && (
              <>
                {new Date(
                  challengeData?.challange_info?.challange_end_date
                ).getTime() >= new Date().getTime() && (
                  <div className="text-start label-text pt-5 countdown-timer">
                    <h4>Countdown Timer</h4>
                  </div>
                )}
                <div className="row justify-content-between">
                  {new Date(
                    challengeData?.challange_info?.challange_end_date
                  ).getTime() >= new Date().getTime() && (
                    <div className="col-md-7 pt-3">
                      <div className="timer-count d-flex justify-content-between">
                        <div className="time-count-container">
                          <div className="count">
                            {getOneDigit(days).decimalPart}
                          </div>
                        </div>
                        <div className="time-count-container">
                          <div className="count">
                            {getOneDigit(days).reminder}
                          </div>
                        </div>
                        <div className="colon">:</div>
                        <div className="time-count-container">
                          <div className="count">
                            {getOneDigit(hours).decimalPart}
                          </div>
                        </div>
                        <div className="time-count-container">
                          <div className="count">
                            {getOneDigit(hours).reminder}
                          </div>
                        </div>
                        <div className="colon">:</div>
                        <div className="time-count-container">
                          <div className="count">
                            {getOneDigit(minutes).decimalPart}
                          </div>
                        </div>
                        <div className="time-count-container">
                          <div className="count">
                            {getOneDigit(minutes).reminder}
                          </div>
                        </div>
                        <div className="colon">:</div>
                        <div className="time-count-container">
                          <div className="count">
                            {getOneDigit(seconds).decimalPart}
                          </div>
                        </div>
                        <div className="time-count-container">
                          <div className="count">
                            {getOneDigit(seconds).reminder}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between time-container-site">
                        <div className="time-detail">Days</div>
                        <div className="time-detail">Hours</div>
                        <div className="time-detail">Minutes</div>
                        <div className="time-detail">Seconds</div>
                      </div>
                    </div>
                  )}

                  {challengeData?.challange_info?.challange_completed_days && (
                    <div className="col-12 ps-lg-2 ps-md-1 mt-4">
                      <div className="d-flex align-items-end">
                        <div className="w-100 ms-2">
                          <div className="text-end days-in-total-text">
                            <p>
                              <span>Days in Total : </span>
                              {no_of_days +
                                1 +
                                "/" +
                                challengeData?.number_of_days}
                            </p>
                          </div>
                          <div class="habits-slider d-flex justify-content-between">
                            {/* <div class="silder-value habits-gray-description">
                        <span>{`${Math.round(
                          (challengeData.challange_info
                            .challange_completed_days /
                            challengeData.number_of_days) *
                            100
                        )}%`}</span>
                      </div> */}
                            <Slider
                              style={{
                                color: get_root_value("--portal-theme-primary"),
                              }}
                              aria-label="Custom marks"
                              valueLabelFormat={`${
                                challengeData.challange_info
                                  .challange_completed_days > 1
                                  ? "Days"
                                  : "Day"
                              } ${
                                challengeData.challange_info
                                  .challange_completed_days
                              }`}
                              defaultValue={
                                (challengeData.challange_info
                                  .challange_completed_days /
                                  challengeData.number_of_days) *
                                100
                              }
                              marks={Days}
                              valueLabelDisplay="on"
                              getAriaValueText={valuetext}
                              step={10}
                              className="mui-slider mt-4"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            {!challengeData.challange_info?.is_complete ? (
              <>
                <div className="text-start label-text pt-5">
                  <h4>{meditationSetting.title}</h4>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6 visioneering-textarea">
                    <label className="mb-2">
                      {meditationSetting.notes_placeholder_1}
                    </label>
                    <TextareaAutosize
                      aria-label="empty textarea"
                      placeholder="Notes *"
                      className="text-color textarea-autosize"
                      sx={{ color: "white" }}
                      minRows={4}
                      required
                      name="note_1"
                      value={inputs.note_1}
                      onChange={handleChangeNote}
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        background: "#141717",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "15px",
                        color: "white",
                        fontSize: "15px",
                        border: "2px solid rgba(71, 66, 34, 0.3)",
                      }}
                    />
                  </div>
                  <div className="col-md-6 visioneering-textarea ps-lg-5">
                    <label className="mb-2">
                      {meditationSetting.notes_placeholder_2}
                    </label>
                    <TextareaAutosize
                      aria-label="empty textarea"
                      placeholder="Notes *"
                      className="text-color textarea-autosize"
                      sx={{ color: "white" }}
                      minRows={4}
                      required
                      name="note_2"
                      value={inputs.note_2}
                      onChange={handleChangeNote}
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        background: "#141717",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "15px",
                        color: "white",
                        fontSize: "15px",
                        border: "2px solid rgba(71, 66, 34, 0.3)",
                      }}
                    />
                  </div>
                  <div className="col-md-12 mt-4">
                    <div className="text-end visioneer-btn mark-complete-check-box-lable">
                      <button>
                        {isLoadingForm ? (
                          "Saving ..."
                        ) : (
                          <>
                            Mark Complete For Today
                            <input type="checkbox" id="MarkComplete" checked />
                            <label htmlFor="MarkComplete"></label>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="row">
                <div className="col-12 mt-4">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: challengeData.challenge_completion_message,
                    }}
                  ></div>
                </div>
              </div>
            )}
          </form>
        </div>
        <CustomConfirmation
          open={openHide}
          setOpen={setOpenHide}
          title={"Are you sure you want to hide video ?"}
          handleAgree={handleHideVideo}
        />
      </div>
      {isCompleted && <CongratulationAnimation rewardData={rewardData} />}
      <CreatePost
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        rewardData={rewardData}
      />

      <GeneralModelBox
        open={openCongratulation}
        setOpen={setOpenCongratulation}
        componentToPassDown={
          <CongratulationsPopup
            handleOpenCreateModal={handleOpenCreateModal}
            rewardData={rewardData}
          />
        }
      />
    </>
  );
}

export default MediationChallenge;
