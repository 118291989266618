import React from "react";
import {
  communityDownload,
  communityfirecracker_1f9e8,
  communityHandScreenshot,
  communityHeart,
  communityMoneyImage,
  Group3Image,
  Group5Image,
  mediumGratitudeIcon,
  smallHeartIcon,
} from "src/assets";
import CloseIcon from "@mui/icons-material/Close";
import Slider from "@mui/material/Slider";
import { get_root_value } from "src/utils/domUtils";
import { get_kmb_number } from "src/utils/formatNumber";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { Filler } from "./components";
import HowToEarn from "./components/HowToEarn";
function AboutSection({
  showInfoBox,
  setShowInfoBox,
  userInfo,
  maximumLikeCount,
  bonusCount,
  descriptions,
  feedSetting,
}) {
  const level = userInfo.community_level;

  let coins_count_for_next_level =
    feedSetting[`${level}_level`].coins_count_for_next_level;
  let coins_count_for_next_level_description =
    feedSetting[`${level}_level`].coins_count_for_next_level_description;

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: maximumLikeCount,
      label: `${get_kmb_number(maximumLikeCount)}`,
    },
  ];

  return (
    <>
      <div className="profile-cards p-3 community-card-title wealth-wallet mt-3 mt-lg-0">
        {showInfoBox && (
          <div className="info-popup-box hide-on-desktop">
            <HowToEarn
              coin_description={descriptions?.coin_description}
              like_description={descriptions?.like_description}
              stick_description={descriptions?.stick_description}
              gratitude_description={descriptions?.gratitude_description}
              setShowInfoBox={setShowInfoBox}
            />
          </div>
        )}
        <h4>WEALTH WALLET</h4>
        <div className="d-flex activity-profile set-open-popover">
          <img
            className="i-icon"
            src={communityDownload}
            alt=""
            onClick={() => {
              setShowInfoBox(true);
            }}
          />
        </div>
        <div className="row mt-4">
          <div className="col-3 col-md-4 col-lg-3 ">
            <div className="favourite-box">
              <img src={smallHeartIcon} alt="" />
              <p>{get_kmb_number(userInfo.like_count)}</p>
            </div>
            <div className="favourite-box mt-4">
              <img src={Group3Image} width="18" alt="" />
              <p>{get_kmb_number(userInfo.gratitude_count)}</p>
            </div>
            <div className="favourite-box mt-4">
              <img src={mediumGratitudeIcon} width="17" alt="" />
              <p>{get_kmb_number(userInfo.coins_count)}</p>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-6">
            <Filler
              maximum_like_count={maximumLikeCount}
              like_count_for_bonus={userInfo.like_count_for_bonus}
              coins_count={userInfo.coins_count}
              coins_count_for_next_level={coins_count_for_next_level}
            />
          </div>
          <div className="col-3 col-md-4 col-lg-3 score-right-side text-end ps-0">
            <h2>{get_kmb_number(maximumLikeCount)}</h2>
            <h2>{get_kmb_number(maximumLikeCount / 2)}</h2>
            <h2>0</h2>
            <h5>{get_kmb_number(coins_count_for_next_level)}</h5>
            <h5>{get_kmb_number(coins_count_for_next_level / 2)}</h5>
            <h5>0</h5>
          </div>
        </div>
        {userInfo.coins_count >= coins_count_for_next_level && (
          <div className="row mt-3">
            <div className="col-12">
              <div
                dangerouslySetInnerHTML={{
                  __html: coins_count_for_next_level_description,
                }}
              ></div>
            </div>
          </div>
        )}
        <div className="row pt-4">
          <div className="col-12 coin-slider p-3">
            <Slider
              style={{ color: get_root_value("--portal-theme-primary") }}
              aria-label="Always visible"
              value={userInfo.like_count_for_bonus}
              max={maximumLikeCount}
              marks={marks}
              valueLabelDisplay="on"
              sx={{ color: "red" }}
              valueLabelFormat={(value) => (
                <div className="coin-slider-label">{get_kmb_number(value)}</div>
              )}
            />
          </div>
          <div className="col-12">
            {/* calculate remaining likes */}
            {maximumLikeCount - userInfo.like_count_for_bonus > 0 && (
              <h5 className="mt-2 activity-win">
                Get{" "}
                {
                  <b className="count-number-text">
                    {get_kmb_number(
                      maximumLikeCount - userInfo.like_count_for_bonus
                    )}
                  </b>
                }{" "}
                more likes to Attract{" "}
                {
                  <b className="count-number-text">
                    {get_kmb_number(bonusCount)}
                  </b>
                }{" "}
                coins
              </h5>
            )}
          </div>
        </div>
        <div className="score-row row mt-2">
          <div className="col-9 mb-1 activity-title">
            Total Likes
            <img src={Group5Image} alt="" />
          </div>
          <div className="col-3 mb-1 text-end ">
            <h2 className="activity-title">
              {get_kmb_number(userInfo.like_count)}
            </h2>
          </div>
          <div className="col-9 mb-1 activity-title">
            Total Gratitudes
            <img src={Group3Image} alt="" />
          </div>
          <div className="col-3 mb-1 text-end">
            <h2 className="activity-title">
              {get_kmb_number(userInfo.gratitude_count)}
            </h2>
          </div>
          <div className="col-9 mb-1 activity-title">
            Total Coins
            <img src={mediumGratitudeIcon} alt="" />
          </div>
          <div className="col-3 mb-1 text-end">
            <h2 className="activity-title">
              {get_kmb_number(userInfo.coins_count)}
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutSection;
