import React from "react";
import ReactVideoPlayer from "src/components/ReactPlayers/ReactVideoPlayer";
import { htmlDecode } from "src/utils/convertHtml";
import ReactVideoDurationPlayer from "../ReactPlayers/ReactVideoDurationPlayer";

export default function ProgramSectionTabs({
  programSection,
  getProgrammesDetail,
}) {
  const [videoProgressData, setVideoProgressData] = React.useState([]);
  React.useEffect(() => {
    let data = {
      recording_id: programSection?._id,
      video_duration: programSection?.video_duration,
      total_video_duration: programSection?.total_video_duration,
      is_complete: programSection?.is_complete,
    };
    setVideoProgressData(data);
  }, [programSection]);
  return (
    <div className="row mt-3 program-sections">
      {programSection.video_url && (
        <div className="col-12">
          <ReactVideoDurationPlayer
            url={programSection.video_url}
            type={"program_section"}
            SaveInfo={"program_section"}
            value={programSection}
            setVideoProgressData={setVideoProgressData}
            videoProgressData={videoProgressData}
          />
        </div>
      )}
      <div className="col-12 text-left section-space">
        <div
          dangerouslySetInnerHTML={{
            __html: programSection.detailed_description,
          }}
        ></div>
      </div>
      {programSection.button_text && (
        <div className="text-end">
          <a target="_blank" href={programSection.button_url}>
            <button
              className={`small-contained-button ${
                programSection.button_url === "" ? "disabled" : ""
              }`}
              disabled={programSection.button_url === "" ? true : false}
            >
              {htmlDecode(programSection.button_text)}
            </button>
          </a>
        </div>
      )}
    </div>
  );
}
