import { format } from "date-fns";
import React from "react";
import Chart from "react-apexcharts";

const formatDate = (dateStr) => {
  const date = new Date(
    `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(6, 8)}`
  );
  return format(date, "MMM dd");
};

const transformData = (dataList, metric) => {
  const categories = ["sep 1", "sep 2", "sep 3", "sep 4"];
  const metricData = ["3", "8", "7", "0"];
  return {
    categories,
    series: [
      {
        name: "current recreational life",
        data: metricData,
        color: "#f6bd4b",
      },
    ],
  };
};

const AnalyticsChartrecreational = ({}) => {
  const transformedData = transformData();

  const options = {
    chart: {
      type: "line",
    },
    xaxis: {
      categories: transformedData?.categories,
      labels: {
        style: {
          colors: Array(transformedData?.categories?.length).fill("#fff"),
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ["#fff"],
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  };

  return (
    <Chart
      options={options}
      series={transformedData.series}
      type="line"
      height={180}
    />
  );
};

export default AnalyticsChartrecreational;
