import React from "react";
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  Tooltip,
} from "@mui/material";
import bugFilled from "@iconify/icons-ant-design/bug-filled";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import moment from "moment";
import Chip from "@mui/material/Chip";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import { convertCurrencyToSign } from "src/utils/constants";
import outlineDateRange from "@iconify/icons-ic/outline-date-range";
import calendarOutline from "@iconify/icons-eva/calendar-outline";
import { htmlDecode } from "src/utils/convertHtml";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

function SubscriptionCard({ bill, handlePage, btn_title }) {
  console.log(bill, "billbill");
  let sale_page_title = "N/A";

  let Product = "N/A";
  let plan_title = "N/A";
  if (bill?.plan) {
    plan_title = bill?.plan?.plan_title;
  }
  if (bill.page_info) {
    sale_page_title = `Sale Page (${bill?.page_info?.sale_page_title} | ${plan_title})`;
  }
  let payment_request = "N/A";
  if (bill?.payment_request_info) {
    payment_request = `Payment Request (${bill?.payment_request_info.request_title} | ${bill?.payment_request_info.request_type})`;
  }
  if (bill?.subscription_created_by == "client") {
    Product = payment_request;
  } else {
    Product = sale_page_title;
  }
  return (
    <Card className="payment-card mb-3">
      <div className="row p-3">
        <div className="col-12">
          <div className="mb-3">
            {bill?.cancelation_requested && bill?.subscription_status && (
              <span>
                <Chip
                  icon={<AccessTimeFilledIcon style={{ fill: "white" }} />}
                  color="primary"
                  label={
                    !bill?.cancelation_requested && bill?.subscription_status
                      ? "Active"
                      : bill?.cancelation_requested &&
                        !bill?.subscription_status
                      ? "Canclled"
                      : bill?.cancelation_requested && bill?.subscription_status
                      ? "Cancels On " +
                        moment(bill.subscription_cancelled_date).format(
                          "DD MMM"
                        )
                      : "Expire"
                  }
                />
              </span>
            )}
          </div>
          <div className="mb-3 d-flex align-items-center svg-color date-color">
            <Icon icon="uiw:date" />
            <span className="ms-2">
              Started: {moment(bill.createdAt).format("MMM DD YYYY")}
            </span>
          </div>
          <div className="row">
            <div className="col-8 col-md-6 col-lg-3 request-type">Product:</div>
            <div className="col-4 col-md-6 col-lg-3 ">
              <Tooltip title={bill.stripe_mode}>
                <p>{Product}</p>
              </Tooltip>
            </div>
            {!bill?.cancelation_requested && bill?.subscription_status ? (
              <>
                <div className="col-8 col-md-6 col-lg-3 request-type">
                  Next Invoice:
                </div>
                <div className="col-4 col-md-6 col-lg-3 ">
                  {moment(bill?.next_invoice_date).format("DD-MM-YYYY")}
                </div>
              </>
            ) : bill?.cancelation_requested && !bill?.subscription_status ? (
              <>
                <div className="col-8 col-md-6 col-lg-3 request-type">
                  Ended:
                </div>
                <div className="col-4 col-md-6 col-lg-3 ">
                  {moment(bill?.subscription_cancelled_date).format(
                    "DD MMM YYYY"
                  )}
                </div>
              </>
            ) : bill?.cancelation_requested && bill?.subscription_status ? (
              <>
                <div className="col-8 col-md-6 col-lg-3 request-type">
                  Next Invoice:
                </div>
                <div className="col-4 col-md-6 col-lg-3 ">
                  No Further Invoice
                </div>
              </>
            ) : (
              ""
            )}

            {/* <>
              <div className="col-8 col-md-6 col-lg-3 mt-3 mt-lg-0 request-type">
                Plan:
              </div>
              <div className="col-4 col-md-6 col-lg-3  mt-3 mt-lg-0 ">
                {bill.plan.plan_title}
              </div>
            </> */}

            {
              <>
                {/* <div className="col-8 col-md-6 col-lg-3 request-type">
                  Subscription Status:
                </div>
                <div className="col-4 col-md-6 col-lg-3">
                  <Chip
                    color="primary"
                    size="small"
                    label={
                      bill?.is_subscription_successfull ? "Succeeful" : "Failed"
                    }
                  />
                </div> */}

                {/* <div className="col-8 col-md-6 col-lg-3  mt-3 request-type">
                  Plan:
                </div>
                <div className="col-4 col-md-6 col-lg-3  mt-3 ">
                  {bill?.plan?.plan_title ? bill?.plan?.plan_title : "N/A"}
                </div> */}
                {/* <div className="col-8 col-md-6 col-lg-3 mt-3  request-type">
                  Total Amount:
                </div>
                <div className="col-4 col-md-6 col-lg-3 mt-3">
                  {convertCurrencyToSign(bill.currency)}
                  {bill.total_amount}
                </div> */}
                {/* <div className="col-8 col-md-6 col-lg-3  mt-3 request-type">
                  Initial Amount:
                </div>
                <div className="col-4 col-md-6 col-lg-3  mt-3 ">
                  {convertCurrencyToSign(bill.currency)}
                  {bill.initial_amount}
                </div> */}
                <div className="col-8 col-md-6 col-lg-3  mt-3 request-type">
                  Installment Amount:
                </div>
                <div className="col-4 col-md-6 col-lg-3  mt-3 ">
                  {convertCurrencyToSign(bill.currency)}
                  {bill.installment_amount}
                </div>

                {/* <div className="col-8 col-md-6 col-lg-3  mt-3 request-type">
                  Installments Plan:
                </div>
                <div className="col-4 col-md-6 col-lg-3 mt-3 ">
                  {bill.month + " Months"}
                </div> */}
              </>
            }
            {handlePage && (
              <div className="col-12 text-center">
                <button
                  className="small-contained-button mt-3"
                  onClick={() => {
                    handlePage(
                      bill.payment_request_type,
                      bill.payment_request_slug
                    );
                  }}
                >
                  {btn_title}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default SubscriptionCard;
