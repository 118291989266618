import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NoteCard from './NoteCard';
export default function HabitDetail({ selectedObject }) {
    return (
        <div className='habit-detail px-4 pb-4'>
            <div className="detail-image">
                <img src={selectedObject.image} alt="" />
            </div>
            <div className="habit-name">
                <span>Habit Name</span>
                <p>{selectedObject.title}</p>
                <span>Type</span>
                <p>{selectedObject.habit_type}</p>
                <span>Target Date</span>
                <p>{selectedObject.target_date}</p>
                <span>Start Date</span>
                <p>{selectedObject.start_date}</p>
                <span>Frequency</span>
                <div className="detail-frequency">
                    <ul>
                        <li>M</li>
                        <li>T</li>
                        <li>W</li>
                        <li>T</li>
                        <li>F</li>
                        <li>S</li>
                        <li>S</li>
                    </ul>
                </div>
                <span>Reminder At</span>
                <p>{selectedObject.reminder}</p>
                <span>Click the arrow to switch weeks and days to complete the habit</span>
                <div className="change-weeks mt-2">
                    <div className="arrow-icon">
                        <div className="change-weeks-bg">
                            <ArrowBackIosIcon />
                        </div>
                    </div>
                    <div className="week-text">
                        <div className="change-weeks-bg">
                            This Week
                        </div>
                    </div>
                    <div className="arrow-icon">
                        <div className="change-weeks-bg">
                            <ArrowForwardIosIcon />
                        </div>
                    </div>
                </div>
                <div className="detail-frequency weeks-days">
                    <ul>
                        <li>
                            <p className='mb-0'>M</p>
                            <CheckCircleOutlineIcon />
                        </li>
                        <li>
                            <p className='mb-0'>T</p>
                        </li>
                        <li>
                            <p className='mb-0'>W</p>
                        </li>
                        <li>
                            <p className='mb-0'>T</p>
                        </li>
                        <li>
                            <p className='mb-0'>F</p>
                        </li>
                        <li>
                            <p className='mb-0'>S</p>
                        </li>
                        <li>
                            <p className='mb-0'>S</p>
                        </li>
                    </ul>
                </div>
                <div className="notes text-center">
                    <p>Notes</p>
                    <div className="view-all-notes">
                        <p className='text-end'><a href="">View All (1)</a></p>
                    </div>
                    <NoteCard />
                </div>
            </div>
        </div>
    )
}
