import React, { useState } from "react";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import { chanePassword } from "src/DAL/Login/Login";
import {
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import { useNavigate } from "react-router-dom";

export default function ChangePassword({
  openPasswordModal,
  setopenPasswordModal,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("other_devices");
  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

  const handleClose = () => {
    setopenPasswordModal(false);
    setInputs({
      password: "",
      repeatPassword: "",
    });
    setShowPassword1(false);
  };

  const [inputs, setInputs] = useState({
    password: "",
    repeatPassword: "",
  });

  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };

  const { STOP_SOCKET_FORCEFULLY } = useContentSetting();
  const { STOP_CHAT_FORCEFULLY, setChatList } = useChat();

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (inputs.password !== inputs.repeatPassword) {
      enqueueSnackbar("Password And Confirm_ Password not matched", {
        variant: "error",
      });
      setIsLoading(false);
    } else {
      const formData = new FormData();
      formData.append("password", inputs.password);
      formData.append("confirm_password", inputs.repeatPassword);
      formData.append("logout_from", selectedValue);
      const result = await chanePassword(formData);
      if (result.code === 200) {
        if (selectedValue == "all_devices") {
          localStorage.removeItem("user_data");
          localStorage.removeItem("token");
          STOP_SOCKET_FORCEFULLY();
          STOP_CHAT_FORCEFULLY();
          setChatList([]);
          navigate("/login");
        }
        setInputs({});
        setIsLoading(false);
        handleClose();
        enqueueSnackbar("Password Changed Successfully", {
          variant: "success",
        });
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  return (
    <>
      <Dialog
        open={openPasswordModal}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: get_root_value("--popup-background-color"),
            color: "green",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle>
          <form onSubmit={handlePasswordSubmit}>
            <div className="row password_input">
              <div className="col-12 text-end">
                <IconButton
                  onClick={() => handleClose()}
                  className="icon-button-modal"
                >
                  <Icon icon={closeFill} width={20} height={20} />
                </IconButton>
              </div>
              <Typography
                className="text-center mb-3"
                id="spring-modal-title"
                variant="h5"
                component="h2"
              >
                Change Password
              </Typography>
              <div className="col-12 mt-3">
                <FormControl sx={{ width: "25ch" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    New Password *
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword1 ? "text" : "password"}
                    className="text-muted"
                    required
                    name="password"
                    value={inputs.password}
                    onChange={handleChange}
                    size="small"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          edge="end"
                        >
                          {showPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password *"
                  />
                </FormControl>
              </div>
              <div className="col-12 mt-3">
                <FormControl sx={{ width: "25ch" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Confirm Password *
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword1 ? "text" : "password"}
                    className="text-muted"
                    name="repeatPassword"
                    value={inputs.repeatPassword}
                    onChange={handleChange}
                    required
                    size="small"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          edge="end"
                        >
                          {showPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password *"
                  />
                </FormControl>
              </div>
              <div className="col-12 mt-2 radio_button">
                <p>For security reasons, would you like to:</p>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue="other_devices"
                    onChange={handleChangeRadio}
                    value={selectedValue}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value="other_devices"
                      label="Logout from other devices"
                    />
                    <FormControlLabel
                      value="all_devices"
                      control={<Radio />}
                      label="Logout from all devices"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-12 mt-3 text-end">
                <button className="small-contained-button fw_14">
                  {isLoading ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
          </form>
        </DialogTitle>
      </Dialog>
    </>
  );
}
