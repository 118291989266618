import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
// material
import { alpha } from "@mui/material/styles";
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";

import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import {
  decode_markdown,
  get_date_with_user_time_zone,
  get_short_string,
} from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import MicIcon from "@mui/icons-material/Mic";
import CodeIcon from "@mui/icons-material/Code";
import { htmlDecode } from "src/utils/convertHtml";

// ----------------------------------------------------------------------

export default function ChatPopover() {
  const anchorRef = useRef(null);
  const {
    userInfo,
    unreadMessageCount,
    handleUnreadMessageCount,
    feedSetting,
  } = useContentSetting();
  const {
    chatList,
    handleSetSelectedChat,
    get_chat_list,
    get_all_unread_count,
    selectedChat,
    mark_as_read,
  } = useChat();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChatClick = (chat) => {
    console.log(chat, "--chat");

    handleSetSelectedChat(chat);
    // mark_as_read(chat._id);
    handleClose();
  };

  useEffect(() => {
    if (open) {
      get_chat_list();
      // handleUnreadMessageCount(0);
    }
  }, [open]);

  return (
    <>
      {/* {chatList.length > 0 && ( */}
      <IconButton
        ref={anchorRef}
        size="large"
        color={open ? "primary" : "default"}
        className="notification-icons-btn"
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <Badge badgeContent={unreadMessageCount} color="error">
          <Icon icon={"ant-design:message-filled"} width={20} height={20} />
        </Badge>
      </IconButton>
      {/* )} */}

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360, border: "none" }}
        className="notification-popover-paper"
      >
        <Box
          sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}
          className="notification-popover"
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Messages</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {unreadMessageCount} unread messages
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Scrollbar
          className="simplebar-content-wrapper"
          sx={{ height: { xs: 340, sm: "auto" } }}
        >
          <List disablePadding>
            {chatList.map((chat, i) => {
              var container = document.createElement("div");
              container.innerHTML = chat.last_message;
              var html_text = container.textContent || container.innerText;

              let level_title =
                chat.receiver_profile.community_level + "_title";
              let level_badge =
                chat.receiver_profile.community_level + "_badge";
              return (
                <div
                  onClick={() => handleChatClick(chat)}
                  className={
                    chat._id === selectedChat?._id
                      ? "chat-list-user selected-chat d-flex p-2"
                      : "chat-list-user hover-effect d-flex p-2"
                  }
                  key={i}
                >
                  <div>
                    <div className="user-profile">
                      {/* showing user level dynamically*/}
                      <div className="user-level">
                        <Tooltip
                          title={
                            feedSetting[level_title]
                              ? feedSetting[level_title]
                              : "Elite"
                          }
                        >
                          <img
                            src={
                              feedSetting[level_badge]
                                ? s3baseUrl + feedSetting[level_badge]
                                : ""
                            }
                          />
                        </Tooltip>
                      </div>
                      <Avatar
                        src={s3baseUrl + chat.receiver_profile.profile_image}
                        style={{ height: 50, width: 50 }}
                      ></Avatar>
                      <div
                        className={
                          chat.receiver_profile.is_online ? "online" : "offline"
                        }
                      ></div>
                    </div>
                  </div>

                  <div className="chat-profile-data">
                    <div className="chat-profile-name ps-2 text-capitalize chat-profile-name-padding">
                      {chat.receiver_profile.first_name +
                        " " +
                        chat.receiver_profile.last_name}
                    </div>
                    <div className="chat-last-message ps-2">
                      {chat?.message_type == "image" ? (
                        <>
                          <PhotoCameraIcon fontSize="10" /> Photo
                        </>
                      ) : chat?.message_type == "video" ? (
                        <>
                          <PlayCircleIcon fontSize="10" /> Video
                        </>
                      ) : chat?.message_type == "html" ? (
                        <>
                          <span className="new_message_text">
                            a new message received
                          </span>
                        </>
                      ) : chat?.message_type == "audio" ? (
                        <>
                          <MicIcon fontSize="18" /> Voice Message
                        </>
                      ) : (
                        chat.last_message &&
                        get_short_string(
                          htmlDecode(decode_markdown(chat.last_message))
                        )
                      )}
                    </div>
                  </div>
                  <div className="chat-last-time text-end ps-2">
                    <Tooltip
                      title={get_date_with_user_time_zone(
                        chat.last_message_date_time,
                        "DD MMM YYYY [at] hh:mm A",

                        userInfo.time_zone,
                        userInfo.adminTimeZone
                      )}
                      arrow
                    >
                      <span className="">
                        {get_date_with_user_time_zone(
                          chat.last_message_date_time,
                          "DD-MM-YYYY hh:mm A",
                          userInfo.time_zone,
                          userInfo.adminTimeZone
                        )}
                      </span>
                    </Tooltip>
                  </div>
                  {chat.my_profile.unread_message_count > 0 && (
                    <div className="chat-unread-count">
                      {chat.my_profile.unread_message_count}
                    </div>
                  )}
                </div>
              );
            })}
          </List>
        </Scrollbar>

        <Divider />

        <Box className="notification-popover" sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            onClick={() => setOpen(false)}
            component={RouterLink}
            to="/chat"
          >
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
