import React, { useEffect, useState } from "react";
import { s3baseUrl } from "src/config/config";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";

export default function RoadMapItemNew({
  reward_object,
  index,
  max_coins_value,
  userInfo,
  datalength,
}) {
  const [itemWith, setItemWith] = useState(0);

  let iconToShow =
    userInfo.coins_count > max_coins_value // if user's coins increases from every limit
      ? s3baseUrl + reward_object.images.thumbnail_1
      : reward_object.coins < max_coins_value
      ? s3baseUrl + reward_object.images.thumbnail_1
      : s3baseUrl + reward_object.without_border_icon.thumbnail_1;

  let lower_index_object = datalength[index - 1];
  let coins_count_bw = 0;
  let coins_count_bw_user = 0;

  if (lower_index_object) {
    coins_count_bw = reward_object.coins - lower_index_object.coins;
    coins_count_bw_user = userInfo.coins_count - lower_index_object.coins;
  } else {
    // if selected value lies in first index
    coins_count_bw = reward_object.coins;
    coins_count_bw_user = userInfo.coins_count;
  }

  let selected_percentage = (coins_count_bw_user / coins_count_bw) * 100;
  let unselected_percentage = Math.abs(selected_percentage - 100);

  // if user's coins increases from every limit
  if (userInfo.coins_count > max_coins_value) {
    selected_percentage = 100;
    unselected_percentage = 0;
  }

  let title_length = reward_object?.icon_title.length;
  let width = 0;
  let road_map_element = document.querySelector(".road-map-scroll");
  if (road_map_element) {
    width = road_map_element.offsetWidth;
  }
  if (datalength.length == 2) {
    width = width - 154;
  } else if (datalength.length == 3) {
    if (width > 575) {
      width = width / 2 - 100;
    } else {
      width = width - 154;
    }
  } else if (datalength.length == 4) {
    if (width > 575) {
      width = width / 3 - 54;
    } else {
      width = width - 154;
    }
  } else if (datalength.length == 5 || datalength.length == 6) {
    if (width > 991) {
      width = width / 4 - 54;
    } else if (width > 575) {
      width = width / 3 - 54;
    } else {
      width = width - 154;
    }
  }

  useEffect(() => {
    setItemWith(width);
  }, [width]);

  return (
    <>
      <div className="road-map-selected-image-new">
        <img src={iconToShow} alt="" className="img-fluid" />
        <div className="road-map-icon-new">
          <div className="title">{reward_object.icon_title}</div>
          {index == 2 && (
            <>
              <div
                className="coins-slider m-auto my-1"
                style={{
                  width: "50%",
                }}
              >
                <div
                  className="coins-slider-outer"
                  style={{
                    height: "6px",
                  }}
                >
                  <div
                    className="coins-slider-inner"
                    style={{
                      width: "43%",
                    }}
                  ></div>
                </div>
              </div>
              <div className="more-coins road-map-icon">
                <span>10k</span> coins required
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
