import { IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { htmlDecode } from "src/utils/convertHtml";

export default function ThankYou() {
  const navigate = useNavigate();
  const { shopContentSettings } = useContentSetting();

  return (
    <>
      <section className="art-thanks">
        <div className="col-12 d-flex mb-3">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(`/shop`)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="container">
          <div className="row justify-content-center mx-lg-5">
            <div className="col-lg-11 mt-5">
              <div className="art-thanks-div text-center pb-5">
                <div
                  dangerouslySetInnerHTML={{
                    __html: shopContentSettings.shop_thaks_page_content,
                  }}
                ></div>
                <div className="border-line-thanks" />
                <div className="mt-5">
                  <a
                    onClick={() => navigate("/shop")}
                    className="thank-button me-3"
                    style={{ cursor: "pointer" }}
                  >
                    {htmlDecode(shopContentSettings.thanks_page_button_text)}
                  </a>
                  {shopContentSettings.view_history_button_text && (
                    <a
                      onClick={() => navigate("/order-history")}
                      className="thank-button"
                      style={{ cursor: "pointer" }}
                    >
                      {htmlDecode(shopContentSettings.view_history_button_text)}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
