import React, { useEffect, useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TimeZones, get_address, handleGetCountry } from "src/utils/constants";
import { EditProfileApi } from "src/DAL/Profile/Profile";
import { Autocomplete, Checkbox, FormControlLabel } from "@mui/material";
import countryList from "react-select-country-list";
import { get_root_value } from "src/utils/domUtils";
import { s3baseUrl } from "src/config/config";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import PhoneInput from "react-phone-input-2";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
const Input = styled("input")({
  display: "none",
});
TimeZones.sort();

function EditProfile() {
  const { handleUserInfo, userInfo } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState("");
  const [open, setOpen] = useState(false);
  const [phoneData, setPhoneData] = useState();
  const [inputValue, setInputValue] = useState("");
  const [timeZoneValue, setTimeZoneValue] = useState("Europe/Dublin");
  const [memberData, setMemberData] = useState({
    first_name: "",
    last_name: "",
    street: "",
    street2: "",
    city: "",
    zip_code: "",
    vat_number: "",
    state: "",
    email: "",
    country: {
      value: "IE",
      label: "Ireland",
    },
    facebook_link: "",
    website_link: "",
    instagram_link: "",
    linkedin_link: "",
    youtube_link: "",
    contact_number: "",
    time_zone: "",
    nineteen_day_plan_currency: "",
    dob: "",
    is_postal_address_same: true,
    biography: "",
  });
  const [postalData, setPostalData] = useState({
    street: "",
    street2: "",
    city: "",
    zip_code: "",
    state: "",
    country: {
      value: "IE",
      label: "Ireland",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [image, setImage] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const handleChangeDate = (event) => {
    setMemberData((prevState) => {
      return {
        ...prevState,
        dob: event.$d,
      };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData();
    var dateString = "";
    if (memberData.dob) {
      dateString = moment(memberData.dob).format("YYYY-MM-DD");
    }
    formData.append("first_name", memberData.first_name);
    formData.append("email", memberData.email);
    formData.append("last_name", memberData.last_name);
    formData.append("street", memberData.street);
    formData.append("street2", memberData.street2);
    formData.append("city", memberData.city);
    formData.append("zip_code", memberData.zip_code);
    formData.append("vat_number", memberData.vat_number);
    formData.append("state", memberData.state);
    formData.append(
      "country",
      memberData.country ? memberData.country.value : ""
    );
    formData.append("time_zone", timeZoneValue);
    formData.append("facebook_link", memberData.facebook_link);
    formData.append("website_link", memberData.website_link);
    formData.append("instagram_link", memberData.instagram_link);
    formData.append("linkedin_link", memberData.linkedin_link);
    formData.append("youtube_link", memberData.youtube_link);
    formData.append(
      "member_biography",
      memberData.member_biography ? memberData.member_biography : ""
    );
    formData.append(
      "is_postal_address_same",
      memberData.is_postal_address_same
    );
    formData.append("contact_number", memberData.contact_number);
    formData.append(
      "postal_addess_info",
      memberData.is_postal_address_same === false
        ? JSON.stringify({
            ...postalData,
            country: postalData.country.value,
          })
        : JSON.stringify({})
    );
    formData.append(
      "nineteen_day_plan_currency",
      memberData.nineteen_day_plan_currency
    );
    formData.append("dob", dateString);
    if (image) {
      formData.append("profile_image", image);
    }
    const result = await EditProfileApi(formData);
    if (result.code === 200) {
      handleUserInfo(result.existingMember);
      localStorage.setItem(`user_data`, JSON.stringify(result.existingMember));
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsSubmitting(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setMemberData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeCountry = (value) => {
    setMemberData((values) => ({ ...values, country: value }));
  };
  const handleChangePostalCountry = (value) => {
    setPostalData((values) => ({ ...values, country: value }));
  };

  const handleChangePhone = (value, selected) => {
    let find_country = options.find((c) => c.label == selected.name);
    let selected_values = {
      contact_number: value,
    };
    if (!Boolean(get_address(userInfo.country))) {
      selected_values.country = find_country;
    }
    setMemberData((values) => ({ ...values, ...selected_values }));
  };

  const handleChangePostal = (event) => {
    const { name, value } = event.target;
    setPostalData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeCheckbox = (event) => {
    let is_checked = event.target.checked;
    setMemberData((old) => ({ ...old, is_postal_address_same: is_checked }));
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  let get_country = (data) => {
    let find_country = {};
    if (userInfo.country) {
      find_country = options.find((c) => c.value == data.country);
    } else {
      find_country = options.find((c) => c.value == handleGetCountry());
    }
    return find_country;
  };

  let address_obj = {
    street: get_address(userInfo.street),
    street2: get_address(userInfo.street2),
    city: get_address(userInfo.city),
    state: get_address(userInfo.state),
    zip_code: get_address(userInfo.zip_code),
    vat_number: get_address(userInfo.vat_number),
    member_biography: get_address(userInfo.member_biography),
    country: get_country(userInfo),
  };

  const handleFormatData = () => {
    setMemberData({ ...userInfo, ...address_obj });
    setPhoneData(userInfo.contact_number);
    setTimeZoneValue(userInfo.time_zone);
    setPostalData({
      ...userInfo.postal_addess_info,
      country: get_country(userInfo.postal_addess_info),
    });
  };

  useEffect(() => {
    handleFormatData();
  }, []);

  return (
    <div className="container ">
      <h2 className="edit-profile">Edit Profile</h2>
      <form onSubmit={handleUpdate}>
        <div className="mt-4 mb-4 d-flex justify-content-center">
          <div className="cards-edit p-4">
            <div className=" image d-flex flex-column justify-content-center align-items-center">
              <div className="edit-profile-icon">
                <img
                  src={
                    previews ? previews : s3baseUrl + memberData.profile_image
                  }
                  height="100"
                  width="100"
                />
                <label htmlFor="icon-button-file">
                  <Input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    name="profile_image"
                    onChange={(e) => handleUpload(e)}
                  />
                  <IconButton
                    className="edit-profile-icon-position"
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCamera />
                  </IconButton>
                </label>
              </div>
              <div className="row w-100 mt-3">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      name="first_name"
                      value={memberData.first_name}
                      onChange={(e) => handleChange(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={memberData.last_name}
                    name="last_name"
                    onChange={(e) => handleChange(e)}
                    required={true}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={memberData.email}
                    name="email"
                    onChange={(e) => handleChange(e)}
                    required={true}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 table-phone-input mt-4">
                  <PhoneInput
                    country={"gb"}
                    inputClass="react-phone-input"
                    dropdownClass="flag-input"
                    inputProps={{
                      name: "contact_number",
                      autoComplete: "off",
                    }}
                    value={phoneData}
                    enableSearch
                    disableSearchIcon
                    countryCodeEditable={false}
                    buttonStyle={{
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    onChange={(value, data) => {
                      handleChangePhone(value, data);
                    }}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mui-date-picker-box mt-4">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of birth"
                      name="dob"
                      className="mui-date-picker"
                      value={memberData.dob}
                      format="DD-MM-YYYY"
                      mask="__/__/____"
                      inputFormat="DD-MM-YYYY"
                      onChange={(e) => handleChangeDate(e)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          size="small"
                          {...params}
                          sx={{
                            input: {
                              color: get_root_value("--input-text-color"),
                            },
                          }}
                          variant="outlined"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4 select-box">
                    <MUIAutocomplete
                      inputLabel="Country"
                      selectedOption={memberData.country}
                      setSelectedOption={handleChangeCountry}
                      optionsList={options}
                      autoComplete="new-password"
                      name="label"
                      size="small"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Town or City"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.city}
                      name="city"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="State"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.state}
                      name="state"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Street Address 1"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.street}
                      name="street"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Street Address 2"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.street2}
                      name="street2"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Area Code"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.zip_code}
                      name="zip_code"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Vat Number"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.vat_number}
                      name="vat_number"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_postal_address_same"
                        checked={memberData.is_postal_address_same}
                        onChange={(e) => handleChangeCheckbox(e)}
                      />
                    }
                    label="Default Postal Address"
                  />
                </div>
                {memberData.is_postal_address_same === false && (
                  <>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <MUIAutocomplete
                        inputLabel="Country"
                        selectedOption={postalData.country}
                        setSelectedOption={handleChangePostalCountry}
                        optionsList={options}
                        autoComplete="new-password"
                        name="label"
                        size="small"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="mt-2">
                        <TextField
                          id="outlined-basic"
                          label="Town or City"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={postalData?.city}
                          name="city"
                          onChange={(e) => handleChangePostal(e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="mt-4">
                        <TextField
                          id="outlined-basic"
                          label="State"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={postalData?.state}
                          name="state"
                          onChange={(e) => handleChangePostal(e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Street Address 1"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={postalData?.street}
                          name="street"
                          onChange={(e) => handleChangePostal(e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Street Address 2"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={postalData?.street2}
                          name="street2"
                          onChange={(e) => handleChangePostal(e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Area Code"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={postalData?.zip_code}
                          name="zip_code"
                          onChange={(e) => handleChangePostal(e)}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4 time-zone-box">
                  <Autocomplete
                    value={timeZoneValue}
                    onChange={(event, newValue) => {
                      setTimeZoneValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={TimeZones}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Time Zone *" />
                    )}
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <FormControl sx={{ minWidth: 120 }} fullWidth>
                      <InputLabel id="demo-controlled-open-select-label">
                        Currency for 90 Day Tracker
                      </InputLabel>
                      <Select
                        className="svg-color"
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={memberData.nineteen_day_plan_currency}
                        name="nineteen_day_plan_currency"
                        label="Currency for 90 Day Tracker"
                        onChange={(e) => handleChange(e)}
                        size="small"
                        sx={{
                          color: get_root_value("--input-text-color"),
                        }}
                      >
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        <MenuItem value="eur">Euro</MenuItem>
                        <MenuItem value="gbp">UK Pounds</MenuItem>
                        <MenuItem value="usd">Dollar</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="mt-4 textarea-block">
                    <TextField
                      id="outlined-basic"
                      label="Biography"
                      variant="outlined"
                      rows={4}
                      multiline
                      fullWidth
                      size="small"
                      value={memberData.member_biography}
                      name="member_biography"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="mt-4">
                    <h3>Social Links</h3>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Facebook link"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.facebook_link}
                      name="facebook_link"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Linkedin Link"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.linkedin_link}
                      name="linkedin_link"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Website Link"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.website_link}
                      name="website_link"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Instagram Link"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.instagram_link}
                      name="instagram_link"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Youtube Link"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={memberData.youtube_link}
                      name="youtube_link"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>

                {/* <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="mt-3">
                <span className="upload-button mt-3">
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="icon-button-file"
                    style={{ display: "none" }}
                    onChange={handleUpload}
                  />
                  <label htmlFor="icon-button-file" className="w-100">
                    <CloudUploadIcon />
                  </label>
                </span>
              </div>
            </div> */}
                {/* <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="mt-3">
                {previews &&
                  previews.map((file, index) => (
                    <div className="col-3 mt-3" key={index}>
                      <div className="preview">
                        <span onClick={() => handleRemove(index)}>x</span>
                        <img src={file} />
                      </div>
                    </div>
                  ))}
              </div>
            </div> */}
              </div>

              <div className="mt-2 ms-auto">
                <button className="small-contained-button mt-4 ">
                  {isSubmitting ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditProfile;
