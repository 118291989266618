import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function LeaderBoardPopup({
  description,
  setShowInfoBox,
  title,
}) {
  return (
    <div className="info-card">
      {/* <h2 className="mb-2">{title ? title : ""}</h2> */}
      <div
        className="cross-sigh"
        onClick={() => {
          setShowInfoBox(false);
        }}
      >
        <span>
          <CloseIcon />
        </span>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}
