import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Chip, Tooltip } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React from "react";
import { useEffect } from "react";
import { dd_date_format } from "src/utils/constants";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { get_sales_listing } from "src/DAL/Dashboard/Dashboard";
import { get_order_listing } from "src/DAL/Dashboard/Dashboard";

import CustomMUITable from "src/components/CustomMUITable/CustomMUITable";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import OrderHistorysDetail from "src/pages/DDShop/OrderHistorysDetail";
import { convertCurrencyToSign } from "src/utils/constants";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function OrderHistory() {
  const [totalSales, setTotalSales] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [orderObject, setorderObject] = useState({});
  const [Open, setOpen] = useState(false);
  const handledetail = (obj) => {
    setOpen(true);
    setorderObject(obj);
  };
  const get_sales_listings = async () => {
    const result = await get_order_listing();
    if (result.code === 200) {
      setTotalSales(result.order_list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handle_privacy_click = (path) => {
    window.open(`${path}`, "_blank");
  };

  const handleOrderDetail = (item) => {
    navigate(`/shop/shopping-checkout/${item._id}`);
  };

  let TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },

    {
      id: "mintsoft_order_id",
      label: "Order Id",
      alignRight: false,
      className: "text-capitalize",
      renderData: (row) => {
        return (
          <span style={{ cursor: "pointer" }} onClick={() => handledetail(row)}>
            {row.mintsoft_order_id ? row.mintsoft_order_id : "N/A"}
          </span>
        );
      },
    },
    {
      id: "total",
      label: "Total Amount",
      alignRight: false,
      className: "text-capitalize",
      renderData: (row) => {
        return (
          <>
            {!!row?.other_info?.converted_total ? (
              <span>
                {row?.other_info?.converted_currency
                  ? convertCurrencyToSign(
                      row?.other_info?.converted_currency.toLowerCase()
                    )
                  : ""}
                {Number(row?.other_info?.converted_total)?.toFixed(2)}
              </span>
            ) : (
              <span>{"£" + Number(row.total).toFixed(2)}</span>
            )}
          </>
        );
      },
    },
    {
      id: "createdAt",
      label: "Date",
      alignRight: false,
      className: "text-capitalize",
      renderData: (row) => {
        return <span>{dd_date_format(row.createdAt)}</span>;
      },
    },
    {
      id: "status",
      label: "Status",
      alignRight: false,
      renderData: (row) => {
        let chipColor;
        let chipTextcolor;
        switch (row.order_status) {
          case "pending":
            chipColor = "#ff9800";
            chipTextcolor = "#fff";
            break;
          case "processing":
            chipColor = "#c19a6b";
            chipTextcolor = "#fff";
            break;
          case "dispatched":
            chipColor = "#0288d1";
            chipTextcolor = "#fff";
            break;
          case "completed":
            chipColor = "#4caf50";
            chipTextcolor = "#fff";
            break;
          case "delivered":
            chipColor = "#4caf50";
            chipTextcolor = "#fff";
            break;
          case "cancelled":
            chipColor = "rgb(211, 47, 47)";
            chipTextcolor = "#fff";

            break;
          default:
            chipColor = "#ff9800";
            chipTextcolor = "#fff";

            break;
        }
        return (
          <div className="d-flex">
            <Chip
              label={
                row.order_status == "initiated" ? "Pending" : row.order_status
              }
              style={{
                backgroundColor: chipColor,
                color: chipTextcolor,
                textTransform: "capitalize",
              }}
            ></Chip>
          </div>
        );
      },
    },
    {
      id: "Tracking Id",
      label: "Track Package",
      alignRight: false,
      renderData: (row) => {
        return (
          <>
            {row.order_status == "initiated" ? (
              <button
                className="pay-initiated-now ms-1 mt-1"
                onClick={() => handleOrderDetail(row)}
              >
                Pay Now
              </button>
            ) : row.TrackingURL ? (
              <Tooltip title="Click here to track your order">
                <span
                  onClick={() => handle_privacy_click(row.TrackingURL)}
                  style={{
                    cursor: "pointer",
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#f6bd4b",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  Click To Track
                </span>
              </Tooltip>
            ) : (
              <Tooltip
                title={`${
                  row.order_status == "cancelled"
                    ? `Your order has been ${row.order_status}`
                    : `Please wait, your order is currently in  ${row.order_status}`
                }`}
              >
                <span
                  style={{
                    cursor: "pointer",
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#6c757d",
                    fontSize: "14px",
                    cursor: "not-allowed",
                  }}
                >
                  Click To Track
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];
  useEffect(() => {
    get_sales_listings();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="mb-3 mt-4">
            <h2>Order History</h2>
          </div>
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={totalSales}
            className="card-with-background"
            hide_search={true}
          />
        </div>
      </div>

      <WhatsappPopUpModel
        open={Open}
        setOpen={setOpen}
        title={"Order Detail"}
        show_date_and_income={true}
        componentToPassDown={<OrderHistorysDetail orderObject={orderObject} />}
      />
    </>
  );
}

export default OrderHistory;
