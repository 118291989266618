import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
const useStyles = makeStyles(() => ({
  loading: {
    // marginLeft: "50%",
    // marginTop: "20%",
    width: "20px !important",
    height: "auto !important",
  },
}));
function CustomConfirmationShop({
  open,
  setOpen,
  handleAgree,
  title,
  loadingPopUp,
  setloadingPopUp,
}) {
  const classes = useStyles();
  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: get_root_value("--popup-background-color"),
            color: "green",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle>
          {title ? title : "Are you sure you want to take this action?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>

          <Button onClick={handleAgree}>
            {loadingPopUp ? (
              <CircularProgress className={classes.loading} color="primary" />
            ) : (
              "Agree"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomConfirmationShop;
