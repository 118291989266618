import React, { useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function QuestionThankYou() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userInfo } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [moduleTitle, setModuleTitle] = useState("");
  const [questionConfigration, setQuestionConfigration] = useState([]);

  const get_questions_list = async () => {
    if (state) {
      setQuestionConfigration(state.question_configration);
      setModuleTitle(state.module_title);
      setIsLoading(false);
    } else {
      navigate(`/mission-control`);
    }
  };

  useEffect(() => {
    get_questions_list();
  }, []);

  const replacedString = (string) => {
    if (string) {
      return string.replace(/{Name}/g, userInfo?.first_name);
    } else {
      return "";
    }
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {moduleTitle && (
            <span className="sale-page-title">{moduleTitle}</span>
          )}
        </div>
      </div>
      <div className="row justify-content-center pb-5">
        <div className="col-12 mt-2">
          {questionConfigration &&
            questionConfigration.thank_you_page_description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: replacedString(
                    questionConfigration?.thank_you_page_description
                  ),
                }}
                className="text-center"
              ></div>
            )}
        </div>
      </div>
    </div>
  );
}
