import { Box, List } from "@mui/material";
import NavSectionItem from "./NavSectionItem";

export default function NavSection({ navConfig }) {
  return (
    <Box>
      <List disablePadding>
        {navConfig.map((item, i) => {
          return <NavSectionItem data={item} key={i} />;
        })}
      </List>
    </Box>
  );
}
