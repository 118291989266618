import React from "react";
import Scrollbar from "src/components/Scrollbar";
import { Link as RouterLink } from "react-router-dom";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { Box } from "@mui/material";
import NavSection from "src/components/NavSection";
import Logo from "src/components/Logo";

export default function RenderNavbar({ navConfig }) {
  const { userInfo, navbarList } = useContentSetting();
  let find_community = navbarList.find((item) => item.value === "community");
  const get_logo_path =
    userInfo.is_membership_purchase || find_community ? "thesource" : "welcome";

  return (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box
          component={RouterLink}
          to={get_logo_path}
          sx={{
            display: "inline-flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Logo />
        </Box>
      </Box>

      <NavSection navConfig={navConfig} />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );
}
