import { Avatar, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { s3baseUrl } from "src/config/config";

export default function MentionSuggestions({
  users,
  handleSelect,
  position,
  suggestionsRef,
}) {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (users.length > 0) {
      setSelected(0);
    }

    const handleKeyDown = (event) => {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setSelected((selected) => (selected + 1) % users.length);
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setSelected((selected) => (selected - 1 + users.length) % users.length);
      } else if (event.key === "Enter") {
        event.preventDefault();

        let selected_index = 0;
        setSelected((selected) => {
          selected_index = selected;
          return selected;
        });
        handleSelect(users[selected_index]);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [users]);

  return (
    <div
      className={`show-suggestions-names ${
        users.length > 0 ? "add-border" : ""
      }`}
      style={{ top: position.top + 3, left: position.left }}
      ref={suggestionsRef}
      tabIndex={-1}
    >
      {users.map((user, index) => {
        return (
          <MenuItem
            className={`d-flex show-suggestions-items ${
              selected === index ? "selected" : ""
            }`}
            key={index}
            onClick={() => handleSelect(user)}
          >
            <Avatar
              src={user.profile_image ? s3baseUrl + user.profile_image : ""}
              alt={user.first_name}
              sx={{ width: 30, height: 30 }}
            />
            <span className="ms-2">{`${user.user_name}`}</span>
          </MenuItem>
        );
      })}
    </div>
  );
}
