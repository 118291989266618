import { useState } from "react";
import MissionFeedDetailNew from "./MissionFeedDetailNew";
import LeaderBoard from "../MissionConTrollNew/LeaderBoard";
import ApexChartDonat from "./ApexChart";
import { fShortenNumber, get_kmb_number } from "src/utils/formatNumber";
import { CircularProgress, Tooltip } from "@mui/material";
import AnalyticsChartHealth from "./AnalyticsChartHealth";
import AnalyticsChartrecreational from "./AnalyticsChartrecreational";
import AnalyticsChart from "./AnalyticsChart";
import ActionBarComponentProps from "../DynamiteDiary/calender";
import Missioncalender from "./Missioncalender";
import { cointlogo } from "src/assets";
let actionDataObject = {
  _id: "66bdec5f8253c036e11bc792",
  mission_info: {
    title: "Principal Mobility Technician",
    _id: "66b0629a1fd715b289d2474a",
  },
  title: "day 1",
  main_heading: "day 1",
  reward_coins: 1000,
  coins_to_unlock: 122,
  number_of_start_days: 1,
  number_of_end_days: 2,
  short_description: "day 1day 1day 1",
  detailed_description: "",
  video_url: "",
  audio_url: "",
  status: true,
  order: 1,
  is_locked: false,
  image: {
    thumbnail_1: "mission_lavel/8fbab949-e37d-4130-8869-04dc8b9485dd.jpg",
    thumbnail_2: "mission_lavel/b9e496d4-f2c9-44a3-ac4e-de209e9a9e2d.jpg",
  },
  action_by: "admin_user",
  action_id: "61fb68261b921d356406d82b",
  allowed_actions: [
    {
      _id: "66bdecd98253c036e11bc8e6",
      action_statement: "Mark the checkbox to complete Your Goal Statement?",
      is_required: true,
      is_checked: false,
    },
    {
      _id: "66bdecd98253c036e11bc8e7",
      action_statement:
        "Watch the video & Mark the checkbox to complete your Meditation? ",
      is_required: true,
      is_checked: false,
    },
    {
      _id: "66bdecd98253c036e11bc8ssse7",
      action_statement:
        "👈 Tick If You have listened Today👇👇👇 We are ONE  - Bliss Lies Below  ",
      is_required: true,
      is_checked: false,
    },
  ],
  updatedAt: "2024-08-16T05:46:16.321Z",
  createdAt: "2024-08-15T11:54:07.191Z",
  __v: 1,
  content_settings: {
    unlock_button_text: "Unlock",
    unlock_cancel_button_text: "Cancel",
    unlock_detail_description: "<p>Unlock Detail Description</p>",
    schedule_completed_description: "",
    congratulation_description:
      '<h2 style="text-align: center;">Congratulation Description on completing actionData</h2>\n<p><img src="https://dd-feature-app-bucket.s3.amazonaws.com/upload/1a368f69-89b7-4c4c-9897-0c7213a6fc9d.gif" alt="" /></p>',
    action_statement_heading: "Your Today's Action Statements",
    complete_schedule_button_text: "Complete actionData",
  },
  schedule_status: "in_progress",
  completed_at: "",
  completed_allowed_actions: [],
  user_data: {
    coins_count: 183169900,
    community_level: "elite",
  },
  started_at: null,
};
let LeaderBoardArray = [
  {
    user_info: {
      _id: "65f134260413beef8807c01f",
      name: "coins Volkman",
      profile_image: "",
      email: "test43805@gmail.com",
    },
    coins: "99%",
    _id: "66012ac9fc43f9a67cec9aac",
  },
  {
    user_info: {
      _id: "65a66831caaa059d150ac891",
      name: "zohaib Testing",
      profile_image: "",
      email: "zohaibirshad862@gmail.com",
    },
    coins: "90%",
    _id: "66012ac9fc43f9a67cec9aad",
  },
  {
    user_info: {
      _id: "63abd24c9afdb872cbc118e2",
      name: "Ammar Yousaf",
      profile_image: "member/977aba38-94a4-4e69-b7ed-ff381a40fef6.jpg",
      email: "y.amm4r@gmail.com",
    },
    coins: "89%",
    _id: "66012ac9fc43f9a67cec9aae",
  },
  {
    user_info: {
      _id: "63c8df1227ad83d7fc8249b8",
      name: "Rida Fatima",
      profile_image: "member/5afea653-05dd-4032-a1e4-6c063277e8b3.jpg",
      email: "ridaf6137@gmail.com",
    },
    coins: "78%",
    _id: "66012ac9fc43f9a67cec9aaf",
  },
  {
    user_info: {
      _id: "64915e0f817399641e05b813",
      name: "Naiya Tariq",
      profile_image: "member/54103e83-9cf6-45d1-aee1-f1d6c9304681.jpg",
      email: "kainattariq.me@gmail.com",
    },
    coins: "69%",
    _id: "66012ac9fc43f9a67cec9ab0",
  },
  {
    user_info: {
      _id: "63abd24c9afdb872cbc118e2",
      name: "Ammar Yousaf",
      profile_image: "member/977aba38-94a4-4e69-b7ed-ff381a40fef6.jpg",
      email: "y.amm4r@gmail.com",
    },
    coins: "50%",
    _id: "66012ac9fc43f9a67cec9aaesadfeasf",
  },
];
const MainDashBoard = () => {
  const [actionData, setActionData] = useState(actionDataObject);
  const [leaderboardDatas, setLeaderboardData] = useState(LeaderBoardArray);
  return (
    <>
      <div className="row">
        <div className="col-lg-8 pe-0">
          {actionData.allowed_actions?.length > 0 && (
            <div className="col-12">
              <form className="allowed-actions-box">
                <h4 className=" actions_heading">
                  {actionData.content_settings?.action_statement_heading}
                </h4>
                {actionData.allowed_actions.map((item, index) => {
                  let { _id, action_statement, is_required, is_checked } = item;

                  let find_answer = null;
                  if (actionData.completed_allowed_actions?.length > 0) {
                    find_answer = actionData.completed_allowed_actions.find(
                      (i) => i._id == _id
                    );
                  }

                  let isDisabled = Boolean(
                    actionData.schedule_status === "locked" ||
                      (find_answer &&
                        actionData.schedule_status === "completed")
                  );

                  return (
                    <div
                      className={`mt-3 action-checkbox-box ${
                        isDisabled ? "disabled" : ""
                      }`}
                      onClick={() =>
                        handleChangeAction(_id, isDisabled, actionData)
                      }
                    >
                      <div
                        className={`action-checkbox ${
                          is_checked ? "checked" : ""
                        }`}
                      >
                        {is_checked && <i class="fa-solid fa-check"></i>}
                      </div>
                      <div className="checkbox-label">{action_statement}</div>
                      <span className="required-asterisk ms-1">
                        {is_required && "*"}
                      </span>
                    </div>
                  );
                })}
              </form>
            </div>
          )}
          <div className="row">
            <div className="col-lg-6 mt-2 pe-0">
              <div className="card d-flex streak-container">
                <div className="d-flex justify-content-around">
                  <div>
                    {/* <ApexChartDonat /> */}
                    <div
                      style={{ position: "relative", display: "inline-flex" }}
                    >
                      {/* Background (Gray) CircularProgress */}
                      <CircularProgress
                        variant="determinate"
                        value={100}
                        size={80}
                        style={{
                          color: "#f6bd4b50",
                        }}
                      />
                      {/* Foreground (Blue) CircularProgress */}
                      <CircularProgress
                        variant="determinate"
                        value={7}
                        size={80}
                        style={{
                          color: "#f6bd4b",
                          position: "absolute",
                          left: 0,
                        }}
                      />
                      <div className="dashboard-progress-text">
                        <div className="dashboard-progress-text-title">
                          {`${get_kmb_number(7)}`}%
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <p className=" mb-0">
                      <div className="d-flex">
                        <span style={{ fontSize: "22px" }}>🔥</span>
                        <div style={{ marginLeft: "8px" }}>
                          <h4 style={{ marginBottom: "0px", fontSize: "24px" }}>
                            7 Day{" "}
                          </h4>
                          <p
                            style={{
                              fontSize: "13px",
                              color: "#f6bd4b",
                              marginTop: "3px",
                            }}
                          >
                            Completed Streaks
                          </p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <span style={{ fontSize: "24px" }}>🏆</span>
                        <div style={{ marginLeft: "8px" }}>
                          <h4 style={{ marginBottom: "0px", fontSize: "22px" }}>
                            93 Day{" "}
                          </h4>
                          <p
                            style={{
                              fontSize: "13px",
                              color: "#f6bd4b",
                              marginBottom: "0px",
                              marginTop: "3px",
                            }}
                          >
                            Remaining Days
                          </p>
                        </div>
                      </div>
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-2 ps-2">
              {" "}
              <div
                className="mission-control-card p-3 w-100 h-100 pt-4"
                style={{ borderRadius: "4px" }}
              >
                <span>
                  <img src={cointlogo} style={{ width: "22%" }} />
                </span>
                <div className="d-flex justify-content-center total-coins">
                  <h4 style={{ marginBottom: "0px", fontSize: "24px" }}>
                    {fShortenNumber(10000)}
                  </h4>
                  {/* <h3></h3> */}
                </div>
                <div className="more-coins mt-2">
                  Get <span>10k</span> coins by completing mission
                </div>
                <div className="coins-slider mt-3  mb-2 getCoins">
                  <div className="coins-slider-outer">
                    <div
                      className="coins-slider-inner"
                      style={{
                        width: "48%",
                      }}
                    ></div>
                    <div style={{ marginTop: "4px" }}>20k</div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="col-lg-4 mt-3">
          {" "}
          <LeaderBoard
            title={"Streak Leader Board"}
            data={leaderboardDatas}
            type="mission"
          />
        </div>
      </div>{" "}
      <div className="row">
        <div className="col-lg-8 pe-0">
          <div className="row">
            <div className="col-lg-6 mt-2 pe-0">
              <div className="card  pt-3">
                <h4 class="ps-2"> Your Personal Growth </h4>
                <AnalyticsChart />
              </div>
            </div>
            <div className="col-lg-6 mt-2 ps-2">
              <div className="card  pt-3">
                <h4 class="ps-2"> your current health and fitness </h4>
                <AnalyticsChartHealth />
              </div>
            </div>
            <div className="col-lg-6 mt-2 pe-0">
              <div className="card  pt-3 ">
                <h4 class="ps-2"> current recreational life </h4>
                <AnalyticsChartrecreational />
              </div>{" "}
            </div>
            <div className="col-lg-6 mt-2 ps-2">
              <div className="card  pt-3 ">
                <h4 class="ps-2"> financial security & freedom </h4>
                <AnalyticsChartHealth />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mt-2">
          {" "}
          <Missioncalender
            // diaryDate={diaryDate}
            // setDiaryDate={setDiaryDate}
            // handleChangeDate={handleChangeDate}
            firstDiaryDate={"2023-03-23"}
          />
        </div>
      </div>
    </>
  );
};

export default MainDashBoard;
