// material
import { Checkbox, CircularProgress, FormControlLabel } from "@mui/material";
import { AppBugReports, AppWebsiteVisits } from "../components/_dashboard/app";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import DashboardNotificationCard from "src/components/_dashboard/app/DashboardNotificationCard";
import { warning_icon } from "src/assets";
import { get_dashboard_setting } from "src/DAL/Dashboard/Dashboard";
import SourceSession from "src/components/_dashboard/app/SourceSession";
import TotalCoinsCount from "src/components/_dashboard/app/TotalCoinsCount";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { projectMode, s3baseUrl } from "src/config/config";
// import RoadMapSlider from "./Community/components/RoadMapSlider/RoadMapSlider";
import DashboardProgramCard from "src/components/_dashboard/app/DashboardProgramCard";
import { get_date_with_user_time_zone } from "src/utils/constants";
import moment from "moment";
import LessonsList from "src/components/_dashboard/app/LessonsList";
import { ReactVideoPlayer } from "src/components";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import SalePages from "src/components/_dashboard/app/SalePages";
import DashboardAgreementCard from "src/components/_dashboard/app/DashboardAgreementCard";
import GraphicsLinks from "src/components/_dashboard/app/GraphicsLinks";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import RoadMapSlider from "src/components/_dashboard/RoadMapSlider";
import { useNavigate } from "react-router-dom";
import WebPageSetting from "./MissionControl/WebPageSetting";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [coinsCount, setCoinsCount] = useState([]);
  const [sourceSessions, setSourceSessions] = useState([]);
  const [lessonsList, setLessonsList] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState({});
  const [booking, setBooking] = useState({});
  const [clientDashboardSetting, setClientDashboardSetting] = useState({});
  const [mSSetting, setMSSetting] = useState({});
  const [notificationData, setNotificationData] = useState([]);
  const [remainingAgreements, setRemainingAgreements] = useState([]);
  const [programsData, setProgramsData] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [totalCoinsCount, setTotalCoinsCount] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const { userInfo, adminTimeZone, navbarList } = useContentSetting();
  // roadmap
  const [roadMap, setRoadMap] = useState([]);
  const [salePages, setSalePages] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [graphLabels, setGraphLabels] = useState([]);
  const [dailyDynamiteStartDate, setDailyDynamiteStartDate] = useState("");
  const [graphicsLinks, setGraphicsLinks] = useState([]);
  const [openHide, setOpenHide] = useState(false);
  const [mCVideoUrl, setMCVideoUrl] = useState(
    localStorage.getItem("mc_video_url")
  );

  const [thanksPageData, setThanksPageData] = useState({});
  const [showThanksPage, setShowThanksPage] = useState(false);

  const get_update_time_with_time_zone = (date) => {
    let new_date = get_date_with_user_time_zone(
      date,
      "YYYY-MM-DD HH:mm:ss",
      userInfo.time_zone,
      adminTimeZone
    );
    return Date.parse(new_date);
  };

  const Dashboard_Data = [
    {
      name: "Gratitude",
      days_count: 90,
      coins_title: "Total Coins",
      remaining_time: "12:07:14",
      coins_count: 500,
      is_completed: true,
    },
    {
      name: "Visioneering",
      days_count: 95,
      coins_title: "Total Coins",
      remaining_time: "07:07:14",
      coins_count: 1000,
      is_completed: true,
    },
    {
      name: "Script Writing",
      days_count: 90,
      coins_title: "Total Coins",
      remaining_time: "02:08:11",
      coins_count: 1200,
      is_completed: true,
    },
  ];

  const getDashboardSetting = async () => {
    // if does not have access of Mission Control move to Welcom Screen
    let find_mc = navbarList.find(
      (item) => item.value === "mission_control" && item.is_access !== false
    );
    if (!find_mc) {
      navigate("/welcome", { replace: true });
      return;
    }

    const result = await get_dashboard_setting();
    if (result.code === 200) {
      setMSSetting(result);
      setBooking(result.dashboard_data.bookings);
      setRoadMap(result.dashboard_data.bonus_road_map_list);
      setLessonsList(result.dashboard_data.lessons);
      setProgramList(result.dashboard_data.program_list);
      setThanksPageData(result.mission_control_setting);
      setShowThanksPage(result.is_show_plan_setting);
      setClientDashboardSetting(result.client_dashboard_setting);
      if (result?.dashboard_data?.program_list) {
        setSelectedLesson(result?.dashboard_data?.program_list[0]);
      }
      setTotalCommission(result.total_commission);
      setTotalLeads(result.total_leads_count);
      setSalePages(result.sale_pages);
      setDailyDynamiteStartDate(
        moment(result.first_dynamite_diary_date, "DD-MM-YYYY").format(
          "MM/DD/YYYY"
        )
      );
      result.list_dynamite_diary.forEach((value) => {
        let total = value.completed_intention + value.incomplete_intention;
        const percentage = total
          ? (value.completed_intention / total) * 100
          : 0;

        graphData.push(percentage?.toFixed(2));
        graphLabels.push(moment(value.date, "DD-MM-YYYY").format("MM/DD/YYYY"));
      });

      let coins_count = [
        {
          name: "Daily Dynamite",
          days_count: result.dashboard_data.dynamite_diary_count,
          coins_title: "Total Coins",
          remaining_time: result.dashboard_data.dynamite_diary_reminder_time,
          type: "growth_tools",
          coins_count: result.dashboard_data.daily_dynamite_coins_count,
          is_completed: result.dashboard_data.is_dynamite_diary_added,
          hours_reminder:
            result?.client_dashboard_setting?.hours_reminder_daily_dynamite,
        },
        {
          name: "Gratitude Daily",
          days_count: result.dashboard_data.dynamite_gratitude_count,
          coins_title: "Total Coins",
          remaining_time:
            result.dashboard_data.dynamite_gratitude_reminder_time,
          type: "growth_tools",
          coins_count: result.dashboard_data.gratitude_coins_count,
          is_completed: result.dashboard_data.is_dynamite_gratitude_added,
          hours_reminder:
            result?.client_dashboard_setting?.hours_reminder_daily_gratitude,
        },
        {
          name: "Meditation",
          days_count: result.dashboard_data.dynamite_meditation_count,
          coins_title: "Total Coins",
          remaining_time:
            result.dashboard_data.dynamite_meditation_reminder_time,
          type: "growth_tools",
          coins_count: result.dashboard_data.meditation_coins_count,
          is_completed: result.dashboard_data.is_dynamite_mediation_added,
          hours_reminder:
            result?.client_dashboard_setting?.hours_reminder_daily_meditation,
        },
      ];

      let Notification_Data = [];
      if (navbarList.growth_tools) {
        if (
          navbarList.dynamite_diary &&
          result.dashboard_data.is_dynamite_diary_added !== true &&
          result.client_dashboard_setting?.daily_dynamite_alert_description
        ) {
          Notification_Data.push({
            description:
              result.client_dashboard_setting?.daily_dynamite_alert_description,
            icon: result.client_dashboard_setting.daily_dynamite_alrt
              ? s3baseUrl + result.client_dashboard_setting.daily_dynamite_alrt
              : warning_icon,
          });
        }
        if (
          navbarList.gratitude_daily &&
          result.dashboard_data.is_dynamite_gratitude_added !== true &&
          result.client_dashboard_setting?.daily_gratitude_alert_description
        ) {
          Notification_Data.push({
            description:
              result.client_dashboard_setting
                ?.daily_gratitude_alert_description,
            icon: result.client_dashboard_setting.daily_gratitude_alrt
              ? s3baseUrl + result.client_dashboard_setting.daily_gratitude_alrt
              : warning_icon,
          });
        }
        if (
          navbarList.mediation &&
          result.dashboard_data?.is_dynamite_mediation_added !== true &&
          result.client_dashboard_setting?.meditation_alert_description
        ) {
          Notification_Data.push({
            description:
              result?.client_dashboard_setting?.meditation_alert_description,
            icon: result.client_dashboard_setting.daily_meditation_alrt
              ? s3baseUrl +
                result.client_dashboard_setting.daily_meditation_alrt
              : warning_icon,
          });
        }
      }

      let remaining_agreements = [];
      if (result.remaining_agreements) {
        result.remaining_agreements.map((plan) => {
          remaining_agreements.push({
            plan_data: plan?.plan,
            page_data: plan?.page,
            icon: result.client_dashboard_setting.plan_agreement_alrt
              ? s3baseUrl + result.client_dashboard_setting.plan_agreement_alrt
              : warning_icon,
          });
        });
      }

      if (result?.dashboard_data?.program_list_with_expiry?.length > 0) {
        let programs_array = [];
        result.dashboard_data.program_list_with_expiry.map((program) => {
          const deadline = get_update_time_with_time_zone(
            moment(program.program_expiry_date, "YYYY-DD-MM hh mm").format(
              "YYYY-MM-DD"
            ) + " 00:00:00"
          );

          const time = deadline
            ? deadline -
              get_update_time_with_time_zone(
                moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
              )
            : "";
          let days = Math.floor(time / (1000 * 60 * 60 * 24));
          if (days <= program.reminder_show_days) {
            program.warn = result.client_dashboard_setting.program_update_alert;
            programs_array.push(program);
          }
        });
        setProgramsData(programs_array);
      }
      setGraphicsLinks(result.graphics);
      setRemainingAgreements(remaining_agreements);
      setNotificationData(Notification_Data);
      setCoinsCount(coins_count);
      setTotalCoinsCount(result.dashboard_data.total_coins_count);
      setSourceSessions(result.dashboard_data.source_session);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboardSetting();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const handleAgreeHide = (value) => {
    setOpenHide(true);
  };

  const handleHideVideo = () => {
    localStorage.setItem("mc_video_url", mSSetting.dashboard_data.video_url);
    setMCVideoUrl(mSSetting.dashboard_data.video_url);
    setOpenHide(false);
  };

  const handleShowVideo = () => {
    localStorage.removeItem("mc_video_url");
    setMCVideoUrl("");
  };

  const is_show_video = () => {
    if (
      mSSetting.dashboard_data.video_url &&
      (!mCVideoUrl || mCVideoUrl !== mSSetting.dashboard_data.video_url)
    ) {
      return true;
    } else {
      return false;
    }
  };

  // if (showThanksPage) {
  //   return <WebPageSetting settingData={thanksPageData} />;
  // }

  return (
    <>
      <CustomConfirmation
        open={openHide}
        setOpen={setOpenHide}
        title={"Are you sure you want to hide video section?"}
        handleAgree={handleHideVideo}
      />
      {showThanksPage && <WebPageSetting settingData={thanksPageData} />}
      <div className="container main-div-img">
        <div className="row dashboard-background">
          <div
            className={`col-12 center-text mb-3 ${
              !is_show_video() && mSSetting.dashboard_data.video_url
                ? "col-lg-8"
                : ""
            }`}
          >
            <PageDescription
              parameter="mission_control_description"
              else_title="Hi, Welcome To Dashboard"
            />
          </div>
          {is_show_video() ? (
            <>
              <div
                className={`col-12 ${
                  mSSetting.dashboard_data.road_map_level_obj
                    ?.detail_description
                    ? "col-md-8"
                    : ""
                } mb-4`}
              >
                <ReactVideoPlayer
                  url={mSSetting.dashboard_data.road_map_level_obj?.video_url}
                />
                <FormControlLabel
                  control={
                    <Checkbox onClick={handleAgreeHide} checked={false} />
                  }
                  label="Don't Show me this video again"
                />
              </div>

              {mSSetting.dashboard_data.road_map_level_obj
                ?.detail_description && (
                <div
                  className={`col-12 road_map_detail_description ${
                    is_show_video() ? "col-lg-4" : ""
                  }`}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        mSSetting.dashboard_data.road_map_level_obj
                          .detail_description,
                    }}
                    className="p-3"
                  ></div>
                </div>
              )}
            </>
          ) : mSSetting.dashboard_data.road_map_level_obj?.video_url ? (
            <div className="col-12 col-lg-4 text-end mb-4">
              <button
                className="small-contained-button float-end mt-1"
                onClick={handleShowVideo}
              >
                Show Video Section
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        {roadMap.length > 1 && (
          <div className="mt-4">
            <RoadMapSlider data={roadMap} />
          </div>
        )}
        <div className="row">
          {programsData?.map((item) => {
            return (
              <div className="col-12 mb-4">
                <DashboardProgramCard data={item} />
              </div>
            );
          })}
          {/* {clientDashboardSetting.booking_alert_description && booking && (
          <div className="col-12 col-md-6 mb-4">
            <DashboardBookingCard
              bookingAlertDescription={clientDashboardSetting.booking_alert_description}
              booking={booking}
            />
          </div>
        )} */}
        </div>
        {programList?.length > 0 && (
          <div className="row">
            <div className="col-12 col-md-8 mb-4">
              {selectedLesson?.landing_lesson_video_url ? (
                <ReactVideoPlayer
                  url={selectedLesson.landing_lesson_video_url}
                />
              ) : (
                <img
                  src={s3baseUrl + selectedLesson.lesson_images.thumbnail_1}
                  className="img-fluid"
                />
              )}
            </div>
            <div className="col-12 col-md-4 profile-cards mb-4">
              <LessonsList
                lessonsList={lessonsList}
                programList={programList}
                setSelectedLesson={setSelectedLesson}
                lessonLockText={clientDashboardSetting.lesson_lock_text}
              />
            </div>
          </div>
        )}
        <div className="row">
          {notificationData.map((item) => {
            return (
              <div className="col-12 col-md-6 mb-4">
                <DashboardNotificationCard data={item} />
              </div>
            );
          })}
          {remainingAgreements.map((item) => {
            return (
              <div className="col-12 col-md-6 mb-4">
                <DashboardAgreementCard
                  data={item}
                  setIsLoading={setIsLoading}
                />
              </div>
            );
          })}
        </div>
        <div className="row">
          <div
            className={`${
              mSSetting.membership_status ? "col-12 col-lg-8" : "col-12"
            }`}
          >
            <div className="row">
              <div
                className={`col-12 mb-4 d-flex col-md-6 ${
                  mSSetting.membership_status ? "" : "col-lg-4"
                }`}
              >
                <TotalCoinsCount
                  title="Total Coins Count"
                  coins_count={totalCoinsCount}
                />
              </div>

              {coinsCount.map((item) => {
                return (
                  <div
                    className={`col-12 mb-4 d-flex col-md-6 ${
                      mSSetting.membership_status ? "" : "col-lg-4"
                    }`}
                  >
                    <AppBugReports data={item} />
                  </div>
                );
              })}
              {projectMode == "DEV" ? (
                <>
                  <div
                    className={`col-12 mb-4 d-flex col-md-6 ${
                      mSSetting.membership_status ? "" : "col-lg-4"
                    }`}
                  >
                    <TotalCoinsCount
                      title="Total Commission"
                      coins_count={totalCommission}
                    />
                  </div>
                  <div
                    className={`col-12 mb-4 d-flex col-md-6 ${
                      mSSetting.membership_status ? "" : "col-lg-4"
                    }`}
                  >
                    <TotalCoinsCount
                      title="Total Leads"
                      coins_count={totalLeads}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          {mSSetting.membership_status && (
            <div className="col-12 col-lg-4 profile-cards mb-4">
              {/* <SourceSession sourceSessions={sourceSessions} /> */}
              <GraphicsLinks graphicsLinks={graphicsLinks} />
            </div>
          )}
        </div>
        {mSSetting.membership_status && (
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="row"></div>
            </div>
            <div className="col-12 mb-4">
              <SalePages title="Affiliate Links" sourceSessions={salePages} />
            </div>
          </div>
        )}

        {projectMode == "DEV" && (
          <div className="row">
            <div className="col-12">
              <AppWebsiteVisits
                graphData={graphData}
                graphLabels={graphLabels}
                startDate={dailyDynamiteStartDate}
              />
            </div>
          </div>
        )}

        {/* <div className="row">
        {Dashboard_Data.map((item) => {
          return (
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <AppBugReports data={item} />
            </div>
          );
        })}
        <div className="col-12 col-md-8">
          <AppWebsiteVisits />
        </div>
        <div className="col-12 col-md-4">
          <AppOrderTimeline />
        </div>
      </div> */}
      </div>
    </>
  );
}
