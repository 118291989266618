import React from "react";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
function GoalStatementList() {
  const navigate = useNavigate();
  return (
    <>
      <div className="row">
        <div className="col-12 mt-4">
          <Divider />
        </div>
        <div className="col-12 mt-4 text-end">
          <button
            className="small-contained-button "
            type="button"
            onClick={() => navigate(`/goal-statement/manage-photos`)}
          >
            Manage Photos
          </button>
        </div>
      </div>
    </>
  );
}

export default GoalStatementList;
