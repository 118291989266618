import { Icon } from "@iconify/react";
import React from "react";
import RewardsList from "./RewardsList";

export default function YourRewards({
  handleSlideLeft,
  handleSlideRight,
  clientDashboardSetting,
  rewardList,
}) {
  return (
    <>
      <section className="membership-section text-center mt-3">
        <div className="container">
          <div className="hz-scroll">
            <span className="hz-scroll-left" onClick={handleSlideLeft}>
              <Icon className="hz-scroll-icon" icon="ep:arrow-left-bold" />
            </span>
            <span className="hz-scroll-right" onClick={handleSlideRight}>
              <Icon className="hz-scroll-icon" icon="ep:arrow-right-bold" />
            </span>
            {rewardList.map((reward, index) => {
              return (
                <RewardsList
                  reward={reward}
                  index={index}
                  clientDashboardSetting={clientDashboardSetting}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
