import { invokeApi } from "src/bl_libs/invokeApi";

export const ge_gaps_videos_api = async () => {
  const requestObj = {
    path: `api/gaps_video/list/client`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
