import { Avatar, AvatarGroup } from "@mui/material";
import React from "react";
import {
  communityHandScreenshot,
  communityHeart,
  communityMoneyImage,
} from "src/assets";
import { s3baseUrl } from "src/config/config";
import { get_kmb_number } from "src/utils/formatNumber";

export default function ActionsCount({
  handleOpenLikedBox,
  likeCount,
  likeCountNew,
  // topLikedUser,
  handleOpenGratitudeBox,
  gratitudeCount,
  topGratitudeUser,
  coinsCount,
  feeds_type,
  commentsCount,
  handleClick,
}) {
  return (
    <div className="my-2 comment-icon d-flex justify-content-between clear-both">
      <div className="svg-color mt-1 comment-icons">
        <div
          className="likes-post d-flex ps-0 ps-md-2 align-content-center"
          onClick={handleOpenLikedBox}
        >
          {likeCount > 0 && (
            <>
              <img src={communityHeart} alt="" />
              <p className="ms-1 mb-0">{get_kmb_number(likeCount)} Likes</p>
              &nbsp;
              {/* {topLikedUser.length > 0 && (
                <AvatarGroup max={2}>
                  {topLikedUser &&
                    topLikedUser.map((liked_user, index) => {
                      return (
                        <Avatar
                          alt={liked_user.user_info_action_by?.name[0]}
                          src={
                            s3baseUrl +
                            liked_user.user_info_action_by?.profile_image
                          }
                          key={index}
                        />
                      );
                    })}
                </AvatarGroup>
              )} */}
              {/* {likeCount > 2 && <span>&nbsp;and {likeCount - 2} others</span>} */}
            </>
          )}
        </div>
      </div>
      {feeds_type === "general" && (
        <>
          <div className="mt-1 likes-post">
            <div
              className="likes-post d-flex ps-0 ps-md-2"
              onClick={handleOpenGratitudeBox}
            >
              {gratitudeCount > 0 && (
                <>
                  <img src={communityHandScreenshot} alt="" />
                  <p className="ms-1 mb-0">
                    {get_kmb_number(gratitudeCount)} Gratitude
                  </p>
                </>
              )}
            </div>
          </div>
          {/* {coinsCount > 0 && (
            <div className="likes-post mt-1 pe-0 pe-md-3 text-end">
              <>
                <img src={communityMoneyImage} alt="" /> ={" "}
                {get_kmb_number(coinsCount)}
              </>
            </div>
          )} */}
        </>
      )}
      {commentsCount > 0 && (
        <div
          className="cursor-pointer"
          onClick={() => {
            if (handleClick) {
              handleClick();
            }
          }}
        >
          {commentsCount > 0 && (
            <span>
              {get_kmb_number(commentsCount)}{" "}
              {`${commentsCount == 1 ? "comment" : "comments"}`}
            </span>
          )}
        </div>
      )}
    </div>
  );
}
