import React from "react";
import { Card, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import moment from "moment";
import { convertCurrencyToSign } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";
import { warnIcon } from "src/assets";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const currnencyText = {
  gbp: "UK Pounds",
  eur: "Euro",
};

function RequestCard({
  bill,
  handlePage,
  btn_title,
  signButton,
  isBankPayment,
}) {
  const navigate = useNavigate();
  const { contentSettingData } = useContentSetting();
  const handleNavigate = () => {
    if (
      bill.agreement_config?.is_sign_agreement &&
      !bill?.is_agreement_signed
    ) {
      navigate(`/agreement/${bill.payment_request_slug}`);
    }
  };

  const replacedString = (string) => {
    if (string) {
      string = string.replace(
        /{exchange_rate}/g,
        `<span class="text-golden">${bill?.cost_in_one_pound?.toFixed(
          2
        )}</span>`
      );
      string = string.replace(
        /{to_currency}/g,
        currnencyText[bill.converted_currency.toLowerCase()] +
          " (" +
          bill.converted_currency.toUpperCase() +
          ")"
      );
      return string;
    }
  };

  return (
    <Card className="payment-card mb-3">
      {signButton && bill.agreement_config && (
        <>
          {bill.agreement_config.is_sign_agreement === true &&
          bill?.is_agreement_signed === false ? (
            <Tooltip title="Please sign your agreement by clicking here">
              <img
                src={warnIcon}
                className="warning-icons"
                onClick={handleNavigate}
              />
            </Tooltip>
          ) : (
            ""
          )}
        </>
      )}
      <div className="row p-3">
        <div className="col-12">
          <div className="mb-3">
            <span>{htmlDecode(bill.request_title)}</span>
          </div>
          <div className="mb-3 d-flex align-items-center svg-color date-color">
            <Icon icon="uiw:date" />
            <span className="ms-2">
              {moment(bill.createdAt).format("MMM DD YYYY")}
            </span>
          </div>
          <div className="row">
            <div className="col-8 col-md-6 col-lg-3 request-type">
              Request Type:
            </div>
            <div className="col-4 col-md-6 col-lg-3 ">
              {bill.request_type === "onetime" ? "OneTime" : "Recurring"}
            </div>
            {bill.request_type === "onetime" && (
              <>
                <div className="col-8 col-md-6 col-lg-3 mt-3 mt-lg-0 request-type">
                  Total Programme Amount:
                </div>
                <div className="col-4 col-md-6 col-lg-3  mt-3 mt-lg-0 ">
                  {convertCurrencyToSign(bill.currency)}
                  {bill.total_amount.toFixed(2)}
                </div>
                {isBankPayment && bill.converted_amount && (
                  <>
                    <div className="col-8 col-lg-9 mt-3">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: replacedString(
                            contentSettingData.payment_request_currency_conversion_text
                          ),
                        }}
                      ></div>
                    </div>
                    <div className="col-4 col-lg-3 mt-3 ">
                      {convertCurrencyToSign(bill.converted_currency)}
                      {bill.converted_amount.toFixed(2)}
                    </div>
                  </>
                )}
              </>
            )}

            {bill.request_type === "recurring" && (
              <>
                <div className="col-8 col-md-6 col-lg-3 request-type">
                  Initial Deposit Amount:
                </div>
                <div className="col-4 col-md-6 col-lg-3">
                  {convertCurrencyToSign(bill.currency)}
                  {bill.initial_amount.toLocaleString()}
                </div>
                <div className="col-8 col-md-6 col-lg-3  mt-3 request-type">
                  Monthly Installments:
                </div>
                <div className="col-4 col-md-6 col-lg-3  mt-3 ">
                  {convertCurrencyToSign(bill.currency)}
                  {bill.installment_amount.toLocaleString()}
                </div>
              </>
            )}

            {handlePage && (
              <div className="col-12 text-center">
                <button
                  className="small-contained-button mt-3"
                  onClick={() => {
                    handlePage(
                      bill.payment_request_type,
                      bill.payment_request_slug,
                      bill.payment_mode
                    );
                  }}
                >
                  {btn_title}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default RequestCard;
