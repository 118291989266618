import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Drawer } from "@mui/material";
import { MHidden } from "../../components/@material-extend";
//
import sidebarConfig from "./SidebarConfig";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import SettingsSidebarConfig from "./SettingsSidebarConfig";
import { default_paths, settings_routes } from "src/utils/constants";
import RenderNavbar from "./RenderNavbar";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  openSettings: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

let access_navitem = (item, currentPath) =>
  (item.path && currentPath.includes(item.path) && item.is_access !== false) ||
  (item.matches?.includes(currentPath) && item.is_access !== false);

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  openSettings,
}) {
  const { pathname } = useLocation();
  const { navbarList } = useContentSetting();
  const navigate = useNavigate();
  let nav_items = navbarList.filter((item) => item.show_on === "main_sidebar");
  let nav_items_1 = navbarList.filter(
    (item) => item.show_on === "setting_sidebar"
  );

  let isMatchRoute = settings_routes.some((route) => pathname.includes(route));

  useEffect(() => {
    onCloseSidebar();
  }, [pathname]);

  const is_access_path = (navItems, currentPath) => {
    let find_default = default_paths.find((path) => currentPath.includes(path));
    if (find_default) {
      return true;
    }
    let find_path = navItems.find((item) => access_navitem(item, currentPath));
    if (find_path) {
      return true;
    }
    let find_child = navItems.find((item) =>
      item.child_options?.find((child) => access_navitem(child, currentPath))
    );
    if (find_child) {
      return true;
    }
    return false;
  };

  const handlePathname = () => {
    if (pathname) {
      const pathParts = pathname.split("/");
      if (pathParts.length > 2) {
        return pathParts.slice(0, 2).join("/");
      } else {
        return pathname;
      }
    }
  };

  useEffect(() => {
    const isPathInNavItems = is_access_path(nav_items, handlePathname());
    if (
      !pathname.includes("mission-control") &&
      !pathname.includes("welcome") &&
      !isPathInNavItems
    ) {
      navigate("/mission-control");
    }
  }, [pathname]);

  return (
    <RootStyle>
      <>
        <Drawer
          open={openSettings}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
            className: "sidebar-drawer",
          }}
        >
          <RenderNavbar navConfig={nav_items_1} />
        </Drawer>
      </>
      <>
        {isOpenSidebar && (
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: { width: DRAWER_WIDTH },
              className: "sidebar-drawer",
            }}
          >
            <RenderNavbar navConfig={nav_items} />
          </Drawer>
        )}
      </>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
            },
          }}
        >
          {isMatchRoute ? (
            <RenderNavbar navConfig={nav_items_1} />
          ) : (
            <RenderNavbar navConfig={nav_items} />
          )}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
