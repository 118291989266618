import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getWheelOfLifeListApi } from "src/DAL/WheelOfLife/WheelOfLifeApi";
import { makeStyles } from "@mui/styles";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import QuestionTopSection from "./QuestionTopSection";
import CompleteQuestions from "./CompleteQuestions";
import AssessmentQuestionsSection from "./Assessment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const QuestionsAndAssessment = () => {
  const navigate = useNavigate();
  const { userInfo, wheelOfLifeSetting, loginInfo } = useContentSetting();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [pageState, setPageState] = useState(1);
  const handleChooseAnswer = (question_id, selected_option) => {
    const updated = questions.map((q) => {
      if (q._id === question_id) {
        return { ...q, answer: selected_option };
      } else {
        return q;
      }
    });
    setQuestions(updated);
  };

  const get_wheel_of_life_list = async () => {
    const result = await getWheelOfLifeListApi();

    if (result.code === 200) {
      let data = result.assessment.map((question) => {
        return {
          ...question,
          isExpanded: true,
        };
      });
      setQuestions(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNext = async () => {
    let is_answered = true;

    questions.forEach((question) => {
      if (!question.answer) {
        is_answered = false;
      }
    });

    if (!is_answered) {
      enqueueSnackbar("Please choose answer against every question.", {
        variant: "error",
      });
      return;
    } else {
      setPageState(2);
      setTimeout(() => {
        window.scrollTo(0, 100);
      }, 1000);
    }
  };

  const expandArea = (index) => {
    let temp_state = [...questions];
    let temp_element = { ...temp_state[index] };
    if (temp_element.isExpanded === false) {
      temp_element.isExpanded = true;
    } else {
      temp_element.isExpanded = false;
    }
    temp_state[index] = temp_element;
    setQuestions(temp_state);
  };

  useEffect(() => {
    get_wheel_of_life_list();
    if (!loginInfo.wheel_of_life) {
      navigate("/mission-control");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, [pageState]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <section className="top-banner-section">
        <QuestionTopSection
          data={wheelOfLifeSetting}
          userInfo={userInfo}
          pageState={pageState}
        />
        {pageState == 1 ? (
          <>
            <CompleteQuestions
              questions={questions}
              expandArea={expandArea}
              handleNext={handleNext}
              handleChooseAnswer={handleChooseAnswer}
              data={wheelOfLifeSetting}
              userInfo={userInfo}
            />
          </>
        ) : (
          <AssessmentQuestionsSection questionsArray={questions} />
        )}
      </section>
    </>
  );
};

export default QuestionsAndAssessment;
