import { CircularProgress, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { MentorShipApi } from "src/DAL/AffirmationCategories/Affirmations/Affirmations";
import { lockIcon } from "src/assets";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { useNavigate, useParams } from "react-router-dom";
import { ReactVideoPlayer } from "src/components";
import GeneralQuestionsList from "../GeneralQuestions/GeneralQuestionsList";
import { ge_gaps_videos_api } from "src/DAL/GapsVideos/GapsVideos";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { get_kmb_number } from "src/utils/formatNumber";
import { htmlDecode } from "src/utils/convertHtml";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function GapsVideos() {
  const { userInfo } = useContentSetting();
  const { created_for_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [videosList, setVideosList] = useState([]);
  const [mainVideo, setMainVideo] = useState({});
  const [showQuestions, setShowQuestions] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();

  const MentorShipList = async () => {
    const result = await ge_gaps_videos_api();
    if (result.code === 200) {
      setVideosList(result.videos_list);
      setMainVideo(result.featured_video);
      navigate(`/gaps-videos/${result.featured_video._id}`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClick = (video, remaing_coins) => {
    if (remaing_coins <= 0) {
      navigate(`/gaps-videos/${video._id}`);
      setMainVideo(video);
    }
  };

  useEffect(() => {
    MentorShipList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      {videosList.length > 0 ? (
        <>
          <div className="container">
            <div className="row mt-4">
              <div className="col-8">
                <h2>{mainVideo.title}</h2>
                <ReactVideoPlayer url={mainVideo.video_url} />
                <div
                  dangerouslySetInnerHTML={{
                    __html: mainVideo.detailed_description,
                  }}
                  className="mt-3"
                />
              </div>
              <div className="col-4">
                <div className="gaps-videos-list">
                  {videosList
                    .filter((item) => item._id !== mainVideo._id)
                    .map((video) => {
                      let locked_statement = "Locked";
                      if (video.locked_statement) {
                        locked_statement = video.locked_statement;
                      }
                      let remaing_coins = video.coins - userInfo.coins_count;

                      return (
                        <div
                          className={`gaps-videos-item card p-2 mb-2 position-relative ${
                            remaing_coins > 0 ? "locked" : " cursor-pointer"
                          }`}
                          onClick={() => handleClick(video, remaing_coins)}
                        >
                          {remaing_coins > 0 && (
                            <>
                              <div
                                className="lock_btn lock_icon_button"
                                style={{ zIndex: "99" }}
                              >
                                <button
                                  style={{ display: "flex", zIndex: "99" }}
                                >
                                  <img
                                    src={lockIcon}
                                    alt=""
                                    className="gaps-lock-image"
                                  />
                                  &nbsp;{locked_statement}
                                </button>
                              </div>
                            </>
                          )}
                          <img
                            src={s3baseUrl + video.image.thumbnail_1}
                            alt=""
                            className="gaps-image"
                          />
                          {remaing_coins > 0 && (
                            <div className="more-coins">
                              Get <span>{get_kmb_number(remaing_coins)}</span>{" "}
                              more coins to unlcok video
                            </div>
                          )}
                          <div
                            className={`p-2 gaps-description ${
                              remaing_coins > 0 ? "mt-3" : ""
                            }`}
                          >
                            <h3>{video.title}</h3>
                            <div className="gaps-short-desc ">
                              {htmlDecode(video.short_description)}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              {mainVideo.question_configration?.show_question && (
                <div className="col-lg-12 text-end mt-4">
                  <button
                    className="small-contained-button float-end mt-1"
                    onClick={() => setShowQuestions(!showQuestions)}
                  >
                    {showQuestions
                      ? "Hide Questions"
                      : mainVideo.question_configration?.button_text}
                  </button>
                </div>
              )}
            </div>
          </div>
          {showQuestions && (
            <GeneralQuestionsList
              created_for="gaps_videos"
              remove_back_button={true}
              hide_dynamite_reply={true}
              heading=""
            />
          )}
        </>
      ) : (
        <RecordNotFound title="Videos Not Found" />
      )}
    </>
  );
}
