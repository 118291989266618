import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get_feeds_list } from "src/DAL/Community/Community";
import { Shop_list } from "src/DAL/Shop/ShopApi";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import ShopSlider from "src/components/Shop/ShopDialog";
import { s3baseUrl } from "src/config/config";
import { convertCurrencyToSign } from "src/utils/constants";
import { makeStyles } from "@mui/styles";
import ShopCard from "./ShopCard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import ShopPopup from "./ShopPopup";
import SearchIcon from "@mui/icons-material/Search";

import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function Shop() {
  const navigate = useNavigate();
  const { cartElement } = useContentSetting();

  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [openLink, setOpenLink] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const classes = useStyles();
  const params = useParams();
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totlePages, setTotlePages] = useState(0);
  const [CatagryInfo, setCatagryInfo] = useState("");
  let [product, setProduct] = useState({});
  const [prevLength, setPrevLength] = useState(cartElement.length);
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
    localStorage.setItem("shop", e.target.value);
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...list];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.name.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };
  const [loadMore, setLoadMore] = useState(
    `api/dynamite_product/list/client/${params.id}?page=1&limit=9&search_text=`
  );
  const handleClick = (object) => {
    navigate(`/shop/${object._id}`, {
      state: object,
    });
  };
  const loadMoreData = () => {
    setIsLoadingMore(true);
    setLoadMore(loadMorePath);
  };

  const ShopLIst = async () => {
    const result = await Shop_list(loadMore);
    if (result.code === 200) {
      setCatagryInfo(result.product_category.title);
      const data = result.product.map((item) => {
        return {
          ...item,
          card_title: item.name,
          selected_quantity: 1,
          product_id: item._id,
          card_image:
            item.is_video === true
              ? s3baseUrl + item.video_thumbnail
              : s3baseUrl + item.image[0].image,
          card_short_description_2: item.detailed_description,
          card_short_description:
            convertCurrencyToSign(item.currency) + item.price.toFixed(2),
          card_classes: "col-lg-4 col-md-4 col-sm-12 mb-4 shop-cards",
          object: item,
        };
      });
      var newArray = list?.concat(data);
      setList(newArray);
      setPageNumber(pageNumber + 1);
      setTotlePages(result.total_pages);
      setLoadMorePath(result.load_more_url_for_client);
      setIsLoadingMore(false);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  let breadCrumbMenu = [
    {
      title: CatagryInfo,
      navigation: `/shop`,
      active: false,
    },

    {
      title: "Products",
      navigation: ``,
      active: true,
    },
  ];
  if (CatagryInfo) {
    localStorage.setItem("product_catagory_title", CatagryInfo);
  }

  useEffect(() => {
    let shop = localStorage.getItem("shop");
    if (shop) {
      setQuery(shop);
    }
    ShopLIst();
  }, [loadMore]);
  useEffect(() => {
    if (cartElement.length > prevLength) {
      setOpenLink(true);
      setPrevLength(cartElement.length);
    }
    setPrevLength(cartElement.length);
  }, [cartElement.length, prevLength]);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container position-relative">
      <div className="row pt-70" id="assessment-container">
        <div className="row">
          <div className="col-12 d-flex mb-4">
            {CatagryInfo && (
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            )}
          </div>
        </div>
      </div>
      <div className="row justify-content-end mb-3">
        <div className="col-4">
          <div>
            <FormControl variant="outlined" style={{ marginTop: "2px" }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                variant="small"
                value={query}
                onChange={handleChangeSearch}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search"
              />
            </FormControl>
          </div>
        </div>
      </div>

      {getFilteredData(query).length > 0 ? (
        <ShopCard
          data={getFilteredData(query)}
          handleClick={handleClick}
          className="dd-shop-cards"
          setProduct={setProduct}
          setOpenLink={setOpenLink}
        />
      ) : (
        <RecordNotFound title="products Not Found" />
      )}

      {totlePages > pageNumber ? (
        <div className="col-12 text-center">
          <button
            className="small-contained-button mt-3"
            onClick={loadMoreData}
            disabled={isLoadingMore}
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </button>
        </div>
      ) : (
        ""
      )}

      {/* <button className="small-contained-button fixed_checkout_button mt-3">
        <ShoppingCartCheckoutIcon />
        CheckOut
      </button> */}
      {openLink && (
        <GeneralPopUpModel
          open={openLink}
          setOpen={setOpenLink}
          title={""}
          componentToPassDown={
            <ShopPopup product={product} setOpenLink={setOpenLink} />
          }
        />
      )}
    </div>
  );
}
