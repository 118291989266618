import React from "react";
import { useLocation } from "react-router-dom";

const EmailTemplateForPayment = () => {
  const { state } = useLocation();
  console.log(state, "statestatestatestatestate");
  return (
    <div className="container">
      <div className="row">
        <h2 className="mb-3">Email Notification Detail</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: state,
          }}
          className="w-100"
        ></div>
      </div>
    </div>
  );
};

export default EmailTemplateForPayment;
