import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import LockIcon from "@mui/icons-material/Lock";

import {
  live_events_list_api,
  _list_of_member_for_chat_against_event,
} from "src/DAL/LiveEvents/LiveEvents";
import { s3baseUrl } from "src/config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import SearchIcon from "@mui/icons-material/Search";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function LiveEvents() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [eventsList, setEventsList] = useState({});
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
    localStorage.setItem("portals", e.target.value);
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...eventsList];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };
  const handleClick = (object) => {
    delete object.card_button;
    if (!object.locked) {
      navigate(`/live-events/${object._id}`);
    } else if (
      object.lock_configration &&
      object.lock_configration.lock_event_description !== ""
    ) {
      navigate(`/live-events-locked/${object._id}`, { state: object });
    }
  };
  const handleNavigate = (object) => {
    // delete object.card_button;
    window.open(object.lock_configration.lock_event_button_link, "_self");
  };

  const getLiveEventsList = async () => {
    const result = await live_events_list_api();
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.member_dynamite_event.map((dynamite_event) => {
        CARD_OPTIONS.push({
          ...dynamite_event,
          card_title: dynamite_event.title,
          card_short_description: dynamite_event.short_description,
          card_image: s3baseUrl + dynamite_event.images.thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
          card_button: dynamite_event.locked === true && {
            button_title: (
              <>
                {/* <LockIcon />
                {` Unlock Access`} */}

                {dynamite_event.lock_configration ? (
                  dynamite_event.lock_configration.lock_event_logo ? (
                    <img
                      src={
                        s3baseUrl +
                        dynamite_event.lock_configration.lock_event_logo
                      }
                      width={20}
                      className="me-1"
                    />
                  ) : (
                    <LockIcon />
                  )
                ) : (
                  <>
                    <LockIcon />
                  </>
                )}
                {dynamite_event.lock_configration &&
                dynamite_event.lock_configration.lock_event_button_text
                  ? dynamite_event.lock_configration.lock_event_button_text
                  : "Unlock Access"}
              </>
            ),
            handleClick: handleClick,
          },
        });
      });
      setEventsList(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    let portals = localStorage.getItem("portals");
    if (portals) {
      setQuery(portals);
    }
    getLiveEventsList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mt-4">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <h2>Portals</h2>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div>
              <FormControl variant="outlined" style={{ marginTop: "2px" }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  variant="small"
                  value={query}
                  onChange={handleChangeSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </div>
          </div>
        </div>
        {eventsList.length > 0 ? (
          <CustomMUICard
            data={getFilteredData(query)}
            handleClick={handleClick}
          />
        ) : (
          <RecordNotFound title="Portals Not Found" />
        )}
      </div>
    </>
  );
}
