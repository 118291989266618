import React from "react";
import { useNavigate } from "react-router-dom";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

export default function ShopThanskPage() {
  const navigate = useNavigate();
  const { contentSettingData } = useContentSetting();

  return (
    <section className="art-thanks">
      <div className="container mt-5">
        <div className="row justify-content-center mt-5 mx-lg-5">
          <div className="col-lg-11 mt-5">
            <div className="art-thanks-div text-center">
              <div
                dangerouslySetInnerHTML={{
                  __html: contentSettingData.shop_thaks_page_content,
                }}
              ></div>
              <div className="border-line-thanks" />
              <a
                onClick={() => navigate("/shop")}
                className="thank-button"
                style={{ cursor: "pointer" }}
              >
                Continue Shopping
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
