import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import PaymentRequest from "./PaymentRequest";

export default function PaymentRequestAuth() {
  const { stripeKey } = useContentSetting();

  console.log(stripeKey, "stripeKey");

  return (
    <Elements stripe={loadStripe(stripeKey)}>
      <PaymentRequest />
    </Elements>
  );
}
