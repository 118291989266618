import { useState, useEffect } from "react";
import SlickCarousel from "src/components/GeneralComponents/SlickCarousel";
import ScriptSlider from "src/components/_dashboard/app/ScriptSlider";
import { s3baseUrl } from "src/config/config";

export default function Upload({
  handleRemove,
  files,
  setFiles,
  handleUpload,
  otherFiles,
  handleChangeImage,
}) {
  const [highlight, setHighlight] = useState(false);
  const [preview, setPreview] = useState("");
  const [drop, setDrop] = useState(false);

  const handleEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("enter!");

    preview === "" && setHighlight(true);
  };

  const handleOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("over!");

    preview === "" && setHighlight(true);
  };

  const handleLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlight(false);
  };

  return (
    <>
      <div
        onDragEnter={(e) => handleEnter(e)}
        onDragLeave={(e) => handleLeave(e)}
        onDragOver={(e) => handleOver(e)}
        onDrop={(e) => handleUpload(e)}
        className={`upload${
          highlight ? " is-highlight" : drop ? " is-drop" : ""
        }`}
        //   style={{ backgroundImage: `url(${preview})` }}
      >
        <form className="my-form">
          <div className="d-flex">
            {files?.length > 0 ? (
              files.map((file, index) => (
                <div className="">
                  <div className="preview upload-div">
                    <span onClick={() => handleRemove(index)}>x</span>
                    {file.type == "file" ? (
                      <img
                        src={
                          file.type == "file"
                            ? URL.createObjectURL(file.path)
                            : s3baseUrl + file.path
                        }
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src={
                          file.type == "file"
                            ? URL.createObjectURL(file.path)
                            : s3baseUrl + file.path
                        }
                        className="img-fluid"
                      />
                    )}
                  </div>
                </div>
              ))
            ) : (
              <>
                <input
                  type="file"
                  className="upload-file"
                  accept="image/*"
                  onChange={(e) => handleUpload(e)}
                />
                <p>+</p>
              </>
            )}
          </div>
          <div className="upload-button-btn">
            <input
              type="file"
              className="upload-file"
              accept="image/*"
              onChange={(e) => handleUpload(e)}
            />
            <button className="button-upload-image">Upload</button>
          </div>
        </form>
      </div>
      <span> Recommended Size ( 500 X 500 ) jpg, jpeg, png</span>
      <div className="mt-2">
        <ScriptSlider
          data={otherFiles}
          handleChangeImage={handleChangeImage}
          files={files}
        />
      </div>

      {/* <div className="d-flex">
        {files
          ? files.map((file, index) => (
              <div className="">
                <div className="preview upload-div">
                  <span onClick={() => handleRemove(index)}>x</span>
                  <img
                    src={
                      file.type == "file"
                        ? URL.createObjectURL(file.path)
                        : s3baseUrl + file.path
                    }
                    className="img-fluid"
                    height="30px"
                  />
                </div>
              </div>
            ))
          : ""}
      </div> */}
    </>
  );
}
