import {
  CircularProgress,
  IconButton,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { cointlogo } from "src/assets";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import ActionBarComponentProps from "./calender";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  deleteDiaryApi,
  diaryListApi,
} from "src/DAL/dynamiteDiary/dynamiteDiary";
import { useEffect } from "react";
import moment from "moment";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import DiaryDetail from "./DetailDiary";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const DiaryList = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [diaryList, setDiaryList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [totalCoin, setTotalCoin] = useState("");
  const [editValue, setEditValue] = useState("");
  const [editDrawerState, setEditDrawerState] = useState(false);

  const handleNavigate = () => {
    navigate(`/dynamite-diary/add-dynamite-diary`);
  };
  const [addArray, setaddArray] = useState([]);
  const handleAdd = () => {
    const addedElement = [...addArray, []];
    setaddArray(addedElement);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteValue(value);
  };
  const handleEdit = (value) => {
    setEditValue(value);
    navigate(`/dynamite-diary/edit-dynamite-diary/${value._id}`, {
      state: value,
    });
  };
  const handleDetail = (value) => {
    setEditValue(value);
  };

  const handleDeleteDiary = async (value) => {
    const result = await deleteDiaryApi(deleteValue._id);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });

      getDiaryList(false);
      setOpenDelete(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDelete(false);
    }
  };

  const getDiaryList = async (loadingValue) => {
    if (loadingValue == false) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }

    let result = await diaryListApi();
    if (result.code == 200) {
      setDiaryList(result.dynamote_diaries);
      setIsLoading(false);
      setTotalCoin(result.total_coins_count_attracted);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };
  const handleOpenEditDrawer = (value) => {
    setEditValue(value);
    setEditDrawerState(true);
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    // {
    //   label: "Detail",
    //   icon: "akar-icons:edit",
    //   handleClick: handleOpenEditDrawer,
    // },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    getDiaryList();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete diary ?"}
        handleAgree={handleDeleteDiary}
      />
      <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Diary Detail"
        componentToPassDown={<DiaryDetail data={editValue} />}
      />
      <section className="dynamite-diary">
        <div className="container">
          <div className="row ">
            {/* <div className="col-12">
              <IconButton
                className="back-screen-button mb-4"
                onClick={() => navigate(-1)}
              >
                <Icon icon="material-symbols:arrow-back-rounded" />
              </IconButton>
            </div> */}
            <div className="col-lg-6">
              <h2 className="heading">Daily Dynamite</h2>
              <h4 className="mt-3 subHeading">Past Journal</h4>
            </div>
            <div className="col-lg-6 ">
              <div className="d-flex text-align">
                <img className="coint-img img-fluid" src={cointlogo}></img>
                <h4 className="coint-detail">
                  Total Coins Attracted: {totalCoin}
                </h4>
              </div>
              <button
                className="small-contained-button float-end mt-3 mb-4"
                onClick={handleNavigate}
              >
                Add Daily Dynamite
              </button>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="recent col-lg-12 d-flex justify-content-end me-2 pe-3"></div>

            {diaryList?.length < 1 ? (
              <RecordNotFound title={"Diary not found"} />
            ) : (
              diaryList?.map((value, i) => {
                return (
                  <div className="col-lg-6 mb-3" style={{ cursor: "pointer" }}>
                    <div className="mini-cards d-flex">
                      <div className="w-100 ms-2 me-2">
                        <div className="diary-font-weight d-flex">
                          <h4 onClick={() => handleEdit(value)}>
                            {value?.date ? value?.date : ""}
                          </h4>
                          <div className="d-flex">
                            <span
                              style={{ paddingTop: "2px" }}
                              onClick={() => handleEdit(value)}
                              className="diary-first-heading pe-2"
                            >
                              {value?.date_time
                                ? moment(value?.date_time).format("hh:mm A")
                                : ""}
                            </span>
                            <CustomPopover menu={MENU_OPTIONS} data={value} />
                          </div>
                        </div>

                        <div className="d-flex justify-content-between ml-3 mt-2 diary-first-heading">
                          <div onClick={() => handleEdit(value)}>
                            <h4>{value.statement_array[0]?.option}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default DiaryList;
