import React, { useState } from "react";
import { audio_1 } from "src/assets";
import ReactWaves from "@dschoon/react-waves";
import { get_root_value } from "src/utils/domUtils";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

const NewWaveAudioPlayer = ({ url }) => {
  const [playing, setIsPlaying] = useState(false);

  const [audioPeaks, setAudiopeaks] = useState([]);

  const onWaveformReady = ({ wavesurfer }) => {
    wavesurfer.exportPCM(null, 1000, true, null).then((exportPCM) => {
      if (exportPCM) {
        setAudiopeaks(exportPCM);
      }
    });
  };

  return (
    <div>
      <div className="">
        <div className="d-flex audiowave">
          <div
            className="play button diary-icon-add"
            onClick={() => {
              setIsPlaying(!playing);
            }}
          >
            {!playing ? <PlayArrowIcon /> : <PauseIcon />}
          </div>
          <ReactWaves
            audioFile={url} // maps the audio to the element
            className={"react-waves"}
            options={{
              backend: "MediaElement", // maps the waveform to an audio element
              normalize: true,
              barHeight: 2,
              cursorWidth: 2,
              mediaType: "audio",
              hideScrollbar: true,
              responsive: true,
              progressColor: get_root_value("--portal-theme-primary"),
              waveColor: "#D1D6DA",
              mediaControls: false,
            }}
            onWaveformReady={onWaveformReady}
            volume={1}
            zoom={1}
            playing={playing}
          />
        </div>
      </div>
    </div>
  );
};

export default NewWaveAudioPlayer;
