import { Icon } from "@iconify/react";
import { Avatar, Badge, Tooltip } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CodeIcon from "@mui/icons-material/Code";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import React, { useState } from "react";
import { s3baseUrl } from "src/config/config";
import {
  decode_markdown,
  get_date_with_user_time_zone,
  get_short_string,
} from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import { useEffect } from "react";
import MicIcon from "@mui/icons-material/Mic";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { _get_chat_detail } from "src/DAL/Chat/Chat";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export default function ChatList() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo, feedSetting } = useContentSetting();
  const chat_id = new URLSearchParams(location.search).get("chat_id");
  const [state, setState] = useState({
    search_text: "",
    event_id: "none",
  });

  const {
    chatList,
    handleSetSelectedChat,
    selectedChatUser,
    get_chat_history_list,
    unreadChatCounts,
  } = useChat();

  const get_profile_from_member = (member_id, members = []) => {
    const member_profile = members.find(
      (member) => member._id._id === member_id
    );
    return member_profile;
  };

  const get_receiver_profile = (members = []) => {
    const profile = members.find((m) => m._id?._id !== userInfo._id);
    return { ...profile, ...profile._id };
  };

  const get_chat_detail = async (chat_id) => {
    const result = await _get_chat_detail(chat_id);
    const profile = get_receiver_profile(result.chat.member);
    const my_profile = get_profile_from_member(
      userInfo._id,
      result.chat.member
    );
    if (result.code === 200) {
      handleSetSelectedChat({
        ...result.chat,
        receiver_profile: profile,
        my_profile: my_profile,
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleClick = (chat) => {
    navigate(`/chat`);
    handleSetSelectedChat(chat);
  };

  useEffect(() => {
    let postObj = { search_text: state.search_text };
    get_chat_history_list(postObj);
  }, [state]);

  useEffect(() => {
    if (chat_id) {
      if (chatList.length > 0) {
        let find_chat = chatList.find((chat) => chat._id == chat_id);
        if (find_chat) {
          handleSetSelectedChat(find_chat);
        }
      } else {
        get_chat_detail(chat_id);
      }
    }
  }, [chat_id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  return (
    <>
      <div className="col-12 col-md-4 chat-left-part">
        {unreadChatCounts > 0 && (
          <div className="d-flex justify-content-between mb-3">
            <div className="">Unread Chats Count</div>
            <div className="unread_chat_counts">
              <Badge badgeContent={unreadChatCounts} color="error">
                {/* <Icon icon={"ant-design:message-filled"} width={20} height={20} /> */}
              </Badge>
            </div>
          </div>
        )}
        <div className="chat-left-header" style={{ height: "50px" }}>
          <div className="chat-search-box mt-3">
            <Icon
              fontSize={20}
              className="chat-search-icon"
              icon="akar-icons:search"
            />
            <input
              className="chat-search-input"
              type="text"
              placeholder="Search"
              value={state.search_text}
              name="search_text"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="position-relative">
          <div className="chat-list">
            {chatList.map((chat, i) => {
              let level_title =
                chat.receiver_profile.community_level + "_title";
              let level_badge =
                chat.receiver_profile.community_level + "_badge";

              return (
                <div
                  onClick={() => handleClick(chat)}
                  className={
                    chat.receiver_profile._id === selectedChatUser?._id
                      ? "chat-list-user selected-chat d-flex p-2"
                      : "chat-list-user hover-effect d-flex p-2"
                  }
                  key={i}
                >
                  <div>
                    <div className="user-profile">
                      {/* showing user level dynamically*/}
                      <div className="user-level">
                        <Tooltip
                          title={
                            feedSetting[level_title]
                              ? feedSetting[level_title]
                              : "Elite"
                          }
                        >
                          <img
                            src={
                              feedSetting[level_badge]
                                ? s3baseUrl + feedSetting[level_badge]
                                : ""
                            }
                          />
                        </Tooltip>
                      </div>
                      <Avatar
                        src={s3baseUrl + chat.receiver_profile.profile_image}
                        style={{ height: 50, width: 50 }}
                      ></Avatar>
                      <div
                        className={
                          chat.receiver_profile.is_online ? "online" : "offline"
                        }
                      ></div>
                    </div>
                  </div>
                  <div className="chat-profile-data">
                    <div className="chat-profile-name ps-2 text-capitalize chat-profile-name-padding">
                      {chat.receiver_profile.first_name +
                        " " +
                        chat.receiver_profile.last_name}
                    </div>
                    <div className="chat-last-message ps-2">
                      {chat?.message_type == "image" ? (
                        <>
                          <PhotoCameraIcon fontSize="10" /> Photo
                        </>
                      ) : chat?.message_type == "video" ? (
                        <>
                          <PlayCircleIcon fontSize="10" /> Video
                        </>
                      ) : chat?.message_type == "html" ? (
                        <>
                          <span className="new_message_text">
                            a new message received
                          </span>
                        </>
                      ) : chat?.message_type == "audio" ? (
                        <>
                          <MicIcon fontSize="18" /> Voice Message
                        </>
                      ) : (
                        chat.last_message &&
                        get_short_string(
                          htmlDecode(decode_markdown(chat.last_message))
                        )
                      )}
                    </div>
                  </div>
                  {chat.last_message_date_time && (
                    <div className="chat-last-time text-end ps-2">
                      <Tooltip
                        title={get_date_with_user_time_zone(
                          chat.last_message_date_time,
                          "DD MMM YYYY [at] hh:mm A",
                          userInfo.time_zone,
                          userInfo.adminTimeZone
                        )}
                        arrow
                      >
                        <span className="">
                          {get_date_with_user_time_zone(
                            chat.last_message_date_time,
                            "DD-MM-YYYY hh:mm A",
                            userInfo.time_zone,
                            userInfo.adminTimeZone
                          )}
                        </span>
                      </Tooltip>
                    </div>
                  )}
                  {chat.my_profile.unread_message_count > 0 && (
                    <div className="chat-unread-count">
                      {chat.my_profile.unread_message_count}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
