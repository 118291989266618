import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

const ShowCard = ({ inputs, setInputs }) => {
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputFocus = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      focus: name,
    }));
  };

  const handleNavigate = () => {
    setInputs({ cvc: "", expiry: "", focus: "", name: "", number: "" });
    navigate(`/change-card`);
  };

  return (
    <div className="container">
      <h2>Card Details</h2>
      <div className="row mt-5">
        <div className="col-md-4 how-img mb-4 update-stripe-card">
          <Cards
            cvc={inputs.cvc}
            expiry={inputs.expiry}
            focused={inputs.focus}
            name={inputs.name}
            number={inputs.number}
            issuer={inputs.type}
            preview={true}
            callback={(e) => ({
              issuer: "visa",
              maxLength: 19,
            })}
          />
        </div>
        <div className="col-md-8">
          <form className="text-center mt-1 mx-auto w-100">
            <div className="row">
              <div className="col-md-6 col-sm-12 mb-4 ">
                <TextField
                  id="outlined-basic"
                  name="number"
                  value={inputs.number}
                  label="Card Number"
                  disabled
                  variant="outlined"
                  placeholder="Card Number"
                  onChange={handleInputChange}
                  fullWidth
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-4 ">
                <TextField
                  id="outlined-basic"
                  name="name"
                  disabled
                  value={inputs.name}
                  label="Card Holder Name"
                  variant="outlined"
                  placeholder="Card Holder Name"
                  onChange={handleInputChange}
                  fullWidth
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-4">
                <TextField
                  id="outlined-basic"
                  name="expiry"
                  disabled
                  value={inputs.expiry}
                  label="Valid Thru"
                  variant="outlined"
                  placeholder="Expiry"
                  fullWidth
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-4">
                <TextField
                  id="outlined-basic"
                  name="cvc"
                  disabled
                  value={inputs.cvc}
                  label="Cvc"
                  variant="outlined"
                  placeholder="Cvc"
                  fullWidth
                  // onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </div>
            </div>
          </form>
          <div className="col-12 text-end mt-4">
            <button className="small-contained-button" onClick={handleNavigate}>
              Change Card
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShowCard;
