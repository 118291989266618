import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Tooltip } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import GeneralQuestionsList from "src/pages/GeneralQuestions/GeneralQuestionsList";
import { vectorTickIcon, videoShowLock } from "src/assets";
import CongratulationMessage from "src/components/MissionLevels/CongratulationMessage";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import DayVideoTab from "src/components/MissionLevels/DayVideoTab";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import {
  complete_mission_schedule_api,
  mission_level_detail_api,
  start_mission_api,
  unlock_mission_schedule_api,
  update_mission_schedule_data_api,
} from "src/DAL/MissionLevels/MissionLevels";
import UnlockVideo from "src/components/MissionLevels/UnlockVideo";
import { number_with_comma, two_date_difference } from "src/utils/constants";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { htmlDecode } from "src/utils/convertHtml";
import StartMission from "src/components/MissionLevels/StartMission";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ScheduleDetails() {
  const { state } = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openMissionConfirm, setOpenMissionConfirm] = useState(false);
  const [missionDetail, setMissionDetail] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const { created_for_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [tabData, setTabData] = useState(0);
  const [missionResult, setMissionResult] = useState({});
  const [unLockCoin, setUnLockCoin] = useState(0);
  const [userCoin, setUserCoin] = useState(0);

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setTabData(schedules[newValue]);
  };

  const get_mission_detail = async () => {
    const result = await mission_level_detail_api(created_for_id);
    if (result.code == 200) {
      setMissionResult(result);
      setMissionDetail({ ...result.mission, user_data: result.user_data });
      let mission_schedules = result.mission_schedules.map((item) => {
        item.allowed_actions.map((i) => {
          let find_answer = item.completed_allowed_actions.find(
            (j) => j._id == i._id
          );
          i.is_checked = Boolean(find_answer);
          return i;
        });
        return item;
      });
      if (state) {
        let find_index = mission_schedules.findIndex(
          (item) => item._id == state._id
        );
        if (find_index >= 0) {
          setTabValue(find_index);
          setTabData(mission_schedules[find_index]);
        }
      }
      setSchedules(mission_schedules);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      handleBack();
      setIsLoading(false);
    }
  };

  const handleUnlock = (unlock_coin, user_coin) => {
    if (user_coin < unlock_coin) {
      enqueueSnackbar("You do not have enough coins to start Schedule", {
        variant: "error",
      });
      return;
    }
    setUnLockCoin(unlock_coin);
    setUserCoin(user_coin);
    setOpenConfirm(true);
    setTimeout(() => {
      setOpenModal(0);
    }, 500);
  };

  const handleConfirmSubmit = async () => {
    setIsSubmitting(true);
    const result = await unlock_mission_schedule_api(selectedSchedule._id);
    if (result.code == 200) {
      setSchedules((old) =>
        old.map((item) => {
          if (item._id === selectedSchedule._id) {
            item.schedule_status = "in_progress";
          }
          return item;
        })
      );
      setOpenConfirm(false);
      setIsSubmitting(false);
      setOpenModal(0);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    navigate(`/mission-levels/level-detail/${created_for_id}`);
  };

  const handleClick = (schedule) => {
    if (missionDetail.mission_status === "not_started") return;
    setSelectedSchedule(schedule);
    setOpenModal(3);
  };

  const handleCheckStatement = async (tab_data) => {
    let postData = {
      allowed_actions: tab_data.allowed_actions
        .filter((item) => item.is_checked == true)
        .map((item) => ({ _id: item._id })),
    };
    await update_mission_schedule_data_api(tabData._id, postData);
  };

  const handleChangeAction = (id, isDisabled, schedule) => {
    if (isDisabled) return;
    let schedule_data = [...schedules];
    let find_index = schedule_data.findIndex(
      (item) => item._id === schedule._id
    );
    let tab_data = schedule_data[find_index];
    tab_data.allowed_actions.map((item) => {
      if (item._id === id) {
        let is_checked = Boolean(item.is_checked);
        item.is_checked = !is_checked;
      }
      return item;
    });
    schedule_data[find_index] = tab_data;
    setSchedules(schedule_data);
    handleCheckStatement(tab_data);
  };

  const handleSuccess = (result) => {
    if (tabData.question_configration.thanks_description) {
      setOpenModal(2);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
    }
  };

  const handleSubmit = async (e, schedule) => {
    e.preventDefault();
    let find = tabData.allowed_actions.find(
      (item) => item.is_required === true && !item.is_checked
    );
    if (find) {
      enqueueSnackbar("Actions with * must be checked", { variant: "error" });
      return;
    }
    setIsSubmitting(true);
    let postData = {
      allowed_actions: tabData.allowed_actions
        .filter((item) => item.is_checked)
        .map((item) => ({ _id: item._id })),
    };
    const result = await complete_mission_schedule_api(tabData._id, postData);
    if (result.code == 200) {
      setSchedules(result.mission_schedules);
      if (tabData.content_settings?.congratulation_description) {
        setOpenModal(4);
      } else {
        enqueueSnackbar(result.message, { variant: "success" });
      }
      setIsSubmitting(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmitting(false);
    }
  };

  const handleStartMission = async () => {
    const result = await start_mission_api(missionDetail._id);
    if (result.code == 200) {
      let mission_status = "in_progress";
      let user_data = result.userMission.user_info;
      setMissionDetail((old) => ({ ...old, mission_status, user_data }));
      setSchedules(result.userMission.mission_schedules);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      setOpenMissionConfirm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setOpenMissionConfirm(false);
    }
  };

  const handleMissionStart = (unlock_coin, user_coin) => {
    if (user_coin < unlock_coin) {
      enqueueSnackbar("You do not have enough coins to start this Mission", {
        variant: "error",
      });
      return;
    }
    setUnLockCoin(unlock_coin);
    setUserCoin(user_coin);
    setOpenMissionConfirm(true);
    setTimeout(() => {
      setOpenModal(0);
    }, 100);
  };

  const handleClickStart = () => {
    if (missionDetail.mission_locked) {
      enqueueSnackbar(
        "You can't start this mission because your level does not match with the level of this mission",
        {
          variant: "error",
        }
      );
      return;
    }
    setOpenModal(5);
  };

  useEffect(() => {
    get_mission_detail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const TABS_OPTIONS = [];
  schedules.map((schedule, index) => {
    TABS_OPTIONS.push({
      title: schedule.title,
      component: (
        <>
          {schedule.schedule_status === "locked" && (
            <div className="row mt-3">
              <div className="locked-component">
                <Tooltip
                  title={`${
                    missionDetail.mission_status === "not_started"
                      ? "Start mission before unlock schedule"
                      : "Click to unlock"
                  }`}
                >
                  <img
                    src={videoShowLock}
                    alt=""
                    className="thumbnail-icon"
                    onClick={() => handleClick(schedule)}
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <DayVideoTab
            setOpenModal={setOpenModal}
            schedule={schedule}
            handleChangeAction={handleChangeAction}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
          />
        </>
      ),
    });
  });

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between challenge-video-detail-title">
              <div className="d-flex">
                <span>
                  <IconButton
                    className="back-screen-button"
                    onClick={handleBack}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </span>
                <h2 className="ms-2">{htmlDecode(tabData.main_heading)}</h2>
              </div>
              {missionDetail.mission_status === "not_started" &&
                missionDetail.content_settings?.start_mission_button_text && (
                  <div className="mb-2">
                    <button
                      className="mc-button-contained"
                      onClick={handleClickStart}
                    >
                      {
                        missionDetail.content_settings
                          ?.start_mission_button_text
                      }
                    </button>
                  </div>
                )}
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-lg-4 mb-3">
                <div className="mc-day-input">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <h6>Coins attracted</h6>
                    <div className="d-flex align-items-center">
                      <span>{`${
                        missionDetail.attracted_coins || 0
                      } coins`}</span>
                      <img src={vectorTickIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className="mc-day-input">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div>
                      <h6>Coins target</h6>
                    </div>
                    <div className="d-flex align-items-center">
                      <span>{`${missionDetail.target_coins || 0} coins`}</span>
                      <img src={vectorTickIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              {missionResult.mission_start_date && (
                <div className="col-lg-4 mb-3">
                  <div className="mc-day-input">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h6>{`You are on day ${
                        two_date_difference(
                          missionResult.mission_start_date,
                          new Date(),
                          "days"
                        ) + 1
                      } of day ${missionDetail.mission_duration} 🔥`}</h6>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-12">
            <MUICustomTabs
              data={TABS_OPTIONS}
              value={tabValue}
              handleChange={handleChange}
              className="mission-levels-schedule-tab challenge-video-days-tabs"
            />
          </div>
        </div>
      </div>
      <GeneralModelBox
        open={Boolean(openModal)}
        setOpen={setOpenModal}
        className={"challenge-video-days-questions-modal"}
        componentToPassDown={
          <>
            {openModal === 1 ? (
              <>
                <div className="questions-top-section">
                  <div
                    className="unlock-popup-cross-icon"
                    onClick={() => setOpenModal(0)}
                  >
                    x
                  </div>
                </div>
                <GeneralQuestionsList
                  created_for="mission_schedule"
                  created_for_id={tabData._id}
                  remove_back_button={true}
                  hide_dynamite_reply={true}
                  prevent_single_answer={true}
                  className="challenge-video-days-questions"
                  collapseAll={true}
                  submit_button_text={
                    tabData.question_configration?.submit_button_text
                  }
                  onSubmitSuccess={handleSuccess}
                />
              </>
            ) : openModal === 2 ? (
              openModal && (
                <CongratulationMessage
                  handleCross={() => setOpenModal(0)}
                  thanks_description={
                    tabData.question_configration?.thanks_description
                  }
                />
              )
            ) : openModal === 4 ? (
              openModal && (
                <CongratulationMessage
                  handleCross={() => setOpenModal(0)}
                  thanks_description={
                    tabData.content_settings?.congratulation_description
                  }
                />
              )
            ) : openModal === 5 ? (
              openModal && (
                <StartMission
                  handleCross={() => setOpenModal(0)}
                  missionDetail={missionDetail}
                  handleUnlock={handleMissionStart}
                  isSubmitting={isSubmitting}
                />
              )
            ) : (
              openModal && (
                <UnlockVideo
                  handleCross={() => setOpenModal(0)}
                  selectedSchedule={selectedSchedule}
                  handleUnlock={handleUnlock}
                  isSubmitting={isSubmitting}
                />
              )
            )}
          </>
        }
      />
      <CustomConfirmation
        open={openConfirm}
        setOpen={setOpenConfirm}
        title={`Unlocking this Schedule will leave you with a remaining balance of ${number_with_comma(
          userCoin - unLockCoin
        )} coins. Are you sure you want to proceed?`}
        handleAgree={handleConfirmSubmit}
        isLoading={isSubmitting}
      />
      <CustomConfirmation
        open={openMissionConfirm}
        setOpen={setOpenMissionConfirm}
        title={`Starting this Mission will leave you with a remaining balance of ${number_with_comma(
          userCoin - unLockCoin
        )} coins. Are you sure you want to proceed?`}
        handleAgree={handleStartMission}
        isLoading={isSubmitting}
      />
    </>
  );
}
