import React, { useState } from "react";
import { DiaryIcon, lifescriptIcon, mediationIcon } from "src/assets";

import { fShortenNumber } from "src/utils/formatNumber";
import FeatureVideo from "../Community/components/FeatureVideo";
// import LeaderBoard from "../Community/components/LeaderBoard";
import MentorChatCard from "../MentorShip/MentorChatCard";
import { ReactVideoPlayer } from "src/components";
import RoadMapSlider from "src/components/_dashboard/RoadMapSlider";
import { Tooltip } from "@mui/material";
import StrakPerformanceMobile from "src/components/mission_control/StrakPerformanceMobile";
import RoadMapSliderNew from "src/components/_dashboard/user/RoadMapSliderNew";
import LeaderBoard from "./LeaderBoard";

export default function MissionControl() {
  const [showChat, setShowChat] = useState(true);
  const [mentorShip, setMentorShip] = useState({
    _id: "65534421c9266c1429ace2a3",
    title: "The Art Of Achievement ",
    mentorship_category_slug: "the-art-of-achievement",
    short_description: "Empowering Personal and Professional Growth.",
    detailed_description:
      "<p>Mentorship &amp; Guidance: This category is dedicated to fostering personal and professional development through structured mentorship. Here, individuals can connect with experienced mentors who provide insightful guidance, share valuable life lessons, and offer support in navigating career paths. Whether you're starting out, seeking to enhance your skills, or looking to make a significant change in your career or personal life, our mentorship program is designed to offer tailored advice, encouragement, and the tools necessary for success. Engage in a journey of learning, growth, and empowerment, all under the guidance of those who have walked the path before you.</p>",
    image: {
      thumbnail_1:
        "mentorship_category/b643a26a-fad7-4469-b2d5-a48f7258b1a8.png",
      thumbnail_2:
        "mentorship_category/f9eb4692-2cab-4514-a558-a5e48dbfaf85.png",
    },
    status: true,
    order: 1,
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    is_default: true,
    upgrade_button_1_text: "Book Call ",
    upgrade_button_2_text: "Embark on a Simple Mentorship Path",
    upgrade_button_1_link:
      "https://sourcedev.dynamitelifestyle.com/the-evergreen-call/appointment",
    upgrade_button_2_link: "https://admindev.dynamitelifestyle.com",
    banner_1_description:
      '<p><img class="img-fluid image-border mb-4" style="display: block; margin-left: auto; margin-right: auto;" src="https://thesourcedev.dynamitelifestyle.com/static/media/1.ba734d5bbe877e4eab05.jpg" /></p>\r\n<h2 style="text-align: center;">what is Mentorship?</h2>\r\n<p style="text-align: center;">Mentorship is an incredible relationship where someone with experience, knowledge, and wisdom (the mentor) guides, advises, and supports someone less experienced (the mentee) to help them grow personally or professionally.</p>',
    banner_2_description:
      '<p><img class="img-fluid image-border mb-4" style="display: block; margin-left: auto; margin-right: auto;" src="https://thesourcedev.dynamitelifestyle.com/static/media/2.65277814446b2da6aad5.jpg" /></p>\r\n<h2 style="text-align: center;">what is Mentorship?</h2>\r\n<p style="text-align: center;">Mentorship is an incredible relationship where someone with experience, knowledge, and wisdom (the mentor) guides, advises, and supports someone less experienced (the mentee) to help them grow personally or professionally.</p>',
    sale_page: {
      payment_mode: "sandBox",
      _id: "658ebae1d465654c5e68c196",
      sale_page_title: "Test | EVERGREEN",
      sale_page_title_slug: "test-or-evergreen",
    },
    plan: "658ebaebd465654c5e68c26a",
    is_purchase_link: true,
    link: "https://source.dynamitelifestyle.com/the-art-of-achievement-1",
    link_image: "mentorship_category/72264fad-b689-4560-93ad-559add2b661f.png",
    createdAt: "14th Nov 2023",
    updatedAt: "5th Jan 2024",
  });
  const [mainVideo, setMainVideo] = useState({
    _id: "6582c1c44273dfb4ab8322b8",
    title: "Session One - Who Am I ?",
    video_url: "https://vimeo.com/825787934?share=copy",
    audio_recording: "",
    status: true,
    short_description:
      "Explore the dynamics of mentorship in 'Navigating Success: A Guide to Effective Mentorship.' This video offers essential strategies for mentors and mentees to foster growth and effective collaboration.",
    detailed_description:
      '<p>A Guide to Effective Mentorship" is an insightful video that explores the dynamic world of mentor-mentee relationships. This engaging guide provides valuable tips and strategies for both mentors and mentees, aiming to enhance their collaboration and mutual growth. The video highlights the importance of effective communication, goal setting, and the power of constructive feedback, making it an essential resource for anyone looking to enrich their professional and personal development through mentorship.</p>',
    order: 1,
    is_locked: false,
    upgrade_button_text: "Unlock Mentoring",
    is_default: true,
    slug_history: [
      "mentorship-video-add-1",
      "effective-mentorship-guide-1",
      "effective-mentorship-guide-1-p1",
      "day-1-session-one-who-am-i",
      "session-one-who-am-i",
    ],
    mentorship_category: "65534421c9266c1429ace2a3",
    image: {
      thumbnail_1: "mentorship_images/fee0aa9d-e1e7-4a53-b4af-814c6d5ed981.jpg",
      thumbnail_2: "mentorship_images/413e3ba7-89eb-4994-8d44-197fac10a3cd.jpg",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    mentorship_video_slug: "session-one-who-am-i",
    updatedAt: "2023-12-30T12:22:50.434Z",
    createdAt: "2023-12-20T10:28:20.731Z",
    __v: 55,
  });
  const [featureVideo, setFeatureVideo] = useState({
    _id: "6513badd5367b9f8ad30fc3d",
    title: "Episode 49- 7 Levels @ 7- With Brian Cassidy and Ciaran Deeney",
    status: true,
    trailer_video_url: "https://vimeo.com/919254600?share=copy",
    video_url: "https://vimeo.com/919254600?share=copy",
    order: 1,
    season_statement:
      "Episode 49- 7 Levels @ 7- With Brian Cassidy and Ciaran Deeney",
    short_description: "Series - 7 Levels @7 - Episode 49",
    detail_description: "<p>Series - 7 Levels @7 - Episode 49</p>",
    date: "2023-09-27T00:00:00.000Z",
    is_feature: true,
    thumbnail: {
      thumbnail_1: "series_videos/1d32a477-8408-4276-aadb-958ffeafed3f.jpg",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    series_category: {
      _id: "6512b5ee64e1508fb93eb0aa",
      title: "Series Category 1",
      status: true,
      short_description: "Series Category",
      detail_description: "",
      order: 5,
      action_by: "admin_user",
      action_id: "61fb68261b921d356406d82b",
      updatedAt: "2023-10-06T12:10:22.639Z",
      createdAt: "2023-09-26T10:43:58.960Z",
      __v: 0,
    },
    updatedAt: "2024-03-06T10:04:52.914Z",
    createdAt: "2023-09-27T05:17:17.488Z",
    __v: 0,
    is_favorite: false,
  });
  const [leaderboardData, setLeaderboardData] = useState([
    {
      user_info: {
        _id: "65f134260413beef8807c01f",
        name: "coins Volkman",
        profile_image: "",
        email: "test43805@gmail.com",
      },
      coins: 403335001,
      _id: "66012ac9fc43f9a67cec9aac",
    },
    {
      user_info: {
        _id: "65a66831caaa059d150ac891",
        name: "zohaib Testing",
        profile_image: "",
        email: "zohaibirshad862@gmail.com",
      },
      coins: 22131927,
      _id: "66012ac9fc43f9a67cec9aad",
    },
    {
      user_info: {
        _id: "63abd24c9afdb872cbc118e2",
        name: "Ammar Yousaf",
        profile_image: "member/977aba38-94a4-4e69-b7ed-ff381a40fef6.jpg",
        email: "y.amm4r@gmail.com",
      },
      coins: 2931361,
      _id: "66012ac9fc43f9a67cec9aae",
    },
    {
      user_info: {
        _id: "63c8df1227ad83d7fc8249b8",
        name: "Rida Fatima",
        profile_image: "member/5afea653-05dd-4032-a1e4-6c063277e8b3.jpg",
        email: "ridaf6137@gmail.com",
      },
      coins: 2627282,
      _id: "66012ac9fc43f9a67cec9aaf",
    },
    {
      user_info: {
        _id: "64915e0f817399641e05b813",
        name: "Naiya Tariq",
        profile_image: "member/54103e83-9cf6-45d1-aee1-f1d6c9304681.jpg",
        email: "kainattariq.me@gmail.com",
      },
      coins: 1383718,
      _id: "66012ac9fc43f9a67cec9ab0",
    },
    {
      user_info: {
        _id: "63abd24c9afdb872cbc118e2",
        name: "Ammar Yousaf",
        profile_image: "member/977aba38-94a4-4e69-b7ed-ff381a40fef6.jpg",
        email: "y.amm4r@gmail.com",
      },
      coins: 2931361,
      _id: "66012ac9fc43f9a67cec9aae",
    },
    {
      user_info: {
        _id: "63c8df1227ad83d7fc8249b8",
        name: "Rida Fatima",
        profile_image: "member/5afea653-05dd-4032-a1e4-6c063277e8b3.jpg",
        email: "ridaf6137@gmail.com",
      },
      coins: 2627282,
      _id: "66012ac9fc43f9a67cec9aaf",
    },
  ]);
  const [roadMap, setRoadMap] = useState([
    {
      _id: "63f768a40f44be36b5cf5e9a",
      title: "Dynamite",
      coins: "6000",
      status: true,
      order: 1,
      images: {
        thumbnail_1: "bonus_road_map/ec91835c-9b92-4769-86d4-c4557d15b898.png",
      },
      without_border_icon: {
        thumbnail_1: "bonus_road_map/aaee5f33-45d7-4eaf-bb63-f8fd2c2e4fa9.png",
      },
      icon_title: "Dynamite",
      notes_for_admin: "",
      community_level: "elite",
      createdAt: "2023-02-23T13:22:44.881Z",
      updatedAt: "2024-03-16T15:21:50.598Z",
    },
    {
      _id: "63f7cee50f44be36b5d01391",
      title: "Rocket",
      coins: "10000",
      status: true,
      order: 2,
      images: {
        thumbnail_1: "bonus_road_map/d5c04d2e-0f2e-4ea3-82ed-c3fa1928c7a6.png",
      },
      without_border_icon: {
        thumbnail_1: "bonus_road_map/91c44252-be97-4078-9bb8-4d5bdbeb8b77.png",
      },
      icon_title: "Rocket",
      notes_for_admin: "",
      community_level: "elite",
      createdAt: "2023-02-23T20:39:01.393Z",
      updatedAt: "2024-03-16T15:32:57.054Z",
    },
    {
      _id: "64059bb4e20b337e4e78ddde",
      title: "Star",
      coins: "50000",
      status: true,
      order: 3,
      images: {
        thumbnail_1: "bonus_road_map/88047f90-f077-4570-a47c-26cec0edfa4f.png",
      },
      without_border_icon: {
        thumbnail_1: "bonus_road_map/f9fa0962-f20a-451a-840c-64c9e9a3c814.webp",
      },
      icon_title: "Star ",
      notes_for_admin: "Star ",
      community_level: "elite",
      createdAt: "2023-03-06T07:52:20.593Z",
      updatedAt: "2024-03-16T15:33:23.864Z",
    },
    {
      _id: "6405b16a58d90f85dbfe5184",
      title: "Orbit",
      coins: "75000",
      status: true,
      order: 4,
      images: {
        thumbnail_1: "bonus_road_map/f0bdbe4e-0c7e-43f1-a406-953bfef1f099.jpeg",
      },
      without_border_icon: {
        thumbnail_1: "bonus_road_map/3c8e6eb8-27e1-4644-9ea8-f4eee21ae214.webp",
      },
      icon_title: "Orbit ",
      notes_for_admin: "Orbit ",
      community_level: "elite",
      createdAt: "2023-03-06T09:24:58.883Z",
      updatedAt: "2024-03-16T15:33:43.598Z",
    },
    {
      _id: "6406c485e3f5f71d18eebef8",
      title: "Supernova",
      coins: "100000",
      status: true,
      order: 5,
      images: {
        thumbnail_1: "bonus_road_map/e6aab0c7-dacd-4b2a-a95a-f046412caf01.png",
      },
      without_border_icon: {
        thumbnail_1: "bonus_road_map/18124ec7-d5a2-4089-8879-ec6b2c888a92.png",
      },
      icon_title: "Supernova",
      notes_for_admin: "Supernova",
      community_level: "elite",
      createdAt: "2023-03-07T04:58:45.902Z",
      updatedAt: "2024-03-16T15:34:07.493Z",
    },
    {
      _id: "640818e588fed68f7f914ccc",
      title: "Universal",
      coins: "150000",
      status: true,
      order: 6,
      images: {
        thumbnail_1: "bonus_road_map/86e6b5da-4651-400a-983f-510f89f9d0c0.png",
      },
      without_border_icon: {
        thumbnail_1: "bonus_road_map/892e586b-390e-456c-90a8-d85eb80404ab.webp",
      },
      icon_title: "Universal",
      notes_for_admin: "Universal",
      community_level: "elite",
      createdAt: "2023-03-08T05:11:01.786Z",
      updatedAt: "2024-03-16T15:34:26.011Z",
    },
    {
      _id: "641198e9eae23d407a179eae",
      title: "Source",
      coins: "1000000",
      status: true,
      order: 7,
      images: {
        thumbnail_1: "bonus_road_map/a722f7dd-e4b7-43e4-b69c-37c2c7fa14ad.png",
      },
      without_border_icon: {
        thumbnail_1: "bonus_road_map/5514acd7-898a-4305-9422-8a879c17703e.png",
      },
      icon_title: "Source",
      notes_for_admin: "Source",
      community_level: "elite",
      createdAt: "2023-03-15T10:07:37.749Z",
      updatedAt: "2024-03-22T10:44:33.126Z",
    },
  ]);

  let daily_dynamite = {
    icon: DiaryIcon,
    title: "Daily Dynamite",
    coins: 950000,
    day_streak: 1,
    longest_streak: 2,
    completed_streak: 3,
    consistency: 75,
    reminder: "Today's Daily Dynamite Not completed click here to complete",
  };

  let gratitude_daily = {
    icon: lifescriptIcon,
    title: "Gratitude Daily",
    coins: 750000,
    day_streak: 2,
    longest_streak: 3,
    completed_streak: 5,
    consistency: 85,
    reminder: "Today's Gratitude daily not completed click here to complete",
  };

  let meditation = {
    icon: mediationIcon,
    title: "Meditation",
    coins: 370000,
    day_streak: 3,
    longest_streak: 2,
    completed_streak: 5,
    consistency: 95,
    reminder: "Today's meditation not completed click here to complete",
  };

  const handleChangePage = (id) => {};

  return (
    <div className="new-community-page">
      <div className="row">
        <div
          className={`col-12 ${showChat ? "col-lg-8" : "mb-3"}`}
          id="video-col"
        >
          <div className="feed-image">
            <h2 className="mb-3">Session One - Who Am I ?</h2>
            <ReactVideoPlayer url="https://vimeo.com/825787934?share=copy" />
          </div>
        </div>
        {showChat && (
          <MentorChatCard
            mainVideo={mainVideo}
            banner_data={mentorShip}
            setIsLoadingPage={() => {}}
            hide_banner={true}
          />
        )}
        <div className="col-12 text-end">
          <button
            onClick={() => setShowChat(!showChat)}
            className="ms-3 comment-submit-button"
          >
            {showChat ? "Hide Chat" : "Show Chat"}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 py-4">
          <FeatureVideo
            featureVideo={featureVideo}
            handleChangePage={handleChangePage}
            show_all_time={true}
          />
        </div>
        <div className="col-12 mt-3">
          <div className="mission-control-card p-3">
            <div className="row">
              <div className="col-md-9 text-start">
                <h2>Upgrate you account</h2>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio
                tempore ea praesentium! Lorem ipsum dolor sit amet consectetur,
                adipisicing elit. Minus assumenda suscipit dicta quaerat
                eligendi pariatur sint,
              </div>
              <div className="col-md-3 mt-3 mt-md-0 upgrade-account-box">
                <button className="small-contained-button">Upgrate Now</button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <RoadMapSliderNew data={roadMap} />
        </div>
        <div className="col-12 col-md-8">
          <div className="row">
            <div className="col-12 col-lg-6 mt-3 d-flex">
              <div className="mission-control-card p-3 w-100">
                <div className="d-flex justify-content-between total-coins">
                  <span>
                    <Tooltip title="Dynamite badge">
                      <img src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/road_map_levels/96ab90ac-ce09-4035-86e3-3ce50c3dc8d3.jpeg" />
                    </Tooltip>
                  </span>
                  <h3>{fShortenNumber(950000)}</h3>
                  <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
                </div>
                <div className="more-coins">
                  Get <span>10k</span> more coins to reach to next level PTA
                </div>
                <div className="coins-slider mt-3">
                  <div className="coins-slider-outer">
                    <div
                      className="coins-slider-inner"
                      style={{
                        width: "48%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-3 d-flex">
              <div className="mission-control-card p-3 w-100">
                <div className="d-flex justify-content-between total-coins">
                  <span className="current-level">Earned Badges</span>
                  <span className="more-icon">More</span>
                </div>
                <div className="d-flex justify-content-between mt-5">
                  <div className="badge-icon">
                    <img
                      src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/badge/ae7a5ba1-f7b0-42b2-b27a-4d4c0ebdc6ed.webp"
                      alt=""
                    />
                  </div>
                  <div className="badge-icon">
                    <img
                      src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/badge/95cdd829-8644-4294-bece-3b2afaeafbde.jpg"
                      alt=""
                    />
                  </div>
                  <div className="badge-icon">
                    <img
                      src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/badge/e3864807-9878-412e-bb17-38b6b119a25c.jpg"
                      alt=""
                    />
                  </div>
                  <div className="badge-icon">
                    <img
                      src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/badge/42462ca6-ce55-415f-b551-eed3f011ea52.png"
                      alt=""
                    />
                  </div>
                  <div className="badge-icon">
                    <img
                      src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/badge/4cf07bf7-d76f-466d-a4f0-22d7bd200c89.jpg"
                      alt=""
                    />
                  </div>
                  <div className="badge-icon">
                    <img
                      src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/badge/b2ae8d3b-8d5c-45cd-b6d0-5094eec4d7ce.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3">
              <StrakPerformanceMobile {...daily_dynamite} />
              {/* <div className="d-none d-lg-block">
            <StrakPerformanceDesktop {...daily_dynamite} />
          </div>
          <div className="d-block d-lg-none">
            <StrakPerformanceMobile {...daily_dynamite} />
          </div> */}
            </div>
            <div className="col-12 mt-3">
              <StrakPerformanceMobile {...gratitude_daily} />
              {/* <div className="d-none d-lg-block">
            <StrakPerformanceDesktop {...gratitude_daily} />
          </div>
          <div className="d-block d-lg-none">
            <StrakPerformanceMobile {...gratitude_daily} />
          </div> */}
            </div>
            <div className="col-12 mt-3">
              <StrakPerformanceMobile {...meditation} />
              {/* <div className="d-none d-lg-block">
            <StrakPerformanceDesktop {...meditation} />
          </div>
          <div className="d-block d-lg-none">
            <StrakPerformanceMobile {...meditation} />
          </div> */}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 mt-3">
          <div className="mission-control-card  p-3">
            <div className="d-flex justify-content-between">
              <span className="challenge-heading">
                Your Continued Challenges
              </span>
            </div>
            <hr />
            <div className="challenges-card">
              <div className="challenge-item">
                <div className="challenge-title">The Bob Proctor Tribute</div>
                <div className="coins-slider mt-1">
                  <div
                    className="coins-slider-outer"
                    style={{
                      height: "5px",
                    }}
                  >
                    <div
                      className="coins-slider-inner"
                      style={{
                        width: "43%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="challenge-item mt-3">
                <div className="challenge-title">
                  The Entrepreneurial GPS Code
                </div>
                <div className="coins-slider mt-1">
                  <div
                    className="coins-slider-outer"
                    style={{
                      height: "5px",
                    }}
                  >
                    <div
                      className="coins-slider-inner"
                      style={{
                        width: "75%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="challenge-item mt-3">
                <div className="challenge-title">
                  Inception 2023 Full Access
                </div>
                <div className="coins-slider mt-1">
                  <div
                    className="coins-slider-outer"
                    style={{
                      height: "5px",
                    }}
                  >
                    <div
                      className="coins-slider-inner"
                      style={{
                        width: "25%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="challenge-item mt-3">
                <div className="challenge-title">The Source Starting Point</div>
                <div className="coins-slider mt-1">
                  <div
                    className="coins-slider-outer"
                    style={{
                      height: "5px",
                    }}
                  >
                    <div
                      className="coins-slider-inner"
                      style={{
                        width: "75%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <LeaderBoard
              title={"Monthly Leader Board"}
              data={leaderboardData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
