import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { get_sales_listing } from "src/DAL/Dashboard/Dashboard";
import CustomMUITable from "src/components/CustomMUITable/CustomMUITable";
import { convertCurrencyToSign } from "src/utils/constants";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function TotalSales() {
  const [totalSales, setTotalSales] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const get_sales_listings = async () => {
    const result = await get_sales_listing();
    console.log(result, "resultresult");
    if (result.code === 200) {
      let data = result.total_sales.map((item) => {
        return {
          ...item,
          name: item.member
            ? item.member.first_name
              ? item.member.first_name + " " + item.member.last_name
              : "N/A"
            : "N/A",
          transaction_dates: item.transaction_date
            ? moment(item.transaction_date).format("DD-MM-YYYY")
            : "N/A",
          cradit:
            convertCurrencyToSign(item?.currency) +
            item?.referral_commission?.toFixed(2),
        };
      });
      setTotalSales(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },

    {
      id: "name",
      label: "User Name",
      alignRight: false,
      className: "text-capitalize",
    },
    {
      id: "transaction_dates",
      label: "Transaction Date",
      alignRight: false,
      className: "text-capitalize",
    },
    {
      id: "cradit",
      label: "Credit",
      alignRight: false,
      className: "text-capitalize",
    },
  ];
  useEffect(() => {
    get_sales_listings();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="mb-3 mt-4">
        <h2>Sales</h2>
      </div>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={totalSales}
        className="card-with-background"
        hide_search={true}
      />
    </>
  );
}

export default TotalSales;
