import React from "react";
import { Card, CircularProgress } from "@mui/material";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import { s3baseUrl } from "src/config/config";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function ChallengeListCard({
  data,
  handleClick,
  MENU_OPTIONS,
  handleEdit,
  isLoading,
}) {
  const classes = useStyles();

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="row">
      {data.map((item, index) => {
        console.log(item, "itemeeeeeeeeeee");
        return (
          <div className={(item.card_classes, "mt-3")} key={index}>
            <Card
              className="side-image-mui-card"
              onClick={
                item.is_completed == false && handleClick
                  ? () => {
                      handleClick(item);
                    }
                  : undefined
              }
            >
              <div className="row p-3">
                <div className="col-lg-2 col-md-3 col-sm-12">
                  <img
                    className="side-image-mui-card-image"
                    src={s3baseUrl + item?.image?.thumbnail_1}
                    alt={item.title}
                  />
                </div>

                <div className="col-lg-8 col-md-9 col-sm-12">
                  <h4 className="side-image-mui-card-heading">{item.title}</h4>
                  <p className="normal-font">
                    {htmlDecode(item.intention_statement)}
                  </p>
                  <p className="text-muted">
                    {item.is_completed == false ? (
                      <>
                        <PanoramaFishEyeIcon /> Pending
                      </>
                    ) : (
                      <>
                        <CheckCircleIcon className="me-1 complete-Status" />
                        Completed
                      </>
                    )}
                  </p>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-12 d-flex justify-content-end">
                  {item.is_completed == true ? (
                    <CustomPopover menu={MENU_OPTIONS} data={item} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
}

export default ChallengeListCard;
