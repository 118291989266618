import React, { useState, useEffect } from "react";
import { TextField, FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { slots_list_api, update_bookings_api } from "src/DAL/Booking/booking";

export default function UpdateBooking({
  selectedBooking,
  dataList,
  onCloseDrawer,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    selected_slot: {},
  });
  const [timeSlotList, setTimeSlotList] = useState([]);
  const [startDateMin, setStartDateMin] = useState(new Date());

  const handleChange = (value, name) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var startdateString = moment(state.date).format("YYYY/MM/DD");
    const data = {
      date: startdateString,
      slot_id: state.selected_slot.slot_id,
      time: state.selected_slot.start_time,
    };

    const result = await update_bookings_api(selectedBooking._id, data);
    if (result.code === 200) {
      dataList();
      setIsLoading(false);
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getTimeListing = async () => {
    const dateString = moment(state.date).format("YYYY/MM/DD");
    let data = {
      date: dateString,
      booking_id: selectedBooking._id,
    };
    const result = await slots_list_api(data);
    if (result.code === 200) {
      setTimeSlotList(result.slots);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    setState(selectedBooking);
  }, [selectedBooking]);

  useEffect(() => {
    getTimeListing();
  }, [state.date]);

  return (
    <div className="container new-memories">
      <form onSubmit={handleSubmit}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="DD-MM-YYYY"
            mask="__/__/____"
            inputFormat="DD-MM-YYYY"
            minDate={startDateMin}
            label="Date"
            name="date"
            value={state.date}
            onChange={(e) => {
              setState(() => {
                return {
                  date: e.$d,
                  selected_slot: null,
                };
              });
            }}
            renderInput={(params) => (
              <TextField {...params} className="mt-3" required={true} />
            )}
          />
        </LocalizationProvider>
        <FormControl fullWidth className="mt-3">
          <Autocomplete
            id="time-slots"
            options={timeSlotList}
            value={state.selected_slot}
            getOptionLabel={(time) => time.start_time + " - " + time.end_time}
            getOptionSelected={(option, value) =>
              option.slot_id === value.slot_id
            }
            onChange={(e, value) => handleChange(value, "selected_slot")}
            renderInput={(params) => (
              <TextField {...params} label="Time Slots*" variant="outlined" />
            )}
            required
          />
        </FormControl>

        <div className="d-flex justify-content-between mt-3">
          <div className="booking-timezone ps-2">
            {selectedBooking.time_zone && <p>{selectedBooking.time_zone} </p>}
          </div>
          <button className="submit-button" disabled={isLoading}>
            {isLoading ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
