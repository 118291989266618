import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { s3baseUrl } from "src/config/config";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useNavigate } from "react-router-dom";
import { set_favourite_video } from "src/DAL/Community/Community";
import { useSnackbar } from "notistack";

const CommunitySliderImages = ({ data }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (id, action) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("action", action);
    formData.append("series_video", id);
    const result = await set_favourite_video(formData);
    if (result.code === 200) {
      if (action === "favorite") {
        data.is_favorite = true;
      } else {
        data.is_favorite = false;
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChangePage = (id) => {
    navigate(`/series-video/${id}`);
  };

  return (
    <div className="slider-box increase-scale" key={data._id}>
      <img
        src={s3baseUrl + data.thumbnail.thumbnail_1}
        className="w-100"
        onClick={() => {
          handleChangePage(data._id);
        }}
      />
      {data.is_favorite ? (
        <div className={`${isLoading ? "favourite-loading" : "star-icon"}`}>
          {isLoading ? (
            <StarBorderIcon />
          ) : (
            <StarIcon
              onClick={() => {
                handleSubmit(data._id, "unfavorite");
              }}
            />
          )}
        </div>
      ) : (
        <div className={`${isLoading ? "star-icon favourite-loading" : ""}`}>
          {isLoading ? (
            <StarIcon />
          ) : (
            <StarBorderIcon
              onClick={() => {
                handleSubmit(data._id, "favorite");
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default CommunitySliderImages;
