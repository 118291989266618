import React from "react";
import RecordNotFound from "../GeneralComponents/RecordNotFound";
import RequestsList from "./RequestsList";

export default function RequestsCardComponent({
  data,
  notFoundTitle,
  signButton,
}) {
  return (
    <div className="row">
      {data.length > 0 ? (
        <div className="col-12 mt-3">
          <RequestsList data={data} signButton={signButton} />
        </div>
      ) : (
        <RecordNotFound title={notFoundTitle} />
      )}
    </div>
  );
}
