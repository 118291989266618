import { Avatar, AvatarGroup, CircularProgress, Slider } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactVideoPlayer } from "src/components";
import { visioneering_challenge_list_api } from "src/DAL/ChallengeApi/Visioneering";
import { get_root_value } from "src/utils/domUtils";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import moment from "moment";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import { cointlogo } from "src/assets";
import {
  challenge_member_list_api,
  meditation_challenge_list_api,
} from "src/DAL/ChallengeApi/meditationApi";
import { s3baseUrl } from "src/config/config";
import CustomListPopup from "src/components/ListPopup";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function LifesscriptList() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isMemberLoadingLoading, setIsMemberLoadingLoading] = useState(true);
  const [challengeList, setChallengeList] = useState([]);
  const [totalMemberList, setTotalMemberList] = useState([]);
  const [totalCoins, setTotalCoins] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [challengeId, setChallengeId] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [memberArray, setMemberArray] = useState([]);
  const [loadMore, setLoadMore] = useState(
    `api/challenge/list_people_doing/${challengeId}?limit=10&page=0`
  );

  const handleClickOpen = (scrollType, value) => () => {
    setChallengeId(value._id);
    setOpen(true);
    setScroll(scrollType);
  };

  function valuetext(value) {
    return `${value}°C`;
  }
  let mem = [
    {
      first_name: "Muneeb",
      first_payment_date: "2023-07-04",
      goal_statement_completed_date: "2023-07-04 11:39:23",
      last_name: "Ali (SQA)",
      profile_image: "member/801005a3-fc33-43b0-870b-9574bef4d188.jpg",
      wheel_of_life_completed_date: "2023-07-04",
    },
    {
      first_name: "Muneeb",
      first_payment_date: "2023-07-04",
      goal_statement_completed_date: "2023-07-04 11:39:23",
      last_name: "Ali (SQA)",
      profile_image: "member/801005a3-fc33-43b0-870b-9574bef4d188.jpg",
      wheel_of_life_completed_date: "2023-07-04",
    },
    {
      first_name: "Muneeb",
      first_payment_date: "2023-07-04",
      goal_statement_completed_date: "2023-07-04 11:39:23",
      last_name: "Ali (SQA)",
      profile_image: "member/801005a3-fc33-43b0-870b-9574bef4d188.jpg",
      wheel_of_life_completed_date: "2023-07-04",
    },
    {
      first_name: "Muneeb",
      first_payment_date: "2023-07-04",
      goal_statement_completed_date: "2023-07-04 11:39:23",
      last_name: "Ali (SQA)",
      profile_image: "member/801005a3-fc33-43b0-870b-9574bef4d188.jpg",
      wheel_of_life_completed_date: "2023-07-04",
    },
    {
      first_name: "Muneeb",
      first_payment_date: "2023-07-04",
      goal_statement_completed_date: "2023-07-04 11:39:23",
      last_name: "Ali (SQA)",
      profile_image: "member/801005a3-fc33-43b0-870b-9574bef4d188.jpg",
      wheel_of_life_completed_date: "2023-07-04",
    },
    {
      first_name: "Muneeb",
      first_payment_date: "2023-07-04",
      goal_statement_completed_date: "2023-07-04 11:39:23",
      last_name: "Ali (SQA)",
      profile_image: "member/801005a3-fc33-43b0-870b-9574bef4d188.jpg",
      wheel_of_life_completed_date: "2023-07-04",
    },
    {
      first_name: "Muneeb",
      first_payment_date: "2023-07-04",
      goal_statement_completed_date: "2023-07-04 11:39:23",
      last_name: "Ali (SQA)",
      profile_image: "member/801005a3-fc33-43b0-870b-9574bef4d188.jpg",
      wheel_of_life_completed_date: "2023-07-04",
    },
    {
      first_name: "Muneeb",
      first_payment_date: "2023-07-04",
      goal_statement_completed_date: "2023-07-04 11:39:23",
      last_name: "Ali (SQA)",
      profile_image: "member/801005a3-fc33-43b0-870b-9574bef4d188.jpg",
      wheel_of_life_completed_date: "2023-07-04",
    },
    {
      first_name: "Muneeb",
      first_payment_date: "2023-07-04",
      goal_statement_completed_date: "2023-07-04 11:39:23",
      last_name: "Ali (SQA)",
      profile_image: "member/801005a3-fc33-43b0-870b-9574bef4d188.jpg",
      wheel_of_life_completed_date: "2023-07-04",
    },
    {
      first_name: "Muneeb",
      first_payment_date: "2023-07-04",
      goal_statement_completed_date: "2023-07-04 11:39:23",
      last_name: "Ali (SQA)",
      profile_image: "member/801005a3-fc33-43b0-870b-9574bef4d188.jpg",
      wheel_of_life_completed_date: "2023-07-04",
    },
    {
      first_name: "Muneeb",
      first_payment_date: "2023-07-04",
      goal_statement_completed_date: "2023-07-04 11:39:23",
      last_name: "Ali (SQA)",
      profile_image: "member/801005a3-fc33-43b0-870b-9574bef4d188.jpg",
      wheel_of_life_completed_date: "2023-07-04",
    },
    {
      first_name: "Muneeb",
      first_payment_date: "2023-07-04",
      goal_statement_completed_date: "2023-07-04 11:39:23",
      last_name: "Ali (SQA)",
      profile_image: "member/801005a3-fc33-43b0-870b-9574bef4d188.jpg",
      wheel_of_life_completed_date: "2023-07-04",
    },
  ];

  const getChallengeListing = async () => {
    const result = await meditation_challenge_list_api("lifeScript");
    if (result.code === 200) {
      setChallengeList(result.challenge);
      setTotalCoins(result.challange_coins_count_attracted);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getChallengeMember = async (load) => {
    setIsMemberLoadingLoading(load ? false : true);
    if (open == false) {
    } else {
      if (challengeId) {
        const result = await challenge_member_list_api(
          challengeId,
          pageNumber,
          10
        );
        if (result.code == 200) {
          var newArray = load
            ? memberArray.concat(result.people_doing)
            : result.people_doing;

          setMemberArray(newArray);
          setPageNumber(result.next_page);
          setLoadMorePath(result.load_more);
          setTotalPages(result.total_pages);
          setIsLoadingMore(false);
          setIsMemberLoadingLoading(false);

          //   setLoadMore(result.load_more);
          //   enqueueSnackbar(result.message, { variant: "success" });
        } else {
          setIsMemberLoadingLoading(false);
          setIsLoadingMore(false);
          enqueueSnackbar(result.message, { variant: "error" });
        }
      }
    }
  };
  const getChallengeMemberLoadMore = async () => {
    if (challengeId) {
      const result = await challenge_member_list_api(
        challengeId,
        pageNumber,
        10
      );

      if (result.code == 200) {
        var newArray = memberArray.concat(result.people_doing);
        setMemberArray(newArray);
        setPageNumber(result.next_page);
        setLoadMorePath(result.load_more);
        setTotalPages(result.total_pages);
        setIsLoadingMore(false);

        //   setLoadMore(result.load_more);
        //   enqueueSnackbar(result.message, { variant: "success" });
      } else {
        setIsLoadingMore(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  useEffect(() => {
    if (open) {
      if (challengeId !== "" && open === true) {
        getChallengeMember();
      }
    } else {
      setPageNumber(0);
      setMemberArray([]);
    }
  }, [challengeId, open]);

  useEffect(() => {
    getChallengeListing();
  }, []);
  useEffect(() => {
    if (!open) {
      setPageNumber(0);
      setMemberArray([]);
    }
  }, [open]);

  const hanleChangePage = (id) => {
    navigate(`/money-script/${id}`);
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(challengeList, "challengeList");

  return (
    <div className="container">
      <div className="row pt-70" id="assessment-container">
        <div className="col-6 mb-4">
          <PageDescription
            parameter="money_script_description"
            else_title="Script Challenge"
          />
        </div>
        <div className="col-lg-6 ">
          <div className="d-flex text-align">
            <img className="coint-img img-fluid" src={cointlogo}></img>
            <h4 className="coint-detail">
              Total Coins Attracted: {totalCoins}
            </h4>
          </div>
        </div>
      </div>
      {challengeList.length > 0 ? (
        challengeList.map((item, index) => {
          let Days = [
            {
              value: 0,
              label: "Day 1",
            },

            {
              value: 100,
              label: `Day ${item?.challange_info?.no_of_days}`,
            },
          ];
          //showing days in total
          let challange_start_date = moment(
            item?.challange_info?.challange_start_date,
            "YYYY-MM-DD"
          );
          let complete_date = moment().add("day", 1);

          if (item.challange_info?.is_complete) {
            complete_date = moment(
              item.challange_info?.challange_completed_date,
              "YYYY-MM-DD"
            ).add("day", 1);
          }
          const no_of_days = complete_date.diff(challange_start_date, "days");

          return (
            <div className="row" key={index}>
              <div className="col-lg-6 col-md-6">
                {item.challange_intro_video_url ? (
                  <ReactVideoPlayer url={item.challange_intro_video_url} />
                ) : (
                  <img
                    src={s3baseUrl + item.challange_image.thumbnail_1}
                    className="img-fluid"
                  />
                )}
              </div>
              <div className="col-lg-6 col-md-6 text-md-start text-center mt-md-0 mt-4 visioneering-box">
                <div
                  className="assetssment-para visioneering-list-description"
                  onClick={() => {
                    hanleChangePage(item._id);
                  }}
                >
                  <h3>{item.title}</h3>
                  <p>{item.short_description}</p>
                </div>
                {item.challange_info?.challange_completed_days ? (
                  <>
                    <div className="d-flex justify-content-between">
                      <div className="challenge-date">
                        <p>
                          <span>Start Date</span> :{" "}
                          {moment(
                            item.challange_info.challange_start_date
                          ).format("DD-MM-YYYY")}
                        </p>
                      </div>
                      <div className="challenge-day-in-tatal">
                        <p>
                          Days in Total:{" "}
                          {no_of_days + "/" + item?.challange_info?.no_of_days}
                        </p>
                      </div>
                    </div>
                    <div class="habits-slider d-flex justify-content-between mt-4 mb-5">
                      <div class="silder-value habits-gray-description">
                        <span>{`${Math.round(
                          (item.challange_info.challange_completed_days /
                            item?.challange_info?.no_of_days) *
                            100
                        )}%`}</span>
                      </div>
                      <Slider
                        style={{
                          color: get_root_value("--portal-theme-primary"),
                        }}
                        valueLabelFormat={`${
                          item.challange_info.challange_completed_days > 1
                            ? "Days"
                            : "Day"
                        } ${item.challange_info.challange_completed_days}`}
                        aria-label="Custom marks"
                        defaultValue={
                          (item.challange_info.challange_completed_days /
                            item?.challange_info?.no_of_days) *
                          100
                        }
                        marks={Days}
                        valueLabelDisplay="on"
                        getAriaValueText={valuetext}
                        step={10}
                        className="mui-slider"
                        disabled
                      />
                    </div>
                    <div className="text-end view-challenge-button-box">
                      {!item.challange_info.is_complete && (
                        <button
                          className="view-challenge-button float-end mt-2"
                          onClick={() => {
                            hanleChangePage(item._id);
                          }}
                        >
                          View Challenge
                        </button>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="text-end view-challenge-button-box">
                    <button
                      className="view-challenge-button ms-4"
                      onClick={() => {
                        hanleChangePage(item._id);
                      }}
                    >
                      Accept Challenge
                    </button>
                  </div>
                )}
                <div className="bottom-fixed-avatar pe-3">
                  <AvatarGroup
                    onClick={handleClickOpen("paper", item)}
                    className="cursor"
                    total={item?.total_people_doing_this}
                    sx={{
                      "& .MuiAvatar-root": {
                        width: 24,
                        height: 24,
                        fontSize: 12,
                      },
                    }}
                  >
                    {item.people_doing?.length > 0 &&
                      item.people_doing.map((val) => {
                        return (
                          <Avatar
                            sx={{ width: 24, height: 24 }}
                            alt={val.first_name}
                            src={s3baseUrl + val.profile_image}
                          />
                        );
                      })}
                  </AvatarGroup>
                </div>
              </div>
              <div className="my-2">
                <hr />
              </div>
            </div>
          );
        })
      ) : (
        <RecordNotFound title="Challenge Not Found" />
      )}
      <CustomListPopup
        memberArray={memberArray}
        open={open}
        setOpen={setOpen}
        scroll={scroll}
        setScroll={setScroll}
        handleClickOpen={handleClickOpen}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        setTotalPages={setTotalPages}
        totalPages={totalPages}
        getChallengeMember={getChallengeMember}
        loadMorePath={loadMorePath}
        setLoadMorePath={setLoadMorePath}
        isLoadingMore={isLoadingMore}
        setIsLoadingMore={setIsLoadingMore}
        isLoading={isMemberLoadingLoading}
      />
    </div>
  );
}
