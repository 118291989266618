import React from "react";
import Chip from "@mui/material/Chip";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import VideocamIcon from "@mui/icons-material/Videocam";
import { s3baseUrl } from "src/config/config";
import { Avatar } from "@mui/material";
import { string_avatar } from "src/utils/constants";
import LiveTvIcon from "@mui/icons-material/LiveTv";

function OpenCreatePostCard({
  handleOpenSimpleBox,
  setFeedType,
  userInfo,
  handleOpenLiveVideoBox,
  handleOpenImageBox,
  handleOpenVideoBox,
}) {
  return (
    <div className="open-create-post-card mb-2 mt-3 mt-lg-0">
      <div className="profile-cards p-3 community-card-title wealth-wallet">
        <div className="input-with-profile d-flex">
          {/* <img src={s3baseUrl + userInfo?.profile_image} alt="" /> */}
          <Avatar src={s3baseUrl + userInfo?.profile_image} alt="photoURL">
            {string_avatar(
              userInfo?.last_name
                ? userInfo?.first_name + " " + userInfo.last_name
                : userInfo?.first_name
            )}
          </Avatar>
          {userInfo?.first_name ? (
            <div className="input-field ms-2" onClick={handleOpenSimpleBox}>
              What's on your mind, {userInfo.first_name}?
            </div>
          ) : (
            <div className="input-field ms-2" onClick={handleOpenSimpleBox}>
              What's on your mind?
            </div>
          )}
        </div>
        <hr className="line" />
        <div className="row">
          <div
            className={`${
              userInfo.is_executive ? "col-4" : "col-6"
            } col-md-4 col-lg-4 mt-sm-0 mt-md-0  main-icon-conatiner`}
          >
            <div className="upload-video upload-video-update">
              <Chip
                onClick={handleOpenVideoBox}
                label={
                  <>
                    <span className="main-icon-content">Upload Video</span>
                    <VideocamIcon />
                  </>
                }
                variant="outlined"
              />
            </div>
          </div>
          <div
            className={`${
              userInfo.is_executive ? "col-4 main-icon-conatiner" : "col-6"
            } col-md-4 col-lg-4 mt-sm-0 mt-md-0  `}
          >
            <div className="upload-video upload-video-update">
              <Chip
                onClick={handleOpenImageBox}
                label={
                  <>
                    <span className="main-icon-content">Upload Image</span>
                    <PhotoCameraIcon />
                  </>
                }
                variant="outlined"
              />
            </div>
          </div>
          <div
            className={`${
              userInfo.is_executive ? "col-4" : "col-6"
            } col-md-4 col-lg-4 mt-sm-0 mt-md-0`}
          >
            <div
              className={`upload-video upload-video-update ${
                userInfo.is_executive ? "" : "force-disabled"
              }`}
            >
              <Chip
                onClick={() => {
                  if (userInfo.is_executive) {
                    handleOpenLiveVideoBox();
                  }
                }}
                label={
                  <>
                    <span className="main-icon-content">Livestream</span>
                    <LiveTvIcon />
                  </>
                }
                variant="outlined"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenCreatePostCard;
