import { get_kmb_number } from "../../../utils/formatNumber";

export default function TotalCoinsCount({ coins_count, title }) {
  return (
    <div className="dashboard-card w-100 pb-3">
      <div className="d-flex justify-content-between">
        <div>
          <p className="title">{title}</p>
          <div className="main-box text-section">
            <h3>
              {title === "Total Commission" && "£"}
              {get_kmb_number(coins_count)}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
