import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import PendingRequests from "./PendingRequests";
import { useLocation, useParams } from "react-router-dom";
import { requestDetailApi } from "src/DAL/Payment/Payment";

export default function PendingRequestsPayment() {
  const { state } = useLocation();
  const params = useParams();
  const { stripeKey, stripeKeyObject } = useContentSetting();
  let [key, setKey] = useState("");
  const getRequestDetail = async () => {
    const result = await requestDetailApi(params.request_slug);
    if (result.code === 200) {
      handleGetStrpeKey(result.payment_request.payment_mode);
    }
  };
  const handleGetStrpeKey = (payment_mode) => {
    let PaymentRequestStripeKey;
    if (payment_mode === "sandBox") {
      setKey(stripeKeyObject.sandBox_publish_key);
    } else {
      setKey(stripeKeyObject.live_publish_key);
    }
    window.history.replaceState({}, document.title);
  };

  useEffect(() => {
    if (state) {
      handleGetStrpeKey(state.payment_mode);
    } else {
      getRequestDetail();
    }
  }, []);
  return (
    <Elements stripe={loadStripe(key)}>
      <PendingRequests />
    </Elements>
  );
}
