import { Outlet, Navigate, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
// components
import ReactGA from "react-ga";
import { member_init_without_token } from "src/DAL/ContentSetting/ContentSetting";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

// ----------------------------------------------------------------------

export default function FullPageLayout() {
  const { handleStripeKey } = useContentSetting();
  const [isLoadingApp, setIsLoadingApp] = useState(true);
  const location = useLocation();

  const handleGetContentData = async () => {
    const result = await member_init_without_token();
    if (result.code === 200) {
      localStorage.setItem(
        `page_content`,
        JSON.stringify(result.content_setting.client_content_setting)
      );
      localStorage.setItem(
        `project_info`,
        JSON.stringify(result.content_setting.client_general_setting)
      );

      // ReactGA.initialize(
      //   result.content_setting.client_general_setting.analytic_google_code,
      //   { debug: true }
      // );

      if (result.site_setting.stripe_mode == "sandBox") {
        handleStripeKey(result.site_setting.sandBox_publish_key);
      } else {
        handleStripeKey(result.site_setting.live_publish_key);
      }

      // ReactGA.pageview(location.pathname + location.search);
      setIsLoadingApp(false);
    } else {
      setIsLoadingApp(false);
    }
  };

  useEffect(() => {
    handleGetContentData();
  }, []);

  // useEffect(() => {
  //   ReactGA.pageview(location.pathname + location.search);
  // }, [location]);

  if (isLoadingApp) {
    return (
      <>
        <CircularProgress className="centered" color="primary" />
      </>
    );
  }

  return (
    <>
      <Outlet />
    </>
  );
}
