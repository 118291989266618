import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "../../config/config";
import { ReactVideoPlayer } from "src/components";
import { useSnackbar } from "notistack";
import {
  StudySessionRecordingDetailApi,
  LessonRecordingDetail,
} from "../../DAL/Programmes/Programmes";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import { meditation_detail } from "src/DAL/Meditations/Meditations";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function MeditationDetail(props) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [meditationData, seMeditationData] = React.useState({});
  const handleNavigate = () => {
    navigate(-1);
  };

  const getMeditationDetail = async () => {
    setIsLoading(true);
    const result = await meditation_detail(params.meditation_slug);
    if (result.code === 200) {
      seMeditationData(result.meditation);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/meditation-categories`);
    }
  };

  useEffect(() => {
    getMeditationDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin ">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button"
              onClick={() => handleNavigate()}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="programmes-heading ms-2">
            {htmlDecode(meditationData.title)}
          </h2>
        </div>
      </div>

      <div className="row media-margin">
        <div className="col-12">
          {meditationData.video_url ? (
            <ReactVideoPlayer url={meditationData.video_url} />
          ) : (
            <img
              src={s3baseUrl + meditationData.image.thumbnail_1}
              alt=""
              srcSet=""
              className="w-100"
            />
          )}
        </div>
        {meditationData.audio_recording && (
          <div className="col-12 section-space">
            <audio
              controlsList="nodownload"
              className="w-100"
              src={s3baseUrl + meditationData.audio_recording}
              controls
            />
          </div>
        )}
        <div className="col-12 section-space">
          <p
            dangerouslySetInnerHTML={{
              __html: meditationData.detailed_description,
            }}
          ></p>
        </div>
      </div>
    </div>
  );
}

export default MeditationDetail;
