import React, { useEffect, useState } from "react";
import {
  List,
  Collapse,
  ListItemText,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import CircleIcon from "@mui/icons-material/Circle";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  matchPath,
  useLocation,
  NavLink as RouterLink,
} from "react-router-dom";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { toolsLockIcon } from "src/assets";
import ChangePassword from "src/layouts/dashboard/ChangePassword";
import { s3baseUrl } from "src/config/config";

export default function NavSectionItem({ data }) {
  const { userInfo, wheelOfLifeSetting, loginInfo } = useContentSetting();
  const { pathname } = useLocation();
  const [openDropdown, setOpenDropdown] = useState(true);
  const [openPasswordModal, setopenPasswordModal] = useState(false);

  const match = ({ path, matches }) => {
    if (matches) {
      let is_active = false;
      matches.forEach((match_path) => {
        const match = match_path
          ? !!matchPath({ path: match_path, end: false }, pathname)
          : false;

        if (match) {
          is_active = true;
        }
      });
      return is_active;
    }
    return path ? !!matchPath({ path, end: false }, pathname) : false;
  };

  const handle_tooltip = () => {
    if (loginInfo.wheel_of_life) {
      if (data.path !== "/questions") {
        return wheelOfLifeSetting.menu_text_on_lock_modules;
      }
    }
  };

  const handle_component = (option, component) => {
    const { path, value, is_link, is_access } = option;
    if (is_access == false || is_link || value === "change_password") {
      return "";
    }
    let return_var = path;
    if (loginInfo.wheel_of_life) {
      if (path === "/questions" && component) {
        return_var = RouterLink;
      } else if (component) {
        return_var = "";
      }
    } else if (path) {
      if (component) {
        return_var = RouterLink;
      }
    }
    return return_var;
  };

  const handle_open_link = (data) => {
    if (data.is_open_new_tab) {
      window.open(data.path);
    } else {
      window.open(data.path, "_self");
    }
  };

  const handle_click = (option) => {
    const { is_access, value, is_link, child_options } = option;
    if (value === "change_password" && !loginInfo.wheel_of_life) {
      setopenPasswordModal(true);
    } else if (is_access !== false) {
      if (is_link && !loginInfo.wheel_of_life) {
        handle_open_link(option);
      } else if (child_options?.length > 0) {
        setOpenDropdown(!openDropdown);
      }
    }
  };

  const RenderComponent = ({ option, is_child }) => {
    const { path, matches, title, icon, is_access, child_options, is_link } =
      option;

    return (
      <Tooltip title={handle_tooltip()}>
        <ListItemButton
          component={handle_component(option, true)}
          target={option.is_open_new_tab ? "_blank" : undefined}
          to={handle_component(option)}
          onClick={() => handle_click(option)}
          sx={{ pl: is_child ? 7 : 4, fontSize: "14px" }}
          className={`menus-list ${
            match({ path: path, matches: matches }) ? "menuActive" : ""
          } `}
        >
          {icon && (
            <img
              src={s3baseUrl + icon}
              width={28}
              height={28}
              className="navbar-icon"
            />
          )}
          <ListItemText
            primaryTypographyProps={{ fontSize: "14px" }}
            primary={title}
          />
          {is_access == false && (
            <img src={toolsLockIcon} width={22} height={22} />
          )}

          {path === "/self-image" &&
            userInfo.self_image_status === "responded" && (
              <Tooltip title="You have a reply to your self image">
                <CircleIcon className="goal_statement_sidebar" />
              </Tooltip>
            )}
          {path === "/goal-statement" &&
            userInfo.save_and_close_status === true && (
              <Tooltip title="You have a reply to your goal statement">
                <CircleIcon className="goal_statement_sidebar" />
              </Tooltip>
            )}
          {child_options && (openDropdown ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
      </Tooltip>
    );
  };

  useEffect(() => {
    //On reload dropdown should not close
    data.child_options?.map((child_option) => {
      if (child_option.path === pathname) {
        setOpenDropdown(true);
      }
    });
  }, []);

  return (
    <>
      <Tooltip title={handle_tooltip()}>
        <RenderComponent option={data} />
      </Tooltip>

      {/* This will only work in case of dropdown*/}
      {data.child_options && (
        <Collapse in={openDropdown} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {data.child_options.map((child_option) => {
              return <RenderComponent option={child_option} is_child={true} />;
            })}
          </List>
        </Collapse>
      )}
      <ChangePassword
        openPasswordModal={openPasswordModal}
        setopenPasswordModal={setopenPasswordModal}
      />
    </>
  );
}
