import { useSnackbar } from "notistack";
import React from "react";
import { useState } from "react";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import {
  AddNote,
  DeleteNote,
  EditNote,
} from "src/DAL/Programmes/Lessons/Notes/Notes";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";

export default function LessonNoteTab({
  notesList,
  lesson_slug,
  progran_slug,
  getLesonDetail,
}) {
  const [isLoadingNotes, setIsLoadingNotes] = useState(false);
  const [inputLessonNote, setInputLessonNote] = useState("");
  const [noteId, setNoteId] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo, adminTimeZone } = useContentSetting();
  const [showButtons, setShowButtons] = useState({
    addButton: true,
    editButton: false,
  });

  //Changing Textarea values with onchange
  const handleNoteChange = (event) => {
    const value = event.target.value;
    setInputLessonNote(value);
  };
  //Adding Note
  const handleSubmit = async (e) => {
    setIsLoadingNotes(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("lesson_note", inputLessonNote);
    formData.append("lesson_slug", lesson_slug);
    formData.append("program_slug", progran_slug);
    setInputLessonNote("");
    const result = await AddNote(formData);
    if (result.code === 200) {
      setInputLessonNote("");
      getLesonDetail();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setTimeout(() => {
      setIsLoadingNotes(false);
    }, 500);
  };

  //Getting Note In textarea
  const handleEdit = (note) => {
    setInputLessonNote(note.lesson_note);
    setNoteId(note._id);
    setShowButtons({
      addButton: false,
      editButton: true,
    });

    const notes = document.getElementById("notes-input");
    setTimeout(() => {
      notes.scrollIntoView();
      notes.focus();
    }, 500);
  };

  const handleCancelUpdate = () => {
    setInputLessonNote("");
    setNoteId("");
    setShowButtons({
      addButton: true,
      editButton: false,
    });
  };

  const handleAgreeDelete = (note) => {
    setNoteId(note._id);
    setOpenDelete(true);
  };

  //Updating Note
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoadingNotes(true);
    const formData = new FormData();
    formData.append("lesson_note", inputLessonNote);
    const result = await EditNote(noteId, formData);
    if (result.code === 200) {
      getLesonDetail();
      setInputLessonNote("");
      setNoteId(0);
      setShowButtons({
        addButton: true,
        editButton: false,
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsLoadingNotes(false);
  };

  //Deleting Note
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteNote(noteId);
    if (result.code === 200) {
      getLesonDetail();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  return (
    <>
      <div className="row">
        {notesList.map((note, index) => (
          <div className="col-12 section-space" key={note._id}>
            <div className="card">
              <div className="card-body notes-input set-display-inline">
                <CustomPopover menu={MENU_OPTIONS} data={note} />
                <p className="mb-0 normal-text">
                  {htmlDecode(note.lesson_note)}
                </p>
                <p className="lesson-notes-title mt-2 mb-1 date-color">
                  {get_date_with_user_time_zone(
                    note.lesson_note_date,
                    "DD-MM-YYYY hh:mm:ss",
                    userInfo.time_zone,
                    adminTimeZone
                  )}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <form onSubmit={showButtons.addButton ? handleSubmit : handleUpdate}>
            <span
              htmlFor="exampleFormControlInput1"
              className="form-label-lesson"
            >
              Lesson Notes:
            </span>
            <textarea
              required
              rows="5"
              id="notes-input"
              className="form-control text-area-task mt-2"
              value={inputLessonNote}
              onChange={handleNoteChange}
              // onFocus={inputLessonNote}
            />
            {showButtons.addButton === true && (
              <button
                type="submit"
                className="mt-2 float-end small-contained-button"
                disabled={isLoadingNotes}
              >
                {isLoadingNotes ? "Saving..." : "Save"}
              </button>
            )}
            {showButtons.editButton === true && (
              <div className="d-flex justify-content-end">
                <button
                  className="mt-2 me-2 small-contained-button"
                  onClick={handleCancelUpdate}
                >
                  Cancel
                </button>
                <button
                  className="mt-2 small-contained-button"
                  type="submit"
                  disabled={isLoadingNotes}
                >
                  {isLoadingNotes ? "Updating..." : "Update"}
                </button>
              </div>
            )}
            <CustomConfirmation
              open={openDelete}
              setOpen={setOpenDelete}
              title={"Are you sure you want to delete this note?"}
              handleAgree={handleDelete}
            />
          </form>
        </div>
      </div>
    </>
  );
}
