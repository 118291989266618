import React, { useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import moment from "moment";
import {
  AddnineteenDayNewEarning,
  EditNinetyDayEarning,
} from "../../DAL/90Day/NinetyDay";
import { useSnackbar } from "notistack";
import { get_root_value } from "src/utils/domUtils";
import { addDaysToDate, convertCurrencyToSign } from "src/utils/constants";
import { InputAdornment } from "@mui/material";
import { _get_user_from_localStorage } from "src/DAL/localStorage/localStorage";
import NumberFormat from "react-number-format";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const ManageEarnings = ({
  editData,
  onCloseDrawer,
  reloadListing,
  formType,
  startDate,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useContentSetting();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    earning: "",
    date: new Date(),
    description: "",
  });

  const handleChange = (event) => {
    const inputName = event.target.name;
    const value = event.target.value;
    setInputs((inputs) => ({
      ...inputs,
      [inputName]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const postData = {
      earning: inputs.earning,
      date: moment(inputs.date).format("YYYY-MM-DD"),
      description: inputs.description,
    };
    const result =
      formType === "ADD"
        ? await AddnineteenDayNewEarning(postData)
        : await EditNinetyDayEarning(postData, editData._id);
    if (result.code == 200) {
      onCloseDrawer();
      reloadListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (formType == "EDIT") {
      setInputs(editData);
    } else {
      setInputs({
        earning: "",
        date: new Date(),
        description: "",
      });
    }
  }, [formType]);

  return (
    <div className="container">
      <div className="row">
        <form onSubmit={handleSubmit}>
          <div className="col-12 mt-4">
            <NumberFormat
              value={inputs?.earning}
              customInput={TextField}
              decimalScale={0}
              sx={{ input: { color: get_root_value("--input-text-color") } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {convertCurrencyToSign(userInfo.nineteen_day_plan_currency)}
                  </InputAdornment>
                ),
              }}
              type="text"
              name="earning"
              className="inputs-fields"
              label="Today's Earning"
              thousandSeparator={true}
              fullWidth
              required
              onValueChange={({ value: v }) =>
                setInputs((inputs) => ({
                  ...inputs,
                  ["earning"]: v,
                }))
              }
            />
          </div>
          <div className="col-12 mt-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                format="DD-MM-YYYY"
                mask="__/__/____"
                inputFormat="DD-MM-YYYY"
                value={inputs?.date}
                fullWidth
                minDate={new Date(startDate)}
                maxDate={addDaysToDate(startDate, 90)}
                onChange={(value) => {
                  setInputs((inputs) => ({
                    ...inputs,
                    ["date"]: value?.$d,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className="text-area-task inputs-fields"
                    name="date"
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
            <TextField
              className="text-area-task inputs-fields"
              fullWidth
              id="outlined-multiline-static"
              label="Add Description"
              multiline
              rows={4}
              name="description"
              value={inputs?.description}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-12 text-end">
            <button
              className="small-contained-button mt-4 mb-4"
              disabled={isLoading}
            >
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ManageEarnings;
