import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { CircularProgress, IconButton } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { get_member_event_detail_by_slug } from "src/DAL/LiveEvents/LiveEvents";
import { s3baseUrl } from "src/config/config";
import { handle_change_page } from "src/utils/constants";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function LiveEventsLockedDescription(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const [data, setData] = useState([]);

  const memberEventDetail = async () => {
    setIsLoading(true);
    const result = await get_member_event_detail_by_slug(params.id);
    if (result.code === 200) {
      console.log(result, "inside member");
      setData(result.member_dynamite_event);
      setIsLoading(false);
    }
  };
  const handleNavigate = () => {
    let button_link = data?.lock_configration?.lock_event_button_link;
    console.log("button_linkbutton_linkbutton_link", button_link);
    if (button_link) {
      handle_change_page(button_link, setIsLoading);
    }
  };

  useEffect(() => {
    if (state) {
      setData(state);
    } else {
      memberEventDetail();
    }
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(data, "data for lock");
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-2"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div
        className="dashboard_description"
        dangerouslySetInnerHTML={{
          __html: data?.lock_configration
            ? data.lock_configration.lock_event_description
            : "",
        }}
      ></div>
      <div className="col-12 mt-4 text-end">
        {data?.lock_configration?.detail_event_button_text && (
          <button className="small-contained-button" onClick={handleNavigate}>
            {data?.lock_configration?.detail_event_button_text}
          </button>
        )}
      </div>
    </div>
  );
}

export default LiveEventsLockedDescription;
