import { TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

export default function ShopPopup({ product, setOpenLink }) {
  console.log(product, "product");
  const navigate = useNavigate();
  const handleCheckOut = () => {
    setOpenLink(false);
    navigate(`/shop/shopping-checkout`);
  };
  const handleShoppingCart = () => {
    setOpenLink(false);
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <img
              src={s3baseUrl + product.image[0].image}
              alt="product"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-lg-7 ps-0 mt-3">
            <h2 class="d-inline">{product.name}</h2>
            <p
              style={{ color: "white", marginTop: "3px", marginBottom: "14px" }}
            >
              was Successfully added to your cart !
            </p>
            <button
              className="small-contained-button  mobile-btn"
              onClick={handleShoppingCart}
            >
              Continue Shopping
            </button>
            <button
              className="small-contained-button add-to-cart-button mt-1 ms-2 mobile-btn-2"
              onClick={handleCheckOut}
            >
              CheckOut
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
