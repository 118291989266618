import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import TicketList from "src/components/_dashboard/SupportTickets/TicketList";
import SupportTicketFilter from "../../components/SupportTickets/SupportTicketFilter";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { tickestsListAPI } from "src/DAL/SupportTicket/SupportTicket";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddOrUpdateTickets from "../../components/SupportTickets/AddOrUpdateTickets";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import SupportTicketsTabs from "src/components/_dashboard/SupportTickets/SupportTicketsTabs";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function SupportTicket() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [drawerState, setDrawerState] = useState(false);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [tickestData, setTickestData] = useState([]);
  const [filterBy, setFilterBy] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState("all");
  const [tabValue, setTabValue] = useState(0);
  const [Open, setOpen] = useState("");
  const { state } = useLocation();
  const [Answered, setAnswered] = useState(" ");
  const [CloseTab, setCloseTab] = useState(true);
  const [waitingTab, setwaitingTab] = useState(true);
  const [Solved, setSolved] = useState("");
  const [fixesCount, setfixesCount] = useState(" ");
  const [reminder, setReminder] = useState(" ");
  const handleChanges = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const getTickestsListing = async (filterBy) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("filter_by", filterBy);
    const result = await tickestsListAPI(formData);
    if (result.code === 200) {
      setTickestData(result.support_ticket);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const myfunction = (value) => {
    navigate(`/support-ticket/${value}`);
  };

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };
  const TABS_OPTIONS = [
    {
      title: `Open (${Open == undefined ? 0 : Open})`,
      component: (
        <SupportTicketsTabs
          tabValue={tabValue}
          setOpen={setOpen}
          setAnswered={setAnswered}
          setSolved={setSolved}
          valueData={"open"}
          waitingTab={waitingTab}
        />
      ),
    },
    // {
    //   title: `Answered (${Answered == undefined ? 0 : Answered})`,
    //   component: (
    //     <SupportTicketsTabs
    //       setOpen={setOpen}
    //       setAnswered={setAnswered}
    //       setSolved={setSolved}
    //       tabValue={tabValue}
    //       valueData={"answered"}
    //     />
    //   ),
    // },
    {
      title: `Solved (${Solved == undefined ? 0 : Solved})`,
      component: (
        <SupportTicketsTabs
          setOpen={setOpen}
          setAnswered={setAnswered}
          setSolved={setSolved}
          tabValue={tabValue}
          valueData={"solved"}
          CloseTab={CloseTab}
        />
      ),
    },
  ];

  useEffect(() => {
    // getTickestsListing(filterBy);
  }, []);
  useEffect(() => {
    if (state) {
      setTabValue(state);
      window.history.replaceState({}, document.title);
    }
  }, [state]);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-sm-12 col-md-6">
          <PageDescription
            parameter="support_ticket_page_title"
            else_title="Support Tickets"
          />
        </div>
        <div className="col-sm-12 col-md-6 text-end">
          <button
            className="small-contained-button me-2 mt-1 mb-4"
            onClick={handleOpenDrawer}
          >
            New Ticket
          </button>
        </div>

        <div className="col-12">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChanges}
          />
        </div>
      </div>
      {/* <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <SupportTicketFilter
            value={selectedValue}
            handleChange={handleChange}
            dataList={getTickestsListing}
            setSelectedValue={setSelectedValue}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      /> */}
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="New Ticket"
        componentToPassDown={
          <AddOrUpdateTickets
            formType="ADD"
            dataList={getTickestsListing}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />
    </div>
  );
}

export default SupportTicket;
