import {
  Link as RouterLink,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
// components
import Logo from "../components/Logo";
import ReactGA from "react-ga";
import { member_init_without_token } from "src/DAL/ContentSetting/ContentSetting";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const [isLoadingApp, setIsLoadingApp] = useState(true);
  const location = useLocation();

  const handleGetContentData = async () => {
    const result = await member_init_without_token();
    if (result.code === 200) {
      localStorage.setItem(
        `page_content`,
        JSON.stringify(result.content_setting.client_content_setting)
      );
      localStorage.setItem(
        `project_info`,
        JSON.stringify(result.content_setting.client_general_setting)
      );

      // ReactGA.initialize(
      //   result.content_setting.client_general_setting.analytic_google_code,
      //   { debug: true }
      // );

      // ReactGA.pageview(location.pathname + location.search);
      setIsLoadingApp(false);
    } else {
      setIsLoadingApp(false);
    }
  };

  useEffect(() => {
    handleGetContentData();
  }, []);

  // useEffect(() => {
  //   ReactGA.pageview(location.pathname + location.search);
  // }, [location]);

  if (isLoadingApp) {
    return (
      <>
        <CircularProgress className="centered" color="primary" />
      </>
    );
  }

  if (localStorage.getItem("token")) {
    return <Navigate to="/mission-control"> </Navigate>;
  }

  return (
    <>
      <HeaderStyle>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
