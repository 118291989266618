import {
  CircularProgress,
  IconButton,
  Slider,
  TextareaAutosize,
} from "@mui/material";
import { useState, useEffect } from "react";
import "react-range-slider-input/dist/style.css";
import { get_root_value } from "src/utils/domUtils";
import { ReactVideoPlayer } from "src/components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import SeekVideoPlayer from "src/components/ReactPlayers/SeekVideoPlayer";
import {
  get_challange_history_by_member_api,
  update_challange_history_by_member_api,
} from "src/DAL/ChallengeApi/Visioneering";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import moment from "moment";
import MUIStaticCalendar from "src/components/GeneralComponents/MUIStaticCalendar";
import CongratulationAnimation from "src/components/challenges/CongratulationAnimation";
import CreatePost from "src/components/challenges/CreatePost";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import CongratulationsPopup from "src/components/challenges/CongratulationsPopup";
import { projectMode, s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

let EMPTY_INPUTS = {
  note_1: "",
  note_2: "",
};

function Visioneering() {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [challengeDate, setChallengeDate] = useState(new Date());
  const [challengeData, setChallengeData] = useState({});
  const [rewardData, setRewardData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [openCongratulation, setOpenCongratulation] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoPlayed, setVideoPlayed] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const classes = useStyles();

  const [inputs, setInputs] = useState(EMPTY_INPUTS);

  const handleDuration = (duration) => {
    setVideoDuration(duration);
  };

  const handleOpenCreateModal = () => {
    setOpenCongratulation(false);
    setOpenCreateModal(true);
  };

  const deadline = moment(
    challengeData?.challange_info?.challange_end_date
  ).add(1, "day");
  const getTime = (deadline) => {
    const time = deadline ? Date.parse(deadline) - Date.now() : "";
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const getOneDigit = (digi) => {
    let reminder = digi % 10;
    let deviser = digi / 10;
    let decimalPart = Math.floor(deviser);
    return { reminder, decimalPart };
  };

  const handleChangeDate = (value) => {
    setChallengeDate(value.$d);
  };

  const getChallengeDetail = async (isCompleting) => {
    const formData = {
      challange: params.visioneering_id,
      challange_date: moment(challengeDate).format("YYYY-MM-DD"),
    };
    const result = await get_challange_history_by_member_api(formData);
    if (result.code === 200) {
      setChallengeData(result.challenge);
      if (result.challenge.challange_history?._id) {
        setInputs(result.challenge.challange_history);
      } else {
        setInputs(EMPTY_INPUTS);
      }
      setIsLoading(false);
      setTimeout(() => {
        if (isCompleting) {
          setIsCompleted(false);
          setOpenCongratulation(true);
        }
      }, 3000);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsCompleted(false);
    }
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = {
      challange: params.visioneering_id,
      challange_date: moment(challengeDate).format("YYYY-MM-DD"),
      note_1: inputs.note_1,
      note_2: inputs.note_2,
    };
    const result = await update_challange_history_by_member_api(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      let is_completed = false;
      if (
        result.data?.is_reward_assigned &&
        result.challenge_reward_content.enable_reward_setting
      ) {
        setRewardData({ ...result.data, ...result.challenge_reward_content });
        setIsCompleted(true);
        setIsLoading(false);
        is_completed = true;
      }
      getChallengeDetail(is_completed);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getChallengeDetail();
  }, [params.visioneering_id, challengeDate]);

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 100);
    return () => clearInterval(interval);
  }, [challengeData]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let challange_start_date = moment(
    challengeData?.challange_info?.challange_start_date,
    "YYYY-MM-DD"
  );
  let complete_date = moment();
  if (challengeData.challange_info?.is_complete) {
    complete_date = moment(
      challengeData.challange_info?.challange_completed_date,
      "YYYY-MM-DD"
    );
  }
  const no_of_days = complete_date.diff(challange_start_date, "days");

  const Days = [
    {
      value: 0,
      label: "Day 1",
    },

    {
      value: 100,
      label: `Day ${challengeData?.number_of_days}`,
    },
  ];

  return (
    <>
      <div className="assessment-wrapper">
        <div className="container">
          <div className="row pt-4">
            <div className="col-lg-6">
              <IconButton
                className="back-screen-button"
                onClick={() => navigate("/visioneering")}
              >
                <ArrowBackIcon />
              </IconButton>
              <span className="title-with-back-button">
                {challengeData.title}
              </span>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-lg-7 col-md-6">
              {challengeData.challange_intro_video_url ? (
                <ReactVideoPlayer
                  url={challengeData.challange_intro_video_url}
                />
              ) : (
                <img
                  src={s3baseUrl + challengeData.challange_image.thumbnail_1}
                  className="img-fluid"
                />
              )}
            </div>

            <div className="col-lg-5 col-md-6 mt-md-0 mt-4">
              <div className="d-flex calender w-100 full-open-calender">
                <MUIStaticCalendar
                  selectedDate={challengeDate}
                  handleChangeDate={handleChangeDate}
                  minDate={
                    challengeData.challange_info.challange_start_date
                      ? challengeData.challange_info.challange_start_date
                      : new Date()
                  }
                  maxDate={new Date()}
                />
              </div>
            </div>
            {challengeData.challange_intro_video_description && (
              <div
                className="programme-card-desc"
                dangerouslySetInnerHTML={{
                  __html: challengeData.challange_intro_video_description,
                }}
              ></div>
            )}
          </div>
        </div>
        <div className="container">
          {challengeData.challange_video_url && (
            <div className="text-center mt-5">
              <SeekVideoPlayer
                url={challengeData.challange_video_url}
                handleDuration={handleDuration}
                setVideoPlayed={setVideoPlayed}
                visioneering_id={params.visioneering_id}
              />
            </div>
          )}
          {challengeData?.challange_info?.challange_end_date && (
            <>
              {new Date(
                challengeData?.challange_info?.challange_end_date
              ).getTime() >= new Date().getTime() && (
                <div className="text-start label-text pt-5 countdown-timer">
                  <h4>Countdown Timer</h4>
                </div>
              )}
              <div className="row justify-content-between">
                {new Date(
                  challengeData?.challange_info?.challange_end_date
                ).getTime() >= new Date().getTime() && (
                  <div className="col-md-7 pt-3">
                    <div className="timer-count d-flex justify-content-between">
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(days).decimalPart}
                        </div>
                      </div>
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(days).reminder}
                        </div>
                      </div>
                      <div className="colon">:</div>
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(hours).decimalPart}
                        </div>
                      </div>
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(hours).reminder}
                        </div>
                      </div>
                      <div className="colon">:</div>
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(minutes).decimalPart}
                        </div>
                      </div>
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(minutes).reminder}
                        </div>
                      </div>
                      <div className="colon">:</div>
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(seconds).decimalPart}
                        </div>
                      </div>
                      <div className="time-count-container">
                        <div className="count">
                          {getOneDigit(seconds).reminder}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between time-container-site">
                      <div className="time-detail">Days</div>
                      <div className="time-detail">Hours</div>
                      <div className="time-detail">Minutes</div>
                      <div className="time-detail">Seconds</div>
                    </div>
                  </div>
                )}

                {challengeData?.challange_info?.challange_completed_days && (
                  <div className="col-12 ps-lg-2 ps-md-1 mt-4">
                    <div className="d-flex align-items-end">
                      <div className="w-100 ms-2">
                        <div className="text-end days-in-total-text">
                          <p>
                            <span>Days in Total : </span>
                            {no_of_days +
                              1 +
                              "/" +
                              challengeData?.number_of_days}
                          </p>
                        </div>
                        <div class="habits-slider d-flex justify-content-between">
                          {/* <div class="silder-value habits-gray-description">
                        <span>{`${Math.round(
                          (challengeData.challange_info
                            .challange_completed_days /
                            challengeData.number_of_days) *
                            100
                        )}%`}</span>
                      </div> */}
                          <Slider
                            style={{
                              color: get_root_value("--portal-theme-primary"),
                            }}
                            aria-label="Custom marks"
                            valueLabelFormat={`${
                              challengeData.challange_info
                                .challange_completed_days > 1
                                ? "Days"
                                : "Day"
                            } ${
                              challengeData.challange_info
                                .challange_completed_days
                            }`}
                            defaultValue={
                              (challengeData.challange_info
                                .challange_completed_days /
                                challengeData.number_of_days) *
                              100
                            }
                            marks={Days}
                            valueLabelDisplay="on"
                            getAriaValueText={valuetext}
                            step={10}
                            className="mui-slider mt-4"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {!challengeData.challange_info?.is_complete ? (
            <>
              <div className="text-start label-text pt-5">
                <h4>Intuitive insights</h4>
              </div>
              <form form onSubmit={handleSubmit}>
                <div className="row mt-4">
                  <div className="col-md-6 visioneering-textarea">
                    <label className="mb-2">
                      Ideas I became aware of in my visioneering session
                    </label>
                    <TextareaAutosize
                      aria-label="empty textarea"
                      placeholder="Notes *"
                      className="text-color textarea-autosize"
                      sx={{ color: "white" }}
                      minRows={4}
                      required
                      name="note_1"
                      value={inputs.note_1}
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        background: "#141717",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "15px",
                        color: "white",
                        fontSize: "15px",
                        border: "2px solid rgba(71, 66, 34, 0.3)",
                      }}
                    />
                  </div>
                  <div className="col-md-6 ps-lg-5 visioneering-textarea">
                    <label className="mb-2">
                      Actions I am going to take in this awareness{" "}
                    </label>
                    <TextareaAutosize
                      aria-label="empty textarea"
                      placeholder="Notes *"
                      className="text-color textarea-autosize"
                      sx={{ color: "white" }}
                      name="note_2"
                      value={inputs.note_2}
                      onChange={handleChange}
                      required
                      minRows={4}
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        background: "#141717",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "15px",
                        color: "white",
                        fontSize: "15px",
                        border: "2px solid rgba(71, 66, 34, 0.3)",
                      }}
                    />
                  </div>
                  <div className="col-md-12 mt-4">
                    <div className="text-end visioneer-btn mark-complete-check-box-lable">
                      <button>
                        Mark Complete For Today
                        <input type="checkbox" id="MarkComplete" checked />
                        <label htmlFor="MarkComplete"></label>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <div className="row">
              <div className="col-12 mt-4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: challengeData.challenge_completion_message,
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isCompleted && <CongratulationAnimation rewardData={rewardData} />}
      <CreatePost
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        rewardData={rewardData}
      />

      <GeneralModelBox
        open={openCongratulation}
        setOpen={setOpenCongratulation}
        componentToPassDown={
          <CongratulationsPopup
            handleOpenCreateModal={handleOpenCreateModal}
            rewardData={rewardData}
          />
        }
      />
    </>
  );
}

export default Visioneering;
