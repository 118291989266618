import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CircularProgress, Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { UserListHead } from "src/components/_dashboard/user";
import SearchNotFound from "src/components/SearchNotFound";
import moment from "moment";
import { filter } from "lodash";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  DeleteSupportTicket,
  get_list_support_ticket_with_pagination,
  markResolved,
} from "src/DAL/SupportTicket/SupportTicket";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import AddOrUpdateTickets from "src/components/SupportTickets/AddOrUpdateTickets";
import CustomPopovers from "src/components/GeneralComponents/CustomPopovers";
import Label from "src/components/Label";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function SupportTicketsTabs({
  tabValue,
  valueData,
  setAnswered,
  setSolved,
  setOpen,
  CloseTab,
  waitingTab,
}) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [drawerState, setDrawerState] = useState(false);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState();
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [order, setOrder] = useState("asc");
  const [totalPages, setTotalPages] = useState(0);
  const [orderBy, setOrderBy] = useState("number");
  const [openDelete, setOpenDelete] = useState(false);
  const [userList, setUserList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [editData, setEditData] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [ticketId, setTicketId] = useState("");
  const [openResolved, setOpenResolved] = useState(false);
  const [memberCounts, setmemberCounts] = useState("");
  const [memberId, setMemberId] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const is_trash_path = window.location.pathname.includes(
    "trash-support-ticket"
  );
  const path = window.location.pathname;
  const { userInfo, adminTimeZone } = useContentSetting();
  const [filterNameStatus, setFilterNameStatus] = useState(false);
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const getTickestsListing = async (value, filterBy) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      if (is_trash_path) {
        formData.append("filter_by", "trash");
      } else {
        if (value == undefined || value == "") {
          formData.append("filter_by", "all");
          if (memberId) {
            formData.append("member_id", memberId);
          }
        } else {
          formData.append("filter_by", value);
        }
      }
      const result = await get_list_support_ticket_with_pagination(
        valueData,
        page,
        rowsPerPage,
        filterName
      );
      if (result.code === 200) {
        setmemberCounts(result.total_count);
        setSolved(result.solved_ticket_count),
          setOpen(result.open_ticket_count),
          setAnswered(result.answered_ticket_count);
        console.log(result, "result");
        const data = result.support_ticket.map((ticket, index) => {
          let time =
            ticket?.resolve_date !== null
              ? moment.utc(ticket?.resolve_date).format("YYYY-MM-DD hh:mm")
              : "empty";
          let timeCreated = moment
            .utc(ticket.support_ticket_date)
            .format("YYYY-MM-DD HH:mm");
          let actionTime = moment
            .utc(ticket.last_action_date)
            .format("YYYY-MM-DD hh:mm");
          let reminder_time =
            ticket.reminder_date_time !== null
              ? moment.utc(ticket.reminder_date_time).format("YYYY-MM-DD hh:mm")
              : "empty";
          console.log(timeCreated, "timeCreated");
          return {
            id: ticket._id,
            createdAt: get_date_with_user_time_zone(
              timeCreated,
              "YYYY-MM-DD HH:mm",
              userInfo.time_zone,
              adminTimeZone
            ),
            name: ticket?.subject,
            userName: ticket?.member,
            status: ticket?.status,
            department: ticket?.department,
            resolve_date: time,
            response_status: ticket?.response_status,
            subject: ticket?.subject,
            ticket_status: ticket?.ticket_status,
            last_activity_date_time: get_date_with_user_time_zone(
              actionTime,
              "YYYY-MM-DD HH:mm",
              userInfo.time_zone,
              adminTimeZone
            ),
            reminder_status: ticket.is_reminder,
            reminder_date: reminder_time,
            object: ticket,
            count: index + 1 + rowsPerPage * page,
          };
        });
        setTotalCount(result?.total_count);
        setTotalPages(result?.total_pages);
        setUserList(data);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };
  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy)
    // filterName
  );
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOpenDrawer = (value) => {
    setDrawerState(true);
    setTicketId(value.id);
    setReminderMessage(value);
  };
  const isUserNotFound = filteredUsers.length === 0;
  const hanleChangePage = (value) => {
    navigate(`/support-ticket/${value.id}`, {
      state: { value, tabValue },
    });
  };
  const handleNavigate = () => {
    setMemberId("");
    navigate("/support-ticket", { state: tabValue });
  };
  const handleAgreeDelete = (value) => {
    setTicketId(value.id);
    setOpenDelete(true);
  };
  const handleEdit = (value) => {
    setEditData(value);
    setEditDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };
  const handleMarkResolvedPopup = (value) => {
    setTicketId(value.id);
    setOpenResolved(true);
  };
  const MENU_OPTIONS1 = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: hanleChangePage,
    },
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Mark Resolve",
      icon: "emojione-monotone:heavy-check-mark",
      handleClick: handleMarkResolvedPopup,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const MENU_OPTIONS = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: hanleChangePage,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const menuHandling = (item) => {
    let menu_options = MENU_OPTIONS;
    if (valueData == "open" || valueData == "answered") {
      menu_options = MENU_OPTIONS1;
    } else {
      menu_options = MENU_OPTIONS;
    }

    return menu_options;
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteSupportTicket(ticketId);
    if (result.code === 200) {
      getTickestsListing("all");
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleMarkResolved = async () => {
    setOpenResolved(false);
    setIsLoading(true);
    const result = await markResolved(ticketId);
    if (result.code === 200) {
      getTickestsListing("all");
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/support-ticket`);
    }
  };
  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false },
    { id: "subject", label: " Subject", alignRight: false },
    { id: "created", label: "Created", alignRight: false },
    { id: "created", label: "Status", alignRight: false },
    { id: "action", label: "Action", alignCenter: true },
  ];
  const resolve_date = {
    id: "created",
    label: "Resolved Date",
    alignRight: false,
  };
  if (CloseTab) {
    TABLE_HEAD.splice(3, 1, resolve_date);
  }
  console.log(CloseTab, "CloseTabCloseTab");
  useEffect(() => {
    getTickestsListing(is_trash_path ? "trash" : "");
  }, [is_trash_path, rowsPerPage, page, path]);
  useEffect(() => {
    if (!filterNameStatus) {
      return;
    }
    getMemberListing();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  moment.tz.setDefault(userInfo.time_zone);

  return (
    <div className="container mt-4">
      <div className="row mobile-margin display-flex">
        {params.member_id ? (
          <div className="col-12">
            <IconButton
              className="back-screen-button mb-4"
              onClick={handleNavigate}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
        ) : (
          ""
        )}

        <Card style={{ overflowX: "auto" }} className="card">
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const {
                      id,
                      name,
                      createdAt,
                      count,
                      subject,
                      resolve_date,
                      response_status,
                      ticket_status,
                    } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="left">{count}</TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className="cursor-pointer"
                          onClick={() => hanleChangePage(row)}
                        >
                          {subject ? subject : "N/A"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className="cursor-pointer"
                          onClick={() => hanleChangePage(row)}
                        >
                          {moment(createdAt, "YYYY-MM-DD HH:mm").fromNow()}
                        </TableCell>

                        {waitingTab && (
                          <TableCell
                            align="left"
                            className="cursor-pointer"
                            onClick={() => hanleChangePage(row)}
                          >
                            <Label
                              variant="ghost"
                              // color={status === false ? "error" : "success"}
                              className={
                                ticket_status === 0 && response_status == 1
                                  ? "answer-ticket"
                                  : ticket_status == 1
                                  ? "solved-ticket"
                                  : ticket_status == 2
                                  ? "trash-ticket"
                                  : "pending-ticket"
                              }
                            >
                              {ticket_status === 0 && response_status == 1
                                ? "Answer"
                                : ticket_status == 1
                                ? " Solved"
                                : ticket_status == 2
                                ? "Trash"
                                : "waiting"}
                            </Label>
                          </TableCell>
                        )}
                        {CloseTab && (
                          <TableCell
                            component="th"
                            scope="row"
                            className="cursor-pointer"
                            onClick={() => hanleChangePage(row)}
                          >
                            {resolve_date !== "empty"
                              ? get_date_with_user_time_zone(
                                  resolve_date,
                                  "YYYY-MM-DD hh:mm A",
                                  userInfo.time_zone,
                                  adminTimeZone
                                )
                              : "N/A"}
                          </TableCell>
                        )}
                        <TableCell style={{ textAlign: "left" }}>
                          {
                            <CustomPopovers
                              menu={menuHandling(row)}
                              data={row}
                            />
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[2, 5, 10, 20, 50]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Pagination
            count={totalPages}
            page={pageCount}
            defaultPage={0}
            onChange={handleChangePages}
            className="pagination-style"
          />
        </Card>
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Ticket?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openResolved}
        setOpen={setOpenResolved}
        title={"Are you sure you want to mark this ticket as resolved?"}
        handleAgree={handleMarkResolved}
      />
      <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Edit Support Ticket"
        componentToPassDown={
          <AddOrUpdateTickets
            editData={editData}
            formType="EDIT"
            dataList={getTickestsListing}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      />
    </div>
  );
}
export default SupportTicketsTabs;
