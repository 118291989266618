import React, { useState } from 'react'
import CustomDrawer from 'src/components/GeneralComponents/CustomDrawer'
import PageDescription from 'src/components/GeneralComponents/PageDescription';
import AddOrUpdateHabit from 'src/components/Habits/AddOrUpdateHabit';
import HabitDetail from 'src/components/Habits/HabitDetail';
import HabitsMainCard from 'src/components/Habits/HabitsMainCard'

export default function HabitsList() {
    const [drawerState, setDrawerState] = useState(false);
    const [detailDrawerState, setDetailDrawerState] = useState(false);
    const [selectedObject, setSelectedObject] = useState({});
    const [formType, setFormType] = useState("ADD");
    const handleOpenDrawer = () => {
        setDrawerState(true);
    };

    const handleCloseDrawer = () => {
        setDrawerState(false);
        setFormType("ADD");
    };
    const handleOpenDetailDrawer = (data) => {
        setSelectedObject(data)
        setDetailDrawerState(true);
    };

    const handleCloseDetailDrawer = () => {
        setDetailDrawerState(false);
    };

    const data = [
        {
            title: 'Code & Sleep',
            image: 'https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/lesson/5bd08532-5b6d-42d2-8f2b-7cefe4345bd3.png',
            target_date: '21 Oct 2026',
            start_date: '11 Oct 2022',
            reminder: '11 50 AM',
            selected_days: [
                "Sun",
                "Mon",
                "Tue",
            ],
            progress: '20',
            habit_type: 'To-Do',
        },
        {
            title: 'Water The Plants',
            image: 'https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/lesson/5bd08532-5b6d-42d2-8f2b-7cefe4345bd3.png',
            target_date: '21 Oct 2023',
            start_date: '21 Oct 2022',
            reminder: '11 50 AM',
            selected_days: [
                "Sun",
                "Mon",
                "Tue",
            ],
            progress: '40',
            habit_type: 'To-Do',
        },
        {
            title: 'Walk Daily',
            image: 'https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/lesson/5bd08532-5b6d-42d2-8f2b-7cefe4345bd3.png',
            target_date: '21 Oct 2024',
            start_date: '21 Oct 2022',
            reminder: '11 50 AM',
            selected_days: [
                "Sun",
                "Mon",
                "Tue",
            ],
            progress: '60',
            habit_type: 'Not-To-Do',
        }
    ]
    return (
        <>
            <div className='container'>
                <PageDescription parameter="habit_page_top_description" else_title="Habits Trackers" />
                <div className="row">
                    <div className="col-12">
                        <button
                            className="small-contained-button float-end mt-1 mb-4"
                            onClick={handleOpenDrawer}
                        >
                            Add New Habit
                        </button>
                    </div>
                </div>
                <HabitsMainCard data={data} handleClick={handleOpenDetailDrawer} />
            </div>
            <CustomDrawer
                isOpenDrawer={drawerState}
                onOpenDrawer={handleOpenDrawer}
                onCloseDrawer={handleCloseDrawer}
                pageTitle="Add New Habit"
                componentToPassDown={
                    <AddOrUpdateHabit
                        formType={formType}
                        // dataList={getEventListing}
                        onCloseDrawer={handleCloseDrawer}
                    />
                }
            />
            <CustomDrawer
                isOpenDrawer={detailDrawerState}
                onOpenDrawer={handleOpenDetailDrawer}
                onCloseDrawer={handleCloseDetailDrawer}
                pageTitle="Habit Detail"
                componentToPassDown={
                    <HabitDetail
                        onCloseDrawer={handleCloseDetailDrawer}
                        selectedObject={selectedObject}
                    />
                }
            />
        </>
    )
}
