import { AppBugReports } from "src/components/_dashboard/app";
import AboutSection from "../Community/AboutSection";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  get_dashboard_setting,
  get_stats_data,
} from "src/DAL/Dashboard/Dashboard";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const YourStates = () => {
  const { userInfo } = useContentSetting();
  const classes = useStyles();

  const [showInfoBox, setShowInfoBox] = useState(false);
  const [maximumLikeCount, setMaximumLikeCount] = useState(0);
  const [bonusCount, setBonusCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [coinsCount, setCoinsCount] = useState([]);
  const [feedSetting, setFeedSetting] = useState({});
  const [descriptions, setDescriptions] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const getDashboardSetting = async () => {
    const result = await get_stats_data("your_stats");
    if (result.code === 200) {
      setMaximumLikeCount(result.maximum_like_count);
      setBonusCount(result.bonus_count);
      setDescriptions({
        stick_description: result.stick_description,
        like_description: result.like_description,
        gratitude_description: result.gratitude_description,
        coin_description: result.coin_description,
      });
      setFeedSetting(result.feed_setting);

      let coins_count = [
        {
          name: "Daily Dynamite",
          days_count: result.dashboard_data.dynamite_diary_count,
          coins_title: "Total Coins",
          remaining_time: result.dashboard_data.dynamite_diary_reminder_time,
          type: "growth_tools",
          coins_count: result.dashboard_data.daily_dynamite_coins_count,
          is_completed: result.dashboard_data.is_dynamite_diary_added,
          hours_reminder:
            result?.client_dashboard_setting?.hours_reminder_daily_dynamite,
        },
        {
          name: "Gratitude Daily",
          days_count: result.dashboard_data.dynamite_gratitude_count,
          coins_title: "Total Coins",
          remaining_time:
            result.dashboard_data.dynamite_gratitude_reminder_time,
          type: "growth_tools",
          coins_count: result.dashboard_data.gratitude_coins_count,
          is_completed: result.dashboard_data.is_dynamite_gratitude_added,
          hours_reminder:
            result?.client_dashboard_setting?.hours_reminder_daily_gratitude,
        },
        {
          name: "Meditation",
          days_count: result.dashboard_data.dynamite_meditation_count,
          coins_title: "Total Coins",
          remaining_time:
            result.dashboard_data.dynamite_meditation_reminder_time,
          type: "growth_tools",
          coins_count: result.dashboard_data.meditation_coins_count,
          is_completed: result.dashboard_data.is_dynamite_mediation_added,
          hours_reminder:
            result?.client_dashboard_setting?.hours_reminder_daily_meditation,
        },
      ];

      setCoinsCount(coins_count);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getDashboardSetting();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-3">
            <h2 style={{ textAlign: "" }}>
              <strong>Your Stats</strong>
            </h2>
          </div>
          <div className="col-lg-4">
            <AboutSection
              setShowInfoBox={setShowInfoBox}
              showInfoBox={showInfoBox}
              userInfo={userInfo}
              maximumLikeCount={maximumLikeCount}
              bonusCount={bonusCount}
              descriptions={descriptions}
              feedSetting={feedSetting}
            />
          </div>

          <div className="col-lg-8 ps-3">
            <div className="row">
              {coinsCount.map((item, index) => {
                return (
                  <div className={`col-lg-6 mb-2 ps-0`}>
                    <AppBugReports data={item} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourStates;
