import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { AddEventApi, EditEventApi } from "src/DAL/Calender/Calender";
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
import TinyEditor from "src/components/GeneralComponents/Ckeditor";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));
export default function AddOrUpdateHabit({
  editValues,
  dataList,
  setShowEditComponent,
  setEditValues,
  formType,
  onCloseDrawer,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isReminder, setIsReminder] = useState(false);
  const [previews, setPreviews] = useState("");
  const [image, setImage] = React.useState("");
  const [shortDescriptionCk, setShortDescriptionCk] = useState("");
  const [state, setState] = useState({
    eventDescription: "",
    habit_name: "",
    eventSlug: "",
    eventColor: "black",
    startTime: "00:00",
    endTime: "00:00",
    itrationNumber: 1,
    habit_type: "daily",
    target_date: new Date(),
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeDate = (event) => {
    setState((prevState) => {
      return {
        ...prevState,
        target_date: event.$d,
      };
    });
  };
  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleChangeSwitch = () => {
    setIsReminder(!isReminder)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var dateString = moment(state.target_date).format("YYYY-MM-DD");
    const formDataObject = {
      title: state.habit_name,
      color: state.eventColor,
      recurring_type: state.habit_type,
      start_date: dateString,
      recurring_iteration: state.itrationNumber,
      description: shortDescriptionCk,
      start_time: state.startTime,
      end_time: state.endTime,
    };

    const result = await AddEventApi(JSON.stringify(formDataObject));
    if (result.code === 200) {
      dataList();
      setIsLoading(false);
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var dateString = moment(state.target_date).format("YYYY-MM-DD");
    const formDataObject = {
      title: state.habit_name,
      color: state.eventColor,
      recurring_type: state.habit_type,
      start_date: dateString,
      recurring_iteration: state.itrationNumber,
      description: shortDescriptionCk,
      start_time: state.startTime,
      end_time: state.endTime,
    };
    const result = await EditEventApi(state.eventSlug, formDataObject);
    let eventObject = {};
    if (result.code === 200) {
      let event = result.event;
      eventObject = {
        event_id: event._id,
        title: event.title,
        event_title: event.title,
        date: event.start_date,
        start_date: event.start_date,
        end_date: event.end_date,
        start_time: event.start_time,
        end_time: event.end_time,
        recurring_iteration: event.recurring_iteration,
        description: event.description,
        event_type: event.event_type,
        color: event.color,
        event_color: event.color,
        recurring_type: event.recurring_type,
        event_slug: event.event_slug,
      };

      setEditValues(eventObject);
      dataList();
      setIsLoading(false);
      setShowEditComponent(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (formType === "EDIT") {
      setState((prevState) => ({
        ...prevState,
        ["habit_name"]: editValues.event_title,
        ["eventColor"]: editValues.event_color,
        ["habit_type"]: editValues.recurring_type,
        ["target_date"]: editValues.start_date,
        ["itrationNumber"]: editValues.recurring_iteration,
        ["eventDescription"]: editValues.description,
        ["startTime"]: editValues.start_time,
        ["endTime"]: editValues.end_time,
        ["eventSlug"]: editValues.event_slug,
      }));
      setShortDescriptionCk(editValues.description);
    }
  }, [formType]);

  return (
    <div className="container new-memories">
      <form onSubmit={formType === "ADD" ? handleSubmit : handleUpdate}>
        <TextField
          className="mt-4"
          id="outlined-basic"
          label="Habit Name"
          variant="outlined"
          name="habit_name"
          value={state.habit_name}
          required={true}
          onChange={(e) => handleChange(e)}
        />
        <FormControl variant="outlined" className="mt-3">
          <InputLabel id="demo-simple-select-outlined-label">
            Type of Habit
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={state.habit_type}
            onChange={(e) => handleChange(e)}
            label="habit_type"
            name="habit_type"
            className="svg-color"
            MenuProps={{
              classes: {
                paper: classes.paper,
              },
            }}
            sx={{
              color: get_root_value("--input-text-color"),
            }}
          >
            <MenuItem value="daily">
              <em>Not-to-Do</em>
            </MenuItem>
            <MenuItem value="weekly">
              <em>To-Do</em>
            </MenuItem>
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="DD-MM-YYYY"
            mask="__/__/____"
            inputFormat="DD-MM-YYYY"
            label="Target Date"
            name="target_date"
            value={state.target_date}
            onChange={(e) => handleChangeDate(e)}
            renderInput={(params) => (
              <TextField {...params} className="mt-3" required={true} />
            )}
          />
        </LocalizationProvider>
        <div className="frequency mt-3">
          <p>Frequency</p>
          <div className="frequency-labels">
            <FormControlLabel control={<Checkbox defaultChecked />} label="M" />
            <FormControlLabel control={<Checkbox defaultChecked />} label="T" />
            <FormControlLabel control={<Checkbox defaultChecked />} label="W" />
            <FormControlLabel control={<Checkbox defaultChecked />} label="T" />
            <FormControlLabel control={<Checkbox defaultChecked />} label="F" />
            <FormControlLabel control={<Checkbox defaultChecked />} label="S" />
            <FormControlLabel control={<Checkbox defaultChecked />} label="S" />
          </div>
        </div>
        <div className="habit-reminder d-flex justify-content-between mt-3">
          <p>Habit Reminder</p>
          <Switch checked={isReminder} onChange={handleChangeSwitch} />
        </div>
        {isReminder &&
          <TextField
            variant="outlined"
            id="time"
            label="Start Time"
            type="time"
            className="mt-3"
            name="startTime"
            required={true}
            value={state.startTime}
            onChange={(e) => handleChange(e)}
          />}
        <p className="mt-4 mb-0">Recommended Size (1000x670) </p>
        <div className="col-md-12 mt-2 d-flex">
          {previews && (
            <span className="preview mt-2">
              <span onClick={handleRemove}>x</span>
              <img src={previews} />
            </span>
          )}
          {previews == "" && (
            <span className="upload-button mt-2">
              <input
                color="primary"
                accept="image/*"
                type="file"
                id="icon-button-file"
                style={{ display: "none" }}
                name="affirmationImage"
                onChange={handleUpload}
              />
              <label htmlFor="icon-button-file">
                <CloudUploadIcon />
              </label>
            </span>
          )}
        </div>
        <div className="text-end mt-3">
          <button className="submit-button" disabled={isLoading}>
            {isLoading
              ? formType === "ADD"
                ? "Saving..."
                : "Updating..."
              : formType === "ADD"
                ? "Save"
                : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
