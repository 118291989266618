import React, { useEffect, useRef, useState } from "react";
import Peer from "peerjs";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CircularProgress, Fab, Tooltip } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import { formatTime } from "src/utils/constants";
import moment from "moment";
const momenttz = require("moment-timezone");

const Background = ({ imageUrl }) => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${imageUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    }}
  />
);

export default function VideoStream(props) {
  const {
    feedDetail,
    is_detail_page,
    handleCloseDetailBox,
    liveStream,
    isFullScreen,
    setIsFullScreen,
  } = props;

  const videoGrid = useRef();
  const hostVideo = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const [isMuted, setIsMuted] = useState(liveStream.is_video_muted);
  const [isPaused, setIsPaused] = useState(liveStream.is_video_paused);
  const [isScreenShared, setIsScreenShared] = useState(
    liveStream.is_screen_shared
  );
  const [isLoading, setIsLoading] = useState(true);
  const [myPeerID, setMyPeerID] = useState(null);
  const { socket, userInfo } = useContentSetting();

  let total_time = 0;
  if (liveStream?.stream_start_time) {
    const startTime = moment(liveStream.stream_start_time);
    const endTime = momenttz.tz(new Date(), "Europe/Dublin");
    total_time = endTime.diff(startTime, "seconds");
  }
  const [timer, setTimer] = useState(total_time);

  const handleChangePage = () => {
    if (is_detail_page) {
      if (!params.event_id) {
        navigate(`/thesource`);
      } else if (params.event_id) {
        navigate(`/live-events/${params.event_id}`);
      } else {
        navigate(-1);
      }
    } else {
      handleCloseDetailBox();
    }
  };

  const manage_stream_participant = (action, peer_id) => {
    const data = {
      user_id: userInfo._id,
      module_id: feedDetail._id,
      action: action,
    };
    if (peer_id) {
      data.peer_id = peer_id;
    }
    socket.emit("manage_stream_participant", data);
  };

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const myPeer = new Peer();
    myPeer.on("call", (call) => {
      console.log("Incoming call:", call);
      call.answer(null);
      call.on("stream", (stream) => {
        hostVideo.current.srcObject = stream;
        setIsLoading(false);
      });
    });

    myPeer.on("open", (id) => {
      setMyPeerID(id);
      manage_stream_participant("user_joined", id);
    });

    return () => {
      myPeer.destroy();
      manage_stream_participant("user_left");
    };
  }, [feedDetail._id, socket, userInfo]);

  useEffect(() => {
    socket.on("is_video_paused", (data) => {
      setIsPaused(data.is_video_paused);
    });
    socket.on("is_screen_shared", (data) => {
      manage_stream_participant("user_joined", myPeerID);
      setIsScreenShared(data.is_screen_shared);
    });
    socket.on("is_video_muted", (data) => {
      setIsMuted(data.is_video_muted);
    });

    return () => {
      socket.off("is_screen_shared");
      socket.off("is_video_paused");
      socket.off("is_video_muted");
    };
  }, [socket, myPeerID]);

  return (
    <Card className="live-stream-wrapper">
      {isLoading ? (
        <CircularProgress className="live-stream-loader" color="primary" />
      ) : (
        isPaused &&
        !isScreenShared && (
          <Background imageUrl={s3baseUrl + feedDetail.image.thumbnail_1} />
        )
      )}
      <div className="live-stream-header">
        <div className="stream-info">
          <Icon
            className="me-1"
            fontSize={16}
            style={{ color: "red" }}
            icon="carbon:dot-mark"
          />
          <span>Live</span>
        </div>
        <div className="call-timer-section">
          <div className="call-timer-box">{formatTime(timer)}</div>
        </div>
        <div className="stream-users-info">
          <Icon
            className="me-1"
            fontSize={16}
            icon="ic:baseline-remove-red-eye"
          />
          <span>{feedDetail.participant_count}</span>
        </div>
      </div>
      <div className="end-stream-btn" onClick={handleChangePage}>
        <Tooltip title="Leave Stream">
          <Fab
            style={{ backgroundColor: "red", color: "white" }}
            size="medium"
            variant="extended"
            aria-label="add"
          >
            <Icon
              className="stream-control-icon"
              icon="material-symbols:call-end"
            />
          </Fab>
        </Tooltip>
      </div>

      <div id="video-grid" ref={videoGrid}></div>
      <video ref={hostVideo} autoPlay playsinline className="w-100" />
      <div className="live-stream-footer">
        <div className="stream-controls-wrapper justify-content-end">
          <Tooltip title={isFullScreen ? "Exit Full Screen" : "Full Screen"}>
            <div className="stream-control">
              <Fab
                onClick={() => setIsFullScreen(!isFullScreen)}
                className="stream-control-button"
                size="medium"
                variant="extended"
                aria-label="add"
              >
                <Icon
                  className="stream-control-icon"
                  icon={`${
                    isFullScreen
                      ? "ic:baseline-fullscreen-exit"
                      : "ic:baseline-fullscreen"
                  }`}
                />
              </Fab>
            </div>
          </Tooltip>
        </div>
      </div>
    </Card>
  );
}
