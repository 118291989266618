import React from "react";
import Suggestions from "./Suggestions";
import StarIcon from "@mui/icons-material/Star";
import TwitterFeeds from "./TwitterFeeds";
import { projectMode } from "src/config/config";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import LeaderBoard from "./components/LeaderBoard";
import { useState } from "react";

function RightPart({
  winFeedsList,
  leaderboardData,
  userInfo,
  feeds_type,
  current_events_array,
  upcoming_events_array,
  eventDetail,
  feedSetting,
  userRoadmap,
  handleChangeUserType,
  handleFavouriteVideosPage,
  handleClickAll,
}) {
  const { roadMapLevels } = useContentSetting();

  const [leaderboardDatas, setLeaderboardData] = useState([
    {
      user_info: {
        _id: "65f134260413beef8807c01f",
        name: "coins Volkman",
        profile_image: "",
        email: "test43805@gmail.com",
      },
      coins: "99",
      _id: "66012ac9fc43f9a67cec9aac",
    },
    {
      user_info: {
        _id: "65a66831caaa059d150ac891",
        name: "zohaib Testing",
        profile_image: "",
        email: "zohaibirshad862@gmail.com",
      },
      coins: "90",
      _id: "66012ac9fc43f9a67cec9aad",
    },
    {
      user_info: {
        _id: "63abd24c9afdb872cbc118e2",
        name: "Ammar Yousaf",
        profile_image: "member/977aba38-94a4-4e69-b7ed-ff381a40fef6.jpg",
        email: "y.amm4r@gmail.com",
      },
      coins: "89",
      _id: "66012ac9fc43f9a67cec9aae",
    },
    {
      user_info: {
        _id: "63c8df1227ad83d7fc8249b8",
        name: "Rida Fatima",
        profile_image: "member/5afea653-05dd-4032-a1e4-6c063277e8b3.jpg",
        email: "ridaf6137@gmail.com",
      },
      coins: "78",
      _id: "66012ac9fc43f9a67cec9aaf",
    },
    {
      user_info: {
        _id: "64915e0f817399641e05b813",
        name: "Naiya Tariq",
        profile_image: "member/54103e83-9cf6-45d1-aee1-f1d6c9304681.jpg",
        email: "kainattariq.me@gmail.com",
      },
      coins: "69",
      _id: "66012ac9fc43f9a67cec9ab0",
    },
    {
      user_info: {
        _id: "63abd24c9afdb872cbc118e2",
        name: "Ammar Yousaf",
        profile_image: "member/977aba38-94a4-4e69-b7ed-ff381a40fef6.jpg",
        email: "y.amm4r@gmail.com",
      },
      coins: "50",
      _id: "66012ac9fc43f9a67cec9aae",
    },
    {
      user_info: {
        _id: "63c8df1227ad83d7fc8249b8",
        name: "Rida Fatima",
        profile_image: "member/5afea653-05dd-4032-a1e4-6c063277e8b3.jpg",
        email: "ridaf6137@gmail.com",
      },
      coins: "20",
      _id: "66012ac9fc43f9a67cec9aaf",
    },
  ]);
  const [leaderboardDatasCoins, setLeaderboardDataCoins] = useState([
    {
      user_info: {
        _id: "65f134260413beef8807c01f",
        name: "coins Volkman",
        profile_image: "",
        email: "test43805@gmail.com",
      },
      coins: "99999999",
      _id: "66012ac9fc43f9a67cec9aac",
    },
    {
      user_info: {
        _id: "65a66831caaa059d150ac891",
        name: "zohaib Testing",
        profile_image: "",
        email: "zohaibirshad862@gmail.com",
      },
      coins: "9000000",
      _id: "66012ac9fc43f9a67cec9aad",
    },
    {
      user_info: {
        _id: "63abd24c9afdb872cbc118e2",
        name: "Ammar Yousaf",
        profile_image: "member/977aba38-94a4-4e69-b7ed-ff381a40fef6.jpg",
        email: "y.amm4r@gmail.com",
      },
      coins: "899999",
      _id: "66012ac9fc43f9a67cec9aae",
    },
    {
      user_info: {
        _id: "63c8df1227ad83d7fc8249b8",
        name: "Rida Fatima",
        profile_image: "member/5afea653-05dd-4032-a1e4-6c063277e8b3.jpg",
        email: "ridaf6137@gmail.com",
      },
      coins: "7800000",
      _id: "66012ac9fc43f9a67cec9aaf",
    },
    {
      user_info: {
        _id: "64915e0f817399641e05b813",
        name: "Naiya Tariq",
        profile_image: "member/54103e83-9cf6-45d1-aee1-f1d6c9304681.jpg",
        email: "kainattariq.me@gmail.com",
      },
      coins: "691111",
      _id: "66012ac9fc43f9a67cec9ab0",
    },
    {
      user_info: {
        _id: "63abd24c9afdb872cbc118e2",
        name: "Ammar Yousaf",
        profile_image: "member/977aba38-94a4-4e69-b7ed-ff381a40fef6.jpg",
        email: "y.amm4r@gmail.com",
      },
      coins: "5200000",
      _id: "66012ac9fc43f9a67cec9aae",
    },
    {
      user_info: {
        _id: "63c8df1227ad83d7fc8249b8",
        name: "Rida Fatima",
        profile_image: "member/5afea653-05dd-4032-a1e4-6c063277e8b3.jpg",
        email: "ridaf6137@gmail.com",
      },
      coins: "200000",
      _id: "66012ac9fc43f9a67cec9aaf",
    },
  ]);

  return (
    <>
      <div className="community-left-sidebar">
        {feeds_type === "general" && (
          <>
            <div className="favourite-videos-buttton-box">
              <button
                className="form-control"
                onClick={handleFavouriteVideosPage}
              >
                <StarIcon /> FAVOURITE VIDEOS
              </button>
            </div>
            {feedSetting.show_level_filter && roadMapLevels.length > 0 && (
              <div className="thesource-filter mt-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Level
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userRoadmap}
                    label="Select Level"
                    size="small"
                    onChange={handleChangeUserType}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {roadMapLevels.map((level) => {
                      return (
                        <MenuItem value={level._id.slug}>
                          {level._id.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            )}

            {/* {projectMode == "DEV" && (
              <div className="mt-3">
                <LeaderBoard
                  title={feedSetting.monthly_leaderboard_heading}
                  data={leaderboardData}
                  handleClickAll={handleClickAll}
                />
              </div>
            )} */}
            {winFeedsList.length > 0 && (
              <Suggestions winFeedsList={winFeedsList} />
            )}
          </>
        )}
        {feeds_type === "event" && (
          <>
            <TwitterFeeds
              upcomingEvents={upcoming_events_array}
              currenEvents={current_events_array}
              userInfo={userInfo}
              feeds_type={feeds_type}
              eventDetail={eventDetail}
            />
          </>
        )}
        {feeds_type === "mission" && (
          <>
            <LeaderBoard
              title={"Streak Leader Board"}
              data={leaderboardDatas}
              type="mission"
            />{" "}
            <div className="mt-2">
              <LeaderBoard
                title={"Coins Leader Board"}
                data={leaderboardDatasCoins}
                type="missjbjion"
              />
            </div>
          </>
        )}
        {/* <TotalPoints /> */}
      </div>
    </>
  );
}

export default RightPart;
