import moment from "moment";
import React from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { convertCurrencyToSign } from "src/utils/constants";

export default function HistoryCard({ earnings }) {
  const { userInfo } = useContentSetting();

  return (
    <div className="row">
      {earnings.map((item) => {
        return (
          <div className="col-12 col-md-6 mb-3">
            <div className="mini-cards px-3">
              <div className="diary-font-weight d-flex mb-1">
                <h4>{moment(item.date).format("DD-MM-YYYY")}</h4>
                <div className="total-earning-history">
                  {`${convertCurrencyToSign(
                    userInfo.nineteen_day_plan_currency
                  )}${item.earning.toLocaleString()}`}
                </div>
              </div>
              <h4 className="earning-description">{item.description}</h4>
            </div>
          </div>
        );
      })}
    </div>
  );
}
