import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { mindflix_icon } from "src/assets";
import { ReactVideoPlayer } from "src/components";
import { htmlDecode } from "src/utils/convertHtml";
import CommunitySlider from "../CommunitySlider";
import StarIcon from "@mui/icons-material/Star";

export default function VideoList({
  featureVideo,
  handleChangePage,
  seriesVideos,
}) {
  const navigate = useNavigate();
  const handleFavouriteVideosPage = () => {
    navigate(`/series-video/favourite-videos`);
  };

  return (
    <div>
      {featureVideo && featureVideo.title && (
        <section className="success-wrapper pt-3">
          <div className="container">
            <div className="row mt-4">
              <div className="col-lg-6 pe-lg-5">
                <div className="row pb-4">
                  <div className="col-8 col-lg-6">
                    <img src={mindflix_icon}></img>
                  </div>
                </div>
                <h1>{htmlDecode(featureVideo.title)}</h1>
                <h2>
                  {featureVideo.series_category &&
                    featureVideo.series_category.title && (
                      <>
                        <span>{featureVideo.series_category.title}</span> &nbsp;
                      </>
                    )}
                  {moment(featureVideo.date).format("YYYY")} &nbsp;
                  {htmlDecode(featureVideo.season_statement)}
                </h2>
                <p>{htmlDecode(featureVideo.short_description)}</p>
                <div className="btn-wrapper d-flex">
                  <button
                    className="play-btn me-2"
                    onClick={() => {
                      handleChangePage(featureVideo._id);
                    }}
                  >
                    <i className="fa-solid fa-play" />
                    &nbsp; Play
                  </button>
                  <div className="favourite-videos-buttton-box">
                    <button className="" onClick={handleFavouriteVideosPage}>
                      <StarIcon /> FAVOURITE VIDEOS
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-3 mt-lg-0">
                <ReactVideoPlayer url={featureVideo.trailer_video_url} />
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="slider py-5">
        <div className="container bg-img">
          <CommunitySlider data={seriesVideos} />
          <div className="row"></div>
        </div>
      </section>
    </div>
  );
}
