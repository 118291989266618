import { Slider, TextareaAutosize, TextField, Tooltip } from "@mui/material";
import { cointlogo } from "src/assets";
import ActionBarComponentProps from "./Calender";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { get_root_value } from "src/utils/domUtils";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ReactVideoPlayer } from "src/components";

function MediationOld() {
  const [value, setValue] = useState(30);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [addArray, setaddArray] = useState([{}, {}, {}]);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [inputs, setInputs] = useState({
    note_1: "",
    note_2: "",
  });

  const deadline = "2023-02-28";

  const getTime = (deadline) => {
    const time = Date.parse(deadline) - Date.now();
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor(time / (1000 * 60 * 60)));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);
    return () => clearInterval(interval);
  }, []);

  const getOneDigit = (digi) => {
    let reminder = digi % 10;
    let deviser = digi / 10;
    let decimalPart = Math.floor(deviser);
    // let decimalPart = (deviser % 1) * 10;
    return { reminder, decimalPart };
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleAdd = () => {
    const addedElement = [...addArray, {}];
    setaddArray(addedElement);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const Days = [
    {
      value: 0,
      label: "Day 1",
    },

    {
      value: 100,
      label: "Day 30",
    },
  ];
  return (
    <div className="assessment-wrapper">
      <div className="container">
        <div className="row pt-4">
          <div className="col-lg-6">
            <PageDescription
              parameter="mediation_description"
              else_title="Mediation"
            />
          </div>
          <div className="col-lg-6">
            <div className="d-flex text-align vision-div align-items-center">
              <img className="coint-img img-fluid" src={cointlogo}></img>
              <h4>Total Coins Attracted: 1000</h4>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-lg-7 col-md-6">
            <ReactVideoPlayer url="https://vimeo.com/799140655" />
          </div>
          <div className="col-lg-5 col-md-6 mt-md-0 mt-4">
            <div className="d-flex calender wi-100">
              <ActionBarComponentProps />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="mediation-text">
          <div className="mt-2 field-container diray-title">
            <h4 className="mb-0">My top 3 intentions today are</h4>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-7">
              {addArray?.map((data, index) => {
                return (
                  <div className="d-flex position-relative mt-3">
                    <div className="mt-2 field-container">
                      <TextField
                        required
                        id="outlined-basic"
                        label={`${index + 1}.`}
                        variant="outlined"
                        name="option"
                        value={data.option}
                        onChange={(e) => handleChange(e, index)}
                        sx={{
                          background: "#141717",
                          borderRadius: "5px",
                          input: {
                            "&::placeholder": {
                              opacity: 1,
                            },
                          },
                          label: { color: "#637381" },
                        }}
                      />
                    </div>
                    {/* <div className="icon-container"> */}
                    <span className="cross-icon">
                      {/* <Icon
                        icon="akar-icons:cross"
                        onClick={() => handleDelete(index)}
                      /> */}
                      {addArray.length > 1 ? (
                        <Tooltip title="Remove">
                          <RemoveCircleOutlineIcon
                            onClick={() => handleDelete(index)}
                            className="diary-icon-remove"
                          />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      <Tooltip title="Add">
                        <AddCircleOutlineIcon
                          onClick={() => handleAdd()}
                          className="diary-icon-add"
                        />
                      </Tooltip>
                    </span>
                    {/* </div> */}
                  </div>
                );
              })}
            </div>
            <div className="col-lg-5 ps-lg-5 mt-lg-0 mt-4">
              <div className="text-start label-text">
                <h4>Play Meditation Audio </h4>
                <p>Sentence to explain these ....</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
          </div>
          <audio className="player_audio w-100 mt-5" src="" controls></audio>
          <div className="text-start label-text pt-5 countdown-timer">
            <h4>Countdown Timer</h4>
          </div>
          <div className="row mt-2 justify-content-between">
            <div className="col-lg-5 col-md-6 pt-3">
              <div className="timer-count d-flex justify-content-between">
                <div className="time-count-container">
                  <div className="count">{getOneDigit(hours).decimalPart}</div>
                </div>
                <div className="time-count-container">
                  <div className="count">{getOneDigit(hours).reminder}</div>
                </div>
                <div className="colon">:</div>
                <div className="time-count-container">
                  <div className="count">
                    {getOneDigit(minutes).decimalPart}
                  </div>
                </div>
                <div className="time-count-container">
                  <div className="count">{getOneDigit(minutes).reminder}</div>
                </div>
                <div className="colon">:</div>
                <div className="time-count-container">
                  <div className="count">
                    {getOneDigit(seconds).decimalPart}
                  </div>
                </div>
                <div className="time-count-container">
                  <div className="count">{getOneDigit(seconds).reminder}</div>
                </div>
              </div>
              <div className="d-flex justify-content-between time-container-site">
                <div className="time-detail">Hours</div>
                <div className="time-detail">Minutes</div>
                <div className="time-detail">Seconds</div>
              </div>
            </div>
            {
              // challengeData?.challange_info?.challange_completed_days &&
              <div className="col-md-6 ps-lg-5 ps-md-4 mt-4 mt-md-0">
                <div className="d-flex align-items-end">
                  <div className="w-100 ms-2">
                    <div className="text-end">
                      <h4 className="mb-4">Days in Total: 25</h4>
                    </div>
                    <div class="habits-slider d-flex justify-content-between">
                      <div class="silder-value habits-gray-description">
                        <span>{`30%`}</span>
                      </div>
                      <Slider
                        style={{
                          color: get_root_value("--portal-theme-primary"),
                        }}
                        valueLabelFormat={`3 Days`}
                        aria-label="Custom marks"
                        defaultValue={10}
                        marks={Days}
                        valueLabelDisplay="on"
                        getAriaValueText={valuetext}
                        step={10}
                        className="mui-slider"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="text-start label-text pt-5">
            <h4>Intuitive insights</h4>
          </div>
          <div className="row mt-4">
            <div className="col-md-6 visioneering-textarea">
              <label className="mb-2">
                Ideas I became aware of in my visioneering session
              </label>
              <TextareaAutosize
                aria-label="empty textarea"
                placeholder="Notes *"
                className="text-color"
                sx={{ color: "white" }}
                minRows={4}
                required
                name="note_1"
                value={inputs.note_1}
                onChange={handleChange}
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  background: "#141717",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "15px",
                  color: "white",
                  fontSize: "15px",
                  border: "2px solid rgba(71, 66, 34, 0.3)",
                }}
              />
            </div>
            <div className="col-md-6 visioneering-textarea ps-lg-5">
              <label className="mb-2">
                Actions I am going to take in this awareness{" "}
              </label>
              <TextareaAutosize
                aria-label="empty textarea"
                placeholder="Notes *"
                className="text-color"
                sx={{ color: "white" }}
                minRows={4}
                required
                name="note_2"
                value={inputs.note_2}
                onChange={handleChange}
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  background: "#141717",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "15px",
                  color: "white",
                  fontSize: "15px",
                  border: "2px solid rgba(71, 66, 34, 0.3)",
                }}
              />
            </div>
            <div className="col-md-12 mt-4">
              <div className="text-end visioneer-btn mark-complete-check-box-lable">
                <button>
                  {isLoadingForm ? (
                    "Saving ..."
                  ) : (
                    <>
                      Mark Complete
                      <input type="checkbox" id="MarkComplete" checked />
                      <label for="MarkComplete"></label>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MediationOld;
