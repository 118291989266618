import { invokeApi } from "src/bl_libs/invokeApi";

export const update_QR_code_api = async (data) => {
  const requestObj = {
    path: `app/check_qrcode`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
