import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import Modal from "@mui/material/Modal";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import VideocamIcon from "@mui/icons-material/Videocam";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Avatar, Tooltip } from "@mui/material";
import {
  add_new_feed,
  add_new_feed_v1,
  update_feed,
  update_feed_v1,
} from "src/DAL/Community/Community";
import { projectMode, s3baseUrl } from "src/config/config";
import {
  current_time_with_timezone,
  string_avatar,
  TIME_ZONE_CONVERSION,
} from "src/utils/constants";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import { useNavigate } from "react-router-dom";
import { update_feed_image_on_s3 } from "src/DAL/Login/Login";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import MentionEditor from "./components/MentionEditor";
import dayjs from "dayjs";
import CreatePoll from "./components/FeedPoll/CreatePoll";
import PollIcon from "@mui/icons-material/Poll";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  maxWidth: "100%",
  maxHeight: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
  overflow: "auto",
};

function areOptionsUnique(optionsArray) {
  const textValues = optionsArray.map((item) => item.text);
  const uniqueTextValues = new Set(textValues);
  return uniqueTextValues.size === textValues.length;
}

export default function CreatePost({
  openCreateModal,
  setOpenCreateModal,
  setFeedType,
  feedType,
  getFeedsListing,
  userInfo,
  formAction,
  selectedFeed,
  setFormAction,
  handleSuccessUpdate,
  feeds_type,
  event_id,
  userType,
  feedSetting,
  userRoadmap,
}) {
  const navigate = useNavigate();
  const { communityLevels, roadMapLevels, socket, adminTimeZone } =
    useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [feedId, setFeedId] = useState("");
  const [levelType, setLevelType] = useState(userType);
  const [mentionedUsers, setMentionedUsers] = useState([]);

  const time_now = new Date(
    current_time_with_timezone(userInfo.time_zone, "YYYY-MM-DD hh:mm a")
  );
  let tomorrowDate = time_now;
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  const [pollInfo, setPollInfo] = useState({
    expiry_date: tomorrowDate,
    expiry_time: time_now,
    is_multiple_allow: true,
  });

  const [pollOptions, setPollOptions] = useState([{ text: "" }, { text: "" }]);

  const [inputs, setInputs] = useState({
    feedAppearBy: "public",
    VideoUrl: "",
    description: "",
    LiveUrl: "",
  });

  const handleRemove = () => {
    setThumbnail("");
  };

  const handleRemoveImage = (path) => {
    setPreviews((images) => {
      return images.filter((image) => image !== path);
    });
  };

  const UploadImages = async (formData) => {
    const result = await update_feed_image_on_s3(formData);
    return result.images_obj;
  };

  const handleUpload = async (event) => {
    let setImageObject = {};
    const fileList = event.target.files;
    const images_array = [];
    for (let i = 0; i < fileList.length; i++) {
      setImageObject = {
        path: fileList[i],
        type: "file",
      };
      images_array.push(setImageObject);
    }
    setPreviews((prevFiles) => [...prevFiles, ...images_array]);
  };

  const handleUploadThumbnail = async (event) => {
    const targetFile = event.target.files[0];
    setThumbnail(targetFile);
  };

  const handleClose = () => {
    setOpenCreateModal(false);
    setInputs({
      feedAppearBy: "public",
      VideoUrl: "",
      description: "",
    });
    setPreviews([]);
    setPollOptions([{ text: "" }, { text: "" }]);
    setPollInfo({
      expiry_date: tomorrowDate,
      expiry_time: new Date(),
      is_multiple_allow: true,
    });
    setImage("");
    setFormAction("ADD");
  };

  const handleCreatePost = async (e) => {
    e.preventDefault();
    if (
      !inputs.VideoUrl &&
      !inputs.description &&
      !inputs.LiveUrl &&
      !image &&
      !thumbnail &&
      previews.length < 1
    ) {
      enqueueSnackbar("Please add data to be posted", {
        variant: "error",
      });
      return;
    }

    if (feedType === "poll") {
      if (!areOptionsUnique(pollOptions)) {
        enqueueSnackbar("All poll option must be unique", { variant: "error" });
        return;
      }
    }

    let expiry_date = moment(pollInfo.expiry_date).format("YYYY-MM-DD");
    let expiry_time = moment(pollInfo.expiry_time).format("HH:mm");
    const givenTime = new Date(expiry_date + " " + expiry_time);
    const timeNow = new Date(
      current_time_with_timezone(userInfo.time_zone, "YYYY-MM-DD hh:mm a")
    );
    if (givenTime <= timeNow) {
      enqueueSnackbar(
        "Past time selection is not allowed. Please choose a future time.",
        { variant: "error" }
      );
      return;
    }

    setIsLoading(true);

    const results = previews.map((image, index) => {
      if (image.type === "file") {
        const formData = new FormData();
        formData.append("image", image.path);
        formData.append("width", 300);
        const result = UploadImages(formData);
        return result;
      } else {
        return image.path;
      }
    });

    Promise.all(results).then(async (img_results) => {
      const formData = new FormData();
      formData.append("feed_appear_by", inputs.feedAppearBy);
      formData.append("feed_type", feedType);
      formData.append("video_url", inputs.VideoUrl);
      formData.append("description", inputs.description);
      formData.append("embed_code", inputs.LiveUrl);
      formData.append("feed_created_for", feeds_type);

      if (formAction === "ADD") {
        formData.append("community_level", levelType);
      }

      if (feeds_type === "event") {
        formData.append("event_id", event_id);
      }
      if (feedType === "image") {
        formData.append("feed_images", JSON.stringify(img_results));
      } else if (feedType === "poll") {
        let poll_info = { ...pollInfo, expiry_date, expiry_time };
        poll_info.options = pollOptions;
        formData.append("poll_info", JSON.stringify(poll_info));
      }
      formData.append("mentioned_users", JSON.stringify(mentionedUsers));
      if (projectMode === "DEV") {
        //for zoom start
        if (feedType === "live") {
          if (!thumbnail) {
            enqueueSnackbar("Please upload live stream image", {
              variant: "error",
            });
            setIsLoading(false);
            return;
          }
          formData.append("image", thumbnail);
        }
        //for zoom end
      }

      const result = await add_new_feed_v1(formData);
      if (result.code === 200) {
        if (result.action_response?.notification_users?.length > 0) {
          const socketData = {
            action: "feed_mentioned",
            feed_id: result.action_response?.feed._id,
            token: localStorage.getItem("token"),
            creator_id: userInfo._id,
            action_by: userInfo._id,
            action_response: result.action_response,
          };
          socket.emit("mention_user_event_listner", socketData);
        }

        if (projectMode === "DEV") {
          //for zoom start
          if (feedType === "live") {
            console.log(feeds_type, "----live");
            navigate(
              `/live-stream/${result.feed._id}/${result.feed.zoom_token}`
            );
          }
          //for zoom end
        }
        setInputs({
          feedAppearBy: "public",
          VideoUrl: "",
          description: "",
        });
        getFeedsListing(
          `api/feeds/feed_list_with_pagination/v1?page=0&limit=10&feed_type=${feeds_type}&event=${
            event_id ? event_id : ""
          }&community_level=${userRoadmap}`
        );
        setIsLoading(false);
        handleClose();
        enqueueSnackbar(result.message, {
          variant: "success",
        });
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    });
  };

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeLevel = (event) => {
    let value = event.target.value;
    setLevelType(value);
  };

  const handleCancelUpdate = (e) => {
    e.preventDefault();
    handleClose();
  };

  const handleUpdatePost = async (e) => {
    e.preventDefault();

    if (
      inputs.description === "" &&
      inputs.VideoUrl === "" &&
      inputs.LiveUrl === "" &&
      image === ""
    ) {
      enqueueSnackbar(
        "There should be one of the content in feed [description, video, image, livestream]",
        { variant: "error" }
      );
      return;
    }

    if (feedType === "poll") {
      if (!areOptionsUnique(pollOptions)) {
        enqueueSnackbar("All poll option must be unique", { variant: "error" });
        return;
      }
    }
    let expiry_date = moment(pollInfo.expiry_date).format("YYYY-MM-DD");
    let expiry_time = moment(pollInfo.expiry_time).format("HH:mm");
    const givenTime = new Date(expiry_date + " " + expiry_time);
    const timeNow = new Date(
      current_time_with_timezone(userInfo.time_zone, "YYYY-MM-DD hh:mm a")
    );
    if (givenTime <= timeNow) {
      enqueueSnackbar(
        "Past time selection is not allowed. Please choose a future time.",
        { variant: "error" }
      );
      return;
    }

    setIsLoading(true);
    const results = previews.map((image, index) => {
      if (image.type === "file") {
        const formData = new FormData();
        formData.append("image", image.path);
        formData.append("width", 300);
        const result = UploadImages(formData);
        return result;
      } else {
        return image;
      }
    });

    Promise.all(results).then(async (img_results) => {
      const formData = new FormData();
      formData.append("feed_appear_by", inputs.feedAppearBy);
      formData.append("feed_type", feedType);
      formData.append("video_url", inputs.VideoUrl);
      formData.append("description", inputs.description);
      formData.append("embed_code", inputs.LiveUrl);
      formData.append("mentioned_users", JSON.stringify(mentionedUsers));

      if (feedType === "image") {
        formData.append("feed_images", JSON.stringify(img_results));
      } else if (feedType === "poll") {
        let poll_info = { ...pollInfo, expiry_date, expiry_time };
        poll_info.options = pollOptions;
        formData.append("poll_info", JSON.stringify(poll_info));
      }

      if (image === "" && selectedFeed.feed_type === "image") {
        formData.append("is_remove_image", true);
      }
      const result = await update_feed_v1(formData, feedId);
      if (result.code === 200) {
        if (result.action_response?.notification_users?.length > 0) {
          const socketData = {
            action: "feed_mentioned",
            feed_id: result.action_response?.feed._id,
            token: localStorage.getItem("token"),
            creator_id: userInfo._id,
            action_by: userInfo._id,
            action_response: result.action_response,
          };
          socket.emit("mention_user_event_listner", socketData);
        }

        setInputs({
          feedAppearBy: "public",
          VideoUrl: "",
          description: "",
        });

        if (!window.location.pathname.includes("/feed-detail")) {
          getFeedsListing(
            `api/feeds/feed_list_with_pagination/v1?page=0&limit=10&feed_type=${feeds_type}&event=${
              event_id ? event_id : ""
            }&community_level=${userType}`
          );
        } else {
          getFeedsListing(feedId);
        }

        setIsLoading(false);
        handleClose();
        enqueueSnackbar(result.message, {
          variant: "success",
        });
        handleSuccessUpdate(selectedFeed);
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    });
  };

  const show_filter_levels = () => {
    let levels_array = [];
    if (feedSetting.show_level_filter) {
      levels_array = communityLevels;
    }
    return levels_array;
  };

  const handle_date = (type) => {
    let format = "YYYY-MM-DD";
    if (type === "time") {
      format = "YYYY-MM-DD hh:mm a";
    }
    return TIME_ZONE_CONVERSION(
      selectedFeed.poll_info.expiry_date_time,
      format,
      adminTimeZone,
      userInfo.time_zone
    );
  };

  useEffect(() => {
    if (formAction === "EDIT") {
      setInputs({
        feedAppearBy: selectedFeed.feed_appear_by,
        VideoUrl: selectedFeed.video_url,
        description: selectedFeed.description,
        LiveUrl: selectedFeed.embed_code,
      });
      setFeedType(selectedFeed.feed_type);
      setFeedId(selectedFeed._id);
      setLevelType(selectedFeed.created_for_level_or_type);
      if (
        selectedFeed.feed_type === "image" &&
        selectedFeed.feed_images &&
        selectedFeed.feed_images.length > 0
      ) {
        setPreviews(selectedFeed.feed_images);
        setImage(s3baseUrl + selectedFeed.image?.thumbnail_1);
      }
      if (selectedFeed.feed_type === "poll") {
        setPollInfo({
          ...selectedFeed.poll_info,
          expiry_date: handle_date("date"),
          expiry_time: new Date(handle_date("time")),
        });
        setPollOptions(selectedFeed.poll_info?.options);
      }
    }
  }, [formAction]);

  useEffect(() => {
    if (userType) {
      if (userType == "all") {
        userType = userInfo.community_level;
      }
      setLevelType(userType);
    }
  }, [userType]);

  return (
    <div>
      <Modal
        open={openCreateModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
          className="create-post-title-form"
          onSubmit={formAction === "ADD" ? handleCreatePost : handleUpdatePost}
        >
          <Box sx={style} className="modal-theme">
            <div
              className="text-center modalIcon clo-12 create-post-title-box"
              onClick={() => {
                handleClose();
              }}
            >
              <h2>{`${formAction == "ADD" ? "Create" : "Update"} post`}</h2>
              <hr />
              <span>x</span>
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <div className="post-creator">
                  <Avatar
                    src={s3baseUrl + userInfo?.profile_image}
                    alt="photoURL"
                  >
                    {string_avatar(
                      userInfo?.last_name
                        ? userInfo?.first_name + " " + userInfo.last_name
                        : userInfo?.first_name
                    )}
                  </Avatar>
                  <div className="creator-name ps-2 ">
                    <h3>{userInfo?.first_name + " " + userInfo?.last_name}</h3>
                    <div className="d-flex">
                      <select name="feedAppearBy" onChange={handleChange}>
                        <option
                          value="public"
                          selected={
                            inputs.feedAppearBy === "public" ? "selected" : ""
                          }
                        >
                          General
                        </option>
                        <option
                          value="win"
                          selected={
                            inputs.feedAppearBy === "win" ? "selected" : ""
                          }
                        >
                          Win
                        </option>
                        {/* <option
                          value="poll"
                          selected={
                            inputs.feedAppearBy === "poll" ? "selected" : ""
                          }
                        >
                          Poll
                        </option> */}
                      </select>

                      {formAction === "ADD" &&
                        feeds_type == "general" &&
                        roadMapLevels.length > 0 && (
                          <div className="ps-2">
                            <select
                              name="community_level"
                              onChange={handleChangeLevel}
                              defaultValue={levelType}
                            >
                              {roadMapLevels.map((level) => {
                                return (
                                  <option
                                    value={level?._id?.slug}
                                    // selected={levelType}
                                  >
                                    {level._id.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3 position-relative">
              <MentionEditor
                setInputs={setInputs}
                inputs={inputs}
                name="description"
                setMentionedUsers={setMentionedUsers}
                mentionedUsers={
                  selectedFeed.mentioned_users?.length > 0
                    ? selectedFeed.mentioned_users
                    : mentionedUsers
                }
                feedLevel={levelType}
                value={inputs.description}
                event_id={event_id}
                placeholder={`What's on your mind, ${userInfo?.first_name}?`}
              />
            </div>
            {feedType === "poll" ? (
              <CreatePoll
                pollInfo={pollInfo}
                setPollInfo={setPollInfo}
                pollOptions={pollOptions}
                setPollOptions={setPollOptions}
              />
            ) : (
              <>
                {feedType === "video" && (
                  <div className="col-12 mt-3 video-url-field search-input">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Video URL"
                      name="VideoUrl"
                      value={inputs.VideoUrl}
                      onChange={handleChange}
                    />
                    <span
                      className="video-url-cross"
                      onClick={() => {
                        setFeedType("general");
                      }}
                    >
                      x
                    </span>
                  </div>
                )}
                {feedType === "live" &&
                  (projectMode == "DEV" ? (
                    //for zoom start
                    <div className="col-md-12 mt-2 d-flex w-100">
                      {thumbnail && (
                        <span className="preview mt-2">
                          <span onClick={handleRemove}>x</span>
                          <img src={URL.createObjectURL(thumbnail)} />
                        </span>
                      )}
                      {thumbnail == "" && (
                        <span className="upload-button mt-2 w-100">
                          <input
                            color="primary"
                            accept="image/*"
                            type="file"
                            id="icon-button-file"
                            style={{ display: "none" }}
                            onChange={handleUploadThumbnail}
                          />
                          <label htmlFor="icon-button-file">
                            <span
                              onClick={() => {
                                setFeedType("general");
                              }}
                            >
                              x
                            </span>
                            <p className="mt-3">
                              Add Live Stream Photo <br />
                              <CloudUploadIcon />
                            </p>
                          </label>
                        </span>
                      )}
                      {/* //for zoom end */}
                    </div>
                  ) : (
                    //for live textare start
                    <div className="col-12 mt-3 video-url-field search-input">
                      <textarea
                        rows="6"
                        className="form-control"
                        placeholder="Livestream Embed Code"
                        name="LiveUrl"
                        value={inputs.LiveUrl}
                        onChange={handleChange}
                      >
                        {inputs.LiveUrl}
                      </textarea>
                      <span
                        className="video-url-cross"
                        onClick={() => {
                          setFeedType("general");
                        }}
                      >
                        x
                      </span>
                    </div>

                    //for live textare end
                  ))}
                {feedType === "image" && (
                  <div className="col-md-12 mt-2">
                    <div className="row">
                      {previews.length > 0 &&
                        previews.map((preview) => {
                          return (
                            <span className="col-3 mb-3 preview create-post-images">
                              <span
                                onClick={() => {
                                  handleRemoveImage(preview);
                                }}
                              >
                                x
                              </span>
                              <img
                                src={
                                  preview.type == "file"
                                    ? URL.createObjectURL(preview.path)
                                    : s3baseUrl + preview.thumbnail_1
                                }
                              />
                            </span>
                          );
                        })}
                    </div>
                    {/* {previews.length < 1 && ( */}

                    <div className="row">
                      <div className={`col-12`}>
                        <span className="upload-button mt-2 w-100">
                          <input
                            multiple
                            color="primary"
                            accept="image/*"
                            type="file"
                            id="icon-button-file"
                            style={{ display: "none" }}
                            onChange={handleUpload}
                          />
                          <label htmlFor="icon-button-file">
                            <span
                              onClick={() => {
                                setFeedType("general");
                              }}
                            >
                              x
                            </span>
                            <p className="mt-3">
                              Add Photo <br />
                              <CloudUploadIcon />
                            </p>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="col-12">
              <div className="video-image-selection">
                <div
                  onClick={() => {
                    setFeedType("image");
                  }}
                  className={`selection-box ${
                    feedType == "image" ? "selected" : ""
                  }`}
                >
                  <PhotoCameraIcon />
                </div>
                <div
                  onClick={() => {
                    setFeedType("video");
                  }}
                  className={`selection-box ${
                    feedType == "video" ? "selected" : ""
                  }`}
                >
                  <VideocamIcon />
                </div>
                <div
                  onClick={() => {
                    if (userInfo.is_executive) {
                      setFeedType("live");
                    }
                  }}
                  className={`selection-box ${
                    feedType == "live" ? "selected" : ""
                  }  ${userInfo.is_executive ? "" : "force-disabled"}`}
                >
                  <LiveTvIcon />
                </div>
                {feedSetting.enable_poll_feed && (
                  <Tooltip title="Poll">
                    <div
                      onClick={() => {
                        setFeedType("poll");
                      }}
                      className={`selection-box ${
                        feedType == "poll" ? "selected" : ""
                      }`}
                    >
                      <PollIcon />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>

            <div className="col-12 text-center">
              {formAction === "ADD" && (
                <button
                  className="small-contained-button post-submit-btn"
                  disabled={isLoading}
                >
                  {isLoading ? "Posting..." : "Post"}
                </button>
              )}
              {formAction === "EDIT" && (
                <div className="d-flex justify-content-end">
                  <button
                    className="me-2 small-contained-button post-submit-btn"
                    onClick={handleCancelUpdate}
                  >
                    Cancel
                  </button>
                  <button
                    className="small-contained-button post-submit-btn"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "Updating..." : "Update"}
                  </button>
                </div>
              )}
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
