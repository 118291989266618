import React from "react";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import SideImageCard from "src/components/GeneralComponents/SideImageCard";

export default function LessonsCardTab({ lessons, programmesDetail }) {
  return (
    <>
      {lessons.length > 0 ? (
        <SideImageCard data={lessons} programmesDetail={programmesDetail} />
      ) : (
        <RecordNotFound title="Lessons Not Found" />
      )}
    </>
  );
}
