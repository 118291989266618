// Modal.js
import React from "react";
import ReactDOM from "react-dom";
import { s3baseUrl } from "src/config/config";

const CongratulationAnimation = ({ rewardData }) => {
  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <img src={s3baseUrl + rewardData?.image} />
    </div>,
    document.getElementById("modal-root")
  );
};

export default CongratulationAnimation;
