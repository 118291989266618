import React from "react";
import { s3baseUrl } from "src/config/config";
import { Avatar, Tooltip } from "@mui/material";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import {
  get_date_with_user_time_zone,
  get_short_string,
} from "src/utils/constants";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

function LessonsList({
  lessonsList,
  programList,
  setSelectedLesson,
  lessonLockText,
}) {
  const navigate = useNavigate();
  const { userInfo, adminTimeZone } = useContentSetting();
  const handleNavigarte = (start_date, lesson) => {
    if (new Date(start_date) <= new Date()) {
      navigate(`/lessons/${lesson.lesson_slug}`);
    }
  };

  const get_update_time_with_time_zone = (date) => {
    let new_date = get_date_with_user_time_zone(
      date,
      "YYYY-MM-DD",
      userInfo.time_zone,
      adminTimeZone
    );
    return Date.parse(new_date);
  };
  console.log(lessonsList, "lessonsList");
  return (
    <div className="mb-3">
      <h4 className="p-3 pb-0">{programList[0].title}</h4>
      <hr />
      <div className="p-3 pt-0 dashboard-lessons-box">
        {lessonsList?.length < 1 ? (
          <RecordNotFound title="Lessons no found" />
        ) : (
          lessonsList?.map((value) => {
            let find_program = programList.find(
              (program) => program._id == value.program[0]._id
            );

            let purchase_date = moment(
              get_update_time_with_time_zone(find_program?.purchase_date_time)
            )
              .add(
                "day",
                value.access_after_days > 0
                  ? value.access_after_days - 1
                  : value.access_after_days
              )
              .format("YYYY-MM-DD");

            var start_date = new Date(purchase_date);
            if (new Date(start_date) <= new Date()) {
              setSelectedLesson(value);
            }

            return (
              <Tooltip
                title={`${
                  new Date(start_date) > new Date() ? lessonLockText : ""
                }`}
                placement="top"
              >
                <div
                  className={`d-flex ${
                    new Date(start_date) > new Date() ? "disabled-lesson" : ""
                  }`}
                  onClick={() => {
                    handleNavigarte(start_date, value);
                  }}
                >
                  <Avatar
                    className="avatar-adjust"
                    sx={{ width: 40, height: 40 }}
                    alt={value?.title}
                    src={s3baseUrl + value?.lesson_images.thumbnail_3}
                  />
                  <div className="text-start comment-icon w-100 ps-2 source-session">
                    <div className="card-title">
                      <h3 className="mb-0">{value?.title}</h3>
                      <p>{get_short_string(value?.short_description, 60)}</p>
                    </div>
                  </div>
                </div>
              </Tooltip>
            );
          })
        )}
      </div>
    </div>
  );
}

export default LessonsList;
