import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "@mui/material";
// import { hasGrantedAllScopesGoogle } from "@react-oauth/google";

const LoginByGoogle = () => {
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => console.log(codeResponse),
    flow: "auth-token",
    scope:
      "email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
    responseType: "code",
  });
  //   const hasAccess = hasGrantedAllScopesGoogle(
  //     tokenResponse,
  //     "google-scope-1",
  //     "google-scope-2"
  //   );
  //   console.log(hasAccess, "hasAccess");
  return (
    <div>
      <Button onClick={() => login()}>Sign in with Google</Button>
    </div>
  );
};

export default LoginByGoogle;
