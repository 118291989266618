import React, { useState, useEffect } from "react";
import PinterestLayout from "src/components/GeneralComponents/Pinterest";
import { quotesListing } from "../../DAL/Quotes/Quotes";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PageDescription from "src/components/GeneralComponents/PageDescription";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function Quotes() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [quotesData, setQuotesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getQuotesListing = async () => {
    const result = await quotesListing();
    if (result.code === 200) {
      let quotationArray = [];
      let quotationObject = {};
      result.quotation.map((quotation, index) => {
        quotationObject = {
          pinteres_id: quotation._id,
          pinterestImage: quotation.quotation_images.thumbnail_1,
          pinterestDescription: quotation.description,
        };
        quotationArray.push(quotationObject);
      });
      setQuotesData(quotationArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleDetailPage = () => {};
  useEffect(() => {
    getQuotesListing();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <PageDescription parameter="quotes_description" else_title="Quotes" />
      </div>
      <div className="container">
        <PinterestLayout
          notFoundtitle="Gratitude Not Found"
          data={quotesData}
          handleDetailPage={handleDetailPage}
        />
      </div>
    </>
  );
}

export default Quotes;
