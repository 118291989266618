import React from "react";
import { useState } from "react";
import { get_short_string } from "src/utils/constants";
import DownloadIcon from "@mui/icons-material/Download";
import { CircularProgress, Tooltip } from "@mui/material";
import { s3baseUrl } from "src/config/config";

function GraphicsLinksCard({ value }) {
  const [isLoading, setIsLoading] = useState(false);
  const [ReadMoreBtnPressed, setReadMoreBtnPressed] = useState(false);
  const [ShowLess, setShowLess] = useState(false);
  const showMOreButton = () => {
    setReadMoreBtnPressed(!ReadMoreBtnPressed);
    setShowLess(!ShowLess);
  };
  const handleDownload = async (path) => {
    setIsLoading(true);
    const file_path = `${s3baseUrl}${path}`;
    fetch(file_path)
      .then(async (res) => {
        if (res.status === 200) {
          let blob = await res.blob();
          saveAs(blob, path);
          setIsLoading(false);
        } else {
          enqueueSnackbar(res.statusText, { variant: "error" });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <div className="">
      <div className="col-12 download-graphic">
        <a
          href={s3baseUrl + value.image.thumbnail_1}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={s3baseUrl + value.image.thumbnail_2}
            alt=""
            className="w-100"
          />
        </a>
        {isLoading ? (
          <CircularProgress
            className="icon-style"
            style={{ width: 20, height: 20 }}
          />
        ) : (
          <Tooltip title="Download">
            <DownloadIcon
              onClick={() => {
                handleDownload(value.image.thumbnail_1);
              }}
              className="icon-style"
            />
          </Tooltip>
        )}
      </div>
      <div className="text-start comment-icon w-100 ps-2 source-session show-less-more-box">
        <div className="card-title mt-2">
          <h3 className="mb-0">{value?.title}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html:
                ShowLess == true
                  ? value?.short_description
                  : get_short_string(value?.short_description, 52),
            }}
          ></div>
          {value?.short_description.length > 52 && (
            <span onClick={() => showMOreButton()} className="show-less-more">
              {ShowLess ? "Show Less" : "Show More"}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default GraphicsLinksCard;
