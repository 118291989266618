import React, { useEffect } from "react";
import { Card, InputAdornment, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { get_root_value } from "src/utils/domUtils";
import NumberFormat from "react-number-format";
import { convertCurrencyToSign } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import {
  AddNineteenDay,
  complete_ninety_day_plan_api,
} from "src/DAL/90Day/NinetyDay";
import GeneralModelBox from "../GeneralComponents/GeneralModelBox";
import PlanModalContent from "./PlanModalContent";

export default function StartDateCard({
  startDate,
  startDateUpdated,
  targetAmount,
  setTargetAmount,
  setStartDate,
  isPlan,
  contentSettings,
  onClosePlan,
  setIsDisabled,
  setTargetAmountUpdated,
  setStartDateUpdated,
}) {
  const { userInfo } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openPlanPopup, setOpenPlanPopup] = useState("");
  const [targetCompleted, setTargetCompleted] = useState(false);

  const handleSubmitTarget = async (e) => {
    e.preventDefault();
    setLoading(true);
    let postData = {
      nineteen_day_vision: {
        target_amount: targetAmount,
        start_date: moment(startDate).format("YYYY-MM-DD"),
      },
    };
    const result = await AddNineteenDay(postData);
    if (result.code == 200) {
      setTargetAmountUpdated(targetAmount);
      setStartDateUpdated(startDate);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsDisabled(false);
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  const handleClosePlan = async () => {
    setIsSubmitting(true);
    const result = await complete_ninety_day_plan_api();
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setTargetCompleted(false);
      onClosePlan();
      setOpenPlanPopup("start_plan");
      setIsSubmitting(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmitting(false);
    }
  };

  const addDays = (date) => {
    var result = new Date(date);
    result.setDate(result.getDate() + 90);
    return moment(result).format("DD MMM YYYY");
  };

  useEffect(() => {
    if (!startDate) {
      if (!isPlan) {
        setOpenPlanPopup("start_plan");
      }
    } else {
      const start_date = moment(startDate);
      const today_date = moment(moment().format("YYYY-MM-DD"));
      const total_days = today_date.diff(start_date, "days");
      if (total_days > 90) {
        setTargetCompleted(true);
        setOpenPlanPopup("close_plan");
      }
    }
  }, []);

  const replacedString = (string) => {
    if (string) {
      return string.replace(
        /{target_date}/g,
        `<span id="date_text_string">${addDays(startDateUpdated)}</span>`
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <Card
        className={`pb-3 ps-3 pe-3  programm-card h-100 secondary-background ${
          isPlan ? "" : "pt-5"
        }`}
      >
        {/* start date and target amount */}
        <form onSubmit={handleSubmitTarget}>
          <div className="row">
            {!isPlan && (
              <>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD-MM-YYYY"
                      mask="__/__/____"
                      inputFormat="DD-MM-YYYY"
                      label="90 Day Start Date"
                      value={startDate}
                      fullWidth
                      onChange={(newValue) => {
                        setStartDate(newValue.$d);
                      }}
                      disabled={Boolean(isPlan)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          required
                          className="inputs-fields"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                  <NumberFormat
                    value={targetAmount}
                    decimalScale={0}
                    sx={{
                      input: { color: get_root_value("--input-text-color") },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {convertCurrencyToSign(
                            userInfo.nineteen_day_plan_currency
                          )}
                        </InputAdornment>
                      ),
                    }}
                    required
                    customInput={TextField}
                    type="text"
                    className="inputs-fields"
                    label="Target Amount"
                    thousandSeparator={true}
                    placeholder="Target Amount"
                    fullWidth
                    onValueChange={({ value: v }) => setTargetAmount(v)}
                    disabled={Boolean(isPlan)}
                  />
                </div>
              </>
            )}
            <>
              <div
                className={`col-sm-12 ${
                  isPlan
                    ? ""
                    : targetCompleted
                    ? "col-md-7"
                    : "col-md-8 col-lg-10"
                }  mt-4 text-center goal_achieved_white goal_achieved_upper_text`}
              >
                {startDateUpdated && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: replacedString(
                        contentSettings?.goal_will_be_achieved_text
                      ),
                    }}
                  ></p>
                )}
              </div>
              {!isPlan && (
                <div
                  className={`col-sm-12 ${
                    targetCompleted ? "col-md-5" : "col-lg-2"
                  } mt-md-0 text-end`}
                >
                  {targetCompleted &&
                    contentSettings?.close_plan_button_text && (
                      <button
                        className="small-contained-button mt-4 me-2"
                        onClick={(e) => {
                          e.preventDefault();
                          handleClosePlan();
                        }}
                        disabled={isSubmitting}
                      >
                        {isSubmitting
                          ? "Submitting..."
                          : contentSettings?.close_plan_button_text}
                      </button>
                    )}
                  <button
                    className="small-contained-button mt-4"
                    disabled={loading}
                  >
                    {loading
                      ? "Saving..."
                      : contentSettings?.save_target_button_text ||
                        "Save Target"}
                  </button>
                </div>
              )}
            </>
          </div>
        </form>
        {startDateUpdated && (
          <div className="col-sm-12 text-center goal_achieved_white goal_achieved_lower_text">
            <p
              dangerouslySetInnerHTML={{
                __html: replacedString(
                  contentSettings?.goal_will_be_achieved_text
                ),
              }}
            ></p>
          </div>
        )}
      </Card>
      <GeneralModelBox
        open={Boolean(openPlanPopup)}
        setOpen={setOpenPlanPopup}
        componentToPassDown={
          <PlanModalContent
            openPlanPopup={openPlanPopup}
            handleClosePlan={handleClosePlan}
            contentSettings={contentSettings}
            isSubmitting={isSubmitting}
            setOpenPlanPopup={setOpenPlanPopup}
          />
        }
      />
    </>
  );
}
