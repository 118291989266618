import React from "react";
import { useNavigate } from "react-router-dom";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

export default function TermsAndConditions() {
  const navigate = useNavigate();
  const { contentSettingData } = useContentSetting();

  return (
    <section className="art-thanks">
      <div className="container mt-2">
        <div className="row ">
          <div className="col-lg-12 mt-2">
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: contentSettingData.privacy_policy_content,
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
