import { Card, IconButton, TextField, Tooltip } from "@mui/material";
import React from "react";
import { SingleCommentInception } from "src/components";
import { s3baseUrl } from "src/config/config";
import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import {
  bottom_in,
  get_bottom_padding,
  get_pinned_comments_height,
  get_pinned_image_height,
  get_video_height,
  handleScrollListener,
  handle_validate_redirect,
  scroll_to_bottom,
} from "../../live_event_functions";
import { attchment_icon, send_chat_icon } from "src/assets";

export default function ChatCardLesson({
  eventDetail,
  pinnedComments,
  handleCommentDelete,
  handleCommentEdit,
  handleCommentPin,
  liveChat,
  handleOpenReplyBox,
  newChat,
  setNewChat,
  setShowScrollArrow,
  showScrollArrow,
  isReply,
  chatMessage,
  setChatMessage,
  formType,
  handleRemoveAll,
  previews,
  handleRemove,
  handleUpload,
  isSubmitting,
  handleSendMessage,
  handleMessageUpdate,
  setIsLoading,
  handleLikeComment,
  handleOpenLikes,
}) {
  return (
    <div className="col-12 col-lg-4 mt-4 mt-lg-0">
      <Card
        style={{
          height: get_video_height(),
        }}
        className="live-comments"
      >
        {eventDetail?.is_purchase_link == true
          ? eventDetail?.link_image && (
              <div id="pinned-image" className="pinned-comment pinned-image">
                <img
                  src={s3baseUrl + eventDetail?.link_image}
                  width="100%"
                  onClick={() =>
                    handle_validate_redirect(
                      eventDetail,
                      "",
                      setIsLoading,
                      false
                    )
                  }
                />
              </div>
            )
          : pinnedComments.length > 0 && (
              <div id="pinned-comment" className="pinned-comment">
                {pinnedComments.map((c) => (
                  <SingleCommentInception
                    handleCommentDelete={handleCommentDelete}
                    handleCommentEdit={handleCommentEdit}
                    handleCommentPin={handleCommentPin}
                    comment={c}
                    eventDetail={eventDetail}
                    handleLikeComment={handleLikeComment}
                    handleOpenLikes={handleOpenLikes}
                  />
                ))}
              </div>
            )}
        <div
          className="live-comments-list"
          onScroll={() => {
            handleScrollListener(setNewChat, setShowScrollArrow);
          }}
          style={{
            paddingBottom: get_bottom_padding(),
            height: get_video_height(),
            paddingTop:
              eventDetail?.is_purchase_link == true
                ? get_pinned_image_height()
                : 25 + get_pinned_comments_height(),
          }}
        >
          {liveChat.map((c) => (
            <SingleCommentInception
              handleCommentDelete={handleCommentDelete}
              handleCommentEdit={handleCommentEdit}
              handleCommentPin={handleCommentPin}
              comment={c}
              handleOpenReplyBox={handleOpenReplyBox}
              handleLikeComment={handleLikeComment}
              handleOpenLikes={handleOpenLikes}
            />
          ))}

          {newChat && (
            <motion.div
              variants={bottom_in}
              initial="hidden"
              animate={"visible"}
              exit="exit"
              onClick={() => {
                scroll_to_bottom(setNewChat, setShowScrollArrow);
              }}
              className="new-chat-chip"
            >
              <span>New Comment</span>{" "}
              <Icon icon="akar-icons:arrow-down"></Icon>
            </motion.div>
          )}

          {showScrollArrow && !newChat && (
            <motion.div
              variants={bottom_in}
              initial="hidden"
              animate={"visible"}
              exit="exit"
              onClick={() => {
                scroll_to_bottom(setNewChat, setShowScrollArrow);
              }}
              className="new-chat-chip"
            >
              <span>Scroll to bottom</span>{" "}
              <Icon icon="akar-icons:arrow-down"></Icon>
            </motion.div>
          )}
        </div>
        <div id="bottom-add-comment-id" className="bottom-add-comment">
          <form>
            <div className="add-event-comment">
              <div className="add-event-comment-text-field">
                <TextField
                  className="mt-2 inputs-fields inception-input"
                  id="outlined-multiline-static"
                  label={isReply ? "Write a reply…" : "Write a comment…"}
                  multiline
                  rows={2}
                  name="description"
                  variant="outlined"
                  style={{ width: "100%" }}
                  required={true}
                  value={chatMessage}
                  onChange={(e) => setChatMessage(e.target.value)}
                />
                {formType === "EDIT" && (
                  <IconButton
                    className="comment-cancel-update"
                    onClick={handleRemoveAll}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </div>

              <div className="d-flex justify-content-end">
                {previews && (
                  <span className="chat-image-preview mt-2 me-auto">
                    <span onClick={handleRemove}>x</span>
                    <img src={previews} />
                  </span>
                )}
                <div className="mt-2 d-flex">
                  <span className="event-chat-upload-button mt-2">
                    <input
                      color="primary"
                      accept="image/*"
                      type="file"
                      id="event-chat-upload-button"
                      style={{ display: "none" }}
                      name="affirmationImage"
                      onChange={handleUpload}
                    />
                    <label htmlFor="event-chat-upload-button">
                      <img src={attchment_icon} alt="" srcSet="" />
                    </label>
                  </span>
                </div>
                {chatMessage ? (
                  <div
                    className="text-end mt-3 ms-1 mb-2 event-chat-send-button"
                    onClick={
                      !isSubmitting && formType === "ADD"
                        ? handleSendMessage
                        : handleMessageUpdate
                    }
                  >
                    <img src={send_chat_icon} alt="" srcSet="" />
                  </div>
                ) : (
                  <Tooltip title="Write a comment">
                    <div className="text-end mt-3 ms-1 mb-2 event-chat-send-button">
                      <img src={send_chat_icon} alt="" srcSet="" />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
}
