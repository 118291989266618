import React from "react";
import { warning_icon } from "src/assets";
import { websiteBaseUrl } from "src/config/config";
import { token_generate_for_upgrade_portal_api } from "src/DAL/WheelOfLife/WheelOfLifeApi";

export default function DashboardAgreementCard({ data, setIsLoading }) {
  const handle_change_page = async () => {
    let page_link = `${websiteBaseUrl}${data.page_data.sale_page_title_slug}/agreement/${data.plan_data.plan_slug}`;
    setIsLoading(true);
    let page_url = {
      page_url: page_link,
    };
    const result = await token_generate_for_upgrade_portal_api(page_url);
    if (result.code === 200) {
      window.open(`${websiteBaseUrl}auth-redirect-controller/${result.token}`);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="dashboard-notification-card">
      <div className="d-flex">
        <img src={data.icon} alt="" className="warning-icon" />
        {data.plan_data?.agrement_configration?.agreement_alert_description && (
          <div
            dangerouslySetInnerHTML={{
              __html:
                data.plan_data?.agrement_configration
                  ?.agreement_alert_description,
            }}
            className="ps-2 w-100"
          ></div>
        )}
      </div>
      <div className="text-center">
        <button onClick={handle_change_page}>Sign Now</button>
      </div>
    </div>
  );
}
