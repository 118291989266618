import React from "react";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";

function TwitterFeeds({
  currenEvents,
  upcomingEvents,
  feeds_type,
  eventDetail,
}) {
  if (currenEvents.length > 0 || upcomingEvents.length > 0) {
    return (
      <div
        className={`profile-cards p-3 community-card-title ${
          feeds_type === "general" && "mt-3"
        }`}
      >
        <div className="row current-events">
          {currenEvents && currenEvents.length > 0 && (
            <h4>
              {feeds_type === "event"
                ? eventDetail.top_text
                  ? eventDetail.top_text
                  : "CURRENT EVENTS"
                : "CURRENT EVENTS"}
            </h4>
          )}
          {currenEvents &&
            currenEvents.map((curren_events, index) => {
              return (
                <div key={index}>
                  <div className="col-12 mt-2">
                    <h6>{htmlDecode(curren_events.title)}</h6>
                    <a
                      href={curren_events.button_link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img src={s3baseUrl + curren_events.images.thumbnail_2} />
                    </a>
                    <p
                      className="mt-2"
                      dangerouslySetInnerHTML={{
                        __html: curren_events.description,
                      }}
                    ></p>
                  </div>

                  <div className="col-12 p-0 mt-2 community-star-icon text-center">
                    {/* <img src={communityStarsImage} /> */}
                    {curren_events.button_text && (
                      <a
                        href={curren_events.button_link}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <button className="join-today-btn mt-0">
                          {htmlDecode(curren_events.button_text)}
                        </button>
                      </a>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        {upcomingEvents && upcomingEvents.length > 0 && (
          <h4 className="mt-4">
            {feeds_type === "event"
              ? eventDetail.bottom_text
                ? eventDetail.bottom_text
                : "UPCOMING EVENTS"
              : "UPCOMING EVENTS"}
          </h4>
        )}
        {upcomingEvents &&
          upcomingEvents.map((upcoming_events, index) => {
            return (
              <div className="row upcoming-events" key={index}>
                <div className="col-8">
                  <h6>{htmlDecode(upcoming_events.title)}</h6>
                </div>
                <div className="col-12">
                  <a
                    href={upcoming_events.button_link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src={s3baseUrl + upcoming_events.images.thumbnail_2} />
                  </a>
                  <p
                    className="mt-2"
                    dangerouslySetInnerHTML={{
                      __html: upcoming_events.description,
                    }}
                  ></p>
                </div>
                <div className="col-12 p-0 community-star-icon text-center">
                  {upcoming_events.button_text && (
                    <a
                      href={upcoming_events.button_link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <button className="join-today-btn mt-0">
                        {htmlDecode(upcoming_events.button_text)}
                      </button>
                    </a>
                  )}
                </div>
              </div>
            );
          })}
        {/* <div className="row upcoming-events">
        <div className="col-8">
          <h6>THE MINSET CODE</h6>
          <p>April 28th 2022 - 90 Day Webinar</p>
        </div>
        <div className="col-2 mt-2">
          <img src={communityTickImage} />
        </div>
        <div className="col-2 mt-2">
          <img src={communityLayer3Image} />
        </div>
      </div> */}
      </div>
    );
  } else {
    return <></>;
  }
}

export default TwitterFeeds;
