import { CircularProgress, Divider, Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import NintyDaySliders from "src/components/NintyDay/NintyDaySliders";
import StartDateCard from "src/components/NintyDay/StartDateCard";
import { DeleteEarning, get_member_earning_api } from "src/DAL/90Day/NinetyDay";
import EarningList from "src/components/NintyDay/EarningList";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import ManageEarnings from "./ManageEarnings";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { dateDifferenceInDays } from "src/utils/constants";
import ChartJs from "src/components/NintyDay/ChartJs";
import { useNavigate } from "react-router-dom";
import PageDescription from "src/components/GeneralComponents/PageDescription";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function NintyDayTracker() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [startDateUpdated, setStartDateUpdated] = useState(null);
  const [targetAmount, setTargetAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [targetAmountUpdated, setTargetAmountUpdated] = useState(0);
  const [planInfo, setPlanInfo] = useState({});
  const [memberEarning, setMemberEarning] = useState([]);
  const [sliderDay, setSliderDay] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("ADD");
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [contentSettings, setContentSetting] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [resultData, setResultData] = useState({});

  const handleOpenDrawer = () => {
    setDrawerState(true);
    setSelectedValues();
  };
  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const getEarningList = async () => {
    const result = await get_member_earning_api();
    if (result.code == 200) {
      setResultData(result);
      setPlanInfo(result.user_ninety_day_plan);
      let { ninety_day_tracker, start_date, target_amount } =
        result.user_ninety_day_plan;

      setTotalAmount(result.total_earning);
      setContentSetting(result.ninety_day_content_settings);
      if (target_amount) {
        setTargetAmountUpdated(target_amount);
        setTargetAmount(target_amount);
      }
      if (start_date && start_date !== "Invalid date") {
        setStartDate(start_date);
        setStartDateUpdated(start_date);
        setIsDisabled(false);
        // day_to_show_on_slider
        if (ninety_day_tracker?.length > 0) {
          let day_to_show_on_slider = dateDifferenceInDays(
            start_date.toString(),
            ninety_day_tracker[0].date.toString()
          );
          if (day_to_show_on_slider > 0) {
            setSliderDay(day_to_show_on_slider + 1);
          }
        } else {
          setSliderDay(1);
        }
      }

      //in earing first index should be open
      if (ninety_day_tracker?.length > 0) {
        let questionsArray = ninety_day_tracker;
        questionsArray[0].isExpanded = true;
        setMemberEarning(questionsArray);
      }

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const expandArea = (index) => {
    let temp_state = [...memberEarning];
    let temp_element = { ...temp_state[index] };
    temp_element.isShowDots = !temp_element.isShowDots;
    temp_element.isExpanded = !temp_element.isExpanded;
    temp_state[index] = temp_element;
    setMemberEarning(temp_state);
  };

  //Getting Note In textarea
  const handleEdit = (value) => {
    setSelectedValues(value);
    setDrawerState(true);
    setFormType("EDIT");
  };

  const handleAgreeDelete = (value) => {
    setSelectedValues(value._id);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  // Deleting earning
  const handleDelete = async (e) => {
    const result = await DeleteEarning(selectedValues);
    if (result.code == 200) {
      const data = memberEarning.filter(
        (earning) => earning._id !== selectedValues
      );
      setMemberEarning(data);
      setOpenDelete(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigate = (is_graph) => {
    navigate(`/ninety-day-tracker/previous-histories`, {
      state: {
        is_graph: is_graph,
      },
    });
  };

  const handleClosePlan = () => {
    setMemberEarning([]);
    setTotalAmount(0);
    setTargetAmountUpdated(0);
    setTargetAmount("");
    setSliderDay(1);
    setStartDate(null);
    setStartDateUpdated(null);
    setIsDisabled(true);
  };

  useEffect(() => {
    getEarningList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="mb-4">
        <PageDescription
          parameter="ninteen_day_chart_description"
          else_title="90 Day Tracker"
        />
      </div>
      <div className="row">
        <div className="col-12">
          <ChartJs
            memberEarning={memberEarning}
            targetToAchieve={targetAmountUpdated}
            totalAmount={totalAmount}
            startDate={startDateUpdated}
            planInfo={planInfo}
          />
        </div>
        <div className="col-12 mt-5">
          <StartDateCard
            startDate={startDate}
            startDateUpdated={startDateUpdated}
            targetAmount={targetAmount}
            setStartDate={setStartDate}
            setStartDateUpdated={setStartDateUpdated}
            setTargetAmount={setTargetAmount}
            contentSettings={contentSettings}
            onClosePlan={handleClosePlan}
            setIsDisabled={setIsDisabled}
            setTargetAmountUpdated={setTargetAmountUpdated}
          />
        </div>
        <div className="col-12">
          <NintyDaySliders
            sliderDay={sliderDay}
            totalAmount={totalAmount}
            targetAmount={targetAmountUpdated}
          />
        </div>
        <div className="col-12 my-4">
          <Divider />
        </div>
        <div className="col-12 text-end mb-4">
          <Tooltip
            title={`${
              isDisabled && contentSettings.tooltip_text_for_unset_date
                ? contentSettings.tooltip_text_for_unset_date
                : ""
            }`}
          >
            <button
              className="small-contained-button me-2"
              onClick={handleOpenDrawer}
              disabled={isDisabled}
            >
              Add New Earning
            </button>
          </Tooltip>
          {contentSettings.tracker_history_button_text &&
            resultData.is_history_available && (
              <>
                <button
                  className="small-contained-button me-2"
                  onClick={() => handleNavigate(false)}
                >
                  {contentSettings.tracker_history_button_text}
                </button>
                {contentSettings.tracker_graphs_button_text && (
                  <button
                    className="small-contained-button"
                    onClick={() => handleNavigate(true)}
                  >
                    {contentSettings.tracker_graphs_button_text}
                  </button>
                )}
              </>
            )}
        </div>
        {memberEarning.length > 0 && (
          <div className="col-12">
            <EarningList
              memberEarning={memberEarning}
              expandArea={expandArea}
              MENU_OPTIONS={MENU_OPTIONS}
            />
          </div>
        )}
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType === "ADD" ? "Add" : "Edit"} Earning`}
        componentToPassDown={
          <ManageEarnings
            reloadListing={getEarningList}
            editData={selectedValues}
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            startDate={startDate}
          />
        }
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete?"}
        handleAgree={handleDelete}
      />
    </div>
  );
}
