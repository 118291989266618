import {
  Card,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { s3baseUrl } from "src/config/config";
import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import {
  bottom_in,
  get_bottom_padding,
  get_pinned_comments_height,
  get_video_height,
  handleScrollListener,
  scroll_to_bottom,
} from "../live_event_functions";
import { attchment_icon, emoji_picker_icon, send_chat_icon } from "src/assets";
import SingleCommentInception from "./SingleCommentInception";
import { _dynamite_event_category_video_chat } from "src/DAL/Inception/Inception";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";

export default function CatagoryChatCard({ video, handleOpenLikes }) {
  const [liveChat, setLiveChat] = useState([]);
  const [pinnedComments, setPinnedComments] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const [newChat, setNewChat] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [chatHeight, setChatHeight] = useState(0);
  const [loadMorePath, setLoadMorePath] = useState("");
  let [loadMore, setLoadMore] = useState("");
  const observer = useRef();

  const handleCommentDelete = () => {};
  const handleCommentEdit = () => {};
  const handleCommentPin = () => {};
  const handleOpenReplyBox = () => {};
  const handleLikeComment = () => {};
  const handleRemoveAll = () => {};
  const handleRemove = () => {};

  const get_video_chats = async (chat, path) => {
    const result = await _dynamite_event_category_video_chat(path);
    if (result.code === 200) {
      setLiveChat([...chat, ...result.dynamite_event_category_video_chat]);
      setPinnedComments(result.dynamite_event_category_video_featured_chat);
      setPageNumber((page) => page + 1);
      setTotalPages(result.total_pages);
      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, loadMorePath, observer]
  );

  useEffect(() => {
    let height = document.getElementById(video._id).offsetHeight;
    setChatHeight(height - 16);
    console.log(height, "<=height", video._id, "<=video");
  }, [video._id]);

  useEffect(() => {
    if (!loadMore) {
      loadMore = `api/dynamite_event_category_video_chat/existing_chat/list/${video._id}?page=0&limit=50`;
    }
    get_video_chats(liveChat, loadMore);
  }, [video, loadMore]);

  const loadMoreData = () => {
    setIsLoadingMore(true);
    setLoadMore(loadMorePath);
  };

  return (
    <div className="mt-4 mt-lg-0">
      <Card
        style={{
          height: chatHeight,
        }}
        className="live-comments overflow-auto"
      >
        {pinnedComments.length > 0 && (
          <div id="pinned-comment" className="pinned-comment">
            {pinnedComments.map((c) => (
              <SingleCommentInception
                handleCommentDelete={handleCommentDelete}
                handleCommentEdit={handleCommentEdit}
                handleCommentPin={handleCommentPin}
                comment={c}
                handleOpenReplyBox={handleOpenReplyBox}
                handleLikeComment={handleLikeComment}
                handleOpenLikes={handleOpenLikes}
                is_pinned={true}
              />
            ))}
          </div>
        )}
        <div
          onScroll={() => {
            handleScrollListener(setNewChat, setShowScrollArrow);
          }}
          style={{
            // paddingBottom: get_bottom_padding(),
            height: chatHeight - get_pinned_comments_height(),
            // paddingTop:
            //   pinnedComments.length > 0 ? 5 + get_pinned_comments_height() : 0,
          }}
        >
          {isLoading ? (
            <CircularProgress
              className="notification-popover-loader"
              color="primary"
            />
          ) : liveChat.length === 0 ? (
            <div className="mt-5">
              <RecordNotFound title="Chat not found" />
            </div>
          ) : (
            liveChat.map((c) => (
              <SingleCommentInception
                handleCommentDelete={handleCommentDelete}
                handleCommentEdit={handleCommentEdit}
                handleCommentPin={handleCommentPin}
                comment={c}
                handleOpenReplyBox={handleOpenReplyBox}
                handleLikeComment={handleLikeComment}
                handleOpenLikes={handleOpenLikes}
              />
            ))
          )}
          {totalPages > pageNumber && !isLoading ? (
            <div className="col-12 text-center p-2">
              <span
                ref={lastBookElementRef}
                className=" mt-3"
                onClick={loadMoreData}
                id="load-more-feed"
              >
                {isLoadingMore ? "Loading..." : "Load More"}
              </span>
            </div>
          ) : (
            ""
          )}

          {newChat && (
            <motion.div
              variants={bottom_in}
              initial="hidden"
              animate={"visible"}
              exit="exit"
              onClick={() => {
                scroll_to_bottom(setNewChat, setShowScrollArrow);
              }}
              className="new-chat-chip"
            >
              <span>New Comment</span>{" "}
              <Icon icon="akar-icons:arrow-down"></Icon>
            </motion.div>
          )}

          {showScrollArrow && !newChat && (
            <motion.div
              variants={bottom_in}
              initial="hidden"
              animate={"visible"}
              exit="exit"
              onClick={() => {
                scroll_to_bottom(setNewChat, setShowScrollArrow);
              }}
              className="new-chat-chip"
              style={{
                bottom: "20px",
              }}
            >
              <span>Scroll to bottom</span>{" "}
              <Icon icon="akar-icons:arrow-down"></Icon>
            </motion.div>
          )}
        </div>
        {/* <div id="bottom-add-comment-id" className="bottom-add-comment">
          <form>
            <div className="add-event-comment">
              <div className="add-event-comment-text-field">
                <TextField
                  // className="mt-2 inputs-fields inception-input"
                  className="mt-2 inception-input"
                  id="outlined-multiline-static"
                  label={isReply ? "Write a reply…" : "Write a comment…"}
                  multiline
                  rows={2}
                  name="description"
                  variant="outlined"
                  style={{ width: "100%" }}
                  required={true}
                  value={chatMessage}
                  onChange={(e) => setChatMessage(e.target.value)}
                />
                {formType === "EDIT" && (
                  <IconButton
                    className="comment-cancel-update"
                    onClick={handleRemoveAll}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </div>
              <div className="d-flex justify-content-between">
                <div className="text-start mt-3 mb-2 event-chat-emoji-picker-button">
                  <img
                    src={emoji_picker_icon}
                    onClick={() => {
                      setShowEmojiPicker((prevState) => !prevState);
                    }}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  {previews && (
                    <span className="chat-image-preview mt-2 me-auto">
                      <span onClick={handleRemove}>x</span>
                      <img src={previews} />
                    </span>
                  )}
                  <div className="mt-2 d-flex">
                    <span className="event-chat-upload-button mt-2">
                      <input
                        color="primary"
                        accept="image/*"
                        type="file"
                        id="event-chat-upload-button"
                        style={{ display: "none" }}
                        name="affirmationImage"
                        onChange={handleUpload}
                      />
                      <label htmlFor="event-chat-upload-button">
                        <img src={attchment_icon} alt="" srcSet="" />
                      </label>
                    </span>
                  </div>

                  {chatMessage ? (
                    <div
                      className="text-end mt-3 ms-1 mb-2 event-chat-send-button"
                      onClick={
                        !isSubmitting && formType === "ADD"
                          ? handleSendMessage
                          : handleMessageUpdate
                      }
                    >
                      <img src={send_chat_icon} alt="" srcSet="" />
                    </div>
                  ) : (
                    <Tooltip title="Write a comment">
                      <div className="text-end mt-3 ms-1 mb-2 event-chat-send-button">
                        <img src={send_chat_icon} alt="" srcSet="" />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div> */}
      </Card>
    </div>
  );
}
