import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { cointlogo } from "src/assets";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import ActionBarComponentProps from "./calender";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import moment from "moment";
import {
  addDynamiteDiaryApi,
  deleteDiaryApi,
  diaryListWithDate,
  updateDynamiteDiaryApi,
  update_reminder_time_api,
} from "src/DAL/dynamiteDiary/dynamiteDiary";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import { ReactVideoPlayer } from "src/components";
import WaveformAudioPlayer from "src/components/WaveformAudioPlayer";
import { projectMode, s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { get_date_with_user_time_zone } from "src/utils/constants";
import {
  addMeditationApi,
  deleteMeditationApi,
  meditationListWithDate,
  updateMeditationApi,
} from "src/DAL/meditationApi/meditationApi";
import NewWaveAudioPlayer from "src/components/NewWaveAudioPlayer";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Meditation = () => {
  const { userInfo, adminTimeZone } = useContentSetting();
  const get_update_time_with_time_zone = (date) => {
    let new_date = get_date_with_user_time_zone(
      date,
      "YYYY-MM-DD",
      userInfo.time_zone,
      adminTimeZone
    );
    return new_date;
  };
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const [addArray, setaddArray] = useState([
    { option: "" },
    { option: "" },
    { option: "" },
  ]);
  const [diaryDate, setDiaryDate] = useState(
    get_update_time_with_time_zone(new Date())
  );
  const [firstDiaryDate, setFirstDiaryDate] = useState(
    get_update_time_with_time_zone(new Date())
  );
  const [diaryDateSelection, setDiaryDateSelection] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [objectDataAvailable, setObjectDataAvailable] = useState(false);
  const [objectData, setObjectData] = useState(false);
  const [isDateUpdate, setIsDateUpdate] = useState(false);
  const [isSubmitReminder, setIsSubmitReminder] = useState(false);
  const [reminderTime, setReminderTime] = useState("2018-01-01 00:00 am");
  const [prevDiaries, setPrevDiaries] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openHide, setOpenHide] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [editValue, setEditValue] = useState("");
  const [editDiaryId, setEditDiaryId] = useState("");
  const [diarySetting, setDiarySetting] = useState();
  const [totalCoins, setTotalCoins] = useState(0);
  const [dailyMessage, setDailyMessage] = useState({});
  const [hideVideo, setHideVideo] = useState(
    localStorage.getItem("hide_meditation_video")
  );
  const [inputs, setInputs] = useState({
    note: "",
    note_2: "",
  });

  const handleChangeNote = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const getDiaryAccordingDate = async (value) => {
    if (value == "load") {
    } else {
      setIsLoading(true);
    }

    let todayDate = moment(diaryDate, "YYYY-MM-DD").format("DD/MM/YYYY");
    let postData = {};
    if (isDateUpdate) {
      postData = { type: "date", date: todayDate };
    } else if (params.id) {
      postData = { type: "_id", _id: params.id };
    } else {
      postData = { type: "date", date: todayDate };
    }

    const result = await meditationListWithDate(postData);
    if (result.code == 200) {
      setFirstDiaryDate(result.first_dynamite_meditation_date);
      if (result.meditation_reminder_time) {
        //2018-01-01 does nothing but timepicker supports to this format you can add event any date
        setReminderTime(`2018-01-01 ${result.meditation_reminder_time}`);
      }
      setDiarySetting(result.mediation_settings);
      setDailyMessage(result.daily_message);
      setTotalCoins(result.total_coins_count_attracted);
      if (Object.keys(result.meditation).length > 0) {
        setObjectDataAvailable(true);
        setEditDiaryId(result.meditation._id);
        setObjectData(result.meditation);
        let diaryObject = result.meditation;
        let formatedDate = result.meditation.date
          ? moment(result.meditation.date, "DD-MM-YYYY").format("YYYY-MM-DD")
          : moment(todayDate, "DD-MM-YYYY").format("YYYY-MM-DD");
        setTitle(diaryObject?.title);
        if (diaryObject?.statement_array) {
          setaddArray(diaryObject?.statement_array);
        }
        setDiaryDate(formatedDate);
        setNote(diaryObject?.note);
        setInputs((inputs) => ({
          ...inputs,
          ["note"]: diaryObject?.note,
          ["note_2"]: diaryObject?.note_2,
        }));
      } else {
        let date = moment(todayDate, "DD/MM/YYYY").format("YYYY-MM-DD");
        setDiaryDate(date);
        setTitle("");
        setNote("");
        setaddArray([{ option: "" }, { option: "" }, { option: "" }]);
        setObjectDataAvailable(false);
        setInputs((inputs) => ({
          ...inputs,
          ["note"]: "",
          ["note_2"]: "",
        }));
      }

      setPrevDiaries(result.prevoius_meditations);
      setIsLoading(false);
    }
  };

  const handleAdd = () => {
    const addedElement = [...addArray, { option: "" }];
    setaddArray(addedElement);
  };
  const handleChangeDate = (value) => {
    setIsDateUpdate(true);
    setDiaryDateSelection(value);
    setDiaryDate(value.$d);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };
  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteValue(value);
  };
  const handleDeleteDiary = async (value) => {
    const result = await deleteMeditationApi(deleteValue._id);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getDiaryAccordingDate();
      setOpenDelete(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDelete(false);
    }
  };
  const handleEdit = (value) => {
    setEditValue(value);
    setIsDateUpdate(false);
    navigate(`/mediation/edit-meditation/${value._id}`, {
      state: value,
    });
  };
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index][name] = value;
    setaddArray(list);
  };
  const handleNavigate = (e, index) => {
    navigate(`/mediation/past-activities`);
  };

  const handleAgreeHide = (value) => {
    setOpenHide(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    let postData = {
      title: title ? title : "",
      date: diaryDate
        ? moment(diaryDate).format("DD-MM-YYYY")
        : moment(new Date()).format("DD-MM-YYYY"),
      note: inputs.note,
      note_2: inputs.note_2,
      statement_array: addArray,
    };
    const result = await addMeditationApi(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      // navigate(`/mediation`);
      setIsSubmit(false);
      getDiaryAccordingDate("load");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmit(false);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    let postData = {
      title: title ? title : "",
      date: diaryDate?.$d
        ? moment(diaryDate?.$d).format("DD-MM-YYYY")
        : objectDataAvailable == true
        ? moment(diaryDate).format("DD-MM-YYYY")
        : moment(new Date()).format("DD-MM-YYYY"),
      note: inputs.note,
      note_2: inputs.note_2,
      statement_array: addArray,
    };
    const result = await updateMeditationApi(
      editDiaryId ? editDiaryId : objectData._id,
      postData
    );

    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      // getDiaryAccordingDate();
      // navigate(`/dynamite-diary`);
      setIsSubmit(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmit(false);
    }
  };
  const handleHideVideo = () => {
    localStorage.setItem("hide_meditation_video", true);
    setHideVideo(true);
    setOpenHide(false);
  };
  const handleShowVideo = () => {
    localStorage.setItem("hide_meditation_video", false);
    setHideVideo(false);
  };

  const handleUpdateReminderTime = async (e) => {
    e.preventDefault();
    setIsSubmitReminder(true);
    let postData = {
      meditation_reminder_time: moment(reminderTime).format("HH:mm"),
    };
    const result = await update_reminder_time_api(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsSubmitReminder(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmitReminder(false);
    }
  };

  const handleChangeReminderTime = (newValue) => {
    setReminderTime(newValue?.$d);
  };

  useEffect(() => {
    getDiaryAccordingDate();
  }, [diaryDateSelection, params]);

  useEffect(() => {
    if (Object.keys(objectData).length === 0) {
      setObjectDataAvailable(false);
    }
  }, [objectData]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete Meditation ?"}
        handleAgree={handleDeleteDiary}
      />
      <CustomConfirmation
        open={openHide}
        setOpen={setOpenHide}
        title={"Are you sure you want to hide video ?"}
        handleAgree={handleHideVideo}
      />

      <section className="container dynamite-diary">
        <div className="row ">
          <div className="col-lg-4">
            <PageDescription
              parameter="meditation_description"
              else_title="Meditation"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-3 mt-lg-2">
            <div className={`d-flex coint-image text-start `}>
              <img className="img-fluid" src={cointlogo}></img>
              <h4 className="coint-detail">
                Total Coins Attracted: {totalCoins}
              </h4>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-4 mt-md-3 mt-lg-2">
            <form
              className="d-flex text-start coint-image reminder-time-form"
              onSubmit={handleUpdateReminderTime}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Reminder Time"
                  value={reminderTime}
                  onChange={handleChangeReminderTime}
                  renderInput={(params) => (
                    <TextField {...params} size="small" required={true} />
                  )}
                />
              </LocalizationProvider>
              <button
                className="submit-button mb-0 ms-2"
                disabled={isSubmitReminder}
              >
                {isSubmitReminder ? "Saving..." : "Save"}
              </button>
            </form>
          </div>
          {(hideVideo == true || hideVideo == "true") && (
            <div className="col-lg-12 text-end mt-3">
              <button
                className="small-contained-button float-end mt-1"
                onClick={handleShowVideo}
              >
                Show Video
              </button>
            </div>
          )}
        </div>
        {JSON.stringify(diarySetting) == "{}" ? (
          ""
        ) : (
          <section className="container top-section-video mt-3 mb-3">
            <div className="row pt-70" id="assessment-container">
              {(hideVideo == false ||
                hideVideo == "false" ||
                hideVideo == null) && (
                <div className="col-lg-7 col-md-6">
                  <ReactVideoPlayer url={diarySetting?.diary_video_url} />
                  <FormControlLabel
                    control={
                      <Checkbox onClick={handleAgreeHide} checked={false} />
                    }
                    label={
                      diarySetting?.diary_dont_show_text
                        ? diarySetting?.diary_dont_show_text
                        : "Don't Show me this video again"
                    }
                  />
                </div>
              )}
              <div
                className={` ${
                  hideVideo == false ||
                  hideVideo == "false" ||
                  hideVideo == null
                    ? "col-lg-5 col-md-6"
                    : "col-12"
                } text-md-start text-center mt-md-0 mt-4`}
              >
                {dailyMessage && dailyMessage?.type == "audio" ? (
                  <div className="waveAudio">
                    <h4>{dailyMessage.title}</h4>
                    {/* <WaveformAudioPlayer
                      url={s3baseUrl + dailyMessage.media_url}
                      title={dailyMessage.title}
                      hideVideo={hideVideo}
                    /> */}
                    <NewWaveAudioPlayer
                      url={s3baseUrl + dailyMessage.media_url}
                    />
                  </div>
                ) : dailyMessage && dailyMessage?.type == "url" ? (
                  <>
                    <h4>{dailyMessage.title}</h4>
                    <ReactVideoPlayer
                      url={dailyMessage.media_url}
                      title={dailyMessage.title}
                    />
                  </>
                ) : (
                  ""
                )}
                <div
                  dangerouslySetInnerHTML={{
                    __html: diarySetting?.diary_video_description,
                  }}
                  className="diary-para"
                ></div>
              </div>
            </div>
          </section>
        )}
        <form
          className="container"
          onSubmit={objectDataAvailable == true ? handleUpdate : handleSubmit}
        >
          <div className="row">
            <div className="col-lg-7">
              <div className="d-flex">
                <div className="mt-2 field-container diray-title">
                  <h4>{diarySetting?.diary_title}</h4>
                </div>
              </div>

              {addArray?.map((data, index) => {
                return (
                  <div className="d-flex position-relative mt-3">
                    <div className="mt-2 field-container">
                      {/* <TextField
                        // className="mt-2"
                        id="outlined-basic"
                        label={`${index + 1}.`}
                        variant="outlined"
                        name="option"
                        required
                        value={data.option}
                        onChange={(e) => handleChange(e, index)}
                        sx={{
                          background: "#141717",
                          borderRadius: "5px",
                          input: {
                            "&::placeholder": {
                              opacity: 1,
                            },
                          },
                          label: { color: "#637381" },
                        }}
                      /> */}
                      {/* <label className="mb-2">
                        {diarySetting?.notes_placeholder_1}
                      </label> */}
                      <TextareaAutosize
                        aria-label="empty textarea"
                        placeholder={`${index + 1}.`}
                        className="text-color textarea-autosize textAreaAutoSize"
                        sx={{ color: "white" }}
                        minRows={1}
                        required
                        name="option"
                        value={data.option}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </div>
                    {/* <div className="icon-container"> */}
                    <span className="cross-icon">
                      {addArray.length > 1 ? (
                        <Tooltip title="Remove">
                          <RemoveCircleOutlineIcon
                            onClick={() => handleDelete(index)}
                            className="diary-icon-remove"
                          />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      <Tooltip title="Add">
                        <AddCircleOutlineIcon
                          onClick={() => handleAdd()}
                          className="diary-icon-add"
                        />
                      </Tooltip>
                    </span>
                    {/* </div> */}
                  </div>
                );
              })}
            </div>
            <div className="col-lg-5">
              <div className="d-flex calender w-100 full-open-calender">
                <ActionBarComponentProps
                  diaryDate={diaryDate}
                  setDiaryDate={setDiaryDate}
                  handleChangeDate={handleChangeDate}
                  firstDiaryDate={firstDiaryDate}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row">
              <div
                dangerouslySetInnerHTML={{
                  __html: diarySetting?.diary_journal_text,
                }}
                className="diary-para"
              ></div>
            </div>
            {/* <div className="col-12">
              <div className="mt-2">
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder={
                    diarySetting?.notes_placeholder
                      ? diarySetting?.notes_placeholder
                      : ""
                  }
                  className="text-color textarea-autosize"
                  value={note}
                  sx={{ color: "white" }}
                  minRows={7}
                  onChange={(e) => setNote(e.target.value)}
                  style={{
                    width: "100%",
                    borderRadius: "11px",
                    background: "#141717",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "15px",
                    color: "white",
                    fontSize: "15px",
                    border: "2px solid rgba(71, 66, 34, 0.3)",
                  }}
                />
              </div>
            </div> */}

            <div className="row mt-4">
              <div className="col-md-6 visioneering-textarea">
                <label className="mb-2">
                  {diarySetting?.notes_placeholder_1}
                </label>
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="Notes *"
                  className="text-color textarea-autosize textAreaAutoSize"
                  sx={{ color: "white" }}
                  minRows={4}
                  required
                  name="note"
                  value={inputs.note}
                  onChange={handleChangeNote}
                />
              </div>
              <div className="col-md-6 visioneering-textarea ps-lg-5">
                <label className="mb-2">
                  {diarySetting?.notes_placeholder_2}
                </label>
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="Notes *"
                  className="text-color textarea-autosize"
                  sx={{ color: "white" }}
                  minRows={4}
                  required
                  name="note_2"
                  value={inputs.note_2}
                  onChange={handleChangeNote}
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    background: "#141717",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "15px",
                    color: "white",
                    fontSize: "15px",
                    border: "2px solid rgba(71, 66, 34, 0.3)",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              {objectDataAvailable ? (
                <button
                  className="small-contained-button float-end mt-1 mb-4"
                  // onClick={() => handleUpdate()}
                >
                  {isSubmit ? "Updating.." : "Update"}
                </button>
              ) : (
                <button
                  className="small-contained-button float-end mt-1 mb-4"
                  // onClick={() => handleSubmit()}
                >
                  {isSubmit ? "Saving.." : "Save"}
                </button>
              )}
            </div>
          </div>
        </form>
        {prevDiaries.length > 0 ? (
          <div className="container mt-5">
            <div className="row">
              <div className="recent col-lg-12 d-flex justify-content-end me-2 pe-3">
                <p>Most Recent</p>
              </div>
              {prevDiaries?.map((value) => {
                return (
                  <div className="col-lg-6 mb-3" style={{ cursor: "pointer" }}>
                    <div className="mini-cards d-flex">
                      <div className="w-100 ms-2 me-2">
                        <div className="diary-font-weight d-flex">
                          <h4 onClick={() => handleEdit(value)}>
                            {value?.date ? value?.date : ""}
                          </h4>
                          <div className="d-flex">
                            <span
                              style={{ paddingTop: "2px" }}
                              onClick={() => handleEdit(value)}
                              className="diary-first-heading pe-2"
                            >
                              {value?.date_time
                                ? moment(value?.date_time).format("hh:mm A")
                                : ""}
                            </span>
                            <CustomPopover menu={MENU_OPTIONS} data={value} />
                          </div>
                        </div>

                        <div className="d-flex justify-content-between ml-3 mt-2 diary-first-heading">
                          <div onClick={() => handleEdit(value)}>
                            <h4>{value.statement_array[0]?.option}</h4>
                          </div>
                          {/* <div className="me-4">
                            <h4 className="date-heading">
                              {value?.date_time
                                ? moment(value?.date_time).format("hh:mm A")
                                : ""}
                            </h4>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <button
                  className="small-contained-button float-end mt-1 mb-4"
                  onClick={handleNavigate}
                >
                  Past Activities
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    </>
  );
};

export default Meditation;
