import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  programmDetail,
  programmeViewCountIncrease,
} from "../../DAL/Programmes/Programmes";
import { htmlDecode } from "../../utils/convertHtml";
import { s3baseUrl } from "../../config/config";
import { styled } from "@mui/material/styles";
import { ReactVideoPlayer } from "src/components";
import LessonsCardTab from "src/components/programmes/LessonsCardTab";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import ProgrammeRecordingTab from "src/components/programmes/ProgrammeRecordingTab";
import { NoAccess } from "src/components/programmes";
import ResourcesCardTab from "src/components/programmes/ResourcesCardTab";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import StudySessionCardTabs from "src/components/programmes/StudySessionCardTabs";
import RatingCardTab from "src/components/programmes/RatingCardTab";
import ProgramSectionTabs from "src/components/programmes/ProgramSectionTabs";
import ModuleCardTab from "src/components/programmes/ModuleCardTab";
import ReactVideoDurationPlayer from "src/components/ReactPlayers/ReactVideoDurationPlayer";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function ProgrammesDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [programmesDetail, setProgrammesDetail] = useState({});
  const [lessonsList, setLessonsList] = useState([]);
  const [modulesList, setModulesList] = useState([]);
  const [resourcesList, setResourcesList] = useState([]);
  const [reviewsList, setReviewsList] = useState([]);
  const [recordingList, setRecordingList] = useState([]);
  const [programSection, setProgramSection] = useState([]);
  const [studySession, setStudySession] = useState([]);
  const [videoProgressData, setVideoProgressData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [watched, setWatched] = useState(false);
  const [programModuleAccess, setProgramModuleAccess] = useState();
  const [programConfiguration, setProgramConfiguration] = useState();

  const ProgrammesImgStyle = styled("img")({
    width: "100%",
    height: "100%",
    objectFit: "cover",
  });

  const imageLinks = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const increaseWatchCount = async (type) => {
    const result = await programmeViewCountIncrease(type, params.slug);
    if (result.code === 200) {
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getProgrammesDetail = async () => {
    const result = await programmDetail(params.slug);
    increaseWatchCount("view");
    if (result.code === 200) {
      setProgrammesDetail(result.program);
      setProgramModuleAccess(result.is_program_module);
      setProgramConfiguration(result.program?.program_configration);
      let modules_array = [];
      result.program_module.map((lesson) => {
        modules_array.push({
          ...lesson,
          card_title: lesson.title,
          card_image: s3baseUrl + lesson.program_images?.thumbnail_3,
          card_short_description: lesson.short_description,
          card_classes: "col-12 mt-3",
          start_time: "",
        });
      });
      let data = [
        {
          recording_id: result.program?._id,
          video_duration: result.program?.video_duration,
          total_video_duration: result.program?.total_video_duration,
          is_complete: result.program?.is_complete,
        },
      ];
      setVideoProgressData(data);
      let lessons_array = [];
      result.lesson.map((lesson) => {
        lessons_array.push({
          ...lesson,
          card_title: lesson.title,
          card_image: s3baseUrl + lesson.lesson_images?.thumbnail_3,
          card_short_description: lesson.short_description,
          card_classes: "col-12 mt-3",
          start_time: "",
        });
      });
      setModulesList(modules_array);
      setLessonsList(lessons_array);
      setResourcesList(result.program_document);
      setReviewsList(result.program_review);

      let recording_array = [];
      result.recording_list.map((recording) => {
        recording_array.push({
          ...recording,
          card_title: recording.title,
          card_image: s3baseUrl + recording.recording_image.thumbnail_2,
          card_short_description: recording.short_description,
          card_classes: "col-12 mt-3",
        });
      });
      setRecordingList(recording_array);
      setProgramSection(result.program_section);

      let study_session_array = [];
      result.study_session.map((study_session) => {
        study_session_array.push({
          ...study_session,
          card_title: study_session.title,
          card_image: s3baseUrl + study_session.image.thumbnail_2,
          card_short_description: study_session.short_description,
          card_classes: "col-12 mt-3",
        });
      });
      setStudySession(study_session_array);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_programme_content = () => {
    if (programmesDetail.program_lock_status === true) {
      return {
        video_url: programmesDetail.locked_program_info.video_url,
        detailed_description:
          programmesDetail.locked_program_info.detailed_description,
        image: programmesDetail.locked_program_info.image,
      };
    } else {
      return {
        video_url: programmesDetail.video_url,
        audio_file: programmesDetail.audio_file
          ? s3baseUrl + programmesDetail.audio_file
          : "",
        detailed_description: programmesDetail.detailed_description,
        image: programmesDetail?.program_images?.thumbnail_1,
      };
    }
  };

  const handleTabClick = (e, row) => {
    navigate(`/programmes/${params.slug}/${row.tab_slug}`);
  };

  const TABS_OPTIONS = [];
  const numAscending =
    programConfiguration &&
    [...programConfiguration].sort((a, b) => +a.order - +b.order);
  numAscending?.map((value, i) => {
    if (value?.document_tab_status) {
      TABS_OPTIONS.push({
        title: value?.document_tab_title,
        tab_slug: "resources-tab",
        component:
          programmesDetail.program_lock_status === false ? (
            <ResourcesCardTab data={resourcesList} imageLink={imageLinks} />
          ) : (
            <NoAccess title="You have no access to see the Resource" />
          ),
        onClick: handleTabClick,
      });
    }
    if (value?.reviews_tab_status) {
      TABS_OPTIONS.push({
        title: value?.reviews_tab_title,
        tab_slug: "reviews-tab",
        component: <RatingCardTab reviews={reviewsList} />,
        onClick: handleTabClick,
      });
    }
    if (value?.recording_tab_status) {
      TABS_OPTIONS.push({
        title: value?.recording_tab_title,
        tab_slug: "recordings-tab",
        component:
          programmesDetail.program_lock_status === false ? (
            <ProgrammeRecordingTab data={recordingList} />
          ) : (
            <NoAccess title="You have no access to see the Recordings" />
          ),
        onClick: handleTabClick,
      });
    }
    if (value?.lesson_tab_status) {
      TABS_OPTIONS.push({
        title: value?.lesson_tab_title,
        tab_slug: "lessons-tab",
        component: (
          <LessonsCardTab
            lessons={lessonsList}
            programmesDetail={programmesDetail}
          />
        ),
        onClick: handleTabClick,
      });
    }
    if (value?.module_tab_status) {
      TABS_OPTIONS.push({
        title: value?.module_tab_title,
        tab_slug: "module-tab",
        component: (
          <ModuleCardTab
            lessons={modulesList}
            programmesDetail={programmesDetail}
            programLockStatus={programmesDetail.program_lock_status}
            programSlug={params.slug}
          />
        ),
        onClick: handleTabClick,
      });
    }
    if (value?.section_tab_status) {
      if (studySession.length > 0) {
        TABS_OPTIONS.push({
          title: value?.section_tab_title,
          tab_slug: "study-session-tab",
          onClick: handleTabClick,
          component:
            programmesDetail.program_lock_status === false ? (
              <StudySessionCardTabs
                studySession={studySession}
                imageLink={imageLinks}
              />
            ) : (
              <NoAccess title="You have no access to see the Study Sessions" />
            ),
        });
      }
    }
  });

  programSection.length > 0 &&
    programSection.map((Section) => {
      TABS_OPTIONS.push({
        title: Section.title,
        tab_slug: Section.section_slug,
        onClick: handleTabClick,
        component:
          programmesDetail.program_lock_status === false ? (
            <ProgramSectionTabs
              programSection={Section}
              getProgrammesDetail={getProgrammesDetail}
            />
          ) : (
            <NoAccess
              title={`You have no access to see the ${Section.title}`}
            />
          ),
      });
    });

  useEffect(() => {
    getProgrammesDetail();
  }, []);

  useEffect(() => {
    if (watched == false) {
    } else {
      increaseWatchCount("watch");
    }
  }, [watched]);

  useEffect(() => {
    setTimeout(() => {
      let indexOfTab = 0;
      if (params.tab_slug) {
        indexOfTab = TABS_OPTIONS?.findIndex(
          (x) => x.tab_slug === params.tab_slug
        );
      }
      setTabValue(indexOfTab);
    }, 1000);
  }, [TABS_OPTIONS]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin ">
        <div className="col-12 d-flex w-100 justify-content-between ">
          <div className="d-flex">
            <span>
              <IconButton
                className="back-screen-button"
                onClick={() => navigate("/programmes")}
              >
                <ArrowBackIcon />
              </IconButton>
            </span>
            <h2 className="ms-2">
              {location.state
                ? htmlDecode(location.state.title)
                : htmlDecode(programmesDetail.title)}
            </h2>
          </div>

          {programmesDetail.program_lock_status === false ? (
            programModuleAccess ? (
              ""
            ) : (
              <button
                className="small-contained-button float-end mt-1"
                onClick={() => navigate(`/programmes/${params.slug}/lessons`)}
              >
                View Lessons
              </button>
            )
          ) : programModuleAccess ? (
            ""
          ) : (
            <button
              className="small-contained-button float-end mt-1 disabled"
              disabled="disabled"
            >
              View Lessons
            </button>
          )}
        </div>
      </div>
      <div className="row section-space"></div>
      <div className="row media-margin">
        <div className="col-12">
          {get_programme_content().video_url ? (
            <ReactVideoDurationPlayer
              url={get_programme_content().video_url}
              type={"program"}
              SaveInfo={"program"}
              value={programmesDetail}
              setVideoProgressData={setVideoProgressData}
              videoProgressData={videoProgressData}
            />
          ) : get_programme_content().image ? (
            <ProgrammesImgStyle
              src={s3baseUrl + get_programme_content().image}
            />
          ) : (
            ""
          )}
        </div>
        {get_programme_content().audio_file && (
          <div className="col-12 section-space">
            <audio
              controlsList="nodownload"
              className="w-100"
              src={get_programme_content().audio_file}
              controls
            />
          </div>
        )}

        <div className="col-12 section-space set-image-center">
          <div
            dangerouslySetInnerHTML={{
              __html: get_programme_content().detailed_description,
            }}
          ></div>
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default ProgrammesDetail;
