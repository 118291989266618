import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { live_stream_by_module_id_api } from "src/DAL/LiveStream/LiveStream";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import ParticipantInfoCard from "./ParticipantInfoCard";

function sortByJoinTimeDescending(data) {
  return data.sort((a, b) => new Date(b.join_time) - new Date(a.join_time));
}

function Participants(props) {
  const { module_id, openParticipants, participants, setParticipants } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const { userInfo } = useContentSetting();

  const getParticipantsList = async () => {
    const result = await live_stream_by_module_id_api(module_id);
    if (result.code === 200) {
      let participants = result.stream.participants.filter(
        (participant) => participant.user_stream_status === "joined"
      );
      setParticipants(sortByJoinTimeDescending(participants));
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (openParticipants) {
      getParticipantsList();
    }
  }, [openParticipants]);

  if (isLoading) {
    return (
      <div className="participants-popover">
        <CircularProgress
          className="notification-popover-loader"
          color="primary"
        />
      </div>
    );
  }

  return (
    <div className="">
      {participants.map((participant) => (
        <ParticipantInfoCard
          profile_image={participant.profile_image}
          user_name={`${participant.first_name + " " + participant.last_name} ${
            participant._id === userInfo._id ? "(You)" : ""
          }`}
          date={participant.join_time}
        />
      ))}
    </div>
  );
}

export default Participants;
