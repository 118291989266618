import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";

import { get_app_logo, project_name } from "../config/config";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};
export default function Logo({ sx }) {
  const logo = get_app_logo();
  return <Box component="img" src={logo} sx={{ width: 200, ...sx }} />;
}
