import React from "react";
import { useNavigate } from "react-router-dom";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import SideImageCard from "src/components/GeneralComponents/SideImageCard";
import ChallengeListCard from "./ChallengeListCard";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ChallengeCardTab({
  challenge,
  programLockStatus,
  programSlug,
  MENU_OPTIONS,
  handleEdit,
  isLoading,
}) {
  const navigate = useNavigate();
  const classes = useStyles();
  const handleClick = (lesson) => {
    if (programLockStatus === false) {
      navigate(`/challenge/${lesson.lesson_slug}`);
    }
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      {challenge.length > 0 ? (
        <ChallengeListCard
          data={challenge}
          handleClick={handleEdit}
          MENU_OPTIONS={MENU_OPTIONS}
          handleEdit={handleEdit}
        />
      ) : (
        <RecordNotFound title="challenge Not Found" />
      )}
    </>
  );
}
