import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { ReactVideoPlayer } from "src/components";
import ReactVideoPlayerForMobile from "src/components/ReactPlayers/ReactVideoPlayerForMobile";

export default function MobilePlayer() {
  const params = useParams();
  const location = useLocation();
  let timeToSeek = location?.search?.split("=")[1];
  console.log(timeToSeek, "timeToSeek");

  return <ReactVideoPlayerForMobile url={params.url} seekTo={timeToSeek} />;
}
