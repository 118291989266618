import React, { useState, useEffect, useRef } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const GPlacesAutocomplete = (props) => {
  const {
    onSelect,
    street,
    handleValidationCheck,
    disabled,
    handleChaAddress,
  } = props;
  const [address, setAddress] = useState("");
  const inputRef = useRef(null);

  const handleChange = (newAddress) => {
    handleChaAddress(newAddress);
  };

  const handleBlur = (e) => {
    if (!!handleValidationCheck) {
      handleValidationCheck(e);
    }
  };

  const handleSelect = (selectedAddress) => {
    geocodeByAddress(selectedAddress)
      .then((results) => {
        console.log(results, "resultsresultsresults");
        let temp = { formated_address: results[0].formatted_address };
        results[0].address_components.forEach((res) => {
          console.log(res, "resresresresresresresresresres");
          let find_country = res.types.find(
            (type) =>
              type == "country" ||
              type == "establishment" ||
              type == "natural_feature"
          );
          let find_state = res.types.find(
            (type) => type == "administrative_area_level_1"
          );
          let find_city = res.types.find(
            (type) =>
              type == "administrative_area_level_2" ||
              type == "sublocality" ||
              type == "locality" ||
              type == "neighborhood"
          );
          let find_street2 = res.types.find(
            (type) => type == "administrative_area_level_3"
          );
          let find_postal = res.types.find((type) => type == "postal_code");
          if (find_country) {
            temp.country = res.short_name;
          } else if (find_state) {
            temp.state = res.long_name;
          } else if (find_city) {
            let city_name = res.long_name.replace(/\bDistrict\b/g, "");
            temp.city = city_name;
          } else if (find_street2) {
            temp.street2 = res.long_name;
          } else if (find_postal) {
            temp.zip_code = res.long_name;
          }
        });
        setAddress(temp.formated_address);
        onSelect(temp);
        return getLatLng(results[0]);
      })
      .then((newLatLng) => {
        console.log(newLatLng, "newLatLng_newLatLng");
      })
      .catch((error) => console.error("Error", error));
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setAttribute("autoComplete", "off");
    }
  }, []); // Ru

  if (disabled) {
    return <div className="disabled-address">{street}</div>;
  }

  return (
    <PlacesAutocomplete
      value={street}
      onChange={handleChange}
      onSelect={handleSelect}
      highlightFirstSuggestion
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <div className="position-relative">
            <input
              type="text"
              required
              placeholder="Address:*"
              className="autocomplete-input"
              value={street}
              name="street"
              min={3}
              autoComplete="off" // Set autoComplete to "off"
              ref={inputRef}
              {...getInputProps({
                placeholder: "Address *",
                onBlur: (e) => {
                  handleBlur(e);
                },
              })}
            />

            <div className="autocomplete-dropdown-container">
              {loading && <div></div>}
              {suggestions.length > 0 &&
                suggestions.map((suggestion, i) => {
                  if (suggestion.description) {
                    const description = suggestion.description
                      .normalize("NFD") // Normalize to decomposed form
                      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
                      .replace(/[^a-zA-Z0-9]/g, " ") // Replace non-alphanumeric characters with spaces;
                      .toLowerCase();

                    let matchingPart = "";
                    let nonMatchingPart = "";
                    if (description.startsWith(address.toLowerCase())) {
                      matchingPart = suggestion.description.slice(
                        0,
                        address.length
                      );
                      nonMatchingPart = suggestion.description
                        .slice(address.length)
                        .trim();
                    }

                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";

                    return (
                      <div
                        key={i}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                        })}
                      >
                        {matchingPart && nonMatchingPart ? (
                          <>
                            <i class="fa-solid fa-location-dot"></i>
                            <b>{matchingPart}</b>
                            <span>{nonMatchingPart}</span>
                          </>
                        ) : (
                          <>
                            <i class="fa-solid fa-location-dot me-1"></i>
                            <span>{suggestion.description}</span>
                          </>
                        )}
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default GPlacesAutocomplete;
