import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Icon } from "@iconify/react";
import CommunitySliderImages from "./CommunitySliderImages";

const CommunitySlider = ({ data }) => {
  var data_length = data.length;
  let set_infinite = true;
  if (data_length < 6) {
    set_infinite = false;
  }
  var settings = {
    dots: false,
    infinite: set_infinite,
    speed: 1000,
    slidesToShow: 5,
    autoplay: true,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="event-slider community-slider">
      {data.map((value, index) => (
        <CommunitySliderImages data={value} key={index} />
      ))}
    </Slider>
  );
};
export default CommunitySlider;
