import React from "react";

export default function CongratulationMessage(props) {
  const { thanks_description, handleCross } = props;

  return (
    <div className="unlock-video p-2 congratulation-message">
      <div className="unlock-popup-cross-icon" onClick={handleCross}>
        x
      </div>
      {thanks_description && (
        <div
          dangerouslySetInnerHTML={{
            __html: thanks_description,
          }}
          className="pe-3"
        ></div>
      )}
    </div>
  );
}
