import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { videoDurationapi } from "src/DAL/Programmes/Programmes";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useSnackbar } from "notistack";
export default function ReactNormailVideoPlayerNew(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [videoDuration, setVideoDuration] = useState();
  const [videoProgress, setVideoProgress] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const playerRef = useRef();
  const { handleVideoList } = useContentSetting();
  const setVideoPlayed = () => {
    setIsPlay(true);
    if (props.setting && props.setting.video_type !== "repeat") {
      localStorage.setItem("is_seen_video", "Yes");
    }
  };
  const handleVideoDuration = (duration) => {
    setVideoDuration(duration);
  };
  const handleVideoProgress = (progress) => {
    setVideoProgress(progress);
  };
  const handleVideoPause = async () => {
    setIsPlay(false);
    const videoDurationString = videoDuration.toString();
    const playedSecondsString = videoProgress.playedSeconds.toString();
    if (videoDuration - videoProgress.playedSeconds > 2) {
      let postData = {
        recording_id: props.value._id,
        video_duration: playedSecondsString,
        is_complete: false,
        total_video_duration: videoDurationString,
        type: props.type,
      };
      const result = await videoDurationapi(postData, props.type);
      if (result.code === 200) {
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
    console.log("pause function");
  };

  const handleVideoEnded = async (progress) => {
    console.log("end function");
    const videoDurationString = videoDuration.toString();
    const playedSecondsString = videoProgress.playedSeconds.toString();
    let postData = {
      recording_id: props.value._id,
      video_duration: playedSecondsString,
      is_complete: true,
      total_video_duration: videoDurationString,
      type: props.type,
    };
    const result = await videoDurationapi(postData, props.type);
    if (result.code === 200) {
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const onReady = useCallback(() => {
    if (!isReady) {
      let timeToStart = 0;
      timeToStart = parseFloat(props.value?.video_duration - 0.5);
      if (timeToStart <= 0) {
        playerRef.current.seekTo(0);
      } else {
        playerRef.current.seekTo(timeToStart, "seconds");
      }

      setIsReady(true);
    }
  }, [isReady]);

  const updateVideoData = () => {
    const videoList = JSON.parse(localStorage.getItem("video_list")) || [];
    const videoIndex = Array.isArray(videoList)
      ? videoList?.findIndex((video) => video.recording_id === props?.value._id)
      : -1;

    // If the video is found
    const videoDurationString = videoDuration.toString();
    const playedSecondsString = videoProgress.playedSeconds.toString();
    if (videoIndex !== -1) {
      videoList[videoIndex].video_duration = playedSecondsString;
      videoList[videoIndex].is_complete = false;
    }
    localStorage.setItem("video_list", JSON.stringify(videoList));
    localStorage.setItem("video_type", props.type);
    handleVideoList(videoList);
  };
  useEffect(() => {
    if (isPlay) {
      updateVideoData();
    }
  }, [videoProgress]);
  return (
    <ReactPlayer
      ref={playerRef}
      controls
      url={props.url}
      width="100%"
      pip
      stopOnUnmount
      playsinline
      // volume={0}
      className="set-max-height"
      // muted={0}
      onPlay={setVideoPlayed}
      onDuration={handleVideoDuration}
      onProgress={handleVideoProgress}
      onPause={handleVideoPause}
      onEnded={handleVideoEnded}
      onReady={onReady}
    />
  );
}
