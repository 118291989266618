import React from "react";
import { Chip, Tooltip } from "@mui/material";
import { websiteBaseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import CustomMUITable from "src/components/CustomMUITable/CustomMUITable";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";

function SalePages({ title, sourceSessions }) {
  const { userInfo } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const handleNavigate = (value) => {
    window.open(
      `${websiteBaseUrl}${value.sale_page_title_slug}/${userInfo.affiliate_url_name}`,
      "_blank"
    );
  };

  const handleCopiedUrl = () => {
    enqueueSnackbar("URL copied to clipboard", { variant: "success" });
  };

  let TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "sale_page_title", label: "Page Title", alignRight: false },
    {
      label: "Copy URL",
      alignRight: false,
      type: "url",
      className: "p-2",
      renderData: (row) => {
        return (
          <CopyToClipboard
            text={`${websiteBaseUrl}${row.sale_page_title_slug}/${userInfo.affiliate_url_name}`}
            onCopy={handleCopiedUrl}
          >
            <Tooltip title="Click to copy Preview URL">
              <Chip label="Copy URL" color="primary" variant="outlined" />
            </Tooltip>
          </CopyToClipboard>
        );
      },
    },
    {
      label: "Preview URL",
      alignRight: false,
      type: "url",
      className: "p-2",
      renderData: (row) => {
        return (
          <Chip
            label="Preview"
            color="primary"
            variant="outlined"
            onClick={() => handleNavigate(row)}
          />
        );
      },
    },
  ];

  return (
    // <div className="mb-3">
    //   <h4 className="p-3 pb-0">{title}</h4>
    //   <hr />
    //   <div className="p-3 pt-0">
    //     {sourceSessions?.length < 1 ? (
    //       <RecordNotFound title="Sale Pages not found" />
    //     ) : (
    //       sourceSessions?.map((value) => {
    //         return (
    //           <div className="d-flex mb-2">
    //             <div className="text-start comment-icon w-100 ps-0 source-session">
    //               <div
    //                 className="card-title"
    //                 onClick={() => handleNavigate(value)}
    //               >
    //                 <h3 className="mb-0">{value?.sale_page_title}</h3>
    //                 {/* <p>{get_short_string(value?.short_description, 60)}</p> */}
    //               </div>
    //             </div>
    //             <Chip
    //               label="Preview"
    //               color="primary"
    //               variant="outlined"
    //               className="cursor-pointer"
    //               onClick={() => handleNavigate(value)}
    //             />
    //           </div>
    //         );
    //       })
    //     )}
    //   </div>
    // </div>
    <>
      <div className="mb-3 mt-4">
        <h2>{title}</h2>
      </div>
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={sourceSessions}
        className="card-with-background"
      />
    </>
  );
}

export default SalePages;
