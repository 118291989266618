import React, { useState } from "react";
import { Box, Card, CircularProgress, Stack, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import { get_short_string } from "src/utils/constants";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

import { add_answers_api } from "src/DAL/Questions/Questions";
import { AddtoCArt } from "src/DAL/ShopApi/ApiShop";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
const ImageStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
});
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
    width: "23px !important",
    height: "auto !important",
    position: "absolute",
    bottom: "0px",
    right: "6px",
  },
}));
export default function ShopCard({
  data,
  handleClick,
  MENU_OPTIONS,
  className,
  lockStatement,
  setProduct,
  setOpenLink,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { handleUpdateCard, cartElement, setCardElement } = useContentSetting();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isLoadingIndex, setIsLoadingIndex] = useState(null);
  const addToCart = (itemData) => {
    setProduct(itemData);
    const existingItem = cartElement.find(
      (item) => item.product_id === itemData._id
    );
    if (existingItem) {
      setCardElement((old) => {
        return old.map((old_item) => {
          if (old_item._id == itemData._id) {
            return {
              ...itemData,
              selected_quantity: old_item.selected_quantity + 1,
            };
          } else {
            return old_item;
          }
        });
      });
      // setCardElement(updatedCart);
    } else {
      setCardElement([...cartElement, itemData]);
    }
  };
  const handleSubmit = async (item, index) => {
    setIsLoadingIndex(index);
    setLoading(true);
    const existingItem = cartElement.find(
      (item_old) => item_old.product_id === item._id
    );

    let postData;
    if (existingItem) {
      postData = {
        product_id: existingItem._id,
        quantity: existingItem.selected_quantity + 1,
        price: existingItem.price,
      };
    } else {
      postData = { product_id: item._id, quantity: 1, price: item.price };
    }
    const result = await AddtoCArt(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setLoading(false);
      setIsLoadingIndex(null);
      addToCart(item);
      setOpenLink(true);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
      // setIsLoadingIndex(null);
    }
  };
  return (
    <div className={`row ${className}`}>
      {data.map((item, index) => {
        return (
          <div className={item.card_classes} key={index}>
            <Card
              className={`mui-custom-card h-100 ${
                item.remaining_quantity <= 0 ? "disable-pointer" : ""
              }`}
              sx={{ cursor: "pointer" }}
            >
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                {item.card_button && item.card_button.button_title && (
                  <button
                    variant="contained"
                    className={
                      lockStatement
                        ? `small-contained-button mui-card-button d-flex centerAlign ${
                            index == 0 ? "centerAlign0" : ""
                          }`
                        : "small-contained-button mui-card-button mui-card-button-program d-flex centerAlign"
                    }
                    onClick={
                      item.card_button.handleClick
                        ? () => {
                            item.card_button.handleClick(item);
                          }
                        : undefined
                    }
                  >
                    {item.card_button.button_title}
                  </button>
                )}
                <ImageStyle
                  alt={item.title}
                  src={item.card_image}
                  onClick={
                    item.remaining_quantity <= 0
                      ? undefined
                      : handleClick
                      ? () => {
                          handleClick(item);
                        }
                      : undefined
                  }
                  className={` ${
                    item.remaining_quantity <= 0 ? "disable-pointer" : ""
                  }`}
                />
                {MENU_OPTIONS && (
                  <div className="mui-custom-card-dots">
                    <CustomPopover menu={MENU_OPTIONS} data={item} />
                  </div>
                )}
              </Box>

              <Stack
                spacing={2}
                className="mui-custom-card-content"
                // onClick={
                //   handleClick
                //     ? () => {
                //         handleClick(item);
                //       }
                //     : undefined
                // }
              >
                <div
                  onClick={
                    item.remaining_quantity <= 0
                      ? undefined
                      : handleClick
                      ? () => {
                          handleClick(item);
                        }
                      : undefined
                  }
                  className={` ${
                    item.remaining_quantity <= 0 ? "disable-pointer" : ""
                  }`}
                >
                  <h3>{htmlDecode(item.card_title)}</h3>
                  {item.card_short_description && (
                    <p className="mui-custom-card-description">
                      {htmlDecode(
                        get_short_string(item.card_short_description, 200)
                      )}
                    </p>
                  )}
                  {item.card_short_description_2 && (
                    <p className="card_lower_title_description shop-card-discription">
                      {htmlDecode(
                        get_short_string(item.card_short_description_2, 170)
                      )}
                    </p>
                  )}
                  {item.card_gray_description && (
                    <p className="mui_gray_description">
                      <span>
                        {get_short_string(item.card_gray_description, 50)}
                      </span>
                    </p>
                  )}
                </div>
                <div className="mt-5"></div>
                <div
                  className={`text-end icon-container-shop `}
                  // style={{ position: "relative" }}
                  onClick={
                    item.remaining_quantity <= 0
                      ? ""
                      : () => handleSubmit(item, index)
                  }
                >
                  <button
                    className={`small-contained-button  w-100 mt-1 ${
                      item.remaining_quantity <= 0 ? "disable-pointer" : ""
                    }`}
                  >
                    {item.remaining_quantity <= 0 ? (
                      <>
                        <ShoppingCartIcon /> Out of Stock
                      </>
                    ) : (
                      <>
                        {index == isLoadingIndex ? (
                          <>
                            <ShoppingCartIcon /> Adding...
                          </>
                        ) : (
                          <>
                            <ShoppingCartIcon /> Add to cart
                          </>
                        )}
                      </>
                    )}
                  </button>
                </div>
              </Stack>
            </Card>
          </div>
        );
      })}
    </div>
  );
}
