import React, { useEffect, useRef, useState } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { CircularProgress, Tooltip } from "@mui/material";
import { FeedComments } from "../Community/components";
import { useNavigate, useParams } from "react-router-dom";
import {
  community_feed_action,
  get_feeds_detail,
} from "src/DAL/Community/Community";
import UserInfoCard from "../Community/components/LikesPopup/UserInfoCard";
import { htmlDecode, urlify } from "src/utils/convertHtml";
import {
  count_chars,
  FEED_TXET_LIMIT,
  post_description,
  replaceUserNamesWithHTML,
} from "src/utils/constants";
import ActionsCount from "../Community/components/LikesPopup/ActionsCount";
import HandleAction from "../Community/components/LikesPopup/HandleAction";
import { LiveStream } from "..";
import { s3baseUrl } from "src/config/config";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import ShowAllLikes from "../Community/components/LikesPopup/ShowAllLikes";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

let max_height_iterations = 10;
let height_iterations = 0;

export default function LiveStreaming() {
  const navigate = useNavigate();
  const { topic } = useParams();
  const userInfoCard = useRef(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isShowMore, setIsShowMore] = useState(true);
  const [feedDetail, setFeedDetail] = useState({});
  const [feedSetting, setFeedSetting] = useState({});
  const [liveStream, setLiveStream] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [topLikedUser, setTopLikedUser] = useState([]);
  const [showTab, setShowTab] = useState(0);
  const [likeCount, setLikeCount] = useState(0);
  const [gratitudeCount, setGratitudeCount] = useState(0);
  const [coinsCount, setCoinsCount] = useState(0);
  const [topGratitudeUser, setTopGratitudeUser] = useState([]);
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [isGratituded, setIsGratituded] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const { socket, userInfo } = useContentSetting();

  const handleFeedAction = async (id, action) => {
    if (action === "feedlike") {
      setIsLiked(true);
    } else if (action === "feedunlike") {
      setIsLiked(false);
    } else if (action === "gratitude") {
      setIsGratituded(true);
    }
    const formData = new FormData();
    formData.append("action", action);
    formData.append("feed", id);
    const result = await community_feed_action(formData);
    if (result.code === 200) {
      const socketData = {
        action,
        feed_id: id,
        token: localStorage.getItem("token"),
        creator_id: result.action_response.creator_id,
        action_by: result.action_response.sender,
        action_response: result.action_response,
      };
      socket.emit("feed_room_action_event", socketData);
      if (action === "feedlike" || action === "feedunlike") {
        setTopLikedUser(result.action_response.top_liked_user);
        setLikeCount(result.action_response.like_count);
        setCoinsCount(result.action_response.coins_count);
      } else if (action === "gratitude") {
        setTopGratitudeUser(result.action_response.top_gratitude_user);
        setGratitudeCount(result.action_response.gratitude_count);
        setCoinsCount(result.action_response.coins_count);
      }
    } else {
      if (action === "feedlike") {
        setIsLiked(false);
      } else if (action === "feedunlike") {
        setIsLiked(true);
      } else if (action === "gratitude") {
        setIsGratituded(false);
      }
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getFeedsDeteil = async () => {
    const result = await get_feeds_detail(topic);
    if (result.code === 200) {
      setFeedDetail(result.feeds);
      setFeedSetting(result.feed_settings);
      setCoinsCount(result.feeds.coins_count);
      setIsLiked(result.feeds.is_liked);
      setIsGratituded(result.feeds.is_gratitude);
      setTopLikedUser(result.feeds.top_liked_user);
      setLikeCount(result.feeds.like_count);
      setTopGratitudeUser(result.feeds.top_gratitude_user);
      setGratitudeCount(result.feeds.gratitude_count);
      setLiveStream(result.live_stream);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/thesource`);
    }
  };

  const handleOpenLikedBox = () => {
    setOpenLikesModal(true);
    setShowTab(1);
  };

  const handleOpenGratitudeBox = () => {
    setOpenLikesModal(true);
    setShowTab(2);
  };

  const handleCloseSimpleBox = () => {
    setOpenLikesModal(false);
    setShowTab();
  };

  const update_feed_likes_by_socket = (data) => {
    setLikeCount(data.action_response.like_count);
    setCoinsCount(data.action_response.coins_count);
    setTopLikedUser(data.action_response.top_liked_user);
    if (userInfo._id == data.action_by) {
      setIsLiked(data.action_response.is_liked);
    }
  };

  const update_feed_gratitude_by_socket = (data) => {
    setTopGratitudeUser(data.action_response.top_gratitude_user);
    setGratitudeCount(data.action_response.gratitude_count);
    setCoinsCount(data.action_response.coins_count);
    if (userInfo._id == data.action_by) {
      setIsGratituded(data.action_response.is_gratitude);
    }
  };

  useEffect(() => {
    getFeedsDeteil();
    socket.emit("live_event_room", "live_feed_room");
    socket.on("live_feed_room_reciever", (data) => {
      console.log(data, "live_feed_room_reciever LiveStreaming");
      if (data.action === "feedlike" || data.action === "feedunlike") {
        update_feed_likes_by_socket(data);
      } else if (data.action === "gratitude") {
        update_feed_gratitude_by_socket(data);
      } else if (
        data.action === "add_comment_reply" ||
        data.action === "add_comment"
      ) {
        setFeedDetail((old) => {
          return {
            ...old,
            comment_count: old.comment_count + 1,
          };
        });
      } else if (
        data.action === "delete_comment_reply" ||
        data.action === "delete_comment"
      ) {
        setFeedDetail((old) => {
          return {
            ...old,
            comment_count: old.comment_count - 1,
          };
        });
      }
    });

    return () => {
      socket.off("live_feed_room_reciever");
    };
  }, []);

  const handle_card_height = () => {
    height_iterations++;
    setTimeout(() => {
      let user_info_card = document.querySelector("#user-info-card");
      let comment_wrapper = document.querySelector("#main-comment-wrapper");
      let add_comment = document.querySelector("#add-comment-box");
      if (user_info_card && comment_wrapper && add_comment) {
        let user_info_height = user_info_card.offsetHeight;
        let add_comment_height = add_comment.offsetHeight;
        comment_wrapper.style.height = `calc(85vh - ${
          user_info_height + add_comment_height
        }px - 40px)`;
        comment_wrapper.style.overflow = "auto";
      } else if (height_iterations < max_height_iterations) {
        handle_card_height();
      }
    }, 300);
  };

  useEffect(() => {
    if (!isLoading && userInfoCard.current && !isFullScreen) {
      handle_card_height();
    }
  }, [isLoading, userInfoCard, isFullScreen]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let streamData = { feedDetail, liveStream, isFullScreen, setIsFullScreen };

  return (
    <>
      <div className="container-fluid p-3 p-lg-5">
        <div className="row ">
          <div className="col-12">
            <h2>Live Stream</h2>
          </div>
        </div>
        <div className="row">
          <div className={`col-12 ${isFullScreen ? "" : "col-lg-8"}`}>
            <LiveStream {...streamData} />
          </div>
          {!isFullScreen && (
            <div className="col-12 col-lg-4">
              <div className="live-stream-comments">
                <div id="user-info-card" ref={userInfoCard}>
                  <div className="d-flex w-100 justify-content-between">
                    <UserInfoCard
                      profile_image={
                        s3baseUrl + feedDetail?.action_info?.profile_image
                      }
                      user_name={htmlDecode(feedDetail?.action_info?.name)}
                      avatar_char={feedDetail?.action_info?.name[0]}
                      date={feedDetail?.createdAt}
                      activity_type={null}
                    />

                    <div className="poster-logo text-end">
                      <span className="d-flex feed-level">
                        <Tooltip
                          title={
                            feedSetting[
                              `${feedDetail.created_for_level_or_type}_title`
                            ]
                              ? feedSetting[
                                  `${feedDetail.created_for_level_or_type}_title`
                                ]
                              : ""
                          }
                        >
                          <img
                            src={
                              feedSetting[
                                `${feedDetail.created_for_level_or_type}_badge`
                              ]
                                ? s3baseUrl +
                                  feedSetting[
                                    `${feedDetail.created_for_level_or_type}_badge`
                                  ]
                                : ""
                            }
                            className="dynamite-level-image"
                          />
                        </Tooltip>
                        {feedDetail.created_for_level_or_type == "win" &&
                          feedSetting.win_badge && (
                            <Tooltip
                              title={
                                feedSetting.win_title
                                  ? feedSetting.win_title
                                  : "Win"
                              }
                            >
                              <img
                                src={
                                  feedSetting.win_badge
                                    ? s3baseUrl + feedSetting.win_badge
                                    : ""
                                }
                                className="dynamite-level-image"
                              />
                            </Tooltip>
                          )}
                      </span>
                    </div>
                  </div>
                  {feedDetail.description && (
                    <div className="mt-2 post-description">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: urlify(
                            count_chars(feedDetail.description) >
                              FEED_TXET_LIMIT && isShowMore
                              ? post_description(
                                  replaceUserNamesWithHTML(
                                    feedDetail.description,
                                    feedDetail.mentioned_users
                                  ),
                                  FEED_TXET_LIMIT
                                )
                              : replaceUserNamesWithHTML(
                                  feedDetail.description,
                                  feedDetail.mentioned_users
                                )
                          ),
                        }}
                      ></p>
                      {count_chars(feedDetail.description) >
                        FEED_TXET_LIMIT && (
                        <span
                          className="ms-2"
                          onClick={() => {
                            setIsShowMore(!isShowMore);
                          }}
                        >
                          {isShowMore ? "See More" : "See Less"}
                        </span>
                      )}
                    </div>
                  )}
                  <ActionsCount
                    handleOpenLikedBox={handleOpenLikedBox}
                    likeCount={likeCount}
                    topLikedUser={topLikedUser}
                    handleOpenGratitudeBox={handleOpenGratitudeBox}
                    gratitudeCount={gratitudeCount}
                    topGratitudeUser={topGratitudeUser}
                    coinsCount={coinsCount}
                    feeds_type="general"
                  />
                  <hr />
                  <HandleAction
                    isLiked={isLiked}
                    handleFeedAction={handleFeedAction}
                    feeds_type="general"
                    setShowAddComment={() => {}}
                    isGratituded={isGratituded}
                    feed_id={topic}
                  />
                  <hr />
                </div>

                <div>
                  <FeedComments
                    is_detail_page={true}
                    feed_id={topic}
                    feed={feedDetail}
                    getFeedsListingOnAddFeed={() => {}}
                    setTotalCommentCount={() => {}}
                    feedSetting={feedSetting}
                    is_stream_page={true}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {openLikesModal && (
        <ShowAllLikes
          openLikesModal={openLikesModal}
          setOpenLikesModal={setOpenLikesModal}
          handleCloseSimpleBox={handleCloseSimpleBox}
          showTab={showTab}
          post_id={topic}
          feeds_type="general"
        />
      )}
    </>
  );
}
