import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import {
  categoriesListing,
  DeleteCategory,
} from "../../DAL/AffirmationCategories/Categories/Categories";
import { s3baseUrl } from "../../config/config";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import AddOrUpdateCategories from "src/components/Affirmations/AddOrUpdateCategories";
import SearchIcon from "@mui/icons-material/Search";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function CategoriesList() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [categoriesData, setCategoriesData] = useState([]);
  const [categorySlug, setCategorySlug] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("ADD");
  const [openDelete, setOpenDelete] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
    localStorage.setItem("affirmation", e.target.value);
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...categoriesData];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.name.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
    setFormType("ADD");
  };

  const getCategoriesListing = async () => {
    const result = await categoriesListing();
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.category.map((category) => {
        CARD_OPTIONS.push({
          ...category,
          card_title: category.name,
          card_short_description: category.description,
          card_image: s3baseUrl + category.image.thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
        });
      });
      setCategoriesData(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (object) => {
    navigate(`/affirmations/affirmation-list/${object.category_slug}`, {
      state: object.name,
    });
  };

  useEffect(() => {
    let affirmation = localStorage.getItem("affirmation");
    if (affirmation) {
      setQuery(affirmation);
    }
    getCategoriesListing();
  }, []);

  const handleEdit = (value) => {
    setEditValues(value);
    setDrawerState(true);
    setFormType("EDIT");
  };

  const handleAgreeDelete = (value) => {
    setCategorySlug(value.category_slug);
    setOpenDelete(true);
  };

  //Deleting Category
  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await DeleteCategory(categorySlug);
    if (result.code === 200) {
      getCategoriesListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <PageDescription
          parameter="affirmation_description"
          else_title="Affirmations"
        />
        <div className="row">
          <div
            className="col-12 d-flex w-100"
            style={{ justifyContent: "end" }}
          >
            <div className="">
              <FormControl
                variant="outlined"
                size="small"
                style={{ marginTop: "2px" }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  variant="small"
                  value={query}
                  onChange={handleChangeSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </div>

            <button
              className="small-contained-button ms-2 float-end mt-1 mb-4"
              onClick={handleOpenDrawer}
            >
              Add Category
            </button>
          </div>
        </div>
        <div className="row">
          {getFilteredData(query).length > 0 ? (
            <CustomMUICard
              data={getFilteredData(query)}
              MENU_OPTIONS={MENU_OPTIONS}
              handleClick={handleChange}
            />
          ) : (
            <RecordNotFound title="Categories Not Found" />
          )}
        </div>
        <CustomDrawer
          isOpenDrawer={drawerState}
          onOpenDrawer={handleOpenDrawer}
          onCloseDrawer={handleCloseDrawer}
          pageTitle={`${formType === "ADD" ? "Add" : "Edit"} Category`}
          componentToPassDown={
            <AddOrUpdateCategories
              reloadListing={getCategoriesListing}
              editData={editValues}
              formType={formType}
              onCloseDrawer={handleCloseDrawer}
            />
          }
        />
      </div>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Category?"}
        handleAgree={handleDelete}
      />
    </>
  );
}

export default CategoriesList;
