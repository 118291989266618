import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { htmlDecode } from "src/utils/convertHtml";

export default function CompleteQuestions({
  questions,
  expandArea,
  handleChooseAnswer,
  handleNext,
  data,
  userInfo,
}) {
  const replacedString = (string) => {
    if (string) {
      return string.replace(/{Name}/g, userInfo?.first_name);
    } else {
      return "";
    }
  };
  return (
    <div className="container">
      <div className="row justify-content-center pt-5 pb-5">
        <div className="col-12 col-lg-9 right-part mt-2">
          <div
            dangerouslySetInnerHTML={{
              __html: replacedString(data.assessment_questions_description),
            }}
            className="text-center"
          ></div>
          {/* {/ questions /} */}
          <div className="col-12 mt-4">
            {questions.length > 0
              ? questions.map((question, i) => {
                  return (
                    <div className="card mb-2" key={question.id}>
                      <Accordion expanded={question.isExpanded}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          onClick={() => expandArea(i)}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordion-summary qustion-section-summary qustion-color"
                        >
                          <Typography>
                            {htmlDecode(
                              replacedString(question.question_statement)
                            )}
                          </Typography>
                        </AccordionSummary>
                        <div
                          className="ms-3"
                          dangerouslySetInnerHTML={{
                            __html: question.description,
                          }}
                        ></div>

                        <AccordionDetails>
                          <FormControl>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue=""
                              name="radio-buttons-group"
                              value={question?.answer}
                              onChange={(e) =>
                                handleChooseAnswer(question._id, e.target.value)
                              }
                            >
                              {question?.options.map((option, index) => (
                                <div
                                  className={`answer-statement-wh ${
                                    question?.answer
                                      ? option === question?.answer
                                        ? "selectedAnswer"
                                        : ""
                                      : ""
                                  }`}
                                  key={option.id}
                                >
                                  <FormControlLabel
                                    value={option}
                                    id={`capsule${index}`}
                                    control={<Radio />}
                                    label={option}
                                  />
                                </div>
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })
              : ""}
          </div>
          <div className="mt-4 text-center">
            <button type="button" onClick={handleNext} className="black-btn">
              {htmlDecode(data.assessment_submit_button_text)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
