import { SnackbarProvider } from "notistack";
import { Button, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// routes
import Routers from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import "bootstrap/dist/css/bootstrap.min.css";
//Dark theme like DD
import "./assets/css/index.css";
import "./assets/css/style.css";
import "./assets/css/chat.css";
import "./assets/css/live-stream.css";
import "react-phone-input-2/lib/style.css";
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
import { ContextPGIMode } from "./Hooks/PGIModeContext";
import { ChatContext } from "./Hooks/ChatContext/ChatContext";
import { get_app_headers, projectMode } from "./config/config";
import { Helmet } from "react-helmet";
import { ContentSettingState } from "./Hooks/ContentContext/ContentSettingState";
import React, { useEffect } from "react";
// import {
//   get_js_code_localStorage,
//   get_css_code_localStorage,
// } from "./DAL/localStorage/localStorage";

// // ----------------------------------------------------------------------
// const Jscode = get_js_code_localStorage();
// const cssCode = get_css_code_localStorage();
export default function App() {
  const page_name = new URLSearchParams(location.search).get("page_name");
  const notistackRef = React.createRef();
  const headers = get_app_headers();

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  if (projectMode !== "DEV" && window.location.hostname !== "localhost") {
    console.log = () => {};
  }
  useEffect(() => {
    if (page_name) {
      localStorage.clear();
    }
  }, [page_name]);

  return (
    <ContextPGIMode>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <SnackbarProvider
          ref={notistackRef}
          action={(key) => (
            <Button
              className="snackbar-cross-icon"
              onClick={onClickDismiss(key)}
            >
              <CloseIcon />
            </Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          TransitionComponent={Slide}
          maxSnack={3}
        >
          <Helmet>
            <title>{headers && headers.title && headers.title}</title>
            <meta
              name="description"
              content={headers && headers.description && headers.description}
            />
            {/* <script>{Jscode}</script>

            <style type="text/css">{cssCode}</style> */}

            <link
              rel="icon"
              type="image/x-icon"
              href={headers && headers.fav_icon && headers.fav_icon}
            />
            <script src="https://static.opentok.com/v2/js/opentok.min.js"></script>
          </Helmet>
          <ContentSettingState>
            <ChatContext>
              <Routers />
            </ChatContext>
          </ContentSettingState>
        </SnackbarProvider>
      </ThemeConfig>
    </ContextPGIMode>
  );
}
