import React, { useCallback, useEffect, useState } from "react";
import { useRef } from "react";
import ReactPlayer from "react-player";

import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useSnackbar } from "notistack";
import { videoDurationapi } from "src/DAL/Programmes/Programmes";

const ReactVideoDurationPlayer = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [videoDuration, setVideoDuration] = useState();
  const [videoProgress, setVideoProgress] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [playedOrNot, setplayedOrNot] = useState(false);
  const playerRef = useRef();
  const videoDurationRef = useRef();
  const videoProgressRef = useRef();
  const { handleVideoList } = useContentSetting();
  const setVideoPlayed = () => {
    setIsPlay(true);
    setplayedOrNot(true);
    if (props.setting && props.setting.video_type !== "repeat") {
      localStorage.setItem("is_seen_video", "Yes");
    }
  };
  const handleVideoDuration = (duration) => {
    videoDurationRef.current = duration;
    setVideoDuration(duration);
    setplayedOrNot(true);
  };

  const handleVideoProgress = (progress) => {
    videoProgressRef.current = progress;
    setVideoProgress(progress);
  };
  const handleVideoPause = async () => {
    setIsPlay(false);
    setplayedOrNot(false);
    const videoDurationString = videoDuration.toString();
    const playedSecondsString = videoProgress.playedSeconds.toString();
    if (videoDuration - videoProgress.playedSeconds > 2) {
      let postData = {
        recording_id: props.value._id,
        // video_duration: videoProgress.playedSeconds,
        video_duration: playedSecondsString,
        is_complete: false,
        total_video_duration: videoDurationString,
        type: props.type,
        // total_video_duration: videoDuration,
      };
      const result = await videoDurationapi(postData, props.type);
      if (result.code === 200) {
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      let postData = {
        recording_id: props.value._id,
        video_duration: playedSecondsString,
        is_complete: true,
        total_video_duration: videoDurationString,
        type: props.type,
      };
      const result = await videoDurationapi(postData, props.type);
      if (result.code === 200) {
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };
  const handlePageUnmount = async (
    videoDurationString,
    playedSecondsString
  ) => {
    setIsPlay(false);
    if (videoDurationString - playedSecondsString > 2) {
      let postData = {
        recording_id: props.value._id,
        // video_duration: videoProgress.playedSeconds,
        video_duration:
          playedSecondsString == 0
            ? props.value?.video_duration
            : playedSecondsString,
        is_complete: false,
        total_video_duration: videoDurationString,
        type: props.type,
        // total_video_duration: videoDuration,
      };
      const result = await videoDurationapi(postData, props.type);
      if (result.code === 200) {
      } else {
        // enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      let postData = {
        recording_id: props.value._id,
        video_duration:
          playedSecondsString == 0
            ? props.value?.video_duration
            : playedSecondsString,
        is_complete: true,
        total_video_duration: videoDurationString,
        type: props.type,
      };
      const result = await videoDurationapi(postData, props.type);
      if (result.code === 200) {
      } else {
        // enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const handleVideoEnded = async () => {
    const videoDurationString = videoDuration.toString();
    const playedSecondsString = videoProgress.playedSeconds.toString();
    let postData = {
      recording_id: props.value._id,
      video_duration: playedSecondsString,
      is_complete: true,
      total_video_duration: videoDurationString,
      type: props.type,
    };
    const result = await videoDurationapi(postData, props.type);
    if (result.code === 200) {
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const onReady = useCallback(() => {
    if (!isReady) {
      let timeToStart = 0;
      timeToStart = parseFloat(props.value?.video_duration - 0.5);
      if (timeToStart <= 0) {
        playerRef.current.seekTo(0);
      } else {
        playerRef.current.seekTo(timeToStart, "seconds");
      }
      setIsReady(true);
    }
  }, [isReady]);

  const updateVideoData = () => {
    const videoList = JSON.parse(localStorage.getItem("video_list")) || [];
    const videoIndex = Array.isArray(videoList)
      ? videoList?.findIndex((video) => video.recording_id === props?.value._id)
      : -1;

    // If the video is found
    const videoDurationString = videoDuration.toString();
    const playedSecondsString = videoProgress.playedSeconds.toString();

    if (videoIndex !== -1) {
      if (videoDuration - videoProgress.playedSeconds > 2) {
        videoList[videoIndex].video_duration = playedSecondsString;
        videoList[videoIndex].total_video_duration = videoDurationString;
        videoList[videoIndex].is_complete = false;
      } else {
        videoList[videoIndex].video_duration = playedSecondsString;
        videoList[videoIndex].total_video_duration = videoDurationString;
        videoList[videoIndex].is_complete = true;
      }
    }

    localStorage.setItem("video_list", JSON.stringify(videoList));
    localStorage.setItem("video_type", props.type);
    handleVideoList(videoList);
    console.log(videoIndex, "videoIndexvideoIndexvideoIndex");
  };

  useEffect(() => {
    if (isPlay) {
      updateVideoData();
    }
  }, [videoProgress]);

  useEffect(() => {
    if (props.type == "program_section") {
      localStorage.setItem(
        "video_list",
        JSON.stringify(props.videoProgressData)
      );
    }
    videoDurationRef.current = videoDuration;
    return () => {
      const videoDurationString = videoDurationRef?.current?.toString();
      const playedSecondsString =
        videoProgressRef?.current?.playedSeconds?.toString() == 0
          ? props.value?.video_duration
          : videoProgressRef?.current?.playedSeconds?.toString();

      handlePageUnmount(videoDurationString, playedSecondsString);
    };
  }, [playedOrNot]);
  return (
    <div className="wrapper">
      <ReactPlayer
        ref={playerRef}
        controls
        className="player"
        url={props.url}
        width="100%"
        height="100%"
        stopOnUnmount
        pip
        playsinline
        onPlay={setVideoPlayed}
        onDuration={handleVideoDuration}
        onProgress={handleVideoProgress}
        onPause={handleVideoPause}
        onEnded={handleVideoEnded}
        onReady={onReady}
      />
    </div>
  );
};

export default ReactVideoDurationPlayer;
