import { IconButton, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import CardInformation from "src/components/PaymentRequests/CardInformation";
import { convertCurrencyToSign } from "src/utils/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  add_free_member_by_web,
  confirm_one_time_payment_for_web,
  confirm_subscription_incomplete_for_web,
  get_web_intent_client_secret_for_one_time,
  pay_now_for_subscription_web,
} from "src/DAL/WebPages/web-pages";
import { useStripe } from "@stripe/react-stripe-js";

const PaymentPage = (props) => {
  const { apiData, planData } = props;
  const navigate = useNavigate();
  const { category_slug } = useParams();
  const stripe = useStripe();
  const [selectedPage, setSelectedPage] = useState(0);
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const { userInfo } = useContentSetting();
  const [clientSecret, setClientSecret] = useState("");
  const [resPostData, setResPostData] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleNavigateToThankyou = () => {
    setSelectedPage(1);
    scrollTo(0, 0);
  };

  const payNowForSubscription = async (card_token, cardElement) => {
    const postData = {
      plan_id: planData._id,
      page_slug: planData.sale_page.sale_page_title_slug,
      contact_number: userInfo.phone,
      source_token: card_token,
      register_url: window.location.href,
      x_sh_auth: localStorage.getItem("token"),
      affliliate_user_name: apiData.affliliate_user_name,
    };

    // if client secret is already generated
    if (clientSecret && resPostData) {
      handleSecureCard(clientSecret, cardElement, resPostData);
      return;
    }
    const result = await pay_now_for_subscription_web(postData);
    if (result.code === 200) {
      setIsLoadingCard(false);
      enqueueSnackbar("Payment succeeded successfully.", {
        variant: "success",
      });
      handleNavigateToThankyou();
      return result;
    } else if (result.code === 210) {
      const postData = {
        email: userInfo.email,
        plan_id: planData._id,
        page_slug: planData.sale_page.sale_page_title_slug,
        contact_number: userInfo.contact_number,
        affliliate_user_name: apiData.affliliate_user_name,
        shipping_object: result.shipping_object,
        subscription_id: result?.strip_subscription_id,
        register_url: window.location.href,
      };

      // save data for failure use
      setClientSecret(result.client_secret);
      setResPostData(postData);
      // handle secure card action
      handleSecureCard(result.client_secret, cardElement, postData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingCard(false);
    }
  };

  const getIntentClientSecretForOneTime = async (card_token, cardElement) => {
    const postData = {
      street: userInfo.street,
      city: userInfo.city,
      zip_code: userInfo.zip_code,
      payment_method: "stripe",
      state: userInfo.state,
      country: userInfo.country,
      plan_id: planData._id,
      page_slug: planData.sale_page.sale_page_title_slug,
      contact_number: userInfo.contact_number,
      register_url: window.location.href,
      affliliate_user_name: apiData.affliliate_user_name,
      x_sh_auth: localStorage.getItem("token"),
    };

    // if client secret is already generated
    if (clientSecret && resPostData) {
      handleSecureCard(clientSecret, cardElement, resPostData);
      return;
    }
    const result = await get_web_intent_client_secret_for_one_time(postData);
    if (result.code === 200) {
      const postData = {
        plan_id: planData._id,
        page_slug: planData.sale_page.sale_page_title_slug,
        contact_number: userInfo.phone,
        affliliate_user_name: apiData.affliliate_user_name,
        shipping_object: result.shipping_object,
        register_url: window.location.href,
        x_sh_auth: localStorage.getItem("token"),
      };
      if (planData.payment_access === "onetime") {
        postData.payment_intent_id = result.payment_intent_id;
      }

      // save data for failure use
      setClientSecret(result.client_secret);
      setResPostData(postData);
      // handle secure card action
      handleSecureCard(result.client_secret, cardElement, postData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingCard(false);
    }
  };

  const confirmCardPayment = async (postData) => {
    if (planData.payment_access === "recursion") {
      const result = await confirm_subscription_incomplete_for_web(postData);
      if (result.code === 200) {
        setIsLoadingCard(false);
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
        handleNavigateToThankyou();
        return result;
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingCard(false);
      }
    } else {
      const result = await confirm_one_time_payment_for_web(postData);
      if (result.code === 200) {
        setIsLoadingCard(false);
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
        handleNavigateToThankyou();
        return result;
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingCard(false);
      }
    }
  };

  const handleSecureCard = (client_secret, cardElement, postData) => {
    enqueueSnackbar("Processing card...", {
      variant: "info",
    });

    stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(function (result) {
        // Handle result.error or result.paymentIntent
        if (result.error) {
          setIsLoadingCard(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        }
        confirmCardPayment(postData);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const handleCardAction = (card_token, cardElement) => {
    if (planData.payment_access === "recursion") {
      payNowForSubscription(card_token, cardElement);
    } else {
      getIntentClientSecretForOneTime(card_token, cardElement);
    }
  };

  const submitFreeUser = async () => {
    setIsLoadingCard(true);
    const postData = {
      plan_id: planData._id,
      page_slug: planData.sale_page.sale_page_title_slug,
      contact_number: userInfo.contact_number,
      register_url: window.location.href,
      affliliate_user_name: apiData.affliliate_user_name,
      x_sh_auth: localStorage.getItem("token"),
    };

    const result = await add_free_member_by_web(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingCard(false);
      handleNavigateToThankyou();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingCard(false);
    }
  };

  const handleNavigate = () => {
    if (!isLoadingCard) {
      navigate(`/mentorship/${category_slug}`);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <span className="d-flex mb-3" style={{ alignItems: "baseline" }}>
          <IconButton
            className="back-screen-button back-buuton-profile pt-2"
            onClick={handleNavigate}
          >
            <ArrowBackIcon />
          </IconButton>
        </span>
        {selectedPage == 0 ? (
          <>
            <div className="col-12 mb-4">
              <div
                dangerouslySetInnerHTML={{
                  __html: planData.plan_package,
                }}
              ></div>
            </div>
            <div className="col-12 col-md-6">
              <div className="payment-plan-box">
                <div className="plan-title">
                  <h3>{planData.plan_title}</h3>
                  <h3 className="amount">
                    {planData.is_plan_free
                      ? "Free"
                      : convertCurrencyToSign(planData.plan_currency) +
                        planData.plan_price}
                  </h3>
                </div>
                <div className="plan-description p-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: planData.detailed_description,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">
              <h2>Personal Information</h2>
              <div className="row">
                <div className="col-12 col-md-6 mt-3">
                  <TextField
                    id="outlined-basic"
                    value={userInfo?.first_name}
                    label="First Name"
                    variant="outlined"
                    placeholder="First Name"
                    disabled
                  />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <TextField
                    id="outlined-basic"
                    value={userInfo?.last_name}
                    label="Last Name"
                    variant="outlined"
                    placeholder="Last Name"
                    disabled
                  />
                </div>
                <div className="col-12 mt-3">
                  <TextField
                    id="outlined-basic"
                    value={userInfo?.email}
                    label="Email"
                    variant="outlined"
                    placeholder="Email"
                    disabled
                  />
                </div>
              </div>
              {planData.is_plan_free ? (
                <button
                  class="small-contained-button mt-4 float-end"
                  onClick={submitFreeUser}
                >
                  {isLoadingCard
                    ? "Submitting..."
                    : planData.plan_button_text
                    ? planData.plan_button_text
                    : "Submit"}
                </button>
              ) : (
                <CardInformation
                  isLoading={isLoadingCard}
                  setIsLoading={setIsLoadingCard}
                  handleCardAction={handleCardAction}
                  classes="mt-3"
                  buttonText={planData.plan_button_text}
                />
              )}
            </div>
          </>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: planData.plan_thanks_info,
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default PaymentPage;
