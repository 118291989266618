import { useState } from "react";
import { ReactVideoPlayer } from "src/components";
import MissionLevelsScheduleTab from "src/components/MissionLevels/MissionLevelsScheduleTab";
let array = [
  {
    _id: "66b4b985269b5455d39d6f27",
    mission_info: {
      title: "Add Mission",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "The Source Code - Gap Analysis 👀",
    main_heading: "Day 1",
    reward_coins: 350,
    coins_to_unlock: 100,
    number_of_start_days: 0,
    number_of_end_days: 1,
    short_description:
      "Embark on your first journey! Discover hidden treasures, solve ancient puzzles, and encounter mystical creatures. This mission sets the stage for an epic adventure that will test your skills and courage. Are you ready to begin?",
    detailed_description:
      '<p><span style="color: #ffffff;">Embark on your first journey! Discover hidden treasures, solve ancient puzzles, and encounter mystical creatures. This mission sets the stage for an epic adventure that will test your skills and courage. Are you ready to begin?</span></p>\r\n<p><span style="color: #ffffff;">Duration&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2 Days</span></p>\r\n<p><span style="color: #ffffff;">Difficulty &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Beginner</span></p>\r\n<p><span style="color: #ffffff;">Commitment &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Daily</span></p>\r\n<p><span style="color: #ffffff;">Unlocking missions opens up new realms filled with unique landscapes, hidden treasures, and intriguing stories. Each mission offers a fresh adventure and a chance to explore the unknown. As you progress through missions, you\'ll earn valuable rewards and powerful upgrades.</span></p>',
    video_url: "https://youtu.be/yAoLSRbwxL8",
    audio_url: "",
    status: true,
    order: 1,
    image: {
      thumbnail_1: "mission_lavel/f0ca6df3-1049-43bc-a456-9163ff485b7f.png",
      thumbnail_2: "mission_lavel/fa516ed5-7fc2-4e14-af83-62b5c75cf41d.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [
      {
        _id: "66b4bc53269b5455d39d7094",
        action_statement:
          "Begin with a morning check-in to set your goals and priorities.",
        is_required: true,
      },
      {
        _id: "66b4bc53269b5455d39d7095",
        action_statement:
          "Begin with a morning check-in to set your goals and priorities.",
        is_required: true,
      },
      {
        _id: "66b4bc53269b5455d39d7096",
        action_statement:
          "Begin with a morning check-in to set your goals and priorities.",
        is_required: true,
      },
      {
        _id: "66b4bc53269b5455d39d7097",
        action_statement:
          "Begin with a morning check-in to set your goals and priorities.",
        is_required: true,
      },
      {
        _id: "66b4bc53269b5455d39d7098",
        action_statement:
          "Begin with a morning check-in to set your goals and priorities.",
        is_required: true,
      },
    ],
    updatedAt: "2024-09-02T18:08:54.737Z",
    createdAt: "2024-08-08T12:26:45.792Z",
    __v: 2,
    content_settings: {
      unlock_button_text: "Unlock G.A.P.S",
      unlock_cancel_button_text: "Cancel, if your not ready to unlock",
      unlock_detail_description:
        '<h2 style="text-align: center;">UNLOCK YOUR SCHEDULE</h2>\n<p style="text-align: center;"><span style="color: #ecf0f1;">Current Your Level&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{community_level}</span></p>\n<p style="text-align: center;"><span style="color: #ecf0f1;">Wallet Balance&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{coins_count}</span></p>',
      schedule_completed_description:
        '<h1 style="text-align: center;">CONGRATULATIONS!!</h1>\n<h2 style="text-align: center;">You have completed your Schedule on {completed_date}</h2>',
      action_statement_heading: "Daily Actions to be Completed ",
      complete_schedule_button_text: "Complete Today's Source Streak and Earn ",
    },
    is_locked: true,
    question_configration: {
      show_question: true,
      button_text: "Answer ",
      submit_button_text: "Complete ",
      questions_top_description: "<p>Start&nbsp;</p>",
      thanks_description: "<p>Thanks&nbsp;</p>",
    },
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c10975a453d33897f91ec3",
    mission_info: {
      title: "Mission One - GAPS",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Qs Needed - General info",
    main_heading: "Day 2 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 2,
    number_of_end_days: 3,
    short_description: "Collect General Information ",
    detailed_description: "",
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 2,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/cd2c2bd6-075b-482d-a771-ffa7ca778447.png",
      thumbnail_2: "mission_lavel/4a1f6563-5320-49cc-93d4-26b9609f905c.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [
      {
        _id: "66c2dd1cb5cbe0cae2baa71a",
        action_statement: "Completed Daily Dynamite ",
        is_required: true,
      },
      {
        _id: "66c2dd1cb5cbe0cae2baa71b",
        action_statement: "Completed Daily Gratitude ",
        is_required: true,
      },
    ],
    updatedAt: "2024-09-02T18:39:48.968Z",
    createdAt: "2024-08-17T20:35:01.944Z",
    __v: 1,
    content_settings: {
      unlock_button_text: "Start Your First Source Streak ",
      unlock_cancel_button_text: "No Not Yet ",
      unlock_detail_description:
        '<h2 style="text-align: center;">UNLOCK YOUR SCHEDULE</h2>\n<p style="text-align: center;"><span style="color: #ecf0f1;">Current Your Level&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{community_level}</span></p>\n<p style="text-align: center;"><span style="color: #ecf0f1;">Wallet Balance&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{coins_count}</span></p>',
      schedule_completed_description:
        '<h1 style="text-align: center;">CONGRATULATIONS!!</h1>\n<h2 style="text-align: center;">You have completed your Schedule on {completed_date}</h2>',
      congratulation_description: "<p>Well done&nbsp;</p>",
      action_statement_heading: "I am ready ",
      complete_schedule_button_text: "Complete ",
    },
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c10ad3a453d33897f92248",
    mission_info: {
      title: "Mission One - GAPS",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Results Code 👀",
    main_heading: "Day 3 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 3,
    number_of_end_days: 4,
    short_description: "Source Streak Day 3 ",
    detailed_description:
      '<p><img src="https://dd-feature-app-bucket.s3.amazonaws.com/upload/3e142046-e57c-4bb4-87cf-b4f19be1ba64.png" alt="" width="150" height="150" /></p>',
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 3,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/777eb8c7-b26b-493e-b28e-7ca9ab9cf9d6.png",
      thumbnail_2: "mission_lavel/c5fe6b8b-dc75-47ac-95bd-cbbfeffd2992.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-09-02T18:09:05.559Z",
    createdAt: "2024-08-17T20:40:51.187Z",
    __v: 0,
    content_settings: {
      unlock_button_text: "Unlock Day 3 Source Streak ",
      unlock_cancel_button_text: "Cancel, if your not ready to unlock",
      unlock_detail_description:
        '<h2 style="text-align: center;">UNLOCK YOUR SCHEDULE</h2>\n<p style="text-align: center;"><span style="color: #ecf0f1;">Current Your Level&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{community_level}</span></p>\n<p style="text-align: center;"><span style="color: #ecf0f1;">Wallet Balance&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{coins_count}</span></p>',
      schedule_completed_description:
        '<h1 style="text-align: center;">CONGRATULATIONS!!</h1>\n<h2 style="text-align: center;">You have completed your Schedule on {completed_date}</h2>',
      congratulation_description: "",
      action_statement_heading: "Daily Actions to be Completed ",
      complete_schedule_button_text: "Complete Today's Source Streak and Earn ",
    },
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c17ef36d68cf6c07a0dc46",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Complete The Wheel of Life?",
    main_heading: "Day 4 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 4,
    number_of_end_days: 5,
    short_description: "Day 4 - Goals ",
    detailed_description: "",
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 4,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/44d1442a-cc15-495c-aaa0-fdb72c2bacc9.png",
      thumbnail_2: "mission_lavel/d84a6548-6672-4b16-8ba7-06f987508cc2.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-09-02T17:59:32.776Z",
    createdAt: "2024-08-18T04:56:19.391Z",
    __v: 0,
    content_settings: {
      unlock_button_text: "Unlock Day 4 Source Streak ",
      unlock_cancel_button_text: "Cancel, if your not ready to unlock",
      unlock_detail_description:
        '<h2 style="text-align: center;">UNLOCK YOUR SCHEDULE</h2>\n<p style="text-align: center;"><span style="color: #ecf0f1;">Current Your Level&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{community_level}</span></p>\n<p style="text-align: center;"><span style="color: #ecf0f1;">Wallet Balance&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{coins_count}</span></p>',
      schedule_completed_description:
        '<h1 style="text-align: center;">CONGRATULATIONS!!</h1>\n<h2 style="text-align: center;">You have completed your Schedule on {completed_date}</h2>',
      congratulation_description: "",
      action_statement_heading: "Daily Actions to be Completed ",
      complete_schedule_button_text: "Complete Today's Source Streak and Earn ",
    },
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c17fb56d68cf6c07a0de41",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Day 5 - Source Streak - Check In",
    main_heading: "Day 5 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 5,
    number_of_end_days: 6,
    short_description: "Day 5 - Source Streak ",
    detailed_description: "",
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 5,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/0e826b6f-3c34-4a2a-af42-5c471fdb3766.png",
      thumbnail_2: "mission_lavel/b544ccca-e0d8-4842-9635-2da1ec231fb1.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-09-02T18:00:02.776Z",
    createdAt: "2024-08-18T04:59:33.023Z",
    __v: 0,
    content_settings: {
      unlock_button_text: "Unlock Day 5 Source Streak",
      unlock_cancel_button_text: "Cancel, if your not ready to unlock",
      unlock_detail_description:
        '<h2 style="text-align: center;">UNLOCK YOUR SCHEDULE</h2>\n<p style="text-align: center;"><span style="color: #ecf0f1;">Current Your Level&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{community_level}</span></p>\n<p style="text-align: center;"><span style="color: #ecf0f1;">Wallet Balance&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{coins_count}</span></p>',
      schedule_completed_description:
        '<h1 style="text-align: center;">CONGRATULATIONS!!</h1>\n<h2 style="text-align: center;">You have completed your Schedule on {completed_date}</h2>',
      congratulation_description: "",
      action_statement_heading: "Daily Actions to be Completed ",
      complete_schedule_button_text: "Complete Today's Source Streak and Earn ",
    },
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c180126d68cf6c07a0dfa4",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Growth Code 👀",
    main_heading: "Day 6 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 6,
    number_of_end_days: 7,
    short_description: "Day 6 source streak",
    detailed_description: "",
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 6,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/2b78eb9c-4629-4bd0-9d66-92ff28978529.png",
      thumbnail_2: "mission_lavel/3eca9af3-2351-42b0-9574-5cf0d74d44ff.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-09-02T18:09:20.080Z",
    createdAt: "2024-08-18T05:01:06.531Z",
    __v: 0,
    content_settings: {
      unlock_button_text: "Unlock Day 6 Source Streak",
      unlock_cancel_button_text: "Cancel, if your not ready to unlock",
      unlock_detail_description:
        '<h2 style="text-align: center;">UNLOCK YOUR SCHEDULE</h2>\n<p style="text-align: center;"><span style="color: #ecf0f1;">Current Your Level&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{community_level}</span></p>\n<p style="text-align: center;"><span style="color: #ecf0f1;">Wallet Balance&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{coins_count}</span></p>',
      schedule_completed_description:
        '<h1 style="text-align: center;">CONGRATULATIONS!!</h1>\n<h2 style="text-align: center;">You have completed your Schedule on {completed_date}</h2>',
      congratulation_description: "",
      action_statement_heading: "Daily Actions to be Completed ",
      complete_schedule_button_text: "Complete Today's Source Streak and Earn",
    },
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c180766d68cf6c07a0e088",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Questions - Submit a short goal /perfect day",
    main_heading: "Day 7 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 7,
    number_of_end_days: 8,
    short_description: "Day 7 ",
    detailed_description: "",
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 7,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/09f16b15-958a-4055-bf24-8e3ff0ef3532.png",
      thumbnail_2: "mission_lavel/fc5eae92-a5f5-4222-a9c3-fba93137446c.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-09-02T18:05:57.148Z",
    createdAt: "2024-08-18T05:02:46.595Z",
    __v: 0,
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c180a06d68cf6c07a0e12f",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Day 8",
    main_heading: "Day 8 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 8,
    number_of_end_days: 9,
    short_description: "day 8 ",
    detailed_description: "",
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 8,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/5bc2e19a-b3ec-47fc-bda8-72e5788ea3db.png",
      thumbnail_2: "mission_lavel/22790e77-867e-489a-b94d-813367b38b97.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-08-18T05:03:28.435Z",
    createdAt: "2024-08-18T05:03:28.435Z",
    __v: 0,
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c180e06d68cf6c07a0e202",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Mind Code - 👀",
    main_heading: "Day 9 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 9,
    number_of_end_days: 10,
    short_description: "day 9",
    detailed_description: "",
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 9,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/240ef802-f8d5-45ee-a5ec-26e721b454fd.png",
      thumbnail_2: "mission_lavel/13ebcd02-ed39-4291-8dbe-3e33f1bc8587.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-09-02T18:18:03.296Z",
    createdAt: "2024-08-18T05:04:32.096Z",
    __v: 0,
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c181156d68cf6c07a0e2a5",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Day 10 - Qs around habits/beliefs/paradigms",
    main_heading: "Day 10 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 10,
    number_of_end_days: 11,
    short_description: "day 10 ",
    detailed_description: "",
    video_url: "",
    audio_url: "",
    status: true,
    order: 10,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/317b0531-59f4-4b3a-b7b9-ec352df97d3c.jpeg",
      thumbnail_2: "mission_lavel/c1bede3c-254a-4582-9714-873fc0615bf0.jpeg",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-09-02T18:41:41.025Z",
    createdAt: "2024-08-18T05:05:25.552Z",
    __v: 0,
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c1814d6d68cf6c07a0e378",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Day 11",
    main_heading: "Day 11 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 11,
    number_of_end_days: 12,
    short_description: "Day 11",
    detailed_description: "",
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 11,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/1a5870fc-5947-4b80-a966-75d04b66e19f.png",
      thumbnail_2: "mission_lavel/3ba1118e-9c69-4641-9538-db8df9001e59.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-08-18T05:06:21.793Z",
    createdAt: "2024-08-18T05:06:21.793Z",
    __v: 0,
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c181796d68cf6c07a0e418",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Confidence Code - 👀",
    main_heading: "Day 12",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 12,
    number_of_end_days: 13,
    short_description: "Day 12",
    detailed_description: "",
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 12,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/005e4d2e-8632-400e-a278-daa4a4d79db7.png",
      thumbnail_2: "mission_lavel/cb4d31c2-a7f5-4c8f-b37a-ca84b3459ca8.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-09-02T18:18:31.803Z",
    createdAt: "2024-08-18T05:07:05.304Z",
    __v: 0,
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c181b66d68cf6c07a0e4ca",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Day 13 - Qs around SI or letting go",
    main_heading: "Day 13 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 13,
    number_of_end_days: 14,
    short_description: "Day 13",
    detailed_description:
      '<p><img src="https://dd-feature-app-bucket.s3.amazonaws.com/upload/42f1d4a3-ac23-49d5-8e1c-0573f2601d0e.png" alt="" width="500" height="500" /></p>',
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 13,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/06166950-d637-4356-b216-9e6db2bd5606.png",
      thumbnail_2: "mission_lavel/db5d8d67-830b-4fd6-96ab-ffeb6581ca52.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-09-02T18:42:06.329Z",
    createdAt: "2024-08-18T05:08:06.848Z",
    __v: 0,
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c181f96d68cf6c07a0e591",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Day 14",
    main_heading: "Day 14 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 14,
    number_of_end_days: 15,
    short_description: "Day 14 ",
    detailed_description: "<p>Day 14 description</p>",
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 14,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/b88b082d-068b-4f41-b101-6de821d1752f.png",
      thumbnail_2: "mission_lavel/6f8267c6-0583-46be-92b4-7a2bb6c47345.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-08-18T05:09:13.087Z",
    createdAt: "2024-08-18T05:09:13.087Z",
    __v: 0,
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c182886d68cf6c07a0e6db",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Abundance Code -👀",
    main_heading: "Day 15 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 15,
    number_of_end_days: 16,
    short_description: "Day 15 ",
    detailed_description: "<p>Description&nbsp;</p>",
    video_url: "https://vimeo.com/776183193",
    audio_url: "lesson_audio/c415c2e6-b336-4d40-ba06-1d176d82705e.mp3",
    status: true,
    order: 15,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/087e284f-a2a0-4167-81f5-dfef9bce6a30.png",
      thumbnail_2: "mission_lavel/e5c71fd3-d475-4b64-b891-e8bee2df11b6.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-09-02T18:19:15.145Z",
    createdAt: "2024-08-18T05:11:36.056Z",
    __v: 0,
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c183a66d68cf6c07a0ea54",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Day 16",
    main_heading: "Day 16 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 16,
    number_of_end_days: 17,
    short_description: "Day 16 Give ",
    detailed_description: "",
    video_url: "https://vimeo.com/776183193",
    audio_url: "lesson_audio/15ffdeed-1a29-4ce8-986f-588f513f425e.mp3",
    status: true,
    order: 16,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/d79f9642-a33f-4823-b638-9d023bbcbf80.png",
      thumbnail_2: "mission_lavel/d83c6d38-752a-4025-b7a6-4973c9d098a6.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-08-18T05:16:22.460Z",
    createdAt: "2024-08-18T05:16:22.460Z",
    __v: 0,
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c183f16d68cf6c07a0eaf4",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Day 17",
    main_heading: "Day 17 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 17,
    number_of_end_days: 18,
    short_description: "Day 17 ",
    detailed_description: "",
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 17,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/58c27573-19c5-45fe-be35-538e18ea68d5.jpg",
      thumbnail_2: "mission_lavel/ed87111f-6f79-414d-921b-d64759f88315.jpg",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-08-18T05:17:37.860Z",
    createdAt: "2024-08-18T05:17:37.860Z",
    __v: 0,
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c184156d68cf6c07a0eba0",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Attraction Code - 👀",
    main_heading: "Day 18 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 18,
    number_of_end_days: 19,
    short_description: "Day 18",
    detailed_description: "",
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 18,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/dfe37eee-8fa7-4152-8d83-c0dc6c04a081.jpg",
      thumbnail_2: "mission_lavel/8443832a-14e0-4b2e-b9e1-867ec870d067.jpg",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-09-02T18:16:53.329Z",
    createdAt: "2024-08-18T05:18:13.955Z",
    __v: 0,
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c184676d68cf6c07a0ec40",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Day 19 - Qs around action",
    main_heading: "Day 19 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 19,
    number_of_end_days: 20,
    short_description: "Song ",
    detailed_description: "",
    video_url: "https://vimeo.com/776183193",
    audio_url: "lesson_audio/640655dd-4c8a-4e5d-90e8-4554e46a1432.mp3",
    status: true,
    order: 19,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/acd8f108-fd6f-4ff7-95d9-5bdf858d98f3.jpg",
      thumbnail_2: "mission_lavel/0463ea34-3d21-4de3-9c67-7616ad3ac5b7.jpg",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-09-02T18:43:19.443Z",
    createdAt: "2024-08-18T05:19:35.274Z",
    __v: 0,
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c184926d68cf6c07a0ecef",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Day 20",
    main_heading: "Day 20 ",
    reward_coins: 100,
    coins_to_unlock: 0,
    number_of_start_days: 20,
    number_of_end_days: 21,
    short_description: "Day 20",
    detailed_description: "<p>Description</p>",
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 20,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/15e1851c-ad70-4abb-86b0-0507b48f2ded.jpg",
      thumbnail_2: "mission_lavel/aa1e0834-2d5c-4ca3-bb37-d671397882bd.jpg",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-08-18T05:20:18.065Z",
    createdAt: "2024-08-18T05:20:18.065Z",
    __v: 0,
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
  {
    _id: "66c184c16d68cf6c07a0ed8f",
    mission_info: {
      title: "All Starts Here - Lets Begin Your Journey Within The Source",
      _id: "66b468c62318f0291aa08eae",
    },
    title: "Activation Code - 👀",
    main_heading: "Day 21 ",
    reward_coins: 1000,
    coins_to_unlock: 0,
    number_of_start_days: 21,
    number_of_end_days: 22,
    short_description: "Day 21 - Qs around plan and Decision",
    detailed_description: "<p>Discussion&nbsp;</p>",
    video_url: "https://vimeo.com/776183193",
    audio_url: "",
    status: true,
    order: 21,
    is_locked: true,
    image: {
      thumbnail_1: "mission_lavel/a73f32f3-2fdf-4e35-8b29-076a94feedaa.png",
      thumbnail_2: "mission_lavel/2e12f1b4-be58-40a4-83f2-2c3d94e20b13.png",
    },
    action_by: "admin_user",
    action_id: "61fb68261b921d356406d82b",
    allowed_actions: [],
    updatedAt: "2024-09-02T18:44:21.056Z",
    createdAt: "2024-08-18T05:21:05.820Z",
    __v: 0,
    schedule_status: "locked",
    completed_at: "",
    completed_allowed_actions: [],
    user_data: {
      coins_count: 183170000,
      community_level: "elite",
    },
    started_at: null,
  },
];
const OverView = () => {
  const [missionDetail, setMissionDetail] = useState({});

  const [schedules, setSchedules] = useState(array);
  const handleClick = (schedule) => {
    const today = moment();
    const startedAt = moment(schedule.started_at);
    setSelectedSchedule(schedule);
    if (
      missionDetail.mission_status === "not_started" &&
      schedule.schedule_status === "locked"
    ) {
      return;
    }

    if (schedule.schedule_status === "locked") {
      if (startedAt.isAfter(today, "day")) {
        let error = `You can't unlock it before ${dd_date_format(
          schedule.started_at
        )}`;
        enqueueSnackbar(error, { variant: "error" });
        return;
      }
      setOpenUnlock(1);
      return;
    }
    navigate(`/mission-levels/schedule-details/${mission__id}`, {
      state: schedule,
    });
  };

  const handleClickStart = () => {
    if (missionDetail.mission_locked) {
      enqueueSnackbar(
        "You can't start this mission because your level does not match with the level of this mission",
        {
          variant: "error",
        }
      );
      return;
    }
    setOpenUnlock(2);
  };
  const TABS_DATA = {
    handleClick,
    schedules,
    missionDetail,
    handleClickStart,
  };
  return (
    <>
      {" "}
      <div className="row mt-3">
        <div className={`col-8 `}>
          <div id="video_detail_image_box">
            <ReactVideoPlayer url={"https://vimeo.com/776183193"} />
          </div>
        </div>

        {schedules.length > 0 && (
          <div className="col-md-4 d-none d-md-block">
            <div className="row">
              <div
                className="col-12 schedule-heading pb-0"
                id="schedule-heading-box"
              >
                <h2>Schedule</h2>
              </div>
            </div>
            <MissionLevelsScheduleTab {...TABS_DATA} />
          </div>
        )}

        <div className="col-12 mission-levels-overview-tab pt-3">
          <p>
            <strong>The Awakening Adventure</strong>
          </p>
          <p>
            Embark on your first journey! Discover hidden treasures, solve
            ancient puzzles, and encounter mystical creatures. This mission sets
            the stage for an epic adventure that will test your skills and
            courage. Are you ready to begin?
          </p>
          <p>
            Duration&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp;10 Days
          </p>
          <p>
            Difficulty
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Beginner
          </p>
          <p>Commitment &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Daily</p>
          <p>
            &nbsp;Unlocking missions opens up new realms filled with unique
            landscapes, hidden treasures, and intriguing stories. Each mission
            offers a fresh adventure and a chance to explore the
            unknown.&nbsp;&nbsp;
          </p>
          <p>
            &nbsp;As you progress through missions, you'll earn valuable rewards
            and powerful upgrades.&nbsp;&nbsp;
          </p>
        </div>
      </div>
    </>
  );
};

export default OverView;
