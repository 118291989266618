import React from 'react'
import { useState } from 'react';
import MUICustomTabs from 'src/components/GeneralComponents/MUICustomTabs'
import HabitsDayWiseCard from 'src/components/Habits/HabitsDayWiseCard';

export default function DayWiseHabits() {
    const [tabValue, setTabValue] = useState(0);
    //Changing tab values
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const data = [
        {
            title: 'Code & Sleep',
            image: 'https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/lesson/5bd08532-5b6d-42d2-8f2b-7cefe4345bd3.png',
            target_date: '21 Oct 2022',
            reminder: '11 50 AM',
            selected_days: [
                "Sun",
                "Mon",
                "Tue",
            ],
            progress: '20',
            completed: true
        },
        {
            title: 'Water The Plants',
            image: 'https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/lesson/5bd08532-5b6d-42d2-8f2b-7cefe4345bd3.png',
            target_date: '21 Oct 2022',
            reminder: '11 50 AM',
            selected_days: [
                "Sun",
                "Mon",
                "Tue",
            ],
            progress: '40',
            completed: false
        },
        {
            title: 'Walk Daily',
            image: 'https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/lesson/5bd08532-5b6d-42d2-8f2b-7cefe4345bd3.png',
            target_date: '21 Oct 2022',
            reminder: '11 50 AM',
            selected_days: [
                "Sun",
                "Mon",
                "Tue",
            ],
            progress: '60',
            completed: true
        }
    ]

    const TABS_OPTIONS = [
        {
            title:
                <div className='habit-day-box'>
                    <p className='mb-2'>Mo</p>
                    <span>26</span>
                </div>,
            component: (
                <HabitsDayWiseCard data={data} />
            ),
        },
        {
            title:
                <div className='habit-day-box'>
                    <p className='mb-2'>Tu</p>
                    <span>27</span>
                </div>,
            component: (
                <HabitsDayWiseCard data={data} />
            ),
        },
        {
            title:
                <div className='habit-day-box'>
                    <p className='mb-2'>We</p>
                    <span>28</span>
                </div>,
            component: (
                <HabitsDayWiseCard data={data} />
            ),
        }, {
            title:
                <div className='habit-day-box'>
                    <p className='mb-2'>Th</p>
                    <span>29</span>
                </div>,
            component: (
                <HabitsDayWiseCard data={data} />
            ),
        }, {
            title:
                <div className='habit-day-box'>
                    <p className='mb-2'>Fr</p>
                    <span>30</span>
                </div>,
            component: (
                <HabitsDayWiseCard data={data} />
            ),
        }, {
            title:
                <div className='habit-day-box'>
                    <p className='mb-2'>Sa</p>
                    <span>31</span>
                </div>,
            component: (
                <HabitsDayWiseCard data={data} />
            ),
        }, {
            title:
                <div className='habit-day-box'>
                    <p className='mb-2'>Su</p>
                    <span>1</span>
                </div>,
            component: (
                <HabitsDayWiseCard data={data} />
            ),
        },

    ];

    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <MUICustomTabs
                        data={TABS_OPTIONS}
                        value={tabValue}
                        handleChange={handleChange}
                    />
                </div>
            </div>
        </div>
    )
}
