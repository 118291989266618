import moment from "moment";
import React from "react";
import { mindflix_icon } from "src/assets";
import { ReactVideoPlayer } from "src/components";
import { htmlDecode } from "src/utils/convertHtml";

export default function FeatureVideo({
  featureVideo,
  handleChangePage,
  show_all_time,
}) {
  return (
    <section
      className={`success-wrapper ${
        show_all_time ? "pt-0" : "d-none d-lg-block"
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 pe-lg-5">
            <div className="row pb-4">
              <div className="col-8 col-lg-6">
                <img src={mindflix_icon}></img>
              </div>
            </div>
            <h1>{htmlDecode(featureVideo.title)}</h1>
            <h2>
              {featureVideo.series_category &&
                featureVideo.series_category.title && (
                  <>
                    <span>{featureVideo.series_category.title}</span> &nbsp;
                  </>
                )}
              {moment(featureVideo.date).format("YYYY")} &nbsp;
              {htmlDecode(featureVideo.season_statement)}
            </h2>
            <p>{htmlDecode(featureVideo.short_description)}</p>
            <div className="btn-wrapper">
              <button
                className="play-btn me-2"
                onClick={() => {
                  handleChangePage(featureVideo._id);
                }}
              >
                <i className="fa-solid fa-play" />
                &nbsp; Play
              </button>
            </div>
          </div>
          <div className="col-lg-6 mt-5 mt-lg-0">
            <ReactVideoPlayer url={featureVideo.trailer_video_url} />
          </div>
        </div>
      </div>
    </section>
  );
}
