import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { get_root_value } from "src/utils/domUtils";
import Slider from "@mui/material/Slider";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { convertCurrencyToSign } from "src/utils/constants";

export default function NintyDaySliders({
  sliderDay,
  totalAmount,
  targetAmount,
}) {
  const theme = createTheme({});
  const { userInfo } = useContentSetting();
  const marks = [
    {
      value: 1,
      label: "Day 1",
    },

    {
      value: 90,
      label: "Day 90",
    },
  ];

  const amount_marks = [
    {
      value: 0,
      label: `${convertCurrencyToSign(userInfo.nineteen_day_plan_currency)}0`,
    },
    {
      value: targetAmount,
      label: `${convertCurrencyToSign(
        userInfo.nineteen_day_plan_currency
      )}${targetAmount}`,
    },
  ];

  return (
    <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-12 ps-4 mt-4">
        <ThemeProvider theme={theme}>
          <Slider
            className={`ms-4 mt-5 mui-slider ninty-day-slider`}
            style={{ color: get_root_value("--portal-theme-primary") }}
            aria-label="Always visible"
            value={sliderDay}
            disabled
            step={1}
            min={1}
            max={90}
            marks={marks}
            valueLabelFormat={`Day ${sliderDay}`}
            valueLabelDisplay="on"
          />
        </ThemeProvider>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 ps-4 mt-4">
        <Slider
          className="ms-4 mt-5 mui-slider ninty-day-slider"
          style={{ color: get_root_value("--portal-theme-primary") }}
          value={Number(totalAmount)}
          disabled
          step={1}
          min={0}
          max={targetAmount}
          marks={amount_marks}
          valueLabelFormat={`${convertCurrencyToSign(
            userInfo.nineteen_day_plan_currency
          )} ${totalAmount.toLocaleString()}`}
          valueLabelDisplay="on"
        />
      </div>
    </div>
  );
}
