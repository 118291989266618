import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import moment from "moment";
import { s3baseUrl } from "../../config/config";
import {
  UploadMagicMomentImageOnS3,
  EditMomentApi,
  DeleteMagicMomentImageOnS3,
  AddMemoriesApi,
} from "src/DAL/Memories/Memories";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function AddOrUpdateMemories({
  editData,
  onCloseDrawer,
  reloadListing,
  setEditValues,
  formType,
  setMemoriesData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [newImagesArray, setNewImagesArray] = useState([]);
  const [oldImagesArray, setOldImagesArray] = useState([]);
  const [imagesArray, setImagesArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    momentDescription: "",
    memoryTitle: "",
    momentSlug: "",
    momentDate: new Date(),
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeDate = (event) => {
    setState((prevState) => {
      return {
        ...prevState,
        momentDate: event.$d,
      };
    });
  };

  const handleRemove = (index) => {
    imagesArray.splice(index, 1);
    setImagesArray([...imagesArray]);
  };

  const handleUpload = (event) => {
    let setImageObject = {};
    const fileList = event.target.files[0];
    setImageObject = {
      path: fileList,
      type: "file",
    };
    setImagesArray((prevPreviews) => [...prevPreviews, setImageObject]);
  };

  const DeleteImages = async (formData) => {
    const result = await DeleteMagicMomentImageOnS3(formData);
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (imagesArray.length < 1) {
      setIsLoading(false);
      enqueueSnackbar("Please select at least 1 image", { variant: "error" });
    } else {
      const results = imagesArray.map((image, index) => {
        const formData = new FormData();
        formData.append("image", image.path);
        const result = UploadMagicMomentImageOnS3(formData);
        return result;
      });

      Promise.all(results).then(async (img_results) => {
        let setImageArray = [];
        img_results.map((images, index) => {
          setImageArray.push(images.image_path);
        });

        var dateString = moment(state.momentDate).format("YYYY-MM-DD");
        const formDataObject = {
          moment_title: state.memoryTitle,
          moment_description: state.momentDescription,
          moment_date: dateString,
          moment_image: setImageArray,
        };

        const result = await AddMemoriesApi(JSON.stringify(formDataObject));
        if (result.code === 200) {
          const newObject = result.magicMoment;
          const memoryObject = {
            pinteres_id: newObject._id,
            pinterestImage: newObject.moment_image[0].thumbnail_2,
            memoriesImages: newObject.moment_image,
            pinterestDescription: newObject.moment_description,
            pinterestTitle: newObject.moment_title,
            pinterestDate: newObject.moment_date,
            pinterestSlug: newObject.moment_slug,
          };
          reloadListing.splice(0, 0, memoryObject);
          setMemoriesData(reloadListing);
          onCloseDrawer();
          setIsLoading(false);
          enqueueSnackbar(result.message, { variant: "success" });
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (imagesArray.length < 1) {
      setIsLoading(false);
      enqueueSnackbar("Please select at least 1 image", { variant: "error" });
    } else {
      const deleted_images_array = [];
      let deleted_images_object = {};
      oldImagesArray.map((old_image, index) => {
        const filtered_array = imagesArray.filter(
          (image, index) => image.thumbnail_1 == old_image.thumbnail_1
        );
        delete old_image.type;
        if (filtered_array.length == 0) {
          deleted_images_array.push(old_image);
        }
      });
      if (deleted_images_array.length > 0) {
        deleted_images_object = {
          moment_image: deleted_images_array,
        };
        const result = DeleteImages(deleted_images_object);
      }
      const UploadImages = async (formData) => {
        const result = await UploadMagicMomentImageOnS3(formData);
        return result.image_path;
      };
      const results = imagesArray.map((image, index) => {
        if (image.type === "file") {
          const formData = new FormData();
          formData.append("image", image.path);
          return UploadImages(formData);
        } else {
          return image;
        }
      });

      Promise.all(results).then(async (img_results) => {
        img_results.map((image_path, index) => {
          newImagesArray.push(image_path);
        });
        var dateString = moment(state.momentDate).format("YYYY-MM-DD");
        const formDataObject = {
          moment_title: state.memoryTitle,
          moment_description: state.momentDescription,
          moment_date: dateString,
          moment_image: newImagesArray,
        };
        const result = await EditMomentApi(state.momentSlug, formDataObject);
        if (result.code === 200) {
          let updatedObject = result.magicMoment;
          let objIndex = reloadListing.findIndex(
            (obj) => obj.pinterestSlug == state.momentSlug
          );

          // //Update object's values
          reloadListing[objIndex].pinterestImage =
            updatedObject.moment_image[0].thumbnail_2;
          reloadListing[objIndex].memoriesImages = updatedObject.moment_image;
          reloadListing[objIndex].pinterestDescription =
            updatedObject.moment_description;
          reloadListing[objIndex].pinterestTitle = updatedObject.moment_title;
          reloadListing[objIndex].pinterestDate = updatedObject.moment_date;
          reloadListing[objIndex].pinterestSlug = updatedObject.moment_slug;
          setEditValues(reloadListing);
          onCloseDrawer();
          enqueueSnackbar(result.message, { variant: "success" });
          setIsLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    if (formType == "EDIT") {
      setState((prevState) => ({
        ...prevState,
        ["memoryTitle"]: editData.pinterestTitle,
        ["momentDescription"]: editData.pinterestDescription,
        ["momentDate"]: editData.pinterestDate,
        ["momentSlug"]: editData.pinterestSlug,
        ["gratitudeId"]: editData.pinteres_id,
      }));
      let setImageObject = {};
      editData.memoriesImages.map((images, index) => {
        setImageObject = {
          thumbnail_1: images.thumbnail_1,
          thumbnail_2: images.thumbnail_2,
          type: "image",
        };
        imagesArray.push(setImageObject);
        oldImagesArray.push(setImageObject);
      });
    }
  }, [formType]);

  return (
    <div className="container new-memories">
      <form onSubmit={formType === "ADD" ? handleSubmit : handleUpdate}>
        <TextField
          className="mt-4"
          id="outlined-basic"
          label="Moment Title"
          variant="outlined"
          name="memoryTitle"
          value={state.memoryTitle}
          required={true}
          onChange={(e) => handleChange(e)}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="DD-MM-YYYY"
            mask="__/__/____"
            inputFormat="DD-MM-YYYY"
            label="Moment Date"
            name="momentDate"
            value={state.momentDate}
            onChange={(e) => handleChangeDate(e)}
            renderInput={(params) => (
              <TextField {...params} className="mt-3" required={true} />
            )}
          />
        </LocalizationProvider>

        <TextField
          id="outlined-multiline-static"
          label="Moment Description"
          multiline
          rows={5}
          variant="outlined"
          style={{ width: "100%" }}
          name="momentDescription"
          value={state.momentDescription}
          onChange={(e) => handleChange(e)}
          className="mt-3"
          required={true}
        />
        <p className="mt-2 mb-0">Recommended Size (1000x670) *</p>
        <div className="col-md-12 mt-2 d-flex">
          <div className="row w-100 add-memories-preview">
            {imagesArray &&
              imagesArray.map((file, index) => (
                <div className="col-3 mt-2" key={index}>
                  <span className="preview mt-2">
                    <span onClick={() => handleRemove(index)}>x</span>
                    {file.type === "file" ? (
                      <img src={URL.createObjectURL(file.path)} />
                    ) : (
                      <img src={s3baseUrl + file.thumbnail_2} />
                    )}
                  </span>
                </div>
              ))}
            <div className="col-3 mt-2">
              <span className="upload-button mt-2">
                <input
                  color="primary"
                  accept="image/*"
                  type="file"
                  id="icon-button-file"
                  style={{ display: "none" }}
                  name="affirmationImage"
                  onChange={handleUpload}
                />
                <label className="" htmlFor="icon-button-file">
                  <CloudUploadIcon />
                </label>
              </span>
            </div>
          </div>
        </div>
        <div className="text-end mt-3">
          <button className="submit-button" disabled={isLoading}>
            {isLoading
              ? formType === "ADD"
                ? "Saving..."
                : "Updating..."
              : formType === "ADD"
              ? "Save"
              : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
