import React from 'react'
import { assessment_body_image, assessment_body_uper_image, assessment_lower_image, assessment_upper_image } from 'src/assets'

export default function AttitudeAssessmentFiller({
    thoughtsGainedMarks,
    thoughtsTotalMarks,
    feelingsTotalMarks,
    feelingsGainedMarks,
    actionsTotalMarks,
    actionsGainedMarks
}) {

    const get_top_filler = () => {
        const percentage_fill = (thoughtsGainedMarks / thoughtsTotalMarks) * 100;
        const percentage_empty = 100 - percentage_fill;
        let image = assessment_upper_image;
        return `linear-gradient(#000 ${percentage_empty}%, #C9A04A ${0}%)`
    };

    const get_bottom_filler = () => {

        // if want to fill from top to bottom

        // const percentage_fill = (feelingsGainedMarks / feelingsTotalMarks) * 100;
        // return `linear-gradient(to bottom, #C9A04A ${percentage_fill}%,#000 ${0}%)`;

        const percentage_fill = (feelingsGainedMarks / feelingsTotalMarks) * 100;
        const percentage_empty = 100 - percentage_fill;
        return `linear-gradient(#000 ${percentage_empty}%, #C9A04A ${0}%)`
    };

    const get_body_filler = () => {
        let percentage_fill = (actionsGainedMarks / actionsTotalMarks) * 100;
        const percentage_empty = 100 - percentage_fill;
        return `linear-gradient(#000 ${percentage_empty}%, #C9A04A ${0}%)`
    };

    return (
        <div className="col-lg-4 col-md-4 assessment_filler_image">
            <img
                src={assessment_upper_image}
                style={{ background: get_top_filler() }}
                className=" img-fluid top-image" alt=""
            />
            <img src={assessment_lower_image}
                style={{ background: get_bottom_filler() }}
                className=" img-fluid" alt=""
            />
            <img src={assessment_body_uper_image}
                className=" img-fluid" alt=""
            />
            <img src={assessment_body_image}
                style={{ background: get_body_filler() }}
                className=" img-fluid" alt=""
            />
        </div>
    )
}
