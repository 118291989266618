import * as React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import moment from "moment";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

export default function ActionBarComponentProps({
  diaryDate,
  handleChangeDate,
  userCreateAt,
}) {
  const { adminTimeZone, userInfo } = useContentSetting();

  const get_update_time_with_time_zone = (date) => {
    let new_date = get_date_with_user_time_zone(
      date,
      "MM-DD-YYYY",
      userInfo.time_zone,
      adminTimeZone
    );
    return new_date;
  };

  return (
    <div className="mission-detail">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          className="static-date-picker"
          value={diaryDate}
          onChange={(newValue) => handleChangeDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
          maxDate={get_update_time_with_time_zone(new Date())}
          minDate={
            userCreateAt
              ? userCreateAt
              : get_update_time_with_time_zone(new Date())
          }
          componentsProps={{
            actionBar: {
              actions: ["today"],
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
