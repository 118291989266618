import React, { useState } from "react";

const StarRating = ({ question, handleAnswer, question_index }) => {
  const [hoverFill, setHoverFill] = useState(null);


  const handleRating = (value) => {
    handleAnswer(question_index, value)
  }

  return (
    <div className="star">
      {[...Array(question.max)].map((_, index) => {
        const ratingValue = index + 1;

        return (
          <button
            key={index}
            onMouseEnter={() => setHoverFill(ratingValue)}
            onMouseLeave={() => setHoverFill(null)}
            onClick={() => handleRating(ratingValue)}
            style={{
              background:
                ratingValue <= (hoverFill || question.answer) ? "#B59D4E" : "transparent",
              textAlign: "center",
            }}
            className="FaStar-button"
          >
            <p
              className="FaStar mb-0"
              style={{
                color:
                  ratingValue <= (hoverFill || question.answer) ? "#ffffff" : "#B59D4E",
              }}
            >{ratingValue}</p>
          </button>
        );
      })}
    </div>
  );
};

export default StarRating;
