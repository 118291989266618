import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { warning_icon } from "src/assets";
import { s3baseUrl, websiteBaseUrl } from "src/config/config";
import { token_generate_for_upgrade_portal_api } from "src/DAL/WheelOfLife/WheelOfLifeApi";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { get_date_with_user_time_zone } from "src/utils/constants";

export default function DashboardProgramCard({ data }) {
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo, adminTimeZone } = useContentSetting();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);

  const get_update_time_with_time_zone = (date) => {
    let new_date = get_date_with_user_time_zone(
      date,
      "YYYY-MM-DD HH:mm:ss",
      userInfo.time_zone,
      adminTimeZone
    );
    return new_date;
  };

  let format_date = moment(data.program_expiry_date, "YYYY-DD-MM").format(
    "MM-DD-YYYY"
  );

  const deadline = get_update_time_with_time_zone(format_date + " 00:00:00");
  const getTime = (deadline) => {
    const time = deadline
      ? Date.parse(deadline) -
        Date.parse(
          get_update_time_with_time_zone(
            moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
          )
        )
      : "";
    setRemainingTime(time);
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  const getOneDigit = (digi) => {
    let reminder = digi % 10;
    let deviser = digi / 10;
    let decimalPart = Math.floor(deviser);
    return { reminder, decimalPart };
  };

  const handle_change_page = async (page_link) => {
    setIsLoading(true);
    let page_url = {
      page_url: page_link,
    };
    const result = await token_generate_for_upgrade_portal_api(page_url);
    if (result.code === 200) {
      window.open(`${websiteBaseUrl}auth-redirect-controller/${result.token}`);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="dashboard-notification-card">
      <div className="row align-items-center">
        <div
          className={`${
            days <= data.reminder_show_days && remainingTime > 0
              ? "col-lg-7"
              : "col-12"
          } `}
        >
          <div className="d-flex">
            <img
              src={data.warn ? s3baseUrl + data.warn : warning_icon}
              alt=""
              className="warning-icon"
            />
            <div className="ps-2 w-100">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.reminder_text_for_page,
                }}
                className="dashboard-program-description"
              ></div>
              {data.button_text && (
                <p className="text-center mt-4">
                  <button
                    onClick={() => {
                      handle_change_page(data.button_link);
                    }}
                    disabled={isLoading}
                  >
                    {data.button_text}
                  </button>
                </p>
              )}
            </div>
          </div>
        </div>
        {days <= data.reminder_show_days && remainingTime > 0 && (
          <div className="col-md-7 col-lg-5 mx-auto dashboard-notification-timer dashboard-program-timer-box">
            <>
              <div className="row justify-content-between w-100">
                <div className="col-12">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.timer_top_description,
                    }}
                  ></div>
                  <div className="timer-count d-flex justify-content-between">
                    <div className="time-count-container">
                      <div className="count">
                        {getOneDigit(days).decimalPart}
                      </div>
                    </div>
                    <div className="time-count-container">
                      <div className="count">{getOneDigit(days).reminder}</div>
                    </div>
                    <div className="colon">:</div>
                    <div className="time-count-container">
                      <div className="count">
                        {getOneDigit(hours).decimalPart}
                      </div>
                    </div>
                    <div className="time-count-container">
                      <div className="count">{getOneDigit(hours).reminder}</div>
                    </div>
                    <div className="colon">:</div>
                    <div className="time-count-container">
                      <div className="count">
                        {getOneDigit(minutes).decimalPart}
                      </div>
                    </div>
                    <div className="time-count-container">
                      <div className="count">
                        {getOneDigit(minutes).reminder}
                      </div>
                    </div>
                    <div className="colon">:</div>
                    <div className="time-count-container">
                      <div className="count">
                        {getOneDigit(seconds).decimalPart}
                      </div>
                    </div>
                    <div className="time-count-container">
                      <div className="count">
                        {getOneDigit(seconds).reminder}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between time-container-site">
                    <div className="time-detail">Days</div>
                    <div className="time-detail">Hours</div>
                    <div className="time-detail">Minutes</div>
                    <div className="time-detail">Seconds</div>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.timer_bottom_description,
                    }}
                    className="mt-3"
                  ></div>
                </div>
              </div>
            </>
          </div>
        )}
      </div>
    </div>
  );
}
