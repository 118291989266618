import * as React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { styled } from "@mui/material/styles";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { get_date_with_user_time_zone } from "src/utils/constants";

export default function MissionCalendar({
  diaryDate,
  handleChangeDate,
  userCreateAt,
}) {
  const { adminTimeZone, userInfo } = useContentSetting();

  const get_update_time_with_time_zone = (date) => {
    return get_date_with_user_time_zone(
      date,
      "MM-DD-YYYY",
      userInfo.time_zone,
      adminTimeZone
    );
  };

  const minDate = dayjs("2024-08-15");
  const maxDate = dayjs("2024-09-15");

  const grayDates = [
    dayjs("2024-08-22"),
    dayjs("2024-08-26"),
    dayjs("2024-09-03"),
    dayjs("2024-09-05"),
  ];

  const CustomDay = styled("div")(({ theme, selected, isGray, isRed }) => ({
    backgroundColor: isGray ? "red" : isRed ? "#32cd32" : undefined,
    color: selected ? theme.palette.primary.contrastText : undefined,
    borderRadius: "50%",
    width: "36px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const renderDay = (date, selectedDates, pickersDayProps) => {
    if (!dayjs(date).isValid()) {
      return <div>{date?.date ? date.date() : "?"}</div>;
    }

    const day = date.date();
    const isInRange = date.isBetween(minDate, maxDate, null, "[]");
    const isGray = grayDates.some((grayDate) => date.isSame(grayDate, "day"));
    const isRed = isInRange && !isGray;

    return (
      <CustomDay
        {...pickersDayProps}
        selected={pickersDayProps.selected}
        isGray={isGray}
        isRed={isRed}
      >
        {day}
      </CustomDay>
    );
  };

  return (
    <div className="mission-detail">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          className="static-date-picker"
          value={diaryDate ? dayjs(diaryDate) : dayjs()} // Updated
          renderDay={renderDay}
          renderInput={(params) => <TextField {...params} />}
          maxDate={dayjs(get_update_time_with_time_zone(new Date()))} // Updated
          minDate={dayjs("2024-08-15")} // Corrected
          shouldDisableDate={() => true}
          componentsProps={{
            actionBar: {
              actions: ["today"],
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
